window.SETUP = {
    debug: 0,
    version: "f243df69e4",
    mode: "standalone",
    company: {
        id: {
            production: "dc0b9a32-e90a-4a1d-b983-b93151b57037"
        },
        baseName: null,
        multiCurrency: false,
        currencyCompanies: null,
        providerID: 1837,
        analytics: {
            dev: null,
            staging: null,
            production: "G-3BLK0QR9FD"
        },
        seo: {
            useH1Tag: false,
            titleTagSeparator: " | "
        },
        currencyVirtual: false,
        currencyVirtualCode: "VM",
        player: {
            title: true,
            academicTitle: false,
            firstAndLastName: true,
            nickname: true,
            email: true,
            taxIdentifier: false,
            birthday: true,
            gender: true,
            address: true,
            postalNumber: true,
            city: true,
            country: true,
            bankAccountNumber: false,
            phoneNumber: {
                enabled: true,
                readonly: false
            },
            mobileNumber: {
                enabled: true,
                readonly: false
            },
            isPoliticallyExposedPerson: false,
            register: {
                checkboxUrls: {
                    acceptTerms: {
                        state: "Company",
                        section: "terms"
                    },
                    acceptGameTerms: {
                        state: "Company",
                        section: "game-terms"
                    },
                    confirmPoliticalInactivity: {
                        state: "Company",
                        section: "political-inactivity"
                    },
                    allowMarketing: {
                        state: "Company",
                        section: "marketing-terms"
                    },
                    isPoliticallyExposedPerson: {
                        state: "Company",
                        section: "politically-exposed"
                    }
                }
            },
            verifyIdentity: {
                fileUpload: {
                    minLimit: 1,
                    maxLimit: 3
                },
                description: false
            },
            bankAccounts: {
                fileUpload: {
                    minLimit: 1,
                    maxLimit: 3
                }
            }
        },
        country: {
            code: "BA"
        },
        forms: {
            payment: {
                deposit: {
                    bankWire: null
                }
            },
            register: {
                country: {
                    visible: true
                },
                timezone: {
                    visible: true
                }
            }
        },
        legal: {
            age: 18
        },
        loginStrategy: "email",
        name: "sigma",
        nativeApps: [
            "Android"
        ]
    },
    api: {
        url: {
            production: "https://seven-casino.gb.nsoftcdn.com/v1"
        },
        gravity: {
            url: {
                production: "https://gravity-2.de.nsoftcdn.com/v1"
            }
        },
        ngs: {
            url: {
                production: "https://games.gb.nsoftcdn.com/api_open"
            },
            ajs: {
                production: "https://games.gb.nsoftcdn.com/"
            },
            gcm: {
                production: [
                    "https://gcm-1.de.nsoftcdn.com:8443",
                    "https://gcm-2.de.nsoftcdn.com:8443",
                    "https://gcm-3.de.nsoftcdn.com:8443"
                ]
            }
        },
        loyalty: {
            url: {
                production: "https://seven-loyalty.de.nsoftcdn.com"
            }
        },
        payten: {
            url: {
                production: "https://api.a8r-payment-plugin-payten.nsoft.cloud"
            }
        },
        mtn: {
            url: {
                production: "https://int-mtn.de-3.nsoft.cloud"
            }
        },
        slap: {
            url: {
                production: "https://seven-integrator.lutrijabih.ba"
            }
        },
        bild: {
            url: {
                dev: null,
                production: "https://www.w-ncorporation.com"
            }
        },
        mts: {
            url: {
                production: "https://int-mts.ubercluster.nsoft.cloud"
            }
        },
        moncash: {
            url: {
                production: "https://int-moncash.de-3.nsoft.cloud"
            }
        },
        ips: {
            url: {
                production: "https://int-ips.de-3.nsoft.cloud"
            }
        },
        ipaymastercard: {
            url: {
                production: "https://int-ipay-mastercard.de-3.nsoft.cloud"
            }
        },
        orangewebpay: {
            url: {
                production: "https://int-orange.de-3.nsoft.cloud"
            }
        },
        adumo: {
            url: {
                production: "https://int-adumo.de-3.nsoft.cloud"
            }
        },
        paytenCse: {
            url: {
                dev: "https://int-payten-cse.staging.de-3.nsoft.cloud",
                staging: "https://int-payten-cse.staging.de-3.nsoft.cloud",
                production: "https://int-payten-cse.de-3.nsoft.cloud"
            }
        },
        mpesa: {
            url: {
                production: "https://int-m-pesa.de-3.nsoft.cloud"
            }
        },
        monri: {
            url: {
                dev: "https://int-payment-plugin-monri.staging.de-2.nsoft.cloud",
                staging: "https://int-payment-plugin-monri.staging.de-2.nsoft.cloud",
                production: "https://int-payment-plugin-monri.de-2.nsoft.cloud"
            },
            scriptUrl: {
                url: {
                    dev: "https://ipgtest.monri.com/dist/lightbox.js",
                    staging: "https://ipgtest.monri.com/dist/lightbox.js",
                    production: "https://ipg.monri.com/dist/lightbox.js"
                }
            }
        },
        vcash: {
            url: {
                dev: "https://int-vcash.staging.de-2.nsoft.cloud",
                staging: "https://int-vcash.staging.de-2.nsoft.cloud",
                production: "https://int-vcash.de-2.nsoft.cloud"
            }
        },
        payspot: {
            url: {
                dev: "https://int-payspot.staging.de-2.nsoft.cloud",
                staging: "https://int-payspot.staging.de-2.nsoft.cloud",
                production: "https://int-payspot.de-3.nsoft.cloud"
            }
        },
        xbon: {
            url: {
                dev: "https://int-xbon.staging.de-2.nsoft.cloud",
                staging: "https://int-xbon.staging.de-2.nsoft.cloud",
                production: "https://int-xbon.de-2.nsoft.cloud"
            }
        },
        kliker: {
            url: {
                dev: "https://int-kliker.staging.de-2.nsoft.cloud",
                staging: "https://int-kliker.staging.de-2.nsoft.cloud",
                production: "https://int-kliker.de-2.nsoft.cloud"
            }
        },
        blinking: {
            url: {
                dev: "https://imo-blinking.staging.de-2.nsoft.cloud",
                staging: "https://imo-blinking.staging.de-2.nsoft.cloud",
                production: "https://imo-blinking.de-2.nsoft.cloud"
            }
        },
        chapa: {
            url: {
                dev: "https://int-chapa.staging.de-2.nsoft.cloud",
                staging: "https://int-chapa.staging.de-2.nsoft.cloud",
                production: "https://int-chapa.de-2.nsoft.cloud"
            }
        },
        yoUganda: {
            url: {
                dev: "https://imo-payment-plugin-yo-uganda.staging.de-2.nsoft.cloud",
                staging: "https://imo-payment-plugin-yo-uganda.staging.de-2.nsoft.cloud",
                production: "https://imo-payment-plugin-yo-uganda.de-2.nsoft.cloud"
            }
        },
        playerTransfer: {
            url: {
                dev: "https://int-player-transfer.staging.de-2.nsoft.cloud",
                staging: "https://int-player-transfer.staging.de-2.nsoft.cloud",
                production: "https://int-player-transfer.de-2.nsoft.cloud"
            }
        },
        ssbt: {
            url: {
                dev: "https://int-merkur-casino-ssbt.staging.de-2.nsoft.cloud",
                staging: "https://int-merkur-casino-ssbt.staging.de-2.nsoft.cloud",
                production: "https://int-merkur-casino-ssbt.de-2.nsoft.cloud"
            }
        },
        mobileRegistration: {
            url: {
                dev: "https://int-mobile-number-registration.staging.de-2.nsoft.cloud",
                staging: "https://int-mobile-number-registration.staging.de-2.nsoft.cloud",
                production: "https://int-mobile-number-registration.de-2.nsoft.cloud"
            }
        },
        novabanka: {
            url: {
                dev: "https://imo-nova-banka.staging.de-2.nsoft.cloud",
                staging: "https://imo-nova-banka.staging.de-2.nsoft.cloud",
                production: "https://imo-nova-banka.de-2.nsoft.cloud"
            }
        },
        avoucher: {
            url: {
                dev: "https://int-avoucher.staging.de-2.nsoft.cloud",
                staging: "https://int-avoucher.staging.de-2.nsoft.cloud",
                production: "https://int-avoucher.de-2.nsoft.cloud"
            }
        },
        assets: {
            url: {
                production: "https://svncms-cdn.s3.eu-central-1.amazonaws.com/assets/cms/production"
            }
        },
        events: {
            scroll: true
        },
        listeners: {
            updateBalance: false,
            terminal: {}
        },
        cms: {
            enabled: true
        },
        maps: {
            url: "https://maps.googleapis.com/maps/api/js?language={language}&key={key}&libraries=places,marker&callback=initComponent&loading=async",
            key: "AIzaSyCBnzD21VrgP1bpcn-2bIKvn0Vq3dcewfA",
            mapId: "490256ae0bb0abef",
            style: {
                pin: {
                    background: "#1565c0",
                    borderColor: "#1565c0"
                },
                cluster: {
                    overMeanBackground: "#1565c0",
                    overMeanGlyphColor: "#000000",
                    underMeanBackground: "#0e4e98",
                    underMeanGlyphColor: "#ffffff"
                }
            },
            plugins: {
                markerClusterer: {
                    url: "https://unpkg.com/@googlemaps/markerclusterer@2.5.3/dist/index.min.js"
                }
            }
        },
        balkanBetLoyalty: {
            url: {
                production: "https://int-future-gaming-cm.de-3.nsoft.cloud"
            }
        },
        vaixTracker: {
            url: {
                production: "https://api.vaix.ai/api/tracker/events"
            }
        }
    },
    analytics: true,
    banners: {
        enabled: true,
        disableMobileModalBanner: false
    },
    meta: {
        description: {
            name: "description",
            content: "Seven Web Channel"
        }
    },
    footer: {
        poweredByLogo: false,
        cardsImage: "default"
    },
    retailApi: {
        production: "https://games.ro.nsoftcdn.com"
    },
    notifications: true,
    notificationsPusher: {
        enabled: false,
        config: {
            dev: {
                app: {
                    key: "50fcb89e349e79484238",
                    cluster: "eu"
                }
            },
            staging: {
                app: {
                    key: "c36fb7f44d66246276fe",
                    cluster: "eu"
                }
            },
            production: {
                app: {
                    key: "cfcd3117b0f46871498c",
                    cluster: "eu"
                }
            }
        }
    },
    loaders: true,
    login: true,
    language: "bs",
    theme: "sigma",
    useCustomImages: false,
    html5: true,
    gcm: true,
    displayRulesLink: false,
    font: "Roboto+Condensed:700|Roboto:300,400,700,900&amp;subset=latin-ext",
    platform: {
        name: "SEVEN",
        api: {
            production: "https://menhir.gb.nsoftcdn.com"
        },
        delivery: "Web",
        clientType: "user",
        clientSubType: "Player",
        authType: "jwt",
        encoding: {
            default: "plaintext"
        }
    },
    bonusAPI: {
        url: {
            production: "https://seven-bonus.gb.nsoftcdn.com"
        }
    },
    sportbookAPI: {
        enabled: true,
        url: {
            production: "https://sports-mts-api.gb.nsoftcdn.com"
        }
    },
    sportbookCashoutAPI: {
        enabled: true,
        url: {
            production: "https://sports-mts-cashout.nsoft.com"
        },
        socketPath: "/client/distribution/protocol/sio2"
    },
    integrationsAPI: {
        enabled: true,
        url: {
            production: "https://int-oddin-prva-firma.staging.de-3.nsoft.cloud"
        }
    },
    assetsBase: "https://assets.nsoft-cdn.com/public/",
    betslip: {
        global: {
            rounding: {
                decimals: 2
            },
            attachTicketPrint: false
        },
        LiveBetting: {
            ticketType: "sport",
            ticketPrintTemplateHook: "getSportPrintTemplate",
            future: false,
            totalOdds: true,
            bonus: true,
            odds: true,
            winnings: true,
            cancellable: false,
            stakePerSystem: false,
            allowCombiningESports: true,
            tickets: [
                {
                    name: "Single",
                    winningStrategy: "sevenSportSingleWinnings",
                    setStakeStrategy: "sevenSportSingleSetStake",
                    type: "3",
                    title: "ticketSingle",
                    priority: 1,
                    fix: false,
                    stake: true,
                    enabled: true
                },
                {
                    name: "Combo",
                    winningStrategy: "sevenSportComboWinnings",
                    type: "1",
                    title: "ticketCombo",
                    priority: 2,
                    fix: false,
                    stake: false,
                    active: true,
                    enabled: true
                },
                {
                    name: "System",
                    winningStrategy: "sevenSportSystemWinnings",
                    type: "2",
                    system: true,
                    title: "ticketSystem",
                    priority: 3,
                    fix: true,
                    stake: false,
                    enabled: true
                }
            ]
        },
        PreMatchBetting: {
            ticketType: "sport",
            ticketPrintTemplateHook: "getSportPrintTemplate",
            future: false,
            totalOdds: true,
            bonus: true,
            odds: true,
            winnings: true,
            stakePerSystem: false,
            tickets: [
                {
                    name: "Single",
                    winningStrategy: "sevenSportSingleWinnings",
                    setStakeStrategy: "sevenSportSingleSetStake",
                    type: "3",
                    title: "ticketSingle",
                    priority: 1,
                    fix: false,
                    stake: true,
                    enabled: true
                },
                {
                    name: "Combo",
                    winningStrategy: "sevenSportComboWinnings",
                    type: "1",
                    title: "ticketCombo",
                    priority: 2,
                    fix: false,
                    stake: false,
                    active: true,
                    enabled: true
                },
                {
                    name: "System",
                    winningStrategy: "sevenSportSystemWinnings",
                    type: "2",
                    system: true,
                    title: "ticketSystem",
                    priority: 3,
                    fix: true,
                    stake: false,
                    enabled: true
                }
            ]
        },
        GreyhoundRaces: {
            ticketType: "games",
            ticketSubType: "races",
            ticketPrintTemplateHook: "getRacesPrintTemplate",
            editable: false,
            future: false,
            totalOdds: false,
            bonus: false,
            odds: true,
            winnings: true,
            cancellable: true,
            tickets: [
                {
                    name: "Single",
                    setStakeStrategy: "sevenVirtualSetStake",
                    winningStrategy: "sevenRaceSingleWinnings",
                    type: "3",
                    title: "ticketSingle",
                    priority: 1,
                    fix: false,
                    stake: true,
                    active: true,
                    enabled: true
                }
            ]
        },
        VirtualGreyhoundRaces: {
            ticketType: "games",
            ticketSubType: "races",
            ticketPrintTemplateHook: "getRacesPrintTemplate",
            editable: false,
            future: false,
            totalOdds: false,
            bonus: false,
            odds: true,
            winnings: true,
            cancellable: true,
            tickets: [
                {
                    name: "Single",
                    setStakeStrategy: "sevenVirtualSetStake",
                    winningStrategy: "sevenRaceSingleWinnings",
                    type: "3",
                    title: "ticketSingle",
                    priority: 1,
                    fix: false,
                    stake: true,
                    active: true,
                    enabled: true
                }
            ]
        },
        VirtualHorseRaces: {
            ticketType: "games",
            ticketSubType: "races",
            ticketPrintTemplateHook: "getRacesPrintTemplate",
            editable: false,
            future: false,
            totalOdds: false,
            bonus: false,
            odds: true,
            winnings: true,
            cancellable: true,
            tickets: [
                {
                    name: "Single",
                    setStakeStrategy: "sevenVirtualSetStake",
                    winningStrategy: "sevenRaceSingleWinnings",
                    type: "3",
                    title: "ticketSingle",
                    priority: 1,
                    fix: false,
                    stake: true,
                    active: true,
                    enabled: true
                }
            ]
        },
        VirtualMotorcycleSpeedway: {
            ticketType: "games",
            ticketSubType: "races",
            ticketPrintTemplateHook: "getRacesPrintTemplate",
            editable: false,
            totalOdds: false,
            bonus: false,
            odds: true,
            winnings: true,
            cancellable: true,
            tickets: [
                {
                    name: "Single",
                    setStakeStrategy: "sevenVirtualSetStake",
                    winningStrategy: "sevenRaceSingleWinnings",
                    type: "3",
                    title: "ticketSingle",
                    priority: 1,
                    fix: false,
                    stake: true,
                    active: true,
                    enabled: true
                }
            ]
        },
        VirtualPenaltyShootout: {
            ticketType: "games",
            ticketSubType: "races",
            editable: false,
            totalOdds: false,
            bonus: false,
            odds: true,
            winnings: true,
            cancellable: true,
            rebet: true,
            tickets: [
                {
                    name: "Single",
                    setStakeStrategy: "sevenVirtualSetStake",
                    winningStrategy: "sevenRaceSingleWinnings",
                    type: "3",
                    title: "ticketSingle",
                    priority: 1,
                    fix: false,
                    stake: true,
                    active: true,
                    enabled: true
                }
            ]
        },
        LuckySix: {
            ticketType: "games",
            ticketSubType: "bingo",
            ticketPrintTemplateHook: "getBingoPrintTemplate",
            editable: true,
            totalOdds: false,
            bonus: false,
            odds: false,
            winnings: false,
            cancellable: true,
            rebet: true,
            tickets: [
                {
                    name: "Single",
                    setStakeStrategy: "sevenVirtualSetStake",
                    type: "3",
                    title: "ticketSingle",
                    priority: 1,
                    fix: false,
                    stake: true,
                    active: true,
                    enabled: true
                }
            ]
        },
        LuckyX: {
            ticketType: "games",
            ticketSubType: "bingo",
            ticketPrintTemplateHook: "getBingoPrintTemplate",
            editable: true,
            totalOdds: false,
            bonus: false,
            odds: false,
            winnings: false,
            cancellable: true,
            rebet: true,
            tickets: [
                {
                    name: "Single",
                    setStakeStrategy: "sevenVirtualSetStake",
                    type: "3",
                    title: "ticketSingle",
                    priority: 1,
                    fix: false,
                    stake: true,
                    active: true,
                    enabled: true
                }
            ]
        },
        NextSix: {
            ticketType: "games",
            ticketSubType: "bingo",
            ticketPrintTemplateHook: "getNextSixPrintTemplate",
            editable: true,
            totalOdds: false,
            bonus: false,
            odds: false,
            winnings: false,
            cancellable: false,
            rebet: true,
            tickets: [
                {
                    name: "Single",
                    setStakeStrategy: "sevenVirtualSetStake",
                    type: "3",
                    title: "ticketSingle",
                    priority: 1,
                    fix: false,
                    stake: true,
                    active: true,
                    enabled: true
                }
            ]
        },
        SlotCarRaces: {
            ticketType: "games",
            ticketSubType: "races",
            editable: false,
            totalOdds: false,
            bonus: false,
            odds: true,
            winnings: true,
            cancellable: true,
            tickets: [
                {
                    name: "Single",
                    setStakeStrategy: "sevenVirtualSetStake",
                    winningStrategy: "sevenRaceSingleWinnings",
                    type: "3",
                    title: "ticketSingle",
                    priority: 1,
                    fix: false,
                    stake: true,
                    active: true,
                    enabled: true
                }
            ]
        },
        Keno: {
            ticketType: "games",
            ticketSubType: "bingo",
            editable: true,
            totalOdds: false,
            bonus: false,
            odds: false,
            winnings: false,
            cancellable: false,
            rebet: true,
            tickets: [
                {
                    name: "Single",
                    setStakeStrategy: "sevenVirtualSetStake",
                    type: "3",
                    title: "ticketSingle",
                    priority: 1,
                    fix: false,
                    stake: true,
                    active: true,
                    enabled: true
                }
            ]
        }
    },
    gateway: {
        dev: {
            allowedOrigins: [
                "http://localhost:9000",
                "http://localhost:8080",
                "http://localhost:8000",
                "https://lotto-client.7platform.net",
                "https://staging.virtual-penalty-shootout.7platform.net",
                "https://dev-sb-sm-prematch-client.nsoft.com",
                "https://dev.numbers-betting-web.7platform.net",
                "https://sb-sm-prematch-client.nsoft.com",
                "https://sp-staging.nsoft.com",
                "https://dev.smprematch.7platform.net",
                "https://dev-firmazadev.smprematch.7platform.net",
                "https://dev.roulette-client.7platform.net"
            ],
            plugins: {
                smPrematch: {
                    name: "PluginSMGatewayPrematch",
                    frameId: "plugin-sm-prematch",
                    autoResize: false,
                    settings: {}
                },
                gatewayVFL2Product: {
                    name: "PluginGatewayVFL2Product",
                    frameId: "plugin-vfl2-gateway-product",
                    autoResize: true,
                    settings: {},
                    config: {
                        source: {
                            host: "https://sp-staging.nsoft.com"
                        }
                    }
                },
                numbersBetting: {
                    name: "PluginNumbersBetting",
                    frameId: "plugin-numbers-betting",
                    autoResize: true,
                    settings: {}
                },
                gatewayVPS: {
                    name: "PluginVPS",
                    frameId: "plugin-vps",
                    autoResize: true,
                    settings: {}
                },
                roulette: {
                    name: "Roulette",
                    frameId: "plugin-roulette",
                    autoResize: true,
                    settings: {},
                    config: {
                        source: {
                            host: "https://dev.roulette-client.7platform.net"
                        }
                    }
                },
                nabLotto: {
                    name: "PluginNabLotto",
                    frameId: "plugin-nablotto",
                    autoResize: true,
                    settings: {},
                    config: {
                        source: {
                            host: "https://lotto-client.7platform.net"
                        }
                    }
                }
            }
        },
        staging: {
            allowedOrigins: [
                "http://localhost:8080",
                "http://localhost:9000",
                "http://localhost:8000",
                "https://lotto-client.7platform.net",
                "https://staging.virtual-penalty-shootout.7platform.net",
                "https://dev-sb-sm-prematch-client.nsoft.com",
                "https://staging.numbers-betting-web.7platform.net",
                "https://sb-sm-prematch-client.nsoft.com",
                "https://sp-staging.nsoft.com",
                "https://staging-prvafirma.smprematch.7platform.net",
                "https://staging-balkanbet.smprematch.7platform.net",
                "https://staging.smprematch.7platform.net",
                "https://dev.roulette-client.7platform.net"
            ],
            plugins: {
                smPrematch: {
                    name: "PluginSMGatewayPrematch",
                    frameId: "plugin-sm-prematch",
                    autoResize: true,
                    settings: {}
                },
                gatewayVFL2Product: {
                    name: "PluginGatewayVFL2Product",
                    frameId: "plugin-vfl2-gateway-product",
                    autoResize: true,
                    settings: {},
                    config: {
                        source: {
                            host: "https://sp-staging.nsoft.com"
                        }
                    }
                },
                numbersBetting: {
                    name: "PluginNumbersBetting",
                    frameId: "plugin-numbers-betting",
                    autoResize: true,
                    settings: {},
                    config: {
                        source: {
                            host: "https://staging.numbers-betting-web.7platform.net"
                        }
                    }
                },
                gatewayVPS: {
                    name: "PluginVPS",
                    frameId: "plugin-vps",
                    autoResize: true,
                    settings: {}
                },
                roulette: {
                    name: "Roulette",
                    frameId: "plugin-roulette",
                    autoResize: true,
                    settings: {},
                    config: {
                        source: {
                            host: "https://dev.roulette-client.7platform.net"
                        }
                    }
                },
                virtualTennisInPlay: {
                    name: "VirtualTennisInPlay",
                    frameId: "plugin-virtual-tennis-in-play",
                    autoResize: true,
                    settings: {},
                    config: {
                        source: {
                            host: "http://localhost:8080"
                        }
                    }
                },
                nabLotto: {
                    name: "PluginNabLotto",
                    frameId: "plugin-nablotto",
                    autoResize: true,
                    settings: {},
                    config: {
                        source: {
                            host: "https://lotto-client.7platform.net"
                        }
                    }
                }
            }
        },
        production: {
            allowedOrigins: [
                "http://localhost:9000",
                "https://dev-sb-sm-prematch-client.nsoft.com",
                "https://virtual-penalty-shootout.7platform.net",
                "https://vps-v1--games-virtual-penalty-shootout.netlify.app",
                "https://sb-sm-prematch-client.nsoft.com",
                "https://sp-production.nsoft.com",
                "https://numbers-betting-web.7platform.net",
                "https://smprematch.7platform.net",
                "https://balkanbet.smprematch.7platform.net",
                "https://sports-smprematch.netlify.com",
                "https://topbet.smprematch.7platform.net",
                "https://roulette-client.7platform.net"
            ],
            plugins: {
                smPrematch: {
                    name: "PluginSMGatewayPrematch",
                    frameId: "plugin-sm-prematch",
                    autoResize: true,
                    settings: {}
                },
                gatewayVFL2Product: {
                    name: "PluginGatewayVFL2Product",
                    frameId: "plugin-vfl2-gateway-product",
                    autoResize: true,
                    settings: {},
                    config: {
                        source: {
                            host: "https://sp-production.nsoft.com"
                        }
                    }
                },
                gatewayVPS: {
                    name: "PluginVPS",
                    frameId: "plugin-vps",
                    autoResize: true,
                    settings: {}
                },
                numbersBetting: {
                    name: "PluginNumbersBetting",
                    frameId: "plugin-numbers-betting",
                    autoResize: true,
                    settings: {},
                    config: {
                        source: {
                            host: "https://numbers-betting-web.7platform.net"
                        }
                    }
                },
                roulette: {
                    name: "Roulette",
                    frameId: "plugin-roulette",
                    autoResize: true,
                    settings: {},
                    config: {
                        source: {
                            host: "https://roulette-client.7platform.net"
                        }
                    }
                },
                liveBettingV2: {
                    name: "PluginLiveBettingV2",
                    frameId: "plugin-live-betting-v2",
                    autoResize: true,
                    settings: {}
                },
                virtualTennisInPlay: {
                    name: "VirtualTennisInPlay",
                    frameId: "plugin-virtual-tennis-in-play",
                    autoResize: true,
                    settings: {},
                    config: {
                        source: {
                            host: "http://localhost:8080"
                        }
                    }
                }
            }
        }
    },
    config: {
        client: {
            label: "Sigma",
            contact: {
                copyright: "Sigma",
                support: {
                    title: "",
                    email: {
                        icon: "envelope",
                        name: "",
                        value: "info@sigmasupersport.com"
                    }
                },
                social: {
                    facebook: {
                        url: "https://www.facebook.com/sigmasupersport"
                    },
                    instagram: {
                        url: "https://www.instagram.com/sigmasupersport123"
                    },
                    twitter: {
                        url: false
                    },
                    googleplus: {
                        url: false
                    },
                    youtube: {
                        url: false
                    }
                }
            },
            apps: {
                android: {
                    name: "android",
                    url: "#android",
                    target: "_self"
                }
            }
        },
        productCategories: {
            prematch: {
                active: true
            },
            live: {
                active: true
            },
            games: {
                active: true
            },
            casino: {
                active: false
            },
            casinoLive: {
                active: false
            },
            poolbet: {
                active: false
            }
        },
        products: {
            luckySix: {
                active: true,
                version: 6,
                plugin: true,
                ticketCheck: true
            },
            luckyX: {
                active: true,
                ticketCheck: true
            },
            greyhoundRaces: {
                active: true,
                schedule: true,
                ticketCheck: true
            },
            virtualGreyhoundRaces: {
                active: true,
                ticketCheck: true
            },
            virtualHorseRaces: {
                active: true,
                ticketCheck: true
            },
            virtualDragRaces: {
                active: false
            },
            virtualMotorcycleSpeedway: {
                active: true,
                ticketCheck: true
            },
            slotCarRaces: {
                active: true
            },
            nextSix: {
                active: true
            },
            preMatchBetting: {
                active: true
            },
            liveBetting: {
                active: true
            },
            goalFlash: {
                active: false
            }
        }
    },
    externalUrls: {},
    facebookBusinessManagerVerification: null,
    canonical: "https://www.sigmasupersport.com",
    cloudflare: {},
    remoteConfig: true,
    server: "production",
    releaseVersion: "1.119.15"
};
// Prototype version extender
String.prototype.version = function (version, force) {
    // Check if version was manually added
    if (version && !this.match(/\?|&v=/)) {
        // Only relative url's
        if (force || this.indexOf('://') < 0) {
            // Add version from setup
            return this + (this.indexOf('?') < 0 ? '?' : '&') + 'v=' + version;
        }
    }

    // Return url
    return this;
};

// JQuery plugins
(function () {
    // Animate rotate and scale
    $.fn.animateWiggle = function (
        angleStart,
        angleEnd,
        scaleStart,
        scaleEnd,
        duration,
        easing,
        complete
    ) {
        // Define arguments and step
        var args = $.speed(duration, easing, complete);
        var step = args.step;

        // Loop element selection
        return this.each(function (i, e) {
            // Define animating properties
            var scaleValue, angleValue;

            // Set custom animation using arguments
            args.complete = $.proxy(args.complete, e);
            args.step = function (now, fx) {
                // Check property
                if (fx.prop === 'scale') {
                    scaleValue = now;
                } else {
                    angleValue = now;
                }

                // Set transform style
                $.style(e, 'transform', 'scale(' + scaleValue + ') rotate(' + angleValue + 'deg)');
                $.style(
                    e,
                    '-webkit-transform',
                    'scale(' + scaleValue + ') rotate(' + angleValue + 'deg)'
                );

                // Check step
                if (step) {
                    // Apply step with arguments
                    return step.apply(e, arguments);
                }
            };

            // Animate
            $({ angle: angleStart, scale: scaleStart }).animate(
                {
                    angle: angleEnd,
                    scale: scaleEnd,
                },
                args
            );
        });
    };

    // Animate scale
    $.fn.animateScale = function (start, end, duration, easing, complete) {
        // Define arguments and step
        var args = $.speed(duration, easing, complete);
        var step = args.step;

        // Loop element selection
        return this.each(function (i, e) {
            // Define animating properties
            var scaleValue;

            // Set custom animation using arguments
            args.complete = $.proxy(args.complete, e);
            args.step = function (now) {
                // Check property
                scaleValue = now;

                // Set transform style
                $.style(e, 'transform', 'scale(' + scaleValue + ')');
                $.style(e, '-webkit-transform', 'scale(' + scaleValue + ')');

                // Check step
                if (step) {
                    // Apply step with arguments
                    return step.apply(e, arguments);
                }
            };

            // Animate
            $({ scale: start }).animate(
                {
                    scale: end,
                },
                args
            );
        });
    };

    // Automatic font sizing
    $.fn.flowtype = function (options) {
        // Default settings
        var settings = $.extend(
                {
                    maximum: 9999,
                    minimum: 1,
                    maxFont: 9999,
                    minFont: 1,
                    fontRatio: 35,
                },
                options
            ),
            // Calculate
            changes = function (el) {
                var $el = $(el),
                    elw = $el.width(),
                    width =
                        elw > settings.maximum
                            ? settings.maximum
                            : elw < settings.minimum
                            ? settings.minimum
                            : elw,
                    fontBase = width / settings.fontRatio,
                    fontSize =
                        fontBase > settings.maxFont
                            ? settings.maxFont
                            : fontBase < settings.minFont
                            ? settings.minFont
                            : fontBase;
                $el.css('font-size', fontSize + 'px');
            };

        // Apply
        return this.each(function () {
            // Context for resize callback
            var that = this;
            // Make changes upon resize
            $(window).resize(function () {
                changes(that);
            });
            // Set changes on load
            changes(this);
        });
    };
})();

// Add global variables
window.SEVENGlobals = {};

var CRAWLER_PATTERN = /Google|bot|crawl|spider|Prerender|HeadlessChrome/;
var userAgent = window.navigator.userAgent;
var isCrawler = CRAWLER_PATTERN.test(userAgent);

var localHostRegex = /.*:[ 0-9]*/g;
var isLocalDevelopment = !!location.host.match(localHostRegex);
var shouldEnableSentry = SETUP.isErrorTrackerActive && !isLocalDevelopment && !isCrawler;
window.SEVENGlobals.isSentryActive = shouldEnableSentry;

var dependencies = [
    'ngSanitize',
    'ui.router',
    'oc.lazyLoad',
    'pusher-angular',
    'btford.socket-io',
    'angular-locker',
    'angular-uuid',
    'angularFileUpload',
    'nabTicket',
    'ngCookies',
];

if (shouldEnableSentry) {
    Sentry.init({
        dsn: 'https://ce18d11a0cc741df9e82075199e94b2a@o73276.ingest.sentry.io/157770',
        integrations: [
            new Sentry.Integrations.Angular(),
            new Sentry.Integrations.GlobalHandlers({
                onunhandledrejection: false,
            }),
        ],
        attachStacktrace: true,
        normalizeDepth: 5,
        release: '7Web-' + 'v' + SETUP.releaseVersion,
        environment: SETUP.server,
        initialScope: {
            tags: {
                git_commit: SETUP.version,
            },
        },
        whitelistUrls: [new RegExp(window.location.host)],
        ignoreErrors: [
            'top.GLOBALS',
            /a.o.postMessage/i,
            /already in progress/i,
            /reconstructing a packet/i,
            /"status":-1/i,
            // All of those originate from Google Translate on iOS
            // TODO: Check can origin of those errors be reliably detected and filtered in beforeSend by origin without need to list every error each time Google Translate build changes.
            /undefined is not an object \(evaluating 'a\.N'\)/i,
            /undefined is not an object \(evaluating 'a\.M'\)/i,
            /undefined is not an object \(evaluating 'a\.L'\)/i,
            /ibFindAllVideos/i,
            /Unexpected identifier 'https'/i,
            /A network error occurred/i,
            /Unexpected token 'else'/i,
            /Cannot redefine property: websredir/i,
            /Method not found/i,
            /Cannot read properties of null \(reading 'removeAttribute'\)/i,
            /Unexpected token '([;<:])'/i,
            /Java/,
            /"undefined" is not valid JSON/i,
            /Unexpected end of input/i,
            /Cannot set properties of undefined \(setting 'controlsList'\)/i,
            /undefined is not an object \(evaluating 'document\.getElementsByClassName\('n-header'\)\[0]\.style'\)/i,
            /\[\$templateRequest:tpload].*\(HTTP status: -1 \)/i,
            /Can't find variable: (_AutofillCallbackHandler|gmo)/i,
            /(tgetT|googletag|getPercent|\$|_pageTimings|removeNightMode|onNetworkDetectHttpProbeResult) is not defined/i,
            /Failed to execute 'querySelectorAll' on 'Document': ':is\(\[id\*='gpt-']\)' is not a valid selector\./,
            /Android\.receiveMessage is not a function/i,
        ],
        denyUrls: [
            /extensions\//i,
            /^chrome:\/\//i,
            /gpt\/pubad/,
            /addonsmash\//,
            /tpc.googlesyndication.com/,
            /\/\.netlify/,
        ],
        beforeBreadcrumb: function (breadcrumb) {
            if (breadcrumb.category === 'xhr' && typeof breadcrumb.data.url === 'object') {
                breadcrumb.data.url = breadcrumb.data.url.valueOf();
            }
            return breadcrumb;
        },
    });

    dependencies.push('ngSentry');
}

setupServiceWorker();

// Main application module
// eslint-disable-next-line
var SEVEN = angular.module('SEVEN', dependencies);

angular.element(document).ready(function () {
    var injector = angular.injector(['ng', 'angular-locker', 'angular-uuid']),
        $q = injector.get('$q'),
        $http = injector.get('$http'),
        $log = injector.get('$log'),
        $timeout = injector.get('$timeout'),
        uuid = injector.get('uuid'),
        checkLanguageSwitch,
        checkInspectSwitch,
        checkLayoutSwitch,
        checkNativeModeSwitch,
        getQueryParam,
        setMultiCurrency,
        getDefaultCurrencyCompany,
        loadBootstrapFiles,
        loadSettings,
        loadPlugins,
        loadLocales,
        configureSirWidgetPlugin,
        createResources,
        init,
        RETRY_LIMIT = 3;

    init = function () {
        var settings,
            resources,
            dataConfig, // Merged config
            dataModules, // Merged modules
            languageShort; // Short language code

        if (SETUP.company.multiCurrency) {
            setMultiCurrency();
        }

        checkForSafariForwardCaching();
        checkLayoutSwitch();
        checkInspectSwitch();
        checkNativeModeSwitch();

        loadLocales().then(function (locales) {
            var setupLanguage = checkLanguageSwitch(locales);

            loadBootstrapFiles()
                .then(function (responses) {
                    var currency = {};

                    // Extend config and modules
                    var appSettings = responses[1].data.appSettings;

                    dataConfig = angular.merge(responses[0].data, responses[1].data);

                    if (SETUP.remoteConfig) {
                        // Merge remote modules
                        dataModules = angular.merge(responses[2].data, appSettings.modules);
                        // Merge remote company definition
                        angular.merge(SETUP, appSettings.config);
                        // Merge rewrites SETUP.language with appSettings.config.language which
                        // is not the real language app is in, so return it to the previous lang
                        SETUP.language = setupLanguage;

                        if (SETUP.company.multiCurrency) {
                            setMultiCurrency();
                        }
                    } else {
                        // Merge local modules
                        dataModules = angular.merge(responses[2].data, responses[3].data);
                    }
                    // Merge company specific config from build
                    angular.merge(dataConfig, SETUP.config);

                    settings = loadSettings();
                    settings.thirdPartyValues = { clientVal: getQueryParam('clientVal') };

                    // Transform messages
                    dataConfig.messages = (function () {
                        // Define new object
                        var keys = {},
                            key,
                            newkey,
                            namespace;
                        // Snake to uppercase with namespace
                        for (key in dataConfig.messages) {
                            namespace = key.substring(0, key.indexOf('_'));
                            newkey = key.substring(key.indexOf('_') + 1);
                            if (!keys[namespace]) keys[namespace] = {};
                            keys[namespace][
                                newkey.replace(/(_[a-zA-Z])/g, function (n) {
                                    return n[1].toUpperCase();
                                })
                            ] = dataConfig.messages[key];
                        }
                        // Return transformed
                        return keys;
                    })();

                    // Remove extra versions and localize products
                    angular.forEach(dataConfig.products, function (product) {
                        if (product.title)
                            product.title = product.title.localize(dataConfig.messages);
                        if (product.versions) {
                            cleanVersions(product);
                        }
                    });

                    angular.forEach(dataConfig.productCategories, function (category) {
                        if (category.title)
                            category.title = category.title.localize(dataConfig.messages);
                    });

                    // Set base api url
                    dataConfig.apiBase = settings.platform.api[settings.server];
                    settings.api.gravity.url = settings.api.gravity.url[settings.server];
                    settings.api.ngs.url = settings.api.ngs.url[settings.server];
                    settings.api.payten.url = settings.api.payten.url[settings.server];
                    settings.api.bild.url = settings.api.bild.url[settings.server];
                    settings.api.mtn.url = settings.api.mtn.url[settings.server];
                    settings.api.moncash.url = settings.api.moncash.url[settings.server];
                    settings.api.ipaymastercard.url =
                        settings.api.ipaymastercard.url[settings.server];
                    settings.api.ips.url = settings.api.ips.url[settings.server];
                    settings.api.orangewebpay.url = settings.api.orangewebpay.url[settings.server];
                    settings.api.adumo.url = settings.api.adumo.url[settings.server];
                    settings.api.paytenCse.url = settings.api.paytenCse.url[settings.server];
                    settings.api.mpesa.url = settings.api.mpesa.url[settings.server];
                    settings.api.monri.url = settings.api.monri.url[settings.server];
                    settings.api.monri.scriptUrl.url =
                        settings.api.monri.scriptUrl.url[settings.server];
                    settings.api.payspot.url = settings.api.payspot.url[settings.server];
                    settings.api.xbon.url = settings.api.xbon.url[settings.server];
                    settings.api.kliker.url = settings.api.kliker.url[settings.server];
                    settings.api.vcash.url = settings.api.vcash.url[settings.server];
                    settings.api.blinking.url = settings.api.blinking.url[settings.server];
                    settings.api.chapa.url = settings.api.chapa.url[settings.server];
                    settings.api.ssbt.url = settings.api.ssbt.url[settings.server];
                    settings.api.mobileRegistration.url =
                        settings.api.mobileRegistration.url[settings.server];
                    settings.api.novabanka.url = settings.api.novabanka.url[settings.server];
                    settings.api.avoucher.url = settings.api.avoucher.url[settings.server];
                    settings.api.yoUganda.url = settings.api.yoUganda.url[settings.server];
                    settings.api.playerTransfer.url =
                        settings.api.playerTransfer.url[settings.server];

                    // Set external assets urls
                    settings.externalSharedImagesPath =
                        settings.api.assets.url[settings.server] + '/7web_assets/';
                    settings.externalCompanyImagesPath =
                        settings.api.assets.url[settings.server] +
                        '/' +
                        settings.company.name +
                        '/7web_assets-images/';

                    // Resolve client assets route
                    dataConfig.client.assets = dataConfig.client.assets.supplant({
                        name: settings.company.name,
                    });

                    // Check languages
                    if (!dataConfig.client.languages) dataConfig.client.languages = [];
                    if (dataConfig.client.languages.length <= 0) {
                        dataConfig.client.languages.push({
                            active: true,
                            value: dataConfig.client.language,
                        });
                    }

                    currency = {
                        symbol: dataConfig.client.currency,
                        virtualSymbol:
                            settings.company.currencyVirtual &&
                            settings.company.currencyVirtualCode,
                    };

                    // TODO: Create Currency service to serve as a single source of truth and use it throughout app
                    // HACK FOR T57816:
                    // Override client currency label from bootstrap with virtual currency code from local config file
                    if (settings.company.currencyVirtual) {
                        dataConfig.client.currency = settings.company.currencyVirtualCode;
                    }

                    // Leave just one environment in company
                    settings.company.id = settings.company.id[settings.server];

                    // Create resources list
                    resources = createResources(settings, dataConfig, dataModules);

                    // Create menus as separate constant
                    var menus = angular.copy(dataConfig.menus);
                    delete dataConfig.menus;

                    var articles = dataConfig.articles || [];

                    // Save constants
                    SEVEN.constant('SEVENLocales', locales);
                    SEVEN.constant('SEVENCurrency', currency);
                    SEVEN.constant('SEVENMenus', menus);
                    SEVEN.constant('SEVENModules', dataModules);
                    SEVEN.constant('SEVENSettings', settings);
                    SEVEN.constant('SEVENConfig', dataConfig);
                    SEVEN.constant('SEVENResources', resources);
                    SEVEN.constant('SEVENRoutes', require('gravity-platform-routes'));
                    SEVEN.constant('SEVENArticles', articles);

                    // Set moment locale
                    languageShort = settings.language.substring(0, 2);
                    moment.locale(languageShort);

                    // Load script plugins
                    loadPlugins(dataModules.plugins, dataModules.fileMap);

                    checkForStaleFileVersions(dataModules);

                    // Bootstrap main application module
                    // NOTE: Load config before bootstraping
                    // RULE: Use angular anotation when creating distribution
                    angular.bootstrap(document, ['SEVEN'], {
                        strictDi: !settings.debug,
                    });

                    // Remove setup variable
                    SETUP = angular.noop();
                })
                .catch(function (err) {
                    var sentryPayload = err && err.data ? err.data : err;
                    var headers =
                        err && err.headers && typeof err.headers === 'function' && err.headers();

                    $log.error('[SEVEN] Loading bootstrap failed', err, headers);
                    sentryCaptureMessage('Loading bootstrap failed', sentryPayload);
                });
        });
    };

    var sentryCaptureMessage = function (message, err) {
        if (angular.isDefined(err.status) && (err.status === -1 || err.status === 0)) return;

        Sentry.withScope(function (scope) {
            scope.setExtra('data', err);
            Sentry.captureMessage(message);
        });
    };

    // Keep only one version of product
    var cleanVersions = function (product) {
        var versionKeys = Object.keys(product.versions);

        if (!product.version) {
            product.version = parseFloat(versionKeys[0]);
        }

        for (var i = 0; i < versionKeys.length; i++) {
            if (product.versions[versionKeys[i]].version === product.version) {
                var version = product.versions[versionKeys[i]];
                product.channel = version.channel;
                product.id = version.id;
                product.uuid = version.uuid;
                product.routes = version.routes;
            }
        }

        delete product.versions;
    };

    /**
     * after visiting an external link and navigating back with browser back button on iOS safari,
     * previous app state will be cached which means controllers won't get reinitialized
     * but "pageshow" event will trigger
     * therefore "isIntegrationMode" which is set after gatewayController initialization
     * will remain set to true and the page will reload
     * @see checkForSafariForwardCaching
     */
    var refreshInIntegrationModeIfPersisted = function (event) {
        var isMobileAppleDevice = window.isMobile.apple.device;

        if (isMobileAppleDevice && event.persisted && window.SEVENGlobals.isIntegrationMode) {
            window.removeEventListener('pageshow', refreshInIntegrationModeIfPersisted);
            document.body.style.display = 'none';
            location.reload();
        }
    };

    /**
     * If browser back button was used, flush cache
     * This ensures that user will always see an accurate, up-to-date view based on their state
     * https://stackoverflow.com/questions/8788802/prevent-safari-loading-from-cache-when-back-button-is-clicked
     */
    var checkForSafariForwardCaching = function () {
        window.addEventListener('pageshow', refreshInIntegrationModeIfPersisted);
    };

    var checkForStaleFileVersions = function (modules) {
        if (!modules || !angular.isObject(modules.fileMap)) return;

        var locker = injector.get('locker').namespace('SEVEN');
        var appJSFile = modules.fileMap['js/app.js'];
        var dependenciesJSFile = modules.fileMap['js/dependencies.js'];

        if (appJSFile && dependenciesJSFile) {
            var $scriptElements = document.querySelectorAll('script[src]');
            var savedStaleVersionInfo = locker.get('VersionMismatch');
            var appJSRegex = /js\/app\.(.*)\.js/;
            var dependenciesJSRegex = /js\/dependencies\.(.*)\.js/;
            var versionInfo = {
                appJSMismatch: false,
                dependenciesJSMismatch: false,
            };

            angular.forEach($scriptElements, function ($scriptElement) {
                if (
                    $scriptElement.src.match(appJSRegex) &&
                    !$scriptElement.src.includes(appJSFile)
                ) {
                    versionInfo.appJSMismatch = true;
                } else if (
                    $scriptElement.src.match(dependenciesJSRegex) &&
                    !$scriptElement.src.includes(dependenciesJSFile)
                ) {
                    versionInfo.dependenciesJSMismatch = true;
                }
            });

            if (
                !savedStaleVersionInfo &&
                (versionInfo.appJSMismatch || versionInfo.dependenciesJSMismatch)
            ) {
                $log.debug('[SEVEN] Files version mismatch detected, reloading');
                locker.put('VersionMismatch', versionInfo);
                window.location.reload();
            } else if (
                savedStaleVersionInfo &&
                (versionInfo.appJSMismatch || versionInfo.dependenciesJSMismatch)
            ) {
                $log.debug(
                    '[SEVEN] Files version mismatch, not reloading to prevent infinite reloads since VersionMismatch exists in local storage and app already reloaded'
                );
            } else {
                locker.forget('VersionMismatch');
            }
        }
    };

    // Get querystring parameter
    getQueryParam = function (name) {
        name = name.replace(/[[\]]/g, '\\$&');

        var url = window.location.href,
            regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);

        if (!results) return null;
        if (!results[2]) return '';
        return window.decodeURIComponent(results[2].replace(/\+/g, ' '));
    };

    // Find currencyCompany which is marked as default and exists on current environment
    getDefaultCurrencyCompany = function (companies) {
        for (var currency in companies) {
            if (
                companies[currency].default &&
                companies[currency].id[SETUP.server] &&
                companies[currency].name[SETUP.server]
            ) {
                return companies[currency];
            }
        }
        return null;
    };

    // Set company uuid and name based on "currency" query parameter
    setMultiCurrency = function () {
        var paramValue = getQueryParam('currency'),
            currencyParam = paramValue ? paramValue.toLowerCase() : paramValue,
            currencyCompanies = SETUP.company.currencyCompanies,
            defaultCurrencyCompany = getDefaultCurrencyCompany(currencyCompanies),
            currencyCompany;

        // If currency param is valid and
        // if company with that currency exists in current environment
        if (
            currencyParam &&
            currencyCompanies[currencyParam] &&
            currencyCompanies[currencyParam].id[SETUP.server]
        ) {
            currencyCompany = currencyCompanies[currencyParam];
        } else if (defaultCurrencyCompany) {
            // Take currencyCompany which is marked as default and exists in current environment
            currencyCompany = defaultCurrencyCompany;
        }

        // Set company id and name, to be used globally
        SETUP.company.id = currencyCompany.id;
        SETUP.company.name = currencyCompany.name[SETUP.server];
    };

    // Check URL for language switch
    checkLanguageSwitch = function (locales) {
        var isLanguageValid = function (language) {
            return !!locales.find(function (lang) {
                return lang.value === language;
            });
        };

        // Define variables
        var localSettings,
            queryLanguage = getQueryParam('lang'),
            // SETUP.language is language set in CMS appSettings.config.language
            setupLanguage = (isLanguageValid(SETUP.language) && SETUP.language) || 'en';

        // Check current URL for lang query and is language allowed for web
        if (queryLanguage && isLanguageValid(queryLanguage)) {
            setupLanguage = queryLanguage;
        } else {
            // Check local storage
            localSettings = injector.get('locker').namespace('SEVEN').all();
            if (
                localSettings &&
                localSettings.Language &&
                isLanguageValid(localSettings.Language)
            ) {
                setupLanguage = localSettings.Language;
            }
        }

        // See https://phabricator.nsoft.ba/T89487
        if (setupLanguage && setupLanguage.toLowerCase() === 'zh-hans') {
            setupLanguage = setupLanguage.substring(0, 2);
        }

        SETUP.language = setupLanguage;
        return setupLanguage;
    };

    // Check manual layout switch
    checkLayoutSwitch = function () {
        var layout = getQueryParam('layout');
        if (layout) {
            SETUP.layout = layout;
        }
    };

    // Check nativeMode switch from runner
    checkNativeModeSwitch = function () {
        var nativeMode = getQueryParam('nativeMode');
        var localSettings = injector.get('locker').namespace('SEVEN').all();

        SETUP.nativeMode = nativeMode === 'true' || localSettings.NativeMode;
    };

    // Create bootstrap files array
    loadBootstrapFiles = function () {
        var config,
            configLocal,
            modules,
            headers = {
                'X-Nsft-SCD-Company-Name': SETUP.company.name,
                'X-Nsft-SCD-Company-Id': SETUP.company.id[SETUP.server],
                'X-Nsft-SCD-App-Name': SETUP.company.name + '_web',
                'X-Nsft-SCD-Locale': SETUP.language,
                'X-Nsft-SCD-Version': 1.1,
                'X-Request-Id': uuid.v4(),
            };

        if (SETUP.api.gravity.appName) {
            headers['X-Nsft-SCD-App-Base-Name'] = SETUP.api.gravity.appName;
        }

        // Use bootstrap from gravity API
        config = SETUP.api.gravity.url[SETUP.server] + '/web/bootstrap';

        // Set local URLs
        configLocal = 'data/config.json'.version(SETUP.version);
        modules = 'data/modules.json'.version(SETUP.version);

        var requests = [
            $http.get(configLocal, {
                headers: {
                    'X-Request-Id': uuid.v4(),
                },
            }),
            $http.get(config.version(SETUP.language, true), {
                headers: headers,
            }),
            $http.get(modules, {
                headers: {
                    'X-Request-Id': uuid.v4(),
                },
            }),
        ];

        if (!SETUP.remoteConfig) {
            // Load local modules when remote config is not active
            var modulesCompany = ('data/' + SETUP.company.name + '/modules.json').version(
                SETUP.version
            );
            requests.push($http.get(modulesCompany));
        }

        // Return promises
        return $q.all(requests);
    };

    loadSettings = function () {
        return {
            // Copy info from setup object
            debug: SETUP.inspect ? true : SETUP.debug,
            gcm: SETUP.gcm,
            inspect: SETUP.inspect,
            isTerminalLayout: SETUP.layout === 'terminal',
            mode: SETUP.mode[SETUP.layout] || SETUP.mode.default || SETUP.mode, // support for different modes per layout
            language: SETUP.language,
            layout: SETUP.layout,
            analytics: SETUP.analytics,
            banners: SETUP.banners,
            externalUrls: SETUP.externalUrls,
            notifications: SETUP.notifications,
            notificationsPusher: SETUP.notificationsPusher,
            login: SETUP.login,
            loaders: SETUP.loaders,
            company: SETUP.company,
            platform: SETUP.platform,
            api: SETUP.api,
            bonusAPI: SETUP.bonusAPI,
            retailAPI: SETUP.retailApi,
            sportbookAPI: SETUP.sportbookAPI,
            sportbookCashoutAPI: SETUP.sportbookCashoutAPI,
            integrationsAPI: SETUP.integrationsAPI,
            assetsBase: SETUP.assetsBase,
            betslip: SETUP.betslip,
            version: SETUP.version,
            server: SETUP.server,
            products: SETUP.products,
            gateway: SETUP.gateway,
            // NOTE: Temporary workaround until backend handles it
            languages: SETUP.languages,
            isMobile: window.isMobile.any,
            nativeMode: SETUP.nativeMode,
            // Create helper properties
            directory: {
                app: 'app/',
                data: 'data/',
                css: 'css/',
            },
            css: {
                active: 'active',
                hidden: 'hidden',
                disabled: 'disabled',
            },
            displayRulesLink: SETUP.displayRulesLink,
            footer: SETUP.footer,
            useCustomImages: SETUP.useCustomImages,
            vaixTracker: SETUP.vaixTracker,
        };
    };

    loadLocales = function () {
        var url = SETUP.platform.api[SETUP.server] + '/web/resource/locales',
            headers = {
                'HTTP-X-SEVEN-CLUB-UUID': SETUP.company.id[SETUP.server],
                'X-Request-Id': uuid.v4(),
            };

        return $http
            .get(url, { headers: headers })
            .then(function (response) {
                return response.data;
            })
            .catch(function (err) {
                $log.error('[SEVEN] Loading locales failed', err);
                return [{ value: 'en', name: 'English' }];
            });
    };

    checkInspectSwitch = function () {
        if (getQueryParam('inspect') !== null) {
            SETUP.inspect = true;
        }
    };

    configureSirWidgetPlugin = function (sirWidget) {
        if (!sirWidget) return;

        // If any of SIR widgets is enabled, sirWidget plugin will be set to `enabled`.
        sirWidget.enabled = Object.values(sirWidget).some(function (widget) {
            return !widget.deprecated && widget.enabled;
        });

        const language = SETUP.language;
        const languageMapper = {};

        // Override unsupported languages in SIR Widgets
        if (language.includes('-')) languageMapper[language] = 'en';
        languageMapper['sr-Latn'] = 'srl';

        sirWidget.data.language = languageMapper[language] || language;
    };

    loadPlugins = function (plugins, fileMap) {
        if (!plugins || isCrawler) return;

        // Important to set sirWidget `enabled` prop before loading plugins.
        configureSirWidgetPlugin(plugins.sirWidget);

        var appendTo;
        angular.forEach(plugins, function (plugin) {
            if (plugin.deprecated || !plugin.enabled || plugin.type !== 'script' || plugin.defer)
                return;

            appendTo = plugin.appendTo || 'body';

            if (plugin.remote) {
                var script = document.createElement('script');
                script.setAttribute('src', plugin.origin);
                angular.element(appendTo).append(script);
            } else {
                var pluginOrigin = (fileMap && fileMap[plugin.origin]) || plugin.origin;

                var loadPlugin = function (config) {
                    var load = !config ? $http.get(pluginOrigin) : $http(config);

                    load.then(function (pluginScript) {
                        if (pluginScript.data) {
                            plugin.data = plugin.data || {};
                            if (plugin.deferLoad && 'requestIdleCallback' in window) {
                                requestIdleCallback(function () {
                                    angular
                                        .element(appendTo)
                                        .append(pluginScript.data.supplant(plugin.data));
                                });
                            } else {
                                angular
                                    .element(appendTo)
                                    .append(pluginScript.data.supplant(plugin.data));
                            }
                        }
                    }).catch(function (err) {
                        var isRetryLimitExceeded =
                            err.config.retryNum && err.config.retryNum >= RETRY_LIMIT;
                        if (err.status !== -1 || isRetryLimitExceeded) {
                            $log.error('[SEVEN] Loading plugins failed', err);
                            return;
                        }

                        err.config.retryNum = err.config.retryNum ? err.config.retryNum + 1 : 1;
                        err.config.url = err.config.url.toString();

                        $timeout(err.config.retryNum * 1000).then(function () {
                            loadPlugin(err.config);
                        });
                    });
                };

                if (plugin.deferLoad && 'requestIdleCallback' in window) {
                    requestIdleCallback(function () {
                        loadPlugin();
                    });
                } else {
                    loadPlugin();
                }
            }
        });
    };

    // Create resources list for $sce and auth
    createResources = function (settings, config, modules) {
        var sceWhitelist = [],
            authTokenlist = [],
            plugin,
            keys,
            i,
            len,
            assetsUrl,
            pluginOrigin;

        // Add self to sce list
        sceWhitelist.push('self');
        // Add platform to authorization list
        authTokenlist.push(config.apiBase);
        authTokenlist.push(settings.bonusAPI.url[settings.server]);
        authTokenlist.push(settings.api.url[settings.server]);
        authTokenlist.push(settings.api.gravity.url);
        authTokenlist.push(settings.api.slap.url[settings.server]);
        authTokenlist.push(settings.api.mts.url[settings.server]);
        authTokenlist.push(settings.api.moncash.url);
        authTokenlist.push(settings.api.ips.url);
        authTokenlist.push(settings.api.orangewebpay.url);
        authTokenlist.push(settings.api.adumo.url);
        authTokenlist.push(settings.api.mtn.url);
        authTokenlist.push(settings.api.paytenCse.url);
        authTokenlist.push(settings.api.mpesa.url);
        authTokenlist.push(settings.api.monri.url);
        authTokenlist.push(settings.api.payspot.url);
        authTokenlist.push(settings.api.xbon.url);
        authTokenlist.push(settings.api.kliker.url);
        authTokenlist.push(settings.api.blinking.url);
        authTokenlist.push(settings.api.chapa.url);
        authTokenlist.push(settings.api.ssbt.url);
        authTokenlist.push(settings.api.novabanka.url);
        authTokenlist.push(settings.api.avoucher.url);
        authTokenlist.push(settings.api.yoUganda.url);
        authTokenlist.push(settings.api.playerTransfer.url);

        // Add assets
        assetsUrl = config.client.assets.supplant({ name: settings.company.name }) + '**';
        sceWhitelist.push('http:' + assetsUrl);
        sceWhitelist.push('https:' + assetsUrl);

        // Check plugins
        if (modules.plugins) {
            keys = Object.keys(modules.plugins);
            len = keys.length;
            for (i = 0; i < len; i++) {
                plugin = modules.plugins[keys[i]];
                // Add plugin origin
                if (plugin.origin) {
                    // Environment dependent origin
                    pluginOrigin = plugin.origin[settings.server] || plugin.origin;
                    // Whitelist
                    if (plugin.register !== false) {
                        sceWhitelist.push(pluginOrigin + '/**');
                    }
                    // Authlist
                    if (plugin.authorize) {
                        authTokenlist.push(pluginOrigin);
                    }
                }
            }
        }

        return {
            sce: {
                whitelist: sceWhitelist,
            },
            auth: {
                tokenlist: authTokenlist,
            },
        };
    };

    init();
});

function setupServiceWorker() {
    if (!('serviceWorker' in navigator) || isCrawler) return;

    navigator.serviceWorker.register('sw.js').then(
        function () {},
        function (error) {
            console.log('Service worker registration failed', error);
        }
    );
    navigator.serviceWorker.addEventListener('message', function (event) {
        if (!(event.data && event.data.type === 'SEVEN:InvalidJSFile')) return;

        var injector = angular.injector(['ng', 'angular-locker']);
        var locker = injector.get('locker').namespace('SEVEN');

        locker.put('InvalidFileReloadTriggered', true);
        window.location.reload();
    });
}

// Attach orientation change event
// NOTE: Needed for some devices like iphone
window.addEventListener(
    'orientationchange',
    function () {
        var originalDisplay = document.body.style.display;
        document.body.style.display = 'none';
        setTimeout(function () {
            document.body.style.display = originalDisplay;
        }, 10);
    },
    false
);

angular.module('SEVEN').config(['$provide', function ($provide) {
    $provide.decorator(
        'ngHrefDirective',
        ['$delegate', '$sce', '$browser', '$urlRouter', '$location', function ngHrefDirectiveDecorator($delegate, $sce, $browser, $urlRouter, $location) {
            var attrName = 'href';
            var normalized = 'ngHref';
            var urlRouterOptions = $urlRouter.getOptions();
            var useAbsoluteURL = urlRouterOptions && urlRouterOptions.absolute;
            var locationPort = $location.port();
            var port = locationPort === 80 || locationPort === 443 ? '' : ':' + locationPort;
            var baseURL = $location.protocol() + '://' + $location.host() + port;

            var generateHrefValue = function (url) {
                var isRelativeUrl = url && url[0] === '/';

                return useAbsoluteURL && isRelativeUrl ? baseURL + url : url;
            };

            $delegate[0].compile = function () {
                return function (scope, element, attr) {
                    var name = attrName;

                    if (toString.call(element.prop('href')) === '[object SVGAnimatedString]') {
                        name = 'xlinkHref';
                        attr.$attr[name] = 'xlink:href';
                    }

                    // We need to sanitize the url at least once, in case it is a constant
                    // non-interpolated attribute.
                    attr.$set(
                        normalized,
                        $sce.getTrustedMediaUrl(generateHrefValue(attr[normalized]))
                    );

                    attr.$observe(normalized, function (value) {
                        if (!value) {
                            if (attrName === 'href') {
                                attr.$set(name, null);
                            }
                            return;
                        }

                        attr.$set(name, generateHrefValue(value));
                    });
                };
            };

            delete $delegate[0].link;

            return $delegate;
        }]
    );
}]);

angular.module('SEVEN').config(['$provide', '$locationProvider', function ($provide, $locationProvider) {
    $provide.decorator(
        '$urlRouter',
        ['$delegate', '$sniffer', '$location', '$browser', function $urlRouterDecorator($delegate, $sniffer, $location, $browser) {
            // This is copied from node_modules/@uirouter/angularjs/src/urlRouter.js since it is not exposed on $urlRouter,
            // but it is used in $urlRouter.href
            var baseHref = $browser.baseHref();

            function appendBasePath(url, isHtml5, absolute) {
                if (baseHref === '/') return url;
                if (isHtml5) return baseHref.slice(0, -1) + url;
                if (absolute) return baseHref.slice(1) + url;
                return url;
            }

            $delegate.overrideOptions = function (options) {
                this.__svenpatched__options = options;
            };

            $delegate.getOptions = function () {
                return this.__svenpatched__options;
            };

            $delegate.href = function (urlMatcher, params, options) {
                var config = angular.isObject(this.__svenpatched__options)
                    ? angular.copy(this.__svenpatched__options)
                    : {};

                options = angular.extend(options || {}, config);

                if (!urlMatcher.validates(params)) return null;

                var isHtml5 = $locationProvider.html5Mode();
                if (angular.isObject(isHtml5)) {
                    isHtml5 = isHtml5.enabled;
                }

                isHtml5 = isHtml5 && $sniffer.history;

                var url = urlMatcher.format(params);
                options = options || {};

                if (!isHtml5 && url !== null) {
                    url = '#' + $locationProvider.hashPrefix() + url;
                }

                // Handle special hash param, if needed
                if (url !== null && params && params['#']) {
                    url += '#' + params['#'];
                }

                url = appendBasePath(url, isHtml5, options.absolute);

                if (!options.absolute || !url) {
                    return url;
                }

                var slash = !isHtml5 && url ? '/' : '',
                    port = $location.port();
                port = port === 80 || port === 443 ? '' : ':' + port;

                return [$location.protocol(), '://', $location.host(), port, slash, url].join('');
            };

            return $delegate;
        }]
    );
}]);

SEVEN.provider('SevenGravitySettings', function () {
    var self = this;

    this._data = {};

    this.setData = function (applicationSettings) {
        this._data = applicationSettings;
    };

    this.getDataByKey = function (key) {
        return this._data[key];
    };

    this.$get = function () {
        return self;
    };
});

SEVEN.config(['SevenGravitySettingsProvider', '$sceDelegateProvider', '$compileProvider', '$httpProvider', '$stateProvider', '$urlRouterProvider', '$urlMatcherFactoryProvider', '$ocLazyLoadProvider', '$locationProvider', '$logProvider', 'SEVENSettings', 'SEVENConfig', 'SEVENModules', 'SEVENResources', function (
    SevenGravitySettingsProvider,
    $sceDelegateProvider,
    $compileProvider,
    $httpProvider,
    $stateProvider,
    $urlRouterProvider,
    $urlMatcherFactoryProvider,
    $ocLazyLoadProvider,
    $locationProvider,
    $logProvider,
    SEVENSettings,
    SEVENConfig,
    SEVENModules,
    SEVENResources
) {
    var settings = SEVENSettings,
        config = SEVENConfig,
        modules = SEVENModules,
        resources = SEVENResources,
        viewportWidth = window.innerWidth,
        hasMultipleLegalEntities =
            settings.company.legalEntities && settings.company.legalEntities.length,
        legalEntitiesURLPaths =
            hasMultipleLegalEntities &&
            settings.company.legalEntities.map(function (e) {
                return e.urlPath;
            }),
        generateStates,
        getLayoutTemplate;

    SevenGravitySettingsProvider.setData(SEVENConfig.appSettings);

    // Optimize
    $compileProvider.debugInfoEnabled(settings.debug);
    $compileProvider.commentDirectivesEnabled(false);
    $compileProvider.cssClassDirectivesEnabled(false);
    $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|s?ftp|mailto|tel|file|skype|viber):/);
    $logProvider.debugEnabled(settings.debug);

    // SCE whitelist
    $sceDelegateProvider.resourceUrlWhitelist(resources.sce.whitelist);

    var getDeviceTemplate = function (value) {
        var getDescendant = function (obj, path) {
            return path.split('.').reduce(function (acc, part) {
                return acc && acc[part];
            }, obj);
        };

        for (var device in value) {
            if (getDescendant(window.isMobile, device) === true) {
                return value[device];
            }
        }
    };

    getLayoutTemplate = function (routeKey) {
        var route = modules.routes[routeKey],
            automaticTemplate,
            template;

        // Automatic layout
        if (route.template) {
            var autolayout = route.template.autolayout;
            if (autolayout) {
                var layoutKeys = Object.keys(autolayout),
                    len = layoutKeys.length,
                    value,
                    key,
                    i;

                for (i = 0; i < len; i++) {
                    key = layoutKeys[i];
                    value = autolayout[key];
                    if (angular.isArray(value) && value.length) {
                        if (angular.isArray(value[0])) {
                            for (var j = 0; j < value.length; j++) {
                                if (
                                    value[j].length === 2 &&
                                    viewportWidth >= value[j][0] &&
                                    viewportWidth < value[j][1]
                                ) {
                                    automaticTemplate = route.template[key];
                                    if (automaticTemplate) return automaticTemplate;
                                }
                            }
                        } else {
                            if (
                                value.length === 2 &&
                                viewportWidth >= value[0] &&
                                viewportWidth < value[1]
                            ) {
                                automaticTemplate = route.template[key];
                                break;
                            }
                        }
                    } else {
                        // If template key is device then apply device check logic using isMobile lib
                        if (key === 'device') {
                            var deviceTemplate = getDeviceTemplate(value);
                            if (deviceTemplate) {
                                automaticTemplate = deviceTemplate.url;
                                route.template.css = deviceTemplate.css;
                            }
                        } else {
                            if (viewportWidth < value) {
                                automaticTemplate = route.template[key];
                                break;
                            }
                        }
                    }
                }

                // Save current template - Needed for css lazyload
                if (automaticTemplate) {
                    route.template.current = key;
                }
            }
        }

        // Layout fallback: Automatic => Manual => Default => Generic
        template =
            automaticTemplate ||
            route.template[settings.layout] ||
            route.template.default ||
            route.template;

        if (modules.fileMap && modules.fileMap[template]) {
            template = modules.fileMap[template];
        }

        return template;
    };

    // Add UI states from module routes
    generateStates = function () {
        var routes = modules.routes,
            routeKeys = Object.keys(routes),
            leni = routeKeys.length,
            lenj,
            key,
            route,
            options,
            resolve,
            resolver,
            resolveKey,
            resolveKeys,
            createResolve,
            i,
            j;

        createResolve = function (definition, name) {
            if (!definition.arguments) definition.arguments = ['$ocLazyLoad'];
            definition.arguments.push(function () {
                var executor = arguments[arguments.length - 1];
                if (!definition.execute) definition.execute = {};
                if (!definition.execute.name) definition.execute.name = 'load';
                if (!definition.execute.arguments) definition.execute.arguments = [name];
                return executor[definition.execute.name].apply(
                    executor,
                    definition.execute.arguments
                );
            });

            return definition.arguments;
        };

        for (i = 0; i < leni; i++) {
            key = routeKeys[i];
            route = routes[key];
            if (route.register) {
                options = {};
                options.data = route.data || {};
                options.data.route = key;
                options.data.disabled = route.disabled || false;
                if (route.abstract) {
                    options.abstract = true;
                }
                if (route.redirect) {
                    options.data.redirect = route.redirect;
                }
                if (route.url && !route.abstract) {
                    var url = route.url;

                    // Select url matching language if url is defined as object with url's per language.
                    // Object needs to have "default" key with url value as failsafe
                    if (typeof route.url === 'object') {
                        url = route.url[settings.language] || route.url.default;

                        angular.forEach(route.url, function (routeUrl) {
                            if (routeUrl !== url) {
                                $urlRouterProvider.when(
                                    $urlMatcherFactoryProvider.compile(routeUrl, {
                                        params: route.params || {},
                                    }),
                                    url
                                );
                            }
                        });
                        options.urls = route.url;
                    }

                    options.url = url;
                }
                if (route.template) {
                    options.templateUrl = getLayoutTemplate(key);
                }
                if (route.controller !== false) {
                    options.controller = route.controller
                        ? route.controller
                        : 'SEVEN' + route.name + 'Ctrl';
                }
                if (route.controllerAs) {
                    options.controllerAs = route.controllerAs;
                }
                if (route.parent) {
                    options.parent = routes[route.parent].name;
                }
                if (route.params) {
                    options.params = route.params;
                }
                if (route.data) {
                    options.data.title = route.data.title
                        ? route.data.title.localize(config.messages)
                        : config.client.title;
                    options.data.titleBase = route.data.titleBase
                        ? route.data.titleBase.localize(config.messages)
                        : config.client.title;
                    options.data.seoTitle =
                        route.data.seoTitle && route.data.seoTitle.localize(config.messages);
                }
                if (route.resolve) {
                    resolver = {};
                    resolveKeys = Object.keys(route.resolve);
                    lenj = resolveKeys.length;
                    for (j = 0; j < lenj; j++) {
                        resolveKey = resolveKeys[j];
                        resolve = route.resolve[resolveKey];
                        if (resolve) resolver[resolveKey] = createResolve(resolve, route.name);
                    }
                    options.resolve = resolver;
                }
                $stateProvider.state(route.name, options);
                delete route.resolve;
            }
        }
    };

    // Configure UI states
    generateStates();

    // Configure lazy load
    $ocLazyLoadProvider.config({
        debug: false,
        events: true,
        modules: (function () {
            var list = [],
                serie = true,
                layoutReplace,
                layoutPlaceholder = '{layout}',
                themeVariantPlaceholder = `{themeVariant}`,
                route,
                searchParams = getSearchParams(),
                themeVariant = searchParams.themeVariant,
                themeVariantReplace = themeVariant ? '-' + themeVariant : '';

            angular.forEach(
                modules.components,
                function (item, key) {
                    route = modules.routes[key];
                    layoutReplace = settings.layout ? '-' + settings.layout : '';
                    if (route && route.template && route.template.current) {
                        if (route.template.css) {
                            layoutReplace = '-' + route.template.css;
                        } else {
                            layoutReplace = '-' + route.template.current;
                        }
                    }

                    this.push({
                        name: item.name,
                        serie: settings.debug ? item.serie || serie : item.serie || serie,
                        files: (function () {
                            var files = [];
                            angular.forEach(
                                settings.debug ? item.files : item.bundle,
                                function (file) {
                                    var layoutFile = file
                                        .replace(layoutPlaceholder, layoutReplace)
                                        .replace(themeVariantPlaceholder, themeVariantReplace);
                                    var versionedFile =
                                        (modules.fileMap && modules.fileMap[layoutFile]) ||
                                        layoutFile.version(settings.version);

                                    this.push(versionedFile);
                                },
                                files
                            );

                            var language = settings.language;
                            // ke is designation for internal `Keyname` language which is used for showing translation keys
                            // so loading files for it isn't required and will break app since localization files don't exist
                            if (language !== 'en' && language !== 'ke') {
                                files.push('locale/i18n/' + language + '.js');
                                files.push('locale/moment/' + language + '.js');
                            }

                            return files;
                        })(),
                    });
                },
                list
            );

            delete modules.components;

            return list;
        })(),
    });

    if (hasMultipleLegalEntities) {
        $urlRouterProvider.rule(function ($injector, $location) {
            var url = $location.url();

            for (var urlPath of legalEntitiesURLPaths) {
                if (url.startsWith(urlPath)) {
                    return url.replace(urlPath, '');
                }
            }
        });
    }

    // Return to home on invalid state
    $urlRouterProvider.otherwise('/');

    // Enable html5 mode to remove hash from url
    $locationProvider.html5Mode(SETUP.html5);

    // TODO: Remove when HTML5 mode is activated for all integration clients
    $locationProvider.hashPrefix('');

    // Configure http
    $httpProvider.defaults.useXDomain = true;
    $httpProvider.defaults.withCredentials = true;
    delete $httpProvider.defaults.headers.common['X-Requested-With'];

    // Add general interceptors
    $httpProvider.interceptors.push('SEVENRetryInt');
    $httpProvider.interceptors.push('SEVENVersionInt');
    $httpProvider.interceptors.push('SEVENPlatformInt');
    $httpProvider.interceptors.push('SEVENTokenInt');
    $httpProvider.interceptors.push('SEVENGravityInt');

    // Add company interceptors
    if (settings.platform.interceptors) {
        angular.forEach(settings.platform.interceptors, function (interceptor) {
            if (interceptor.name) $httpProvider.interceptors.push(interceptor.name);
        });
    }

    function getSearchParams() {
        var searchParams = {};
        var query = window.location.search.replace('?', '');

        query.replace(/([^=&]+)=([^&]*)/g, (m, key, value) => {
            searchParams[window.decodeURIComponent(key)] = window.decodeURIComponent(value);
        });

        return searchParams;
    }
}]);

SEVEN.run(['$location', '$q', '$rootScope', '$state', '$urlRouter', '$urlMatcherFactory', '$timeout', 'locker', 'uuid', 'SEVENUser', 'SEVENConfig', 'SEVENIntegrator', 'SEVENSettings', 'SEVENHelpers', 'SEVENModules', 'SEVENPlayerAuthService', 'SEVENBrowserService', 'SEVENGatewayService', 'SEVENRouteService', 'SEVENWidgetService', 'SEVENGoogleTagManager', 'SEVENGoogleAnalyticsService', 'SEVENSegmentAnalyticsService', 'SEVENSentry', 'SEVENFacebookPixel', 'SEVENXtremepush', 'SEVENVaixTracker', 'SEVENUiService', 'SEVENToken', 'SEVENIncomeAccessAffiliateService', function (
    $location,
    $q,
    $rootScope,
    $state,
    $urlRouter,
    $urlMatcherFactory,
    $timeout,
    locker,
    uuid,
    SEVENUser,
    SEVENConfig,
    SEVENIntegrator,
    SEVENSettings,
    SEVENHelpers,
    SEVENModules,
    SEVENPlayerAuthService,
    SEVENBrowserService,
    SEVENGatewayService,
    SEVENRouteService,
    SEVENWidgetService,
    SEVENGoogleTagManager,
    SEVENGoogleAnalyticsService,
    SEVENSegmentAnalyticsService,
    SEVENSentry,
    SEVENFacebookPixel,
    SEVENXtremepush,
    SEVENVaixTracker,
    SEVENUiService,
    SEVENToken,
    SEVENIncomeAccessAffiliateService
) {
    $rootScope.state = $state;
    $rootScope.meta = SETUP.meta;
    $rootScope.canonical = SETUP.canonical;
    $rootScope.touchfix = window.isMobile.apple.device;
    $rootScope.nativeMode = SEVENSettings.nativeMode;
    $rootScope.disableBodyScroll = false;

    var user = SEVENUser,
        tokenService = SEVENToken,
        playerAuthService = SEVENPlayerAuthService,
        routeService = SEVENRouteService,
        settings = SEVENSettings,
        helpers = SEVENHelpers,
        modules = SEVENModules,
        integrator = SEVENIntegrator,
        config = SEVENConfig,
        widgetService = SEVENWidgetService,
        gatewayService = SEVENGatewayService,
        uiService = SEVENUiService;

    var tokenIsSetFromQueryParameter = false;
    var lastPlayerData = {};
    setLinksFormat();

    // TODO: Remove this abomination (no need to keep it in root ... inject model where needed)
    $rootScope.user = SEVENUser;

    user.fillUserFromLocal();

    SEVENSentry.init();
    widgetService.registerWidgetsFromAppSettings(config.appSettings);
    gatewayService.init();
    SEVENBrowserService.init();

    SEVENGoogleTagManager.init();
    SEVENGoogleAnalyticsService.init();
    SEVENFacebookPixel.init();
    SEVENXtremepush.init();
    SEVENVaixTracker.init();
    SEVENSegmentAnalyticsService.init();
    uiService.init();
    SEVENIncomeAccessAffiliateService.init();
    // This should be only used as documentUiState in index.html where $scope isn't available, other views
    // and controller should bind it instead of using documentUiState
    $rootScope.documentUiState = uiService.uiState;

    var redirect = function (redirectTo) {
        $rootScope.$broadcast('SEVEN.CloseModals');
        if (redirectTo) {
            return $state.go(redirectTo);
        }
        if (modules.routes.home.disabled !== true) {
            $state.go('Home');
        }
    };

    var saveDeviceIdentifier = function () {
        locker.add('Device', uuid.v4());
    };

    var saveNativeModeFlag = function () {
        locker.add('NativeMode', settings.nativeMode);
    };

    // Set redirectUrl on $rootScope if user is not logged in and there is a redirectAfterLogin query param in url
    var setRedirectUrl = function () {
        var redirectAfterLogin = $location.search().redirectAfterLogin;

        $location.search('redirectAfterLogin', null);

        if (redirectAfterLogin) {
            $rootScope.redirectUrl = $location.url();
        }
    };

    var checkNativeApp = function (stateChangeEvent) {
        var apps = settings.company.nativeApps;
        if (apps) {
            var device = helpers.getNativeDevice(),
                index = apps.indexOf(device);
            if (index > -1) {
                if ($location.search().native !== 'false') {
                    if (locker.get('Native') !== false) {
                        stateChangeEvent.preventDefault();
                        $state.go(apps[index]);
                    }
                } else {
                    locker.add('Native', false);
                }
            }
        }
    };

    var checkShowLoginParam = function () {
        // If showLogin param doesn't have a valid value or player is logged in, remove query param from URL
        if ($location.search().showLogin !== 'true' || user.logged || tokenService.getToken()) {
            $location.search('showLogin', null);
        } else {
            $timeout(function () {
                $rootScope.$broadcast('SEVEN.ShowLoginDialog');
            });
        }
    };

    var setHreflangLinks = function () {
        var stateHreflang = $state.current.data && $state.current.data.hreflang;

        if (stateHreflang) {
            if (stateHreflang.links) {
                $rootScope.hreflang = stateHreflang.links;
            } else if (stateHreflang.dynamicLinks) {
                $rootScope.hreflang = {};

                angular.forEach(stateHreflang.dynamicLinks, function (link, lang) {
                    // For routes that have parameters url needs to be generated on the fly based on current state
                    // parameters for each defined language
                    var currentStateURLs = $state.current.urls;

                    if (currentStateURLs && currentStateURLs[link.urlLang]) {
                        var urlMatcher = $urlMatcherFactory
                            .compile(currentStateURLs[link.urlLang], {})
                            .concat('?lang');
                        var params = angular.merge(angular.merge({}, $state.params), {
                            lang: link.searchParamValue,
                        });

                        $rootScope.hreflang[lang] = $urlRouter.href(urlMatcher, params);
                    }
                });
            }
        } else {
            $rootScope.hreflang = null;
        }
    };

    var setStateCanonical = function () {
        if ($state.current.data && $state.current.data.generateCanonical) {
            $rootScope.stateCanonical = $location.url();
        } else {
            $rootScope.stateCanonical = null;
        }
    };

    /**
     * @description This method checks for token in query parameter and sets it for user and removes from URL when 7Web is in standalone mode
     * and current route is not in excluded list. Returns boolean representing if above mentioned conditions are met
     * @name setUserTokenFromQueryParameter
     * @param {Object} state UI router state
     * @return {boolean}  Returns boolean representing if conditions for setting token are met
     */
    var setUserTokenFromQueryParameter = function (state) {
        var token = $location.search().token;
        var isStandaloneMode = settings.mode === 'standalone';
        var excludedRoutes = [
            'PlayerAccountClosureConfirm',
            'PlayerVerify',
            'PlayerPasswordChange',
        ];
        var isExcludedRoute = excludedRoutes.includes(state.name);

        // If 7Web is in standalone mode and has token in query and isn't on Player account closure
        // (since it has token query parameter with same name that shouldn't be used here) set user token to trigger loginCheck
        if (isStandaloneMode && token && !isExcludedRoute) {
            user.setToken(token);
            $location.search('token', null);
            return true;
        }

        return false;
    };

    function setLinksFormat() {
        if (settings.company && settings.company.seo && settings.company.seo.useAbsoluteURLs) {
            $urlRouter.overrideOptions({
                absolute: true,
            });
        }
    }

    function setupIntegrator(state) {
        if (state.data && state.data.waitForIntegrationUserAuth) return;

        // Setup integration
        integrator.watch();
    }

    $rootScope.$on('$stateChangeStart', function (event, toState, toParam, fromState, fromParam) {
        var preventLoginCheck = false;
        var nativeModeArticle =
            config.appSettings.config && config.appSettings.config.nativeModeRedirectArticle;
        var useNativeModeArticleRedirect = !!($rootScope.nativeMode && nativeModeArticle);
        var nativeModeArticleRouteName = 'PlayerOffer';
        var previousStateChangeStart = locker.get('PreviousStateChangeStart');
        var reloadTriggered = locker.get('InvalidFileReloadTriggered') === true;
        var homeRedirect = modules.routes.home && modules.routes.home.redirect;
        var appConfig = config.appSettings.config;
        var requireLogin = toState.data && toState.data.requireLogin;

        $rootScope.$broadcast('SEVEN.CloseModals');

        if (reloadTriggered) {
            event.preventDefault();
            locker.forget('InvalidFileReloadTriggered');

            return $state.go(previousStateChangeStart.name, previousStateChangeStart.params);
        } else {
            locker.put('PreviousStateChangeStart', {
                name: toState.name,
                params: toParam,
            });
        }

        if (useNativeModeArticleRedirect && toState.name !== nativeModeArticleRouteName) {
            event.preventDefault();

            return $state.go(nativeModeArticleRouteName, {
                name: nativeModeArticle,
            });
        }

        // Check native app only on home page or the redirect page when home isn't present
        if (
            (toState.name === 'Home' || (!!homeRedirect && toState.name === homeRedirect)) &&
            !settings.nativeMode &&
            !(appConfig && appConfig.preventRedirectToNativePage)
        ) {
            checkNativeApp(event);
        }

        if (toState.data.redirect) {
            event.preventDefault();
            $state.go(toState.data.redirect);
        } else if (toState.data && toState.data.disabled === true) {
            var parentState = $state.get(toState.parent);
            if (toState.data.disabled || (parentState.data && parentState.data.disabled)) {
                event.preventDefault();
                redirect();
            }
        } else if (requireLogin && requireLogin.enabled && !user.logged) {
            event.preventDefault();

            if (!locker.get('PostLoginState')) {
                locker.put('PostLoginState', { name: toState.name, params: toParam });
            }

            if (fromState.abstract) {
                if (
                    playerAuthService.isAuthorizationPending() ||
                    playerAuthService.isAuthInProgress()
                ) {
                    var authFailed = false;
                    var loginDirectiveLoaded = false;

                    const loginFailedListener = $rootScope.$on('SEVEN.AuthFailed', function () {
                        if (loginDirectiveLoaded) {
                            $rootScope.$broadcast('SEVEN.ShowLoginDialog');
                            loginFailedListener();
                            loginDirectiveListener();
                        } else {
                            authFailed = true;
                        }
                    });

                    const loginDirectiveListener = $rootScope.$on(
                        'SEVEN.LoginDirectiveLoaded',
                        function () {
                            loginDirectiveLoaded = true;
                            if (authFailed) {
                                $rootScope.$broadcast('SEVEN.ShowLoginDialog');
                                loginFailedListener();
                                loginDirectiveListener();
                            }
                        }
                    );
                } else {
                    const loginDirectiveListener = $rootScope.$on(
                        'SEVEN.LoginDirectiveLoaded',
                        function () {
                            $rootScope.$broadcast('SEVEN.ShowLoginDialog');
                            loginDirectiveListener();
                        }
                    );
                }

                $state.go(homeRedirect || 'Home');
            } else {
                $rootScope.$broadcast('SEVEN.ShowLoginDialog');
            }
        }

        // Avoid triggering login via token on each state change, just try on first navigation when transitioning from
        // initial "" named state
        if (fromState.name === '') {
            if (tokenIsSetFromQueryParameter) {
                preventLoginCheck = true;
            } else {
                tokenIsSetFromQueryParameter = setUserTokenFromQueryParameter(toState);
            }
        }

        setupIntegrator(toState);

        var createGuest = function () {
            user.removeLocal();
            user.reset();
            user.saveLocal();
        };

        var checkProtectedRoute = function (redirectTo) {
            if (toState.data.logged) {
                event.preventDefault();
                redirect(redirectTo);
            }
        };

        var fillPlayerData = function (playerData) {
            if (!playerData) {
                createGuest();
                checkProtectedRoute();
            } else {
                // Fill player data
                user.set(playerData.data.email, playerData.data, playerData.token);
                user.logged = true;

                if (!integrator.isBalanceChangedListenerActive()) {
                    user.getThrottledBalance();
                }

                user.fillProfile(true)
                    .catch(angular.noop)
                    .finally(function () {
                        // NOTE: Force password change has priority over force nickname change
                        // Check force password change
                        if (playerData.data.isPasswordChangeRequired) {
                            $state.go('PlayerPasswordUpdate', {
                                force: true,
                            });
                            // Check force nickname change
                            // NOTE: Force nickname change only for standalone (full web) clients
                        } else if (
                            settings.mode === 'standalone' &&
                            playerData.data.isAutoGeneratedNickname
                        ) {
                            $state.go('PlayerProfile', {
                                force: true,
                            });
                        }

                        $rootScope.$broadcast('SEVEN.PlayerProfileChange');
                    });
                SEVENXtremepush.setUserId(playerData.data.Uuid);
                if (
                    !angular.equals(
                        lastPlayerData && lastPlayerData.data,
                        playerData && playerData.data
                    )
                ) {
                    $rootScope.$broadcast('SEVEN.PlayerLoggedIn', {
                        playerProfile: playerData.data,
                    });
                }
            }

            lastPlayerData = playerData;
        };

        var checkAndHandleAuth = function (redirectTo) {
            // Disable route change on password change required
            if (user.profile && user.profile.isPasswordChangeRequired) {
                if (fromState.name === 'PlayerPasswordUpdate' && fromParam.force) {
                    event.preventDefault();
                    return false;
                }
            }
            // Disable route change on nickname change required
            // NOTE: Disable it only for standalone (full web) clients
            if (
                settings.mode === 'standalone' &&
                user.profile &&
                user.profile.isAutoGeneratedNickname
            ) {
                if (fromState.name === 'PlayerProfile' && fromParam.force) {
                    event.preventDefault();
                    return false;
                }
            }

            if (preventLoginCheck) return;

            // Check authentication
            playerAuthService
                .loginCheck()
                .then(fillPlayerData)
                .catch(function (e) {
                    if (e.status && e.status > 0) {
                        var rememberMeValue = locker.get('rememberMe');

                        // Try to login user with new access token if rememberMe option is selected
                        if (rememberMeValue === true) {
                            playerAuthService
                                .updateAccessAndRefreshTokens()
                                .then(playerAuthService.loginCheck)
                                .then(function (loginCheckResponse) {
                                    if (tokenService.getToken()) {
                                        return loginCheckResponse;
                                    } else {
                                        return $q(function (resolve) {
                                            return $rootScope.$watch(
                                                function () {
                                                    return tokenService.getToken();
                                                },
                                                function (token) {
                                                    if (token) resolve(loginCheckResponse);
                                                }
                                            );
                                        });
                                    }
                                })
                                .then(fillPlayerData)
                                .catch(function () {
                                    createGuest();
                                    checkProtectedRoute(redirectTo);
                                });
                        } else {
                            createGuest();
                            checkProtectedRoute(redirectTo);
                        }
                    }
                });
        };

        // Check if user token is valid if token exist
        if (playerAuthService.getAuthToken()) {
            checkAndHandleAuth();
        } else if (
            toState.data &&
            toState.data.logged &&
            settings.mode === 'integration' &&
            settings.platform.authType === 'b2b'
        ) {
            var loginFailRedirectRouteName =
                toState.data && toState.data.loginFailRedirectRouteName;
            var integratorAuthPromise =
                toState.data && toState.data.waitForIntegrationUserAuth
                    ? integrator.watchAndResolveUserAuthInit()
                    : $q.resolve();

            return integratorAuthPromise
                .then(function () {
                    return playerAuthService.b2bAuthPromise;
                })
                .then(function () {
                    checkAndHandleAuth(loginFailRedirectRouteName);
                })
                .catch(function () {
                    createGuest();
                    checkProtectedRoute(loginFailRedirectRouteName);
                })
                .finally(function () {
                    // Save "currency" query parameter (if exists)
                    routeService.saveQueryParams(['currency', 'themeVariant']);
                });
        } else {
            createGuest();
            checkProtectedRoute();
        }

        // Save "currency" query parameter (if exists)
        routeService.saveQueryParams(['currency', 'themeVariant']);
    });

    $rootScope.$on('$stateChangeSuccess', function (event, toState) {
        // Refresh bonus visibility
        if (!modules.routes.playerOffers.disabled) {
            if (user && user.logged) {
                user.setBonusVisibility();
            }
        }

        if (user && user.profile) {
            var exclusion = toState.data.exclusion || toState.name;
            var excludedProduct = Array.isArray(user.profile.selfExcludedProducts)
                ? user.profile.selfExcludedProducts.find(function (excludedProduct) {
                      return excludedProduct.product === exclusion;
                  })
                : null;

            $timeout(function () {
                $rootScope.$broadcast('SEVEN.ToggleExclusionOverlay', excludedProduct);
            });
        }

        // Persist "currency" query parameter
        routeService.applyQueryParams(['currency', 'themeVariant']);

        // Check if Login dialog should be displayed
        checkShowLoginParam();

        setHreflangLinks();

        setStateCanonical();

        // Emit to remove preboot loader
        $rootScope.$broadcast('SEVEN.StateLoaded');
    });

    $rootScope.$on('$locationChangeSuccess', function () {
        setStateCanonical();

        $rootScope.$broadcast('SEVEN.SetSEO');
    });

    $rootScope.$watch(
        function () {
            return user.logged;
        },
        function (next, previous) {
            // Redirect if protected page
            if (next !== previous && !next && $state.current.data && $state.current.data.logged) {
                redirect();
            }
        }
    );

    setRedirectUrl();
    saveDeviceIdentifier();
    saveNativeModeFlag();
}]);

SEVEN.component('sevenAuthoritySeal', {
    templateUrl: 'app/shared/authority-seal/authority-seal-view.html',
    controller: ['$scope', '$attrs', '$interval', '$timeout', 'SEVENModules', 'SEVENConfig', function ($scope, $attrs, $interval, $timeout, SEVENModules, SEVENConfig) {
        var modules = SEVENModules;
        var config = SEVENConfig;
        var authority = config.regulatoryAuthority;
        if (modules.plugins && authority) {
            var authorityPlugin = modules.plugins[authority.plugin];
            $scope.authorityPlugin = authorityPlugin;
            $scope.width = $attrs.width;
            $scope.pluginName = authority.name;

            if (authorityPlugin && authorityPlugin.data) {
                $timeout(function () {
                    var authorityPluginInterval = $interval(function () {
                        if (window[authorityPlugin.executable]) {
                            window[authorityPlugin.executable].init();
                            $interval.cancel(authorityPluginInterval);
                        }
                    }, 100);
                });
            }
        }
    }],
});

SEVEN.component('sevenAutocompletePlaces', {
    controller: /*@ngInject*/ ['$window', '$document', '$interval', 'SEVENMapService', function ($window, $document, $interval, SEVENMapService) {
        var mapService = SEVENMapService,
            vm = this,
            autocomplete,
            initPlacesInterval,
            api;

        var init = function () {
            $window.initComponent = function () {
                setAutocomplete();
            };

            // Prevent loading Google Maps script
            if (vm.noScriptLoad) {
                initPlacesInterval = $interval(function () {
                    if ($window.google && $window.google.maps && $window.google.maps.places) {
                        $interval.cancel(initPlacesInterval);
                        setAutocomplete();
                        return;
                    }
                }, 500);
            } else if ($window.google && $window.google.maps && $window.google.maps.places) {
                setAutocomplete();
            } else {
                mapService.loadScripts().then(function (scripts) {
                    var body = $document.find('body').eq(0);
                    scripts.forEach(function (script) {
                        body.append(script[0]);
                    });
                });
            }

            vm.$onChanges = function (changes) {
                var restrict = changes.restrictTo,
                    clearOn = changes.clearOn;

                if (
                    restrict &&
                    restrict.currentValue &&
                    restrict.currentValue !== restrict.previousValue
                ) {
                    restrictAutocomplete(restrict.currentValue);
                }
                if (
                    clearOn &&
                    clearOn.currentValue &&
                    clearOn.currentValue !== clearOn.previousValue
                ) {
                    // Reset input field value
                    angular
                        .element('#' + vm.elementId)
                        .focus()
                        .val('')
                        .blur();
                }
            };
        };

        var setAutocomplete = function () {
            api = $window.google.maps.places;

            var autocompleteElem = document.getElementById(vm.elementId),
                options = {
                    fields: ['name', 'geometry'],
                },
                placeTypes = {
                    city: '(cities)',
                };

            // Set place type restriction if provided
            if (vm.placeTypeRestriction && placeTypes[vm.placeTypeRestriction]) {
                options.types = [placeTypes[vm.placeTypeRestriction]];
            }

            // Create autocomplete object
            autocomplete = new api.Autocomplete(autocompleteElem, options);
            autocomplete.addListener('place_changed', updatePlace);

            // If $onChanges has already been triggered and failed to set restriction
            // because autocomplete was not yet initialized - set initial restriction
            if (vm.restrictTo) {
                restrictAutocomplete(vm.restrictTo);
            }

            // If map is attached, set up a listener
            if (vm.attachedMapId) {
                // On Enter key, choose first matched place from dropdown
                autocompleteElem.addEventListener('keydown', function (e) {
                    // If place from dropdown isn't already selected and if enter isn't already triggered
                    if (
                        e.keyCode === 13 &&
                        !angular.element('.pac-item-selected').length &&
                        !e.triggered
                    ) {
                        $window.google.maps.event.trigger(this, 'keydown', {
                            keyCode: 40,
                        });
                        $window.google.maps.event.trigger(this, 'keydown', {
                            keyCode: 13,
                            triggered: true,
                        });
                    }
                });
            }
        };

        var updatePlace = function () {
            var autocompleteElem = angular.element('#' + vm.elementId),
                place = autocompleteElem.val().split(',')[0],
                elementModel = autocompleteElem.controller('ngModel');

            // If element has ngModel attached, update it
            if (elementModel && elementModel.hasOwnProperty('$viewValue')) {
                // Set only name of place (excluding region and country name)
                elementModel.$setViewValue(place);
                elementModel.$render();
            }

            // If map is attached, zoom to chosen place
            if (vm.attachedMapId) {
                vm.clearMapSelection();
                zoomMap();
            }
        };

        var zoomMap = function () {
            var elem = angular.element('#' + vm.attachedMapId),
                map = elem.data('map'),
                place = autocomplete.getPlace();

            // If no place matches entered query, return
            if (!place.geometry) {
                return;
            }

            // If the place has a geometry, present it on a map
            if (place.geometry.viewport) {
                map.fitBounds(place.geometry.viewport);
            } else {
                map.setCenter(place.geometry.location);
                map.setZoom(8);
            }
        };

        var restrictAutocomplete = function (value) {
            var restrict = vm.restrictBy,
                restriction = {};

            if (autocomplete && restrict && value) {
                restriction[restrict] = value.toLowerCase();
                autocomplete.setComponentRestrictions(restriction);
            }
        };

        var onDestroy = function () {
            $interval.cancel(initPlacesInterval);
        };

        vm.$onInit = init;
        vm.$onDestroy = onDestroy;
    }],
    controllerAs: 'autocompleteVm',
    bindings: {
        elementId: '<',
        restrictBy: '<',
        restrictTo: '<',
        placeTypeRestriction: '<',
        attachedMapId: '<',
        clearOn: '<',
        clearMapSelection: '&',
        noScriptLoad: '<',
    },
});

SEVEN.component('sevenBetslipToggler', {
    templateUrl: 'app/shared/betslip-toggler/betslip-toggler-view.html',
    controllerAs: 'toggler',
    bindings: {
        toggleSidebar: '&',
        showBetslipCount: '=',
        betslipBetCount: '<',
        isUserLogged: '<',
        history: '<',
        historyParamSection: '<',
        historyParamType: '<',
    },
});

(function () {
    SEVEN.component('sevenBottomMenu', {
        templateUrl: 'app/shared/bottom-menu/bottom-menu-view.html',
        controllerAs: 'bottomMenu',
        controller: ['$scope', '$state', 'SEVENConfig', 'SEVENMenus', 'SEVENModules', 'SEVENUser', 'SEVENPlayerService', 'SevenGravitySettings', 'SEVENSettings', 'SEVENHelpers', function (
            $scope,
            $state,
            SEVENConfig,
            SEVENMenus,
            SEVENModules,
            SEVENUser,
            SEVENPlayerService,
            SevenGravitySettings,
            SEVENSettings,
            SEVENHelpers
        ) {
            var vm = this;
            var menus = SEVENMenus;
            var modules = SEVENModules;
            var messages = SEVENConfig.messages;
            var playerService = SEVENPlayerService;
            var settings = SEVENSettings;
            var user = SEVENUser;
            var helpers = SEVENHelpers;

            var init = function () {
                var currentStateData = vm.currentState && vm.currentState.data;
                var showBetslip =
                    currentStateData &&
                    (currentStateData.betslip || currentStateData.mobileBottomMenuBetslip);
                var showLastTickets = user.logged && currentStateData.mobileBottomMenuLastTickets;

                vm.isValidUrl = helpers.isValidUrl;
                vm.popoutActive = false;
                vm.popoutMenu = menus[vm.menu];
                vm.helpPage =
                    menus.company_sidebar &&
                    menus.company_sidebar.find(function (item) {
                        return item.customData.helpPage;
                    });
                vm.messages = messages;
                vm.routes = modules.routes;
                vm.user = user;
                vm.cmsSettings = SevenGravitySettings.getDataByKey('widget.bottomMenu');
                vm.ctaButton =
                    !settings.nativeMode &&
                    vm.cmsSettings &&
                    vm.cmsSettings.popout &&
                    vm.cmsSettings.popout.ctaButton;
                vm.cmsSettings.customTab = vm.cmsSettings.customTab || {};

                vm.actions = {
                    activeAction: 'currentState',
                    currentState: {
                        icon: {
                            dark: '',
                            light: '',
                        },
                        show: true,
                        title: '',
                        onClick: function () {
                            vm.actions.activeAction = 'currentState';
                            closeBetslipWithoutPropagation();
                            vm.showBettingArea();
                            $state.go(vm.currentState.name);
                        },
                    },
                    prematchMTSButton: {
                        icon: {
                            dark: 'n-i-soccer-a',
                            light: 'n-i-soccer-a',
                        },
                        title: messages.general.prematchMtsButton,
                        show: true,
                        routeName: 'PreMatchSport',
                        onClick: function () {
                            vm.actions.activeAction = 'prematchMTSButton';

                            if (vm.currentState.name === vm.actions.prematchMTSButton.routeName) {
                                vm.showBettingArea();
                            } else {
                                $state.go('PreMatch');
                            }

                            closeBetslipWithoutPropagation();
                        },
                    },
                    prematchBettingV2Button: {
                        icon: {
                            dark: 'n-i-soccer-a',
                            light: 'n-i-soccer-a',
                        },
                        title: messages.general.prematchMtsButton,
                        show: true,
                        routeName: 'PreMatchBettingV2',
                        onClick: function () {
                            vm.actions.activeAction = 'prematchBettingV2Button';

                            if (
                                vm.currentState.name ===
                                vm.actions.prematchBettingV2Button.routeName
                            ) {
                                vm.showBettingArea();
                            } else {
                                $state.go(vm.actions.prematchBettingV2Button.routeName);
                            }

                            closeBetslipWithoutPropagation();
                        },
                    },
                    smPrematchButton: {
                        icon: {
                            dark: 'n-i-soccer-a',
                            light: 'n-i-soccer-a',
                        },
                        title: messages.general.smPrematchButton,
                        show: true,
                        routeName: 'SMPrematch',
                        onClick: function () {
                            vm.actions.activeAction = 'smPrematchButton';

                            if (vm.currentState.name === vm.actions.smPrematchButton.routeName) {
                                vm.showBettingArea();
                            } else {
                                $state.go(vm.actions.smPrematchButton.routeName);
                            }

                            closeBetslipWithoutPropagation();
                        },
                    },
                    liveButton: {
                        icon: {
                            dark: 'n-i-live-b',
                            light: 'n-i-live-b',
                        },
                        title: messages.general.liveButton,
                        show: true,
                        routeName: 'Live',
                        onClick: function () {
                            vm.actions.activeAction = 'liveButton';

                            if (vm.currentState.name === vm.actions.liveButton.routeName) {
                                vm.showBettingArea();
                            } else {
                                $state.go(vm.actions.liveButton.routeName);
                            }

                            closeBetslipWithoutPropagation();
                        },
                    },
                    openPopout: {
                        icon: {
                            dark: 'n-i-menu-a',
                            light: 'n-i-menu-a',
                        },
                        title: messages.general.menu,
                        show: true,
                        onClick: function () {
                            vm.toggleBetslip({ close: true });
                            vm.actions.activeAction = 'openPopout';
                            vm.openPopout();
                        },
                    },
                    toggleBetslip: {
                        icon: {
                            dark: 'n-i-betslip',
                            light: 'n-i-betslip-a',
                        },
                        title: messages.general.betslip,
                        show: showBetslip,
                        onClick: function () {
                            vm.toggleBetslip();
                            if (vm.actions.activeAction !== 'toggleBetslip') {
                                vm.actions.activeAction = 'toggleBetslip';
                            } else {
                                setActiveButton();
                            }
                        },
                    },
                    playerHistory: {
                        icon: {
                            dark: 'n-i-user-details ',
                            light: 'n-i-files',
                        },
                        title: messages.pages.playerHistory,
                        show: vm.user.logged,
                        onClick: function () {
                            vm.actions.activeAction = 'playerHistory';
                            closeBetslipWithoutPropagation();
                            $state.go(vm.playerHistoryState.state, vm.playerHistoryState.params);
                        },
                    },
                    lastTickets: {
                        icon: {
                            dark: 'n-i-files',
                            light: 'n-i-files',
                        },
                        title: messages.general.activeTickets,
                        show: showLastTickets,
                        onClick: function () {
                            vm.actions.activeAction = 'lastTickets';
                            closeBetslipWithoutPropagation();
                            vm.showLastTickets();
                        },
                    },
                    customTab: {
                        icon: {
                            dark: helpers.isIcon(vm.cmsSettings.customTab.icon)
                                ? vm.cmsSettings.customTab.icon
                                : 'n-i-seven',
                            light: helpers.isIcon(vm.cmsSettings.customTab.icon)
                                ? vm.cmsSettings.customTab.icon
                                : 'n-i-seven',
                        },
                        title: messages.general.customTab || '',
                        show: true,
                        isLink: true,
                        target: vm.cmsSettings.customTab.target,
                        url: vm.cmsSettings.customTab.url,
                        onClick: function () {},
                    },
                };
                vm.openPopout = openPopout;
                vm.closePopout = closePopout;
                setPlayerHistoryState();
                setActiveButton();
                setCurrentActionState();
                setLoginListener();
                setActionListener();
            };

            var onChanges = function (changes) {
                var currentStateChanges = changes.currentState;

                if (currentStateChanges) {
                    var currentState = currentStateChanges.currentValue;
                    var currentStateData = (currentState && currentState.data) || {};
                    var previousState = currentStateChanges.previousValue;
                    var previousStateData = (previousState && previousState.data) || {};

                    setPlayerHistoryState();

                    if (vm.actions) {
                        setActiveButton();
                        setCurrentActionState();
                    }

                    if (
                        !currentStateChanges.isFirstChange() &&
                        (currentStateData.betslip !== previousStateData.betslip ||
                            currentStateData.mobileBottomMenuBetslip !==
                                previousStateData.mobileBottomMenuBetslip)
                    ) {
                        vm.actions.toggleBetslip.show =
                            currentStateData.betslip || currentStateData.mobileBottomMenuBetslip;
                    }

                    if (
                        !currentStateChanges.isFirstChange() &&
                        currentStateData.mobileBottomMenuLastTickets !==
                            previousStateData.mobileBottomMenuLastTickets
                    ) {
                        vm.actions.lastTickets.show = currentStateData.mobileBottomMenuLastTickets;
                    }
                }
            };

            var setPlayerHistoryState = function () {
                vm.playerHistoryState = playerService.getPlayerHistoryState();
            };

            var setCurrentActionState = function () {
                if (!vm.cmsSettings.availableActions.currentState) return;

                var currentStateData = vm.currentState.data;
                var parentStateName = currentStateData.parent;
                var parentState = $state.get(parentStateName);
                var parentStateData = (parentState && parentState.data) || {};

                vm.actions.activeAction = 'currentState';
                vm.actions.currentState.icon.dark = currentStateData.icon;
                vm.actions.currentState.icon.light = currentStateData.icon;
                vm.actions.currentState.title =
                    parentStateData.title ||
                    currentStateData.title ||
                    parentStateData.seoTitle ||
                    currentStateData.seoTitle;
            };

            var setLoginListener = function () {
                $scope.$on('SEVEN.UserLogin', function (e, isLoggedIn) {
                    vm.actions.playerHistory.show = isLoggedIn;
                    vm.actions.lastTickets.show =
                        isLoggedIn &&
                        vm.currentState &&
                        vm.currentState.data &&
                        vm.currentState.data.mobileBottomMenuLastTickets;
                });
            };

            var setActionListener = function () {
                $scope.$on('SEVEN.ProductUiUpdated', function (event, data) {
                    if (data.event !== 'shown') return;

                    $scope.$applyAsync(function () {
                        if (data.name.includes('Betslip')) {
                            vm.actions.activeAction = 'toggleBetslip';
                            vm.toggleBetslip({
                                close: false,
                                stopPropagation: true,
                            });
                        } else if (data.name.includes('LastTickets')) {
                            closeBetslipWithoutPropagation();
                            vm.actions.activeAction = 'lastTickets';
                        } else {
                            closeBetslipWithoutPropagation();
                            setActiveButton();
                        }
                    });
                });
            };

            var setActiveButton = function () {
                angular.forEach(vm.actions, function (action, key) {
                    if (action.routeName && action.routeName === $state.current.name) {
                        vm.actions.activeAction = key;
                    }
                });
            };

            var closeBetslipWithoutPropagation = function () {
                vm.toggleBetslip({
                    close: true,
                    stopPropagation: true,
                });
            };

            var openPopout = function () {
                vm.popoutActive = true;
            };

            var closePopout = function () {
                vm.popoutActive = false;
                setActiveButton();
            };

            vm.$onInit = init;
            vm.$onChanges = onChanges;
        }],
        bindings: {
            betslipBetCount: '<',
            currentState: '<',
            menu: '@',
            toggleBetslip: '&',
            showLastTickets: '&',
            showBettingArea: '&',
        },
    });
})();

SEVEN.service('SEVENBrowserService', ['$rootScope', 'locker', 'SEVENUser', function ($rootScope, locker, SEVENUser) {
    var user = SEVENUser;

    var listenStorageChange = function () {
        var lockerNamespace = locker.getNamespace() + '.';

        window.addEventListener('storage', function (event) {
            if (event.key !== lockerNamespace + 'Token') return;

            $rootScope.$applyAsync(function () {
                user.fillUserFromLocal();
                user.logged = !!event.newValue;

                // Following should be performed only on user login/logout, avoid infinite loop
                if (event.newValue && event.oldValue) return;
                if (user.logged) user.getThrottledBalance();
                else user.reset();
            });
        });
    };

    return {
        init: function () {
            listenStorageChange();
        },
    };
}]);

SEVEN.component('sevenCheckboxRadio', {
    templateUrl: 'app/shared/checkbox-radio/checkbox-radio-view.html',
    controllerAs: 'checkboxRadio',
    bindings: {
        value: '@',
        selectedValue: '=',
        onChange: '&',
        name: '@',
        label: '<',
        help: '<',
    },
});

SEVEN.component('sevenCheckboxToggle', {
    templateUrl: 'app/shared/checkbox-toggle/checkbox-toggle-view.html',
    controllerAs: 'checkboxToggle',
    bindings: {
        ngModel: '=',
        name: '@',
        label: '<',
        labelUrl: '<',
        url: '<',
        checkRequired: '<',
    },

    controller: function () {
        var vm = this,
            linkPart;

        var init = function () {
            linkPart = vm.labelUrl
                ? '<a href="' + vm.url + '" target="_blank"">' + vm.labelUrl + '</a>'
                : '';

            if (vm.label) {
                var fullLabel =
                    vm.label.indexOf('{linkLabel}') > 0
                        ? vm.label.replace('{linkLabel}', vm.labelUrl ? linkPart : '')
                        : vm.url
                        ? '<a href="' + vm.url + '" target="_blank">' + vm.label + '</a>'
                        : vm.label;

                vm.label = vm.url ? '<span>' + fullLabel + '</span>' : vm.label;
            }
        };

        vm.$onInit = init;
    },
});

SEVEN.component('sevenCombobox', {
    templateUrl: 'app/shared/combobox/combobox-view.html',
    controller: /*@ngInject*/ ['SEVENConfig', function (SEVENConfig) {
        var vm = this,
            config = SEVENConfig;

        vm.$onInit = function () {
            vm.messages = config.messages.general;

            vm.model.$render = function () {
                // Set initial value from parent model
                // $render triggers anytime model gets changed through parent controller or view
                vm.selected = vm.model.$viewValue;
            };

            vm.setSelected = function (item) {
                vm.selected = item;
                vm.model.$setViewValue(vm.selected);
            };
        };
    }],
    require: {
        model: 'ngModel',
    },
    controllerAs: 'comboboxVm',
    bindings: {
        list: '<',
        label: '@',
        isRequired: '<',
        isSubmitted: '<', // Submit trigger for form validation
    },
});

SEVEN.component('sevenCookieBlockedMessage', {
    templateUrl: 'app/shared/cookie-blocked-message/cookie-blocked-message-view.html',

    controller: ['$scope', 'SEVENModalSvc', 'SEVENConfig', function ($scope, SEVENModalSvc, SEVENConfig) {
        var config = SEVENConfig;
        var modalSrv = SEVENModalSvc;

        var init = function () {
            $scope.showDetails = showDetails;
        };

        var showDetails = function () {
            modalSrv
                .showModal({
                    templateUrl: 'app/shared/modal/info.html',
                    controller: [
                        '$scope',
                        'close',
                        function (scope, close) {
                            scope.title = config.messages.maintenance.title;
                            scope.content = [
                                '<p>',
                                config.messages.general.cookiesBlockedMessageInfo,
                                '</p>',
                            ].join('');
                            scope.close = close;
                        },
                    ],
                })
                .then(function (modal) {
                    modal.element.show();
                });
        };

        init();
    }],
});

// Cookies acceptance popup component

SEVEN.component('sevenCookiePopup', {
    templateUrl: 'app/shared/cookie-popup/cookie-popup-view.html',
    controller: ['$scope', 'locker', 'SEVENConfig', function ($scope, locker, SEVENConfig) {
        $scope.config = SEVENConfig;
        $scope.isCookieAccepted = locker.get('IsCookieAccepted');

        // Set local storage aceptance status
        $scope.setIsAccepted = function () {
            $scope.isCookieAccepted = true;
            locker.put('IsCookieAccepted', $scope.isCookieAccepted);
        };
    }],
});

// Datepicker directive
SEVEN.directive('sevenDatepicker', ['$rootScope', 'SEVENConfig', 'SEVENModules', function ($rootScope, SEVENConfig, SEVENModules) {
    // Reference dependencies
    var config = SEVENConfig,
        modules = SEVENModules;

    // Remove time from date
    var removeTime = function (date) {
        return date.hour(0).minute(0).second(0).millisecond(0);
    };

    // Reset date
    var resetDate = function (date) {
        return date.day(0).hour(0).minute(0).second(0).millisecond(0);
    };

    const isDayDisabled = (scope, date) => {
        if (scope.disableFutureDays && date.isAfter()) return true;
        if (!scope.activeFrom && !scope.activeTo) return false;

        return !date.isBetween(scope.activeFrom, scope.activeTo, undefined, '[)');
    };

    // Create month object
    var createMonth = function (scope, start, month) {
        scope.weeks = [];
        var done = false,
            date = start.clone(),
            monthIndex = date.month(),
            count = 0;
        while (!done) {
            scope.weeks.push({
                days: createWeek(scope, date.clone(), month),
            });
            date.add(1, 'w');
            done = count++ > 2 && monthIndex !== date.month();
            monthIndex = date.month();
        }
    };

    // Create week object
    var createWeek = function (scope, date, month) {
        var days = [];
        for (var i = 0; i < 7; i++) {
            days.push({
                name: date.format('dd').substring(0, 1),
                number: date.date(),
                isCurrentMonth: date.month() === month.month(),
                isToday: date.isSame(new Date(), 'day'),
                isDisabled: isDayDisabled(scope, date),
                date: date,
            });
            date = date.clone();
            date.add(1, 'd');
        }

        return days;
    };

    var handleRangeValue = function (scope) {
        scope.weeks.forEach(function (week) {
            week.days.forEach(function (weekDay) {
                weekDay.selectionStart = false;
                weekDay.selectionEnd = false;
                weekDay.selected = false;

                if (weekDay.date.isSame(scope.selected[1])) {
                    weekDay.selectionEnd = true;
                    weekDay.selected = true;
                }

                if (weekDay.date.isSame(scope.selected[0])) {
                    weekDay.selectionStart = true;
                    weekDay.selected = true;

                    if (scope.selected.length === 1) weekDay.selectionEnd = true;
                }

                if (scope.selected.length === 1) return;

                if (weekDay.date.isBetween(scope.selected[0], scope.selected[1])) {
                    weekDay.selected = true;
                }
            });
        });
    };

    // Return directive definition
    return {
        restrict: 'E',
        replace: true,
        templateUrl: modules.directives.datepicker,
        scope: {
            selected: '=?',
            initialSelectedFrom: '=?',
            initialSelectedTo: '=?',
            show: '=',
            input: '=',
            range: '=',
            onDayChange: '&',
            disableFutureDays: '<?',
            activeFrom: '<?',
            activeTo: '<?',
        },
        link: function (scope) {
            scope.config = config;

            var hasSelectedBeenPassed = scope.selected !== undefined;
            var isSelectedValidRange =
                angular.isArray(scope.selected) && scope.selected.length === 2;
            var shouldUseSelectedDates = isSelectedValidRange && hasSelectedBeenPassed;

            var selectedDates = shouldUseSelectedDates
                ? [removeTime(scope.selected[0]), removeTime(scope.selected[1])]
                : removeTime(scope.selected || moment());

            scope.selected = scope.range
                ? scope.initialSelectedFrom && scope.initialSelectedTo && !shouldUseSelectedDates
                    ? [removeTime(scope.initialSelectedFrom), removeTime(scope.initialSelectedTo)]
                    : angular.isArray(selectedDates)
                    ? selectedDates
                    : [selectedDates]
                : selectedDates;

            // Determine the month and start date
            scope.month = angular.isArray(selectedDates)
                ? selectedDates[0].clone()
                : selectedDates.clone();
            var start = scope.month.clone().date(1);

            // Reset and create month
            resetDate(start.day(0));
            scope.weekdays = moment.weekdaysShort();
            createMonth(scope, start, scope.month);

            if (scope.range) {
                handleRangeValue(scope, true);
            }

            scope.$watchGroup(['activeFrom', 'activeTo'], function () {
                var start = scope.month.clone();
                start.date(1);
                resetDate(start.day(0));
                createMonth(scope, start, scope.month);
                handleRangeValue(scope);
            });

            // Select day
            scope.select = function (day) {
                scope.selected = day.date;

                if (scope.input) {
                    var element = $('[name=' + scope.input.$name + ']');
                    scope.input.$setViewValue(day.date.format('L'));
                    scope.input.$render();
                    element.blur();
                }

                scope.show = false;
                scope.onDayChange({
                    selected: scope.selected,
                });
            };

            // Select day in range mode
            scope.rangeSelect = function (day) {
                if (day.isDisabled) return;

                if (scope.selected.length === 2) {
                    scope.selected = [day.date];
                } else {
                    scope.selected.push(day.date);
                }

                scope.selected.sort(function (a, b) {
                    return a.isBefore(b) ? -1 : 1;
                });

                handleRangeValue(scope);
            };

            // Next month
            scope.next = function () {
                var next = scope.month.clone();
                resetDate(next.month(next.month() + 1).date(1));
                scope.month.month(scope.month.month() + 1);
                createMonth(scope, next, scope.month);
                handleRangeValue(scope);
            };

            // Previous month
            scope.previous = function () {
                var previous = scope.month.clone();
                resetDate(previous.month(previous.month() - 1).date(1));
                scope.month.month(scope.month.month() - 1);
                createMonth(scope, previous, scope.month);
                handleRangeValue(scope);
            };

            // Confirm selection
            scope.confirmSelection = function () {
                var payload = scope.selected;

                if (scope.range && scope.selected.length === 1) {
                    payload.push(scope.selected[0]);
                }

                $rootScope.$broadcast('SEVEN.DatePickerChange', payload);
                scope.show = false;
            };

            // Reset selection
            scope.resetSelection = function () {
                var now = removeTime(moment());
                scope.selected =
                    scope.initialSelectedFrom && scope.initialSelectedTo
                        ? [
                              removeTime(scope.initialSelectedFrom),
                              removeTime(scope.initialSelectedTo),
                          ]
                        : scope.range
                        ? [now.clone(), now.clone()]
                        : [now.clone()];

                handleRangeValue(scope);
                $rootScope.$broadcast('SEVEN.DatePickerChange', scope.selected);
                scope.show = false;
            };
        },
    };
}]);

SEVEN.directive('sevenAccordion', function () {
    return {
        restrict: 'A',
        link: function (scope, elem, attrs) {
            var data = scope.$eval(attrs.sevenAccordion);

            elem.on('click', data.header, function (e) {
                if (e.target.classList.contains('accordion-favorite-icon')) return;

                // Find item elements
                var header = $(this);
                var item = header.closest(data.item);
                var body = data.body ? item.find(data.body) : null;
                var children = data.children ? item.find(data.children.target) : null;

                // Calculate speed depending on body height
                var speedRatio = body ? Math.ceil(body.outerHeight() / 1000) : 1;
                var speed = 400 * speedRatio;

                // Check toggle mode
                if (data.toggle) {
                    var siblings;
                    if (data.animate !== false) {
                        siblings = item.siblings();
                        if (siblings.length > 0) {
                            siblings.find(data.body).stop().slideUp(speed);
                            body.stop().slideToggle(speed);
                        } else {
                            body.stop().slideToggle(speed);
                        }
                    } else {
                        siblings = item.siblings();
                        if (siblings.length > 0) {
                            siblings.find(data.body).hide();
                            body.toggle();
                        } else {
                            body.toggle();
                        }
                    }
                } else {
                    var elem = body ? body : children; // Define element(s) for toggling
                    var collapsed = children ? scope.groupsCollapsed : null; // Collapsed flag (if multiple elements are collapsing)

                    // Slide toggle element(s)
                    if (collapsed) {
                        elem.stop().slideUp(data.animate !== false ? speed : 0);
                    } else if (collapsed === false) {
                        elem.stop().slideDown(data.animate !== false ? speed : 0);
                    } else {
                        elem.stop().slideToggle(data.animate !== false ? speed : 0);
                    }
                }

                // Emit rendered
                setTimeout(
                    function () {
                        scope.$emit('SEVEN.Rendered');
                    },
                    data.animate ? speed : 200
                );
            });
        },
    };
});

// Activate directive
SEVEN.directive('sevenActivate', ['SEVENSettings', function (SEVENSettings) {
    // Reference dependencies
    var settings = SEVENSettings;

    return {
        restrict: 'A',
        link: function (scope, elem, attrs) {
            elem.on('click', function (e) {
                // Prevent default if submit
                e.preventDefault();
                e.stopPropagation();

                // Get targets
                var targets = $(attrs.sevenActivate);

                // Check selector
                if (targets.selector) {
                    // Toggle target active class
                    targets.toggleClass(settings.css.active);
                    // Focus only without touch support
                    if (!targets.is(':focus') && targets.is(':input')) {
                        setTimeout(function () {
                            if (targets.hasClass(settings.css.active)) {
                                targets.focus();
                            }
                        }, 400);
                    }
                }
            });
        },
    };
}]);

SEVEN.directive('sevenAutoFocus', ['$timeout', function ($timeout) {
    return {
        restrict: 'A',
        link: function (scope, elem, attrs) {
            angular.element(document).ready(function () {
                if (attrs.sevenAutoFocusIf) {
                    if (attrs.sevenAutoFocusIf === 'true') {
                        focusElement(elem);
                    }

                    return;
                }

                if (attrs.sevenAutoFocus) {
                    // Focus on specified target element
                    focusElement($(attrs.sevenAutoFocus)[0]);
                } else {
                    // Focus on element itself
                    focusElement(elem);
                }

                function focusElement(element) {
                    $timeout(function () {
                        element.focus();
                    });
                }
            });
        },
    };
}]);

// Autohide directive
// NOTE: This will hide element when scroll top is greater then limit
SEVEN.directive('sevenAutohide', function () {
    return {
        restrict: 'A',
        link: function (scope, elem, attrs) {
            // Define variables
            // NOTE: sevenAutohide can hold related element that needs expanding
            var cssClass = 'autohide',
                expand = attrs.sevenAutohide;

            // Listen to scrolling event
            scope.$on('SEVEN.Scrolling', function (event, data) {
                if (!data) return;
                if (expand) expand = angular.element(expand);

                // Check dimensions
                if (data.top > 0) {
                    elem.addClass(cssClass);
                    expand.addClass(cssClass);
                } else {
                    elem.removeClass(cssClass);
                    expand.removeClass(cssClass);
                }
            });
        },
    };
});

SEVEN.directive('sevenBanner', ['$state', '$rootScope', 'SEVENBanners', function ($state, $rootScope, SEVENBanners) {
    var banners = SEVENBanners;
    var loadedOnce = {};
    var alwaysRefresh = {
        E: true,
        D: true,
    };

    return {
        restrict: 'E',
        replace: true,
        scope: {
            position: '@',
            pager: '=',
        },
        link: function (scope, elem, attrs) {
            var generateHtmlFromMarketingSlotItem = function (item) {
                var DFP_PROVIDER = 2;
                var html = [];

                if (item.provider === DFP_PROVIDER) {
                    html = [
                        '<div id="{reference}" class="dfp-banner-wrapper">',
                        '<script>',
                        'googletag.cmd.push(function() {',
                        !loadedOnce[item.reference] && !alwaysRefresh[scope.position.toUpperCase()]
                            ? 'googletag.display("{reference}");'
                            : 'googletag.display("{reference}"); googletag.pubads().refresh();',
                        '});',
                        '</script>',
                        '</div>',
                    ];

                    if (!loadedOnce[item.reference]) {
                        loadedOnce[item.reference] = true;
                    }
                } else if (item.hasResource && item.hasTextContent) {
                    html = [
                        '<a class="slide {style}" href="{url}" target="{target}">',
                        '<img class="image" src="{source}" alt="{title}" title="{title}">',
                        item.textContent,
                        '</div>',
                    ];
                } else if (item.hasTextContent) {
                    html = [
                        '<a class="slide {style}" href="{url}" target="{target}">',
                        '<div class="middle">',
                        item.textContent,
                        '</div>',
                        '</a>',
                    ];
                } else if (item.hasResource) {
                    html = [
                        '<a class="slide {style}" href="{url}" target="{target}">',
                        '<img class="image" src="{source}" alt="{title}" title="{title}">',
                        '</a>',
                    ];
                }

                return html.join('');
            };

            var addDefaultsToItem = function (item) {
                var DEFAULT_TARGET = '';
                var DEFAULT_STYLE = 'normal';

                angular.merge(item, {
                    style: item.style || DEFAULT_STYLE,
                    target: item.target || DEFAULT_TARGET,
                });
            };

            var createFromMarketingSlots = function () {
                var marketingSlot = banners.marketingSlots.find(function (marketingSlot) {
                    return marketingSlot.position === scope.position.toUpperCase();
                });

                if (marketingSlot && marketingSlot.backgroundColor) {
                    scope.bannerStyle = 'background-color: ' + marketingSlot.backgroundColor + ';';
                }

                scope.responsive = marketingSlot && marketingSlot.responsive ? '-responsive' : '';
                scope.bannerTitle = marketingSlot && marketingSlot.title;

                scope.bannerClass = (
                    'n-banner-' +
                    scope.position.replace(/_\d/, '') +
                    scope.responsive
                ).toLowerCase();

                if (marketingSlot && marketingSlot.items && marketingSlot.items.length) {
                    var items = marketingSlot.items;

                    angular.forEach(items, function (item, index) {
                        var html = generateHtmlFromMarketingSlotItem(item);

                        if (html) {
                            addDefaultsToItem(item);
                            addBanner(html, item, index);
                        }
                    });

                    if (items.length > 1) {
                        // Append pager
                        if (scope.pager) {
                            elem.append('<div class="cycle-pager"></div>');
                        }

                        initCycle();
                    }
                } else {
                    elem.remove();
                }
            };

            var addBanner = function (html, item, index) {
                elem.append(html.supplant(item));

                // Find slide
                var slide = elem.find('.slide').eq(index);
                slide.on('click', function () {
                    var eventPayload = {
                        bannerTitle: scope.bannerTitle,
                        bannerRedirectUrl: item.url,
                        itemPosition: item.priority,
                    };

                    scope.$emit('SEVEN.BannerClicked', eventPayload);
                });
                // Check url
                if (!item.url) {
                    slide.removeAttr('href');
                }
                // Activate first slide
                if (index === 0) {
                    slide.addClass('active');
                }
            };

            var initCycle = function () {
                // Init cycle
                // NOTE: Get properties from attributes
                elem.cycle({
                    slides: '> .slide',
                    log: false,
                    swipe: true,
                    pauseOnHover: true,
                    easing: 'easeInQuad',
                    fx: attrs.transition || 'fade',
                    speed: attrs.speed || 1000,
                    timeout: attrs.timeout || 5000,
                    random: attrs.random || false,
                });
            };

            var cleanup = function () {
                if (elem.data()['cycle.API']) {
                    elem.cycle('destroy');
                    elem = angular.noop();
                }
            };

            var init = function () {
                if (banners.marketingSlots) {
                    createFromMarketingSlots();
                    elem.on('$destroy', cleanup);
                } else {
                    elem.remove();
                }
            };

            init();
        },
        template: '<div class="n-banner" ng-class="[bannerClass]" style="{{bannerStyle}}"></div>',
    };
}]);

SEVEN.directive('sevenCheckboxGroup', function () {
    return {
        restrict: 'A',
        scope: {
            sevenCheckboxGroup: '=',
            activeTab: '=',
        },
        link: function (scope) {
            var cases,
                checkCases = function (type) {
                    cases = {
                        1: {
                            1: { 3: true },
                            2: { 3: true },
                            3: { 1: true, 2: true },
                        },
                        2: {
                            1: { 4: true },
                            2: { 4: true },
                            3: { 4: true },
                            4: { 1: true, 2: true, 3: true },
                        },
                    };

                    return scope.activeTab ? cases[scope.activeTab][type] : cases[type];
                };

            scope.checkboxStatus = {};

            scope.$watch(
                'sevenCheckboxGroup',
                function () {
                    scope.hasOwnValue();
                },
                true
            );

            scope.hasOwnValue = function () {
                scope.checkboxStatus = {};

                // find if any obj prop has true(checked) value
                for (var prop in scope.sevenCheckboxGroup) {
                    for (var data in scope.sevenCheckboxGroup[prop]) {
                        if (scope.sevenCheckboxGroup[prop][data]) {
                            scope.checkboxStatus = checkCases(prop);
                        }
                    }
                }
            };

            scope.$parent.hideCheckbox = function (prop) {
                if (scope.checkboxStatus.hasOwnProperty(prop)) {
                    return scope.checkboxStatus[prop];
                }
            };
        },
    };
});

// Custom checkbox directive
SEVEN.directive('sevenCheckbox', ['SEVENSettings', function (SEVENSettings) {
    // Declare variables
    var settings = SEVENSettings,
        icon = 'checkbox-icon checkbox-icon-inactive n-i',
        iconActive = 'checkbox-icon checkbox-icon-active n-i',
        iconType = 'n-i-checkbox',
        iconTypeActive = 'n-i-checkbox-a',
        labelClass = 'checkbox-label',
        labelLinkClass = 'checkbox-label-link-part';

    // Return object
    return {
        require: 'ngModel',
        replace: true,
        template: '<span class="n-checkbox"></span>',
        link: function (scope, elem, attrs, model) {
            // Check icon type attribute
            iconType = attrs.iconType ? attrs.iconType : iconType;
            iconTypeActive = attrs.iconTypeActive ? attrs.iconTypeActive : iconTypeActive;

            elem.append('<i class="' + icon + ' ' + iconType + '"></i>');
            elem.append('<i class="' + iconActive + ' ' + iconTypeActive + '"></i>');

            var linkPart = attrs.labelUrl
                ? '<a href="' +
                  attrs.url +
                  '" target="_blank" class="' +
                  labelLinkClass +
                  '"">' +
                  attrs.labelUrl +
                  '</a>'
                : '';

            // Append label
            if (attrs.label) {
                var fullLabel =
                    attrs.label.indexOf('{linkLabel}') > 0
                        ? attrs.label.replace('{linkLabel}', attrs.labelUrl ? linkPart : '')
                        : attrs.url
                        ? '<a href="' + attrs.url + '" target="_blank">' + attrs.label + '</a>'
                        : attrs.label;

                var label = attrs.url ? '<span>' + fullLabel + '</span>' : attrs.label;
                elem.append('<span class="' + labelClass + '">' + label + '</span>');
            } else {
                var removeLabelObserver = attrs.$observe('label', function (labelValue) {
                    var fullLabel =
                        labelValue.indexOf('{linkLabel}') > 0
                            ? labelValue.replace('{linkLabel}', attrs.labelUrl ? linkPart : '')
                            : attrs.url
                            ? '<a href="' + attrs.url + '" target="_blank">' + labelValue + '</a>'
                            : labelValue;
                    var label = attrs.url
                        ? '<a href="' + attrs.url + '" target="_blank">' + fullLabel + '</a>'
                        : labelValue;
                    elem.append('<span class="' + labelClass + '">' + label + '</span>');
                    removeLabelObserver();
                });
            }

            // IMPORTANT: 'checkRequired' attribute always receives a String type value,
            // which means that 'attrs.checkRequired' is always a truthy value.
            if (!angular.isUndefined(attrs.checkRequired)) {
                // If value of 'checkRequired' attribute is 'true' (String), set it to true (Boolean).
                // In any other case ('1', 'someString', 'false', 'NULL' ...), 'checkRequired' will be set to false (Boolean).
                attrs.checkRequired = attrs.checkRequired === 'true';
            }

            // Set check required
            if (!angular.isUndefined(attrs.checkRequired) && attrs.checkRequired) {
                model.$validators.checkRequired = function (modelValue, viewValue) {
                    var value = modelValue || viewValue || false;
                    var match = scope.$eval(attrs.ngTrueValue) || true;
                    return value && match === value;
                };
            }

            // Element click event
            elem.on('click', function (e) {
                // Check disabled
                if (elem.hasClass(settings.css.disabled) || attrs.disabled) {
                    e.stopImmediatePropagation();
                    return;
                }

                function isLinkClicked() {
                    return e.target.tagName === 'A';
                }
                if (isLinkClicked()) return;

                // Apply model change
                scope.$apply(function () {
                    elem.toggleClass(settings.css.active);
                    model.$setViewValue(elem.hasClass(settings.css.active));
                });
            });

            // Toggle checkbox on space key
            elem.on('keydown', function (e) {
                if (e.keyCode != 32) return;
                e.preventDefault();

                // Check disabled
                if (elem.hasClass(settings.css.disabled) || attrs.disabled) {
                    e.stopImmediatePropagation();
                    return;
                }

                // Apply model change
                scope.$apply(function () {
                    elem.toggleClass(settings.css.active);
                    model.$setViewValue(elem.hasClass(settings.css.active));
                });
            });

            // Render
            model.$render = function () {
                if (!elem.hasClass(settings.css.active) && model.$viewValue) {
                    elem.addClass(settings.css.active);
                } else if (elem.hasClass(settings.css.active) && !model.$viewValue) {
                    elem.removeClass(settings.css.active);
                }
            };
        },
    };
}]);

// Click once button directive
SEVEN.directive('sevenClickOnce', ['$timeout', function ($timeout) {
    return {
        restrict: 'A',
        scope: {
            done: '=sevenClickOnce',
            condition: '=sevenClickOnceCondition',
        },
        link: function (scope, elem) {
            // Element click
            elem.bind('click', function () {
                // Return if condition not met
                if (!scope.condition && !angular.isUndefined(scope.condition)) return;

                // Disable button
                $timeout(function () {
                    elem.attr('disabled', 'disabled');
                });
            });

            // Watch done variable
            if (!elem.attr('ng-disabled')) {
                scope.$watch('done', function (done) {
                    if (done) {
                        // Enable button
                        $timeout(function () {
                            elem.removeAttr('disabled');
                        });
                    }
                });
            }
        },
    };
}]);

// Click outside of element directive
SEVEN.directive(
    'sevenClickout',
    ['$document', function (
        // Dependencies
        $document
    ) {
        return {
            restrict: 'A',
            link: function (scope, elem, attrs) {
                // Create click handler
                var clickHandler = function (event) {
                    var isChild = $(elem).has(event.target).length > 0;
                    var isSelf = elem[0] === event.target;
                    var isInside = isChild || isSelf;

                    if (!angular.isDefined(attrs.sevenClickoutIsActive)) {
                        if (!isInside) {
                            scope.$applyAsync(attrs.sevenClickout);
                        }
                    } else if (!isInside && attrs.sevenClickoutIsActive === 'true') {
                        scope.$applyAsync(attrs.sevenClickout);
                    }
                };

                // Set on document click
                $document.on('click', clickHandler);

                // Remove on scope destroy
                scope.$on('$destroy', function () {
                    $document.off('click', clickHandler);
                });
            },
        };
    }]
);

// Clock directive
SEVEN.directive('sevenClock', ['$interval', function ($interval) {
    // Directive definition
    return {
        restrict: 'E',
        scope: {
            offset: '=?',
            format: '@',
        },
        link: function (scope, element) {
            scope.format = scope.format || 'LTS';

            var hours;
            var minutes;
            var render = function () {
                hours = scope.offset ? parseInt(scope.offset.split(':')[0], 10) : 0;
                minutes = scope.offset ? parseInt(scope.offset.split(':')[1], 10) : 0;
                return (
                    scope.offset ? moment.utc().add(hours, 'h').add(minutes, 'm') : moment()
                ).format(scope.format);
            };

            $interval(function () {
                element.html(render());
            }, 1000);
        },
    };
}]);

// Cycle directive
SEVEN.directive('sevenCycle', function () {
    // Reference dependencies

    return {
        restrict: 'A',
        scope: true,
        link: function (scope, elem, attrs) {
            // Get options from attibute
            var options = scope.$eval(attrs.sevenCycle);

            // Check if cycle exists on element
            var initialized = function () {
                return elem.data()['cycle.API'] ? true : false;
            };

            // Options
            if (options) {
                // Locked options
                options.log = false;
                options.swipe = true;
                options.pauseOnHover = true;

                // Adapt height on cycle-after
                if (options.after) {
                    elem.on('cycle-after', function () {
                        var elemHeight = elem.find('.cycle-slide-active').outerHeight();

                        elem.css({ height: elemHeight });
                    });
                }
            }

            // Extend default options
            var defaults = $.fn.cycle.defaults;
            var settings = $.extend({}, defaults, options);

            // Initialize when ready
            scope.$on('SEVEN.Rendered', function () {
                elem.cycle(settings);
            });

            // Refresh
            scope.$on('SEVEN.CycleRefresh', function (event, data) {
                $.extend(settings, data);
                elem.cycle('destroy');
                elem.cycle(settings);
            });

            // Cleanup
            elem.on('$destroy', function () {
                if (initialized()) {
                    elem.cycle('destroy');
                }
            });
        },
    };
});

// Data loader directive
SEVEN.directive('sevenDataLoader', ['$timeout', function ($timeout) {
    // Create template
    var template = [
        '<div class="n-data-loader">',
        '<div class="data-loader-wrap">',
        '<div class="data-loader-spinner"></div>',
        '</div>',
        '</div>',
    ];

    // Return definition
    return {
        restrict: 'E',
        replace: true,
        template: function () {
            return template.join('');
        },
        link: function (scope, elem, attr) {
            if (attr.timeout) {
                $timeout(function () {
                    elem.removeClass('active');
                }, attr.timeout);
            }
        },
    };
}]);

// Expand directive
SEVEN.directive('sevenExpand', function () {
    return {
        restrict: 'A',
        link: function (scope) {
            // Default expanded state
            scope.expanded = false;

            // Toggle expanded state
            scope.toggle = function () {
                scope.expanded = !scope.expanded;
                scope.$broadcast('SEVEN.Rendered');
            };

            // Contract
            scope.contract = function () {
                scope.expanded = false;
                scope.$broadcast('SEVEN.Rendered');
            };

            // Listen on expand event
            scope.$on('SEVEN.ExpandToggle', function () {
                scope.toggle();
            });
        },
    };
});

// Floater directive
SEVEN.directive('sevenFloater', function () {
    return {
        restrict: 'A',
        link: function (scope, elem, attrs) {
            // Define variables
            var parentWindow,
                parentWindowElem,
                parentDocument,
                parentDocumentHeight,
                scrollTop,
                scrollTopLast,
                scrollTopParent,
                scrollTopParentLast = 0,
                scrollLimit,
                scrollGutter = attrs.sevenFloaterGutter
                    ? parseInt(attrs.sevenFloaterGutter, 10)
                    : 0,
                scrollBottom =
                    attrs.sevenFloaterBottom !== undefined
                        ? scope.$eval(attrs.sevenFloaterBottom)
                        : false,
                elementOffset = attrs.sevenFloaterOffset
                    ? parseInt(attrs.sevenFloaterOffset, 10)
                    : 0,
                getScrollLimit,
                onBottom = false,
                animate,
                animateSpeed = 600,
                animateEasing = 'swing';

            // Get limit
            getScrollLimit = function () {
                return (
                    (attrs.sevenFloaterLimit ? parseInt(attrs.sevenFloaterLimit, 10) : 0) +
                    $(attrs.sevenFloaterOffsetElement).outerHeight()
                );
            };

            // Animate function
            animate = function (skipChecks) {
                // No need to tweak scroll property
                if (skipChecks) {
                    elem.stop().animate(
                        {
                            marginTop: scrollTop,
                        },
                        animateSpeed,
                        animateEasing
                    );
                    return;
                }

                // Check limits
                if (scrollLimit) {
                    if (scrollTop <= scrollLimit) scrollTop = 0;
                    if (scrollTop > scrollLimit) scrollTop -= scrollLimit - scrollGutter;
                }

                // Scroll
                if (scrollTop >= 0) {
                    elem.stop().animate(
                        {
                            marginTop: scrollTop,
                        },
                        animateSpeed,
                        animateEasing
                    );
                }
            };

            // Get parent
            if ('parentIFrame' in window) {
                // Use iFrameResizer: Cross-origin supported
                parentWindow = window.parentIFrame;
                parentDocument = $(document);
                scrollTopLast = 0;

                // NOTE: This is a hack, parent scroll detection is needed
                window.SEVENFloaterInterval = setInterval(function () {
                    var overflowScroll = false;
                    parentDocumentHeight = parentDocument.height();
                    parentWindow.getPageInfo(function (parentData) {
                        scrollTopParent = parentData.scrollTop;
                        scrollTop = scrollTopParent;

                        // Check if any scrolling occured
                        if (scrollTopParentLast === scrollTopParent) {
                            return;
                        }

                        // Update scroll limit
                        scrollLimit = getScrollLimit();

                        // When element exceeds available viewport, while scrolling down,
                        // change calculation and make bottom part of element visible
                        if (
                            elem.outerHeight() + scrollLimit - elementOffset >
                                parentData.clientHeight &&
                            scrollTopParentLast < scrollTopParent &&
                            scrollTop >= scrollLimit
                        ) {
                            overflowScroll = true;

                            scrollTop -=
                                scrollLimit +
                                (elem.outerHeight() - parentData.clientHeight) +
                                elementOffset / 2;
                        }

                        // Update parent's last topScroll value
                        scrollTopParentLast = scrollTopParent;

                        onBottom =
                            scrollBottom &&
                            scrollBottom &&
                            parentDocumentHeight - parentData.scrollTop <= elem.outerHeight();
                        if (onBottom)
                            scrollTop = parentDocumentHeight - elem.outerHeight() + elementOffset;
                        if (scrollTop !== scrollTopLast) {
                            scrollTopLast = scrollTop;
                            animate(overflowScroll && !onBottom);
                        }
                    });
                }, animateSpeed);
            } else {
                // Normal parent: Cross-origin not supported
                parentWindow = parent && parent.window ? parent.window : window;
                parentWindowElem = $(parentWindow);
                parentDocument = $(parentWindow.document);
                // Scroll on parent
                parentWindowElem.on('scrollstop', function () {
                    scrollTop = parentDocument.scrollTop();
                    parentDocumentHeight = parentDocument.height();
                    onBottom =
                        scrollBottom &&
                        parentWindowElem.scrollTop() + parentWindowElem.height() ===
                            parentDocumentHeight;
                    if (onBottom) scrollTop = parentDocumentHeight - elem.outerHeight();
                    animate();
                });
            }

            // Cleanup
            elem.on('$destroy', function () {
                window.clearInterval(window.SEVENFloaterInterval);
            });
        },
    };
});

SEVEN.directive('sevenFocus', ['$timeout', function ($timeout) {
    return {
        restrict: 'A',
        link: function (scope, elem, attrs) {
            elem.on('click', function () {
                // Focus on target
                if (attrs.sevenFocus) {
                    $timeout(function () {
                        var target = $(attrs.sevenFocus);
                        target[0].focus();
                    });
                }
            });
        },
    };
}]);

SEVEN.directive('sevenFramebox', ['uuid', 'SEVENSettings', 'SEVENModules', 'SEVENToken', function (uuid, SEVENSettings, SEVENModules, SEVENToken) {
    // Reference dependencies
    var tokenService = SEVENToken,
        settings = SEVENSettings,
        plugins = SEVENModules.plugins;

    // Create template
    var template = [
        '<iframe',
        'id="{{identity}}"',
        'frameborder="0"',
        'scrolling="no"',
        'ng-show="src"',
        'ng-src="{{src}}"',
        '>',
    ]
        .join(' ')
        .trim();

    return {
        restrict: 'E',
        replace: true,
        scope: {
            identity: '@',
            plugin: '@',
        },
        template: template,
        link: function (scope, elem) {
            // Define variables
            var plugin = plugins[scope.plugin],
                tokenListener;

            // Check plugin
            if (!scope.plugin || !plugin) return;

            // Generate id if not provided
            scope.identity = scope.identity ? scope.identity.trim() : uuid.v4();

            // Set plugin
            plugin = plugins[scope.plugin];
            scope.src =
                plugin.origin[settings.server] +
                plugin.path.supplant({
                    company: settings.company.id,
                });

            // Setup resizer
            if ('iFrameResize' in window) {
                elem.on('load', function () {
                    elem.iFrameResize({
                        checkOrigin: [plugin.origin[settings.server]],
                        resizedCallback: function () {
                            scope.$emit('SEVEN.Rendered');
                        },
                        messageCallback: function (response) {
                            if (response.message.type === 'state' && response.message.ready) {
                                // Watch user token change and send auth message
                                tokenListener = scope.$watch(
                                    function () {
                                        return tokenService.getToken();
                                    },
                                    function (current, previous) {
                                        if (current) {
                                            response.iframe.iFrameResizer.sendMessage(
                                                {
                                                    type: 'auth',
                                                    action: 'authenticate',
                                                    method: 'jwt',
                                                    token: current,
                                                },
                                                plugin.origin[settings.server]
                                            );
                                        } else {
                                            if (previous) {
                                                response.iframe.iFrameResizer.sendMessage(
                                                    {
                                                        type: 'auth',
                                                        action: 'unauthenticate',
                                                        method: 'jwt',
                                                    },
                                                    plugin.origin[settings.server]
                                                );
                                            }
                                        }
                                    }
                                );
                            }
                        },
                    });
                });
            }

            // Cleanup
            scope.$on('$destroy', function () {
                if (tokenListener) tokenListener();
            });
        },
    };
}]);

SEVEN.directive(
    'sevenGoogleAnalyticsOn',
    ['SEVENGoogleAnalyticsService', 'SEVENGoogleTagManager', function (SEVENGoogleAnalyticsService, SEVENGoogleTagManager) {
        var analytics = SEVENGoogleAnalyticsService,
            googleTagManager = SEVENGoogleTagManager;

        var isSevenGoogleAnalyticsProperty = function isSevenGoogleAnaltyicsProperty(name) {
            return (
                name.substring(0, 20) === 'sevenGoogleAnalytics' &&
                ['Event'].indexOf(name.substring(20)) === -1
            );
        };

        var getPropertyName = function (name) {
            var s = name.slice(20); // slice off the 'sevenGoogleAnalytics' prefix

            if (typeof s !== 'undefined' && s !== null && s.length > 0) {
                return s.substring(0, 1).toUpperCase() + s.substring(1);
            } else {
                return s;
            }
        };

        return {
            restrict: 'A',
            link: function ($scope, $element, $attrs) {
                var eventType = $attrs.sevenGoogleAnalyticsOn || 'click';
                var trackingData = {};

                angular.forEach($attrs.$attr, function (attr, name) {
                    if (isSevenGoogleAnalyticsProperty(name)) {
                        trackingData[getPropertyName(name)] = $attrs[name];
                        $attrs.$observe(name, function (value) {
                            trackingData[getPropertyName(name)] = value;
                        });
                    }
                });

                angular.element($element[0]).on(eventType, function () {
                    var eventName = $attrs.sevenGoogleAnalyticsEvent;

                    googleTagManager.trackEvent(eventName, trackingData);
                    analytics.eventTrack(eventName, trackingData);
                });
            },
        };
    }]
);

// Height directive: Get element outer height
SEVEN.directive('sevenHeight', function () {
    return {
        restrict: 'A',
        link: function (scope, elem, attrs) {
            // Find target element
            var target = angular.element(attrs.sevenHeightElement);

            // Watch height change
            scope.$watch(
                function () {
                    return target[0].clientHeight;
                },
                function (current, previous) {
                    if (current !== previous) {
                        scope[attrs.sevenHeight] = current;
                    }
                }
            );
        },
    };
});

// Error image fallback directive
SEVEN.directive('sevenErrorImage', function () {
    return {
        restrict: 'A',
        link: function (scope, elem, attrs) {
            var errorHanlder = function () {
                elem.off('error', errorHanlder);
                var src = attrs.sevenErrorImage;
                if (elem[0].src !== src) {
                    elem[0].src = src;
                }
            };

            elem.on('error', errorHanlder);
        },
    };
});

SEVEN.directive('sevenInfiniteScroll', ['$timeout', '$parse', function ($timeout, $parse) {
    return {
        link: function (scope, elem, attrs) {
            var previousScrollTop = 0;

            var infiniteScroll = $parse(attrs.sevenInfiniteScroll);
            var containerSelector = attrs.infiniteScrollContainer;
            var scrollOffset = Number(attrs.infiniteScrollOffset) || 0;
            var scrollDisabled = $parse(attrs.infiniteScrollDisabled)(scope);
            var infiniteScrollContainer;

            function handleOffset() {
                if (!attrs.infiniteScrollOffsetElement) return;

                var scrollOffsetElement = window.$(attrs.infiniteScrollOffsetElement);
                if (!scrollOffsetElement) return;

                var elementHeight = scrollOffsetElement[0].offsetHeight;

                if (elementHeight > scrollOffset) {
                    scrollOffset = elementHeight + scrollOffset;
                }
            }

            function scrollHandler() {
                var containerHeight = infiniteScrollContainer.height();
                var containerScrollHeight = infiniteScrollContainer[0].scrollHeight;
                var containerScrollTop = infiniteScrollContainer.scrollTop();

                var shouldScroll =
                    containerScrollHeight - containerHeight <= containerScrollTop + scrollOffset;
                var isScrollDown = previousScrollTop < containerScrollTop;
                previousScrollTop = containerScrollTop;

                if (shouldScroll && isScrollDown && !scrollDisabled) {
                    scope.$evalAsync(infiniteScroll);
                }
            }

            function setContainer(containerSelector) {
                if (infiniteScrollContainer) {
                    infiniteScrollContainer.unbind('scroll', scrollHandler);
                }

                infiniteScrollContainer = window.$(containerSelector);

                infiniteScrollContainer.bind('scroll', scrollHandler);
            }

            setContainer(containerSelector);
            $timeout(function () {
                handleOffset();
            }, 1000);
            attrs.$observe('infiniteScrollDisabled', function () {
                scrollDisabled = $parse(attrs.infiniteScrollDisabled)(scope);
            });
        },
    };
}]);

SEVEN.directive('sevenInputMask', function () {
    return {
        restrict: 'A',
        require: 'ngModel',
        scope: {
            sevenInputMaskRegex: '@',
            sevenInputMaskLength: '@',
            sevenInputMaskSeparator: '@',
        },
        link: function (scope, elem, attrs, ngModel) {
            if (
                !scope.sevenInputMaskRegex ||
                !scope.sevenInputMaskLength ||
                !scope.sevenInputMaskSeparator
            )
                return;

            elem.on('keyup', function (event) {
                if (event.which === 8 || !elem.val()) {
                    return;
                }

                var regexPattern = new RegExp(scope.sevenInputMaskRegex, 'g');
                var value = elem.val().toString().replace(/[^\d]/g, '');
                value = value
                    .replace(regexPattern, `$1${scope.sevenInputMaskSeparator}`)
                    .trim()
                    .slice(0, parseInt(scope.sevenInputMaskLength));

                ngModel.$setViewValue(value);
                ngModel.$render();
            });
        },
    };
});

SEVEN.directive(
    'sevenMaintenance',
    ['$http', '$timeout', '$log', 'SEVENConfig', 'SEVENModalSvc', 'SEVENProductService', 'SEVENMaintenanceService', function (
        $http,
        $timeout,
        $log,
        SEVENConfig,
        SEVENModalSvc,
        SEVENProductService,
        SEVENMaintenanceService
    ) {
        var config = SEVENConfig,
            productSvc = SEVENProductService,
            maintenanceSvc = SEVENMaintenanceService,
            modalSrv = SEVENModalSvc;

        return {
            restrict: 'E',
            scope: {
                enabled: '=',
            },
            template: [
                '<div class="maintenance-content" ng-if="enabled && activeMaintenance" ng-show="enabled">',
                '<span>{{activeMaintenance.message.title}}</span>',
                '<button>{{button}}</button>',
                '</div>',
            ].join(''),
            link: function ($scope, element) {
                $scope.activeMaintenance = null;
                $scope.button = config.messages.maintenance
                    ? config.messages.maintenance.button
                    : '';

                var init = function () {
                    $scope.showDetail = showDetail;

                    toggleVisibility();
                    checkEnabled();

                    element.on('click', 'button', showDetail);
                };

                var toggleVisibility = function () {
                    if ($scope.enabled) {
                        element.show();
                    } else {
                        element.hide();
                    }
                };

                var checkEnabled = function () {
                    return isEnabled().then(function (enabled) {
                        $scope.enabled = enabled;
                        toggleVisibility();
                        startCheckEnabledInterval();
                    });
                };

                var setAffectedProducts = function () {
                    if (
                        $scope.activeMaintenance.message &&
                        $scope.activeMaintenance.message.scope
                    ) {
                        $scope.activeMaintenance.products = [];
                        angular.forEach(
                            $scope.activeMaintenance.message.scope,
                            function (provider) {
                                angular.forEach(provider, function (products, channel) {
                                    if (channel === 'Web') {
                                        angular.forEach(products, function (product) {
                                            var productInfo = productSvc.getProductByName(product);
                                            $scope.activeMaintenance.products.push(
                                                productInfo ? productInfo.title : product
                                            );
                                        });
                                    }
                                });
                            }
                        );
                    }
                };

                var startCheckEnabledInterval = function () {
                    if (maintenanceSvc.scheduleRequestInterval) {
                        $timeout(checkEnabled, maintenanceSvc.scheduleRequestInterval);
                    }
                };

                var isEnabled = function () {
                    $scope.activeMaintenance = null;
                    return maintenanceSvc
                        .getSchedule()
                        .then(function (data) {
                            var currentTime = moment().utc();
                            for (var i = 0; i < data.results.length; i++) {
                                if (data.results[i].schedule) {
                                    for (var j = 0; j < data.results[i].schedule.length; j++) {
                                        var schedule = data.results[i].schedule[j];
                                        if (
                                            currentTime >= moment.utc(schedule.start) &&
                                            currentTime <= moment.utc(schedule.end)
                                        ) {
                                            $scope.activeMaintenance = data.results[i];
                                            setAffectedProducts();
                                            return true;
                                        }
                                    }
                                }
                            }

                            return false;
                        })
                        .catch(function (response) {
                            $log.warn(
                                '[SEVEN] Error getting maintenance schedule =>',
                                response.data
                            );
                            return false;
                        });
                };

                var showDetail = function () {
                    var content = ['<p>', $scope.activeMaintenance.message.body, '</p>'];
                    var title = $scope.activeMaintenance.message.title;

                    if ($scope.activeMaintenance.products.length) {
                        var productsLabel = config.messages.maintenance.affectedProducts;
                        if (productsLabel) {
                            Array.prototype.push.apply(content, ['<p>', productsLabel, '</p>']);
                        }

                        content.push('<ul>');
                        Array.prototype.push.apply(
                            content,
                            $scope.activeMaintenance.products.map(function (product) {
                                return '<li>' + product + '</li>';
                            })
                        );
                        content.push('</ul>');
                    }

                    modalSrv
                        .showModal({
                            templateUrl: 'app/shared/modal/info.html',
                            controller: [
                                '$scope',
                                'close',
                                function (scope, close) {
                                    scope.title = title;
                                    scope.content = content.join('');
                                    scope.close = close;
                                },
                            ],
                        })
                        .then(function (modal) {
                            modal.element.show();
                        });
                };

                init();
            },
        };
    }]
);

// Match model directive
SEVEN.directive('sevenMatch', ['$parse', function ($parse) {
    return {
        require: '?ngModel',
        restrict: 'A',
        link: function (scope, elem, attrs, ctrl) {
            // Parse attribute
            var matchGetter = $parse(attrs.sevenMatch);
            var unbindWatcher;

            var init = function () {
                // Get match value
                var getMatchValue = function () {
                    var match = matchGetter(scope);
                    if (angular.isObject(match) && match.hasOwnProperty('$viewValue')) {
                        match = match.$viewValue;
                    }

                    return match;
                };

                // Watch model change
                scope.$watch(getMatchValue, function () {
                    ctrl.$$parseAndValidate();
                });

                // Add validator
                ctrl.$validators.match = function () {
                    return ctrl.$viewValue === getMatchValue();
                };
            };

            if (matchGetter(scope)) {
                init();
            } else {
                // In dialogs this directive is initialized before controller so watcher is required
                unbindWatcher = scope.$watch(
                    function () {
                        return matchGetter(scope);
                    },
                    function (value) {
                        if (value) {
                            init();
                            unbindWatcher();
                        }
                    }
                );
            }
        },
    };
}]);

// Pager directive
SEVEN.directive('sevenPager', ['$rootScope', function ($rootScope) {
    // Directive definition
    return {
        restrict: 'AE',
        scope: {
            pager: '=sevenPager',
            pageSize: '=pageSize',
            pagerType: '@pagerType',
        },
        link: function (scope) {
            // Define variables
            var init, calculate, setPagerType, changePage, nextPage, prevPage, reset, pager;

            // Change current page
            changePage = function (page) {
                // Check cycled
                var cycled = false;

                // Next page intent
                if (page === pager.currentPage + 1) {
                    if (page === pager.totalPages + 1) {
                        pager.currentPage = pager.cycle ? 1 : pager.currentPage;
                        cycled = true;
                    }
                }

                // Previous page intent
                if (page === pager.currentPage - 1) {
                    if (page === 0) {
                        pager.currentPage = pager.cycle ? pager.totalPages : pager.currentPage;
                        cycled = true;
                    }
                }

                // Normal change
                if (!cycled) {
                    pager.currentPage = page;
                }

                // Calculate properties
                calculate();
            };

            // Go to next page
            nextPage = function () {
                changePage(pager.currentPage + 1);
            };

            // Go to prev page
            prevPage = function () {
                changePage(pager.currentPage - 1);
            };

            // Calculate properties
            calculate = function () {
                pager.offset = (pager.currentPage - 1) * pager.pageSize;
                pager.nextEnabled = pager.cycle || pager.currentPage < pager.totalPages;
                pager.prevEnabled = pager.cycle || pager.currentPage > 1;
            };

            reset = function () {
                if (!pager) return;
                pager.currentPage = 1;
                calculate();
            };

            setPagerType = function () {
                if (pager.pagerType === 'tickets') {
                    $rootScope.$on('SEVEN.LoadData', function () {
                        reset();
                    });
                }
            };

            init = function () {
                // Watch list change
                scope.$watch('pager.list.length', function (current, previous) {
                    if (current) {
                        // Reference pager
                        pager = scope.pager;
                        pager.pageSize = pager.pageSize ? pager.pageSize : scope.pageSize;
                        pager.pagerType = scope.pagerType ? scope.pagerType : null;
                        setPagerType();

                        // Set functionality
                        pager.changePage = changePage;
                        pager.nextPage = nextPage;
                        pager.prevPage = prevPage;

                        // Check change
                        if (current !== previous) {
                            pager.enabled = current > 0;
                            pager.totalPages = Math.ceil(current / pager.pageSize);
                            pager.currentPage = 1;
                            calculate();
                        }
                    }
                });
            };

            // Initialize
            init();
        },
    };
}]);

SEVEN.directive('sevenPrebootLoader', function () {
    return {
        restrict: 'A',
        link: function (scope, element) {
            var listener = scope.$on('SEVEN.StateLoaded', function () {
                element.addClass('fade-out');
                listener();
                setTimeout(function () {
                    element.remove();
                    // eslint-disable-next-line no-undef
                    scope = element = attributes = null;
                }, 400);
            });
        },
    };
});

// Rendered directive
// NOTE: Use to check when repeater is finished
SEVEN.directive('sevenRendered', function () {
    var emit = function (scope, delay) {
        setTimeout(
            function () {
                scope.$emit('SEVEN.Rendered');
            },
            angular.isUndefined(delay) ? 100 : delay
        );
    };

    return {
        restrict: 'A',
        link: function (scope, elem, attrs) {
            // Attached above repeater: watch length
            scope.$watch(
                function () {
                    return attrs.sevenRendered;
                },
                function (current, previous) {
                    if (current && current !== previous) {
                        emit(scope, undefined, attrs.sevenRenderedLabel);
                    }
                }
            );

            // Attached to repeater
            if (scope.$last === true) {
                emit(scope);
            }
        },
    };
});

// Ripple directive
// BASED ON: https://github.com/nelsoncash/angular-ripple
// Removed errors in IE9 and added support for iScroll

SEVEN.directive('sevenRipple', ['SEVENSettings', function (SEVENSettings) {
    return {
        restrict: 'A',
        link: function (scope, elem) {
            // Reference dependencies
            var settings = SEVENSettings;

            // Element click event
            elem.on('click', function (event) {
                var x, y, size, offsets, ripple;

                // Check disabled state
                if (elem.hasClass(settings.css.disabled)) {
                    return;
                }

                // Create ripple
                ripple = $('<span>');
                ripple.addClass('n-ripple animate');
                $(this).prepend(ripple);

                // Set ripple size
                size = Math.max(elem[0].offsetWidth, elem[0].offsetHeight);
                ripple.width(size);
                ripple.height(size);

                // Set new ripple position by click or touch position
                function getPos(element) {
                    var de = document.documentElement;
                    var box = element.getBoundingClientRect();
                    var top = box.top + window.pageYOffset - de.clientTop;
                    var left = box.left + window.pageXOffset - de.clientLeft;

                    return { top: top, left: left };
                }

                // Set ripple position
                offsets = getPos(elem[0]);
                x = event.pageX;
                y = event.pageY;

                ripple.css({
                    left: x - offsets.left - size / 2 + 'px',
                    top: y - offsets.top - size / 2 + 'px',
                });

                setTimeout(function () {
                    ripple.remove();
                }, 400);
            });
        },
    };
}]);

// Scroller directive
SEVEN.directive('sevenScroller', ['SEVENSettings', '$parse', '$rootScope', function (SEVENSettings, $parse, $rootScope) {
    // Define variables
    var settings = SEVENSettings;
    var stopPropagation = function (e) {
        e.stopPropagation();
    };

    // Return definition
    return {
        restrict: 'A',
        scope: true,
        controller: ['$scope', '$element', '$attrs', function ($scope, $element, $attrs) {
            // Define variables
            var self = this,
                defaults,
                custom,
                options,
                keyBindings;

            // Set defaults
            defaults = {
                bindKeys: false,
                enabled: true,
            };
            keyBindings = {
                pageUp: 33,
                pageDown: 34,
                end: 35,
                home: 36,
                left: 37,
                up: 38,
                right: 39,
                down: 40,
            };

            // Get options from attributes
            custom = $attrs.sevenScroller;
            custom = custom ? $parse(custom)($scope) : {};

            // Extend defaults with custom options
            options = angular.extend({}, defaults, custom);
            this.options = options;
            this.emit = !!$attrs.sevenScrollerEmit;
            this.toTopListener = !!$attrs.sevenScrollerToTopListener;

            // Custom solution for dropdowns
            this.setDropdownHeight = function () {
                var scrollDropdown = $element.find('.n-scrollable-dropdown'),
                    parentHeight;

                if (scrollDropdown) {
                    parentHeight = scrollDropdown.parent().height();

                    // Scroller needs parent height of dropdown to be set
                    if (parentHeight) {
                        scrollDropdown.css('height', parentHeight + 'px');
                    }
                }
            };

            // Initialize function
            this.init = function () {
                // Define variables
                var scrollable = $element.find('.n-scrollable'),
                    scrollElement = scrollable.length
                        ? scrollable
                        : $element.find('.n-scrollable-modal'),
                    scrollTopElement = $element.find('.n-scrollable-top'),
                    containerElement = $element.parent(),
                    fadeSpeed = 1000,
                    scrollSpeed = 1000,
                    onScrollStop,
                    onScroll,
                    timer;

                onScroll = function () {
                    // Detect scroll stop event
                    clearTimeout(timer);
                    timer = setTimeout(function () {
                        timer = null;
                        onScrollStop();
                    }, 100);
                };

                if (scrollElement) {
                    scrollElement.addClass('scrollbar-macosx');
                    // Make sure DOM is loaded before scrollbar init
                    setTimeout(function () {
                        // Set dropdown wrapper height right away so scroll can be initialized on it
                        self.setDropdownHeight();

                        // Initialize jQuery Scrollbar
                        scrollElement.scrollbar({
                            onScroll: onScroll,
                        });

                        // Hack: Override .scroll-content element height in dropdowns (not modals) to prevent slow opening
                        var scrollContent = $element.find('.scroll-content'),
                            modalExists = $('.n-modal:visible').length;

                        if (scrollContent && !modalExists) {
                            scrollContent.css('height', 'auto');
                        }
                    }, 10);
                }

                // Scroll stop callback
                onScrollStop = function () {
                    // Show/hide scrollTopElement
                    if (Math.abs(scrollElement[0].scrollTop) > containerElement.height() / 2) {
                        scrollTopElement.fadeIn(fadeSpeed);
                        return;
                    }
                    scrollTopElement.fadeOut(fadeSpeed);
                };

                // Enable keyboard control
                if (options.bindKeys) {
                    // Add tabindex to make element focusable
                    $(scrollElement).attr('tabindex', -1);

                    angular.element(document).on('keydown', function (e) {
                        angular.forEach(keyBindings, function (keyBinding) {
                            if (keyBinding === e.which) {
                                var modalExists = $('.n-modal:visible').length;
                                // Do not set focus if modal exists
                                if (!modalExists) {
                                    $(scrollElement).focus();
                                }

                                onScrollStop();
                            }
                        });
                    });
                }

                // Go to scroller top
                $scope.scrollTop = function () {
                    $(scrollElement).animate(
                        {
                            scrollTop: 0,
                            scrollLeft: 0,
                        },
                        scrollSpeed
                    );
                };

                if (this.toTopListener) {
                    $rootScope.$on('scrollToTop', function () {
                        $scope.scrollTop();
                    });
                }
            };
        }],
        link: function (scope, elem, attrs, ctrl) {
            if (!ctrl.options.enabled) return;
            // Use native scroller if touch device (mobile, tablet)
            if (settings.isMobile) {
                var modal = elem.find('.n-scrollable-modal'),
                    len = elem[0].classList.length,
                    i;

                if (modal) {
                    modal.removeClass('n-scrollable-modal');
                }

                elem.addClass('native-scroll');
                for (i = 0; i < len; i++) {
                    if (elem[0].classList[i] === 'list-wrap') {
                        elem.addClass('native-scroll-height-min');
                    }
                }

                // Emit scroll event
                if (settings.api.events.scroll && ctrl.emit) {
                    elem.on('scroll', function (event) {
                        if (event.currentTarget) {
                            $rootScope.$broadcast('SEVEN.Scrolling', {
                                top: event.currentTarget.scrollTop,
                                left: event.currentTarget.scrollLeft,
                            });
                        }
                    });
                }
                return;
            }

            var hasScrollbarClickListener = false;
            var scrollbarElement;

            // Use jQuery Scrollbar if not mobile or tablet device
            elem.removeClass('native-scroll-height-min');
            elem.removeClass('native-scroll');
            ctrl.init();

            // Refresh when content rendered
            scope.$on('SEVEN.Rendered', function () {
                if (!hasScrollbarClickListener) {
                    scrollbarElement = elem.find('.scroll-y .scroll-bar');
                    if (scrollbarElement[0]) {
                        scrollbarElement.on('click', stopPropagation);
                        hasScrollbarClickListener = true;
                    }
                }

                setTimeout(function () {
                    // Refresh scroller
                    ctrl.setDropdownHeight();
                }, 400);
            });

            scope.$on('$destroy', function () {
                if (scrollbarElement) {
                    scrollbarElement.off('click', stopPropagation);
                }
            });
        },
    };
}]);

// Select text directive
SEVEN.directive('sevenSelectText', ['SEVENHelpers', function (SEVENHelpers) {
    var helpers = SEVENHelpers;

    return {
        restrict: 'A',
        link: function (scope, elem, attrs) {
            if (attrs.sevenSelectText === 'selectOnClick') {
                var touched = false;

                elem.on('click', function () {
                    if (!touched) {
                        touched = true;
                        elem.select();
                    }
                });

                elem.on('blur', function () {
                    touched = false;
                });
            } else if (attrs.sevenSelectText !== 'none') {
                if (helpers.getNativeDevice() === 'iPhone') {
                    elem.on('touchstart', function () {
                        elem.trigger('focus');
                    });
                }

                elem.on('focus', function () {
                    // Delay selection
                    var self = this;
                    window.setTimeout(function () {
                        // Check support
                        if (angular.isFunction(self.setSelectionRange)) {
                            // Required for mobile Safari
                            self.setSelectionRange(0, self.value.length);
                        } else if (angular.isFunction(self.select)) {
                            // Select text
                            self.select();
                        }
                    }, 100);
                });
            }
        },
    };
}]);

SEVEN.directive('sevenScrollIntoViewIf', ['$timeout', function ($timeout) {
    return {
        restrict: 'A',
        link: function (scope, element, attrs) {
            scope.$watch(attrs.sevenScrollIntoViewIf, function (newValue) {
                if (newValue) {
                    $timeout(function () {
                        if (attrs.hasOwnProperty('sevenScrollIntoViewFocus')) {
                            element[0].focus();
                        }

                        element[0].scrollIntoView({
                            block: 'center',
                            behavior: 'smooth',
                        });
                    });
                }
            });
        },
    };
}]);

// Slider directive
// NOTE: This directive is using touch events
SEVEN.directive('sevenSlider', ['$timeout', function ($timeout) {
    return {
        restrict: 'A',
        scope: {
            data: '=?sevenSlider',
        },
        link: function (scope, elem) {
            // Declare variables
            var calcSliderWidth,
                calcTolerance = 1,
                init,
                unregister;

            // Calculate slider width
            calcSliderWidth = function () {
                var width = 0;
                elem.children().each(function () {
                    width += Math.ceil($(this).outerWidth(true));
                });
                // Add tolerance of 1px for calculation difference
                return width + calcTolerance;
            };

            // Initialize
            init = function () {
                // Get variables needed for calculation
                var container = elem.parent(),
                    containerWidth = Math.ceil(container.innerWidth()),
                    sliderWidth = calcSliderWidth(),
                    toleranceWidth = 5,
                    speed = scope.data.speed ? scope.data.speed : 400,
                    sliderLeft = container.find('[seven-slider-left]'),
                    sliderRight = container.find('[seven-slider-right]');

                // Add swipe support if needed
                if (sliderWidth > containerWidth + toleranceWidth) {
                    // Set slider width
                    elem.css({ width: sliderWidth });

                    // Set swipe move
                    var move = 0,
                        moveIndex = 0,
                        currentSlide = 1,
                        setMove = function () {
                            move = elem.children().eq(moveIndex).outerWidth(true);
                        },
                        setIndicators = function () {
                            // Right
                            if (currentSlide === 1) {
                                sliderRight.hide();
                            } else {
                                sliderRight.show();
                            }

                            // Left
                            if (currentSlide === scope.length) {
                                sliderLeft.hide();
                            } else {
                                sliderLeft.show();
                            }
                        };

                    // Set initial move
                    setMove();

                    // Swipe left
                    elem.on('swipeleft', function () {
                        if (
                            Math.abs(elem.position().left) + containerWidth + calcTolerance <
                            sliderWidth
                        ) {
                            elem.stop().animate(
                                {
                                    left: '-=' + move,
                                },
                                speed,
                                function () {
                                    moveIndex -= 1;
                                    currentSlide += 1;
                                    setIndicators();
                                    setMove();
                                }
                            );
                        }
                    });

                    // Swipe right
                    elem.on('swiperight', function () {
                        if (elem.position().left < 0) {
                            moveIndex += 1;
                            setMove();
                            elem.stop().animate(
                                {
                                    left: '+=' + move,
                                },
                                speed,
                                function () {
                                    currentSlide -= 1;
                                    setIndicators();
                                }
                            );
                        }
                    });

                    // Add manual left
                    sliderLeft.on('click', function () {
                        elem.trigger('swipeleft');
                    });

                    // Add manual right
                    sliderRight.on('click', function () {
                        elem.trigger('swiperight');
                    });

                    setIndicators();
                }
            };

            // Watch to see if children are rendered
            unregister = scope.$watch('data.model', function (next, prev) {
                if (next && (next !== prev || scope.data.nocheck)) {
                    var length = angular.isArray(next)
                        ? next.length
                        : angular.isObject(next)
                        ? Object.keys(next).length
                        : next;
                    if (elem.children().length === length) {
                        scope.length = length;
                        $timeout(function () {
                            init();
                            unregister();
                        }, 400);
                    }
                }
            });
        },
    };
}]);

// Stop event propagation directive
SEVEN.directive('sevenStop', ['SEVENSettings', function (SEVENSettings) {
    return {
        restrict: 'A',
        link: function (scope, element, attr) {
            var settings = SEVENSettings;
            // Bind to event
            element.bind(attr.sevenStop, function (event) {
                // Default values
                var preventDefault =
                    attr.sevenStopDefault !== undefined ? scope.$eval(attr.sevenStopDefault) : true;
                // Stop propagation
                event.stopPropagation();
                // Prevent default action
                if (preventDefault) event.preventDefault();
                // emit stopped clicks if integration requires click relaying
                if (
                    event.type === 'click' &&
                    settings.mode === 'integration' &&
                    settings.api.events.click
                ) {
                    scope.$emit('Seven.StoppedClick');
                }
            });
        },
    };
}]);

SEVEN.directive('sevenSwipeLeftIgnore', function () {
    return {
        restrict: 'A',
        link: function (scope, element) {
            element.on('swipeleft', function (event) {
                event.stopPropagation();
            });

            scope.$on('$destroy', function () {
                element.off('swipeleft');
            });
        },
    };
});

SEVEN.directive('sevenSwipeLeft', ['$timeout', '$parse', function ($timeout, $parse) {
    return {
        restrict: 'A',
        link: function (scope, element, attrs) {
            element.on('swipeleft', function () {
                var handler = $parse(attrs.sevenSwipeLeft);
                if (angular.isFunction(handler)) {
                    $timeout(function () {
                        handler(scope);
                    });
                }
            });

            scope.$on('$destroy', function () {
                element.off('swipeleft');
            });
        },
    };
}]);

SEVEN.directive('sevenSwipeRightIgnore', function () {
    return {
        restrict: 'A',
        link: function (scope, element) {
            element.on('swiperight', function (event) {
                event.stopPropagation();
            });

            scope.$on('$destroy', function () {
                element.off('swiperight');
            });
        },
    };
});

SEVEN.directive('sevenSwipeRight', ['$timeout', '$parse', function ($timeout, $parse) {
    return {
        restrict: 'A',
        link: function (scope, element, attrs) {
            element.on('swiperight', function () {
                var handler = $parse(attrs.sevenSwipeRight);
                if (angular.isFunction(handler)) {
                    $timeout(function () {
                        handler(scope);
                    });
                }
            });

            scope.$on('$destroy', function () {
                element.off('swiperight');
            });
        },
    };
}]);

// Tabs directive
SEVEN.directive('sevenTabs', ['SEVENSettings', function (SEVENSettings) {
    // Declare variables
    var settings = SEVENSettings;

    // Return definition
    return {
        restrict: 'A',
        link: function (scope, elem) {
            elem.on('click', '.tabs-button', function () {
                // Get elements
                var button = $(this),
                    panes = elem.find('.tabs-pane'),
                    buttons = elem.find('.tabs-button'),
                    index = buttons.index(button),
                    iframe = panes.eq(index).find('iframe');

                // Set active to selected tab
                buttons.removeClass(settings.css.active);
                button.addClass(settings.css.active);
                panes.removeClass(settings.css.active);
                panes.eq(index).addClass(settings.css.active);

                if (iframe.length > 0) {
                    if (iframe[0].iFrameResizer) {
                        iframe[0].iFrameResizer.resize();
                    }
                }

                // Emit tab change event
                scope.$emit('SEVEN.TabsChanged', index);
                scope.$emit('SEVEN.Rendered', index);
            });
        },
    };
}]);

// Thumb directive
SEVEN.directive('sevenThumb', ['$window', function ($window) {
    var helper = {
        support: !!($window.FileReader && $window.CanvasRenderingContext2D),
        isFile: function (item) {
            return angular.isObject(item) && item instanceof $window.File;
        },
        isImage: function (file) {
            var type = '|' + file.type.slice(file.type.lastIndexOf('/') + 1) + '|';
            return '|jpg|png|jpeg|bmp|gif|'.indexOf(type) !== -1;
        },
    };

    return {
        restrict: 'A',
        template: '<canvas/>',
        link: function (scope, element, attributes) {
            if (!helper.support) return;

            var params = scope.$eval(attributes.sevenThumb);

            if (!helper.isFile(params.file)) return;
            if (!helper.isImage(params.file)) return;

            var canvas = element.find('canvas');
            var reader = new FileReader();

            reader.onload = onLoadFile;
            reader.readAsDataURL(params.file);

            function onLoadFile(event) {
                var img = new Image();
                img.onload = onLoadImage;
                img.src = event.target.result;
            }

            function onLoadImage() {
                var width = params.width || (this.width / this.height) * params.height;
                var height = params.height || (this.height / this.width) * params.width;
                canvas.attr({ width: width, height: height });
                canvas[0].getContext('2d').drawImage(this, 0, 0, width, height);
            }
        },
    };
}]);

// Ticket resolver directive
SEVEN.directive(
    'sevenTicketResolver',
    ['$log', '$http', '$timeout', '$rootScope', 'SEVENConfig', 'SEVENRoutes', 'SEVENSettings', 'SEVENModalSvc', 'SEVENProductService', 'SEVENUser', 'SEVENLocale', 'SEVENIntegrator', function (
        // Dependencies
        $log,
        $http,
        $timeout,
        $rootScope,
        SEVENConfig,
        SEVENRoutes,
        SEVENSettings,
        SEVENModalSvc,
        SEVENProductService,
        SEVENUser,
        SEVENLocale,
        SEVENIntegrator
    ) {
        // Define variables
        var user = SEVENUser,
            config = SEVENConfig,
            routes = SEVENRoutes,
            settings = SEVENSettings,
            modals = SEVENModalSvc,
            service = SEVENProductService,
            locale = SEVENLocale,
            integrator = SEVENIntegrator,
            template,
            statuses;

        // Create template
        template = [
            '<div class="n-ticket-resolver">',
            '<div class="item item-status-{{item.status}} clearfix" ng-repeat="item in items" ng-attr-id="{{item.id}}">',
            '<div class="item-icon">',
            '<i class="n-i n-i-{{item.icon}}"></i>',
            '</div>',
            '<div class="item-title" ng-bind="item.title"></div>',
            '<div class="item-description" ng-bind="item.description"></div>',
            '</div>',
            '</div>',
        ].join('');

        // Status enum
        statuses = {
            resolving: 1,
            success: 2,
            rejected: 3,
            error: 4,
            resolved: 5,
            generated: 6,
        };

        // Return directive object
        return {
            restrict: 'E',
            replace: true,
            scope: true,
            template: template,
            link: function (scope) {
                // Bind scope
                scope.items = [];

                // Declare variables
                var addItem,
                    changeStatus,
                    setIcon,
                    timeout = null,
                    check = null,
                    timeoutLength = 30000,
                    checkTimeout = 10000,
                    checkTicket,
                    startTimeout,
                    hideOnResolve,
                    openDetail,
                    message = config.messages.general;

                // Add item
                addItem = function (data) {
                    var item = {
                        id: data.id,
                        status: data.status || statuses.resolving,
                        title: data.title || message.ticketResolving,
                        description: data.description || message.pleaseWait,
                    };

                    item.icon = setIcon(item.status);
                    scope.items.push(item);
                };

                // Change status
                changeStatus = function (data) {
                    // Declare variables
                    var item = {},
                        rejected = false,
                        existing,
                        current,
                        dataStatus;

                    // Check data parameter
                    if (typeof data === 'string') {
                        // Set unique id
                        item.id = data;
                    } else {
                        // Set unique id
                        item.id = data.requestUuid;
                        dataStatus = (
                            data.status && data.status.value ? data.status.value : data.status
                        ).toUpperCase();
                        // Check error
                        if (data.code && data.code.toString().length > 0) {
                            // Show error
                            rejected = dataStatus === 'REJECTED';
                            item.status = rejected ? statuses.rejected : statuses.error;
                            item.title = rejected ? message.ticketError : message.ticketRejected;
                            item.description = data.message;
                        } else {
                            // TEMP: Ignore payedout status
                            if (dataStatus === 'PAYEDOUT') return;
                            // Show status
                            item.status = statuses.success;
                            item.title =
                                data.action && data.action.toUpperCase() === 'CANCEL'
                                    ? message.ticketCanceled
                                    : message.ticketConfirmed;
                            item.description = message.ticketCode + ': ' + data.id;
                        }
                    }

                    // Check if item exists
                    existing = scope.items.filter(function (n) {
                        return n.id === item.id;
                    });

                    // Update or add new item
                    if (existing.length === 1) {
                        current = existing[0];
                        current.status = item.status;
                        current.title = item.title;
                        current.description = item.description;
                        current.icon = setIcon(current.status);
                    } else {
                        addItem(item);
                    }

                    // Hide message on resolve
                    hideOnResolve(item);
                };

                // Hide on resolve
                hideOnResolve = function (item) {
                    if (item.status && item.status !== statuses.resolving) {
                        $timeout(function () {
                            $('#' + item.id).fadeOut(function () {
                                // Remove item
                                scope.items = scope.items.filter(function (n) {
                                    return n.id !== item.id;
                                });
                            });
                        }, 5000);
                    }
                };

                // Set item icon
                setIcon = function (status) {
                    var icon;
                    switch (status) {
                        case statuses.resolving:
                            icon = 'arrow-right-a';
                            break;
                        case statuses.success:
                        case statuses.generated:
                            icon = 'check-a';
                            break;
                        case statuses.rejected:
                        case statuses.error:
                            icon = 'close';
                            break;
                    }

                    return icon;
                };

                // Start timeout on connection lost
                startTimeout = function (data) {
                    var item = {};
                    timeout = $timeout(function () {
                        item.id = data;
                        item.status = statuses.resolved;
                        hideOnResolve(item);
                        if (!integrator.isBalanceChangedListenerActive()) {
                            user.getThrottledBalance();
                        }
                        // Remove bets and enable payin after timeout
                        $rootScope.$broadcast('SEVEN.TicketResolved', false);
                    }, timeoutLength);
                };

                // Check ticket on timeout and resolve it if not pending
                checkTicket = function (item) {
                    // Define check URL
                    var url = config.apiBase + routes.web.player.ticket.check.url.supplant(item);

                    // Create timeout with check
                    check = $timeout(function () {
                        $http
                            .get(url)
                            .then(function (response) {
                                if (response.data.status.value.toUpperCase() !== 'PENDING') {
                                    $rootScope.$broadcast('SEVEN.TicketResolved', response.data);
                                }
                            })
                            .catch(function (response) {
                                $log.info('Ticket: Check error =>', response.data);
                            });
                    }, checkTimeout);
                };

                // Open detail
                openDetail = function (item) {
                    $http
                        .get(item.url, {
                            params: {
                                webCode: item.description,
                                cpvUuid: item.cpvUuid,
                                locale: locale.activeLanguage,
                            },
                        })
                        .then(function (response) {
                            // Parse data
                            var data = response.data,
                                type = service.getTicketType(data.product),
                                tickets = service.parseTickets(data.product, type, [data]);

                            // Open modal
                            if (tickets.length) {
                                modals
                                    .showModal({
                                        templateUrl:
                                            settings.directory.app +
                                            'shared/tickets/view-' +
                                            type +
                                            '-ticket.html',
                                        controller: [
                                            '$scope',
                                            'close',
                                            function (scope, close) {
                                                // Bind to modal scope
                                                scope.ticket = tickets[0];
                                                scope.messages = config.messages.general;
                                                scope.code = item.description;
                                                scope.close = close;
                                                scope.print = function () {
                                                    $http
                                                        .post(
                                                            settings.api.url[settings.server] +
                                                                '/print/ticket-code',
                                                            {
                                                                page: {
                                                                    title: item.title,
                                                                },
                                                                data: {
                                                                    code: item.description,
                                                                },
                                                            }
                                                        )
                                                        .then(function (response) {
                                                            var frame = window.frames.printFrame;
                                                            frame.document.write(response.data);
                                                            frame.document.close();
                                                            frame.focus();
                                                            frame.print();
                                                        });
                                                };
                                            },
                                        ],
                                    })
                                    .then(function (modal) {
                                        modal.element.show();
                                    });
                            }
                        })
                        .finally(function () {
                            hideOnResolve(item);
                        });
                };

                // Listen to resolving event
                scope.$on('SEVEN.TicketResolving', function (event, item) {
                    changeStatus(item.requestUuid);
                    checkTicket(item);
                    startTimeout(item.requestUuid);
                });

                // Listen to resolved event
                scope.$on('SEVEN.TicketResolved', function (event, item) {
                    if (!item) return;
                    $timeout.cancel(check);
                    $timeout.cancel(timeout);
                    changeStatus(item);
                });

                // Listen to code generated event
                scope.$on('SEVEN.TicketCodeGenerated', function (event, data) {
                    if (!data) return;

                    var item = {
                        id: data.requestUuid,
                        icon: setIcon(statuses.generated),
                        status: statuses.generated,
                        title: message.ticketCodeGenerated,
                        description: data.code,
                        url: data.url,
                        cpvUuid: data.cpvUuid,
                    };

                    scope.items.push(item);
                    openDetail(item);
                });

                // Controller destroy
                scope.$on('$destroy', function () {
                    // cancel timeout
                    $timeout.cancel(timeout);
                    $timeout.cancel(check);
                });
            },
        };
    }]
);

// Toggle loader directive
SEVEN.directive('sevenToggleLoader', function () {
    return {
        restrict: 'A',
        link: function (scope) {
            if (scope.$last) {
                scope.$emit('SEVEN.StateLoading', false);
            } else {
                scope.$emit('SEVEN.StateLoading', true);
            }
        },
    };
});

// Toggle directive
SEVEN.directive('sevenToggle', ['$document', 'SEVENSettings', function ($document, SEVENSettings) {
    // Reference dependencies
    var settings = SEVENSettings;

    return {
        restrict: 'A',
        link: function (scope, elem, attrs) {
            // Get attibutes
            var group = attrs.sevenToggleGroup;

            // Element click event
            elem.on('click', function () {
                // Toogle active class
                elem.toggleClass(settings.css.active);
                if (group) {
                    // Remove active class from group elements
                    var groupElem = $('[seven-toggle-group="' + group + '"]');
                    groupElem.not(elem).removeClass(settings.css.active);
                }
            });

            // Contract
            scope.contract = function () {
                elem.removeClass(settings.css.active);
            };
        },
    };
}]);

SEVEN.directive('sevenTranslate', ['$filter', function ($filter) {
    return {
        restrict: 'A',
        link: function (scope, element, attributes) {
            element.text($filter('translate')(attributes.sevenTranslate));
        },
    };
}]);

SEVEN.component('sevenDownloadList', {
    templateUrl: /*@ngInject*/ ['SEVENModules', function (SEVENModules) {
        return SEVENModules.directives.downloadList;
    }],
    controller: /*@ngInject*/ ['SEVENCmsService', 'SEVENConfig', function (SEVENCmsService, SEVENConfig) {
        var vm = this;

        var init = function () {
            vm.message = null;
            vm.page = page;
        };

        var loadList = function (currentPage) {
            vm.loading = true;
            return SEVENCmsService.getList(vm.listName, currentPage, vm.pageSize)
                .then(function (response) {
                    vm.data = response.data;
                    vm.message =
                        vm.data.list.items.length > 0
                            ? null
                            : SEVENConfig.messages.general.warningNoData;
                    vm.loading = false;
                })
                .catch(function () {
                    vm.loading = false;
                });
        };

        var page = function (move) {
            if (vm.data.pagination) {
                return loadList(vm.data.pagination.currentPage + move);
            }
        };

        var update = function (changes) {
            if (changes.listName.currentValue) {
                loadList();
            }
        };

        vm.$onInit = init;
        vm.$onChanges = update;
    }],
    controllerAs: 'downloadVm',
    bindings: {
        listName: '@',
        pageSize: '<',
    },
});

// Emptybox directive
SEVEN.directive('sevenEmptybox', ['SEVENSettings', function (SEVENSettings) {
    // Reference dependencies
    var settings = SEVENSettings;

    return {
        restrict: 'E',
        replace: true,
        templateUrl: settings.directory.app + 'shared/emptybox/view.html',
        scope: {
            enabled: '=',
            url: '=?',
            game: '@',
            button: '=',
            description: '=',
        },
    };
}]);

SEVEN.component('sevenExclusionOverlay', {
    template: [
        '<div class="n-overlay exclusion-overlay" ng-class="{ mobile: exclusionOverlay.mobile }">',
        '<div class="exclusion-overlay-wrapper">',
        '<div class="exclusion-chip text-bold" ng-bind="::(exclusionOverlay.chipText)"></div>',
        '<div class="exclusion-message" ng-bind="::(exclusionOverlay.exclusionMessage)"></div>',
        '<div class="exclusion-period-message" ng-bind="exclusionOverlay.periodMessage"></div>',
        '</div>',
        '</div>',
    ].join(''),
    controllerAs: 'exclusionOverlay',
    controller: /*@ngInject*/ ['SEVENConfig', function (SEVENConfig) {
        var vm = this,
            config = SEVENConfig;

        var init = function () {
            vm.chipText = config.messages.player.selfExcluded;
            vm.exclusionMessage = config.messages.player.selfExclusionFromPlaying;
            vm.periodMessage = config.messages.player.selfExclusionUntil.supplant({
                date: vm.permanentlyExcluded
                    ? config.messages.player.accountClosurePermanent
                    : moment(vm.dateTo).format('l'),
            });
        };

        var onDateChange = function (changes) {
            if (changes.dateTo || changes.permanentlyExcluded) {
                vm.periodMessage = config.messages.player.selfExclusionUntil.supplant({
                    date: vm.permanentlyExcluded
                        ? config.messages.player.accountClosurePermanent
                        : moment(vm.dateTo).format('l'),
                });
            }
        };

        vm.$onInit = init;
        vm.$onChanges = onDateChange;
    }],
    bindings: {
        dateTo: '<',
        mobile: '<',
        permanentlyExcluded: '<',
    },
});

// TODO: Convert to common component (not just games) and implement favorites from Gravity
SEVEN.directive('sevenFavorites', ['SEVENSettings', 'SEVENMenus', function (SEVENSettings, SEVENMenus) {
    var settings = SEVENSettings,
        menus = SEVENMenus;

    return {
        replace: true,
        scope: {
            title: '=',
            menu: '=',
        },
        templateUrl: function () {
            return settings.directory.app + 'shared/favorites/view.html';
        },
        controller: ['$scope', function ($scope) {
            var init = function () {
                var menu =
                    settings.isTerminalLayout && menus.virtual_games_terminal_layout
                        ? menus.virtual_games_terminal_layout
                        : menus[$scope.menu || 'virtual_games'];

                $scope.items = menu;
                $scope.useH1TagForActiveItem = settings.company.seo.useH1Tag;
            };

            init();
        }],
    };
}]);

// Conver object to array filter
SEVEN.filter('arrayize', function () {
    return function (obj, addKey) {
        if (!angular.isObject(obj)) return obj;
        if (addKey === false) {
            return Object.keys(obj).map(function (key) {
                return obj[key];
            });
        } else {
            return Object.keys(obj).map(function (key) {
                var value = obj[key];
                return angular.isObject(value)
                    ? Object.defineProperty(value, '$key', {
                          enumerable: false,
                          value: key,
                      })
                    : { $key: key, $value: value };
            });
        }
    };
});

SEVEN.filter('capitalize', function () {
    return function (input) {
        return angular.isString(input) && input.length > 0
            ? input.charAt(0).toUpperCase() + input.substr(1).toLowerCase()
            : input;
    };
});

// Contains array filter
SEVEN.filter('contains', function () {
    return function (list, array, element) {
        if (list && array && array.length > 0) {
            if (element) {
                return list.filter(function (item) {
                    if (element.indexOf('.') > -1) {
                        var prop = item;
                        element.split('.').forEach(function (n) {
                            prop = prop[n];
                        });
                        return array.indexOf(prop) > -1;
                    }
                    return array.indexOf(item[element]) > -1;
                });
            } else {
                return list.filter(function (item) {
                    return array.indexOf(item) > -1;
                });
            }
        }

        return list;
    };
});

// Empty filter
SEVEN.filter('empty', function () {
    var key, input, condition;

    return function (inputs, obj) {
        if (inputs) {
            key = Object.keys(obj)[0];
            if (inputs.length > 0) {
                input = inputs[0];
                if (input[key]) {
                    condition = obj[key] ? input[key].length === 0 : input[key].length > 0;
                    if (condition) {
                        return inputs;
                    }
                }
            }
        }
    };
});

// Exists in object filter
SEVEN.filter('exists', function () {
    return function (inputs, prop) {
        return inputs.filter(function (input) {
            return input[prop];
        });
    };
});

// Format filter
SEVEN.filter('format', function () {
    return function (input, data) {
        if (input && data) {
            return input.supplant(data);
        }

        return input;
    };
});

// Greater than filter
SEVEN.filter('greaterThan', function () {
    return function (list, element, value) {
        if (list) {
            if (element) {
                return list.filter(function (item) {
                    return item[element] > value;
                });
            } else {
                return list.filter(function (item) {
                    return item > value;
                });
            }
        }

        return list;
    };
});

// Object key filter
SEVEN.filter('key', function () {
    return function (items, key) {
        var result = {};

        angular.forEach(items, function (itemValue, itemKey) {
            if (itemKey.indexOf(key) > -1) {
                result[itemKey] = itemValue;
            }
        });

        return result;
    };
});

// Leading zero filter
SEVEN.filter('leading', function () {
    return function (input, length) {
        // Convert number
        var number = parseInt(input, 10);

        // Check valid
        length = parseInt(length, 10);
        if (isNaN(number) || isNaN(length)) {
            return input;
        }

        // Add leading zeros
        number = '' + number;
        while (number.length < length) {
            number = '0' + number;
        }

        // Return formatted
        return number;
    };
});

// Localize filter
// NOTE: Breaks locale string key into object
SEVEN.filter('localize', function () {
    // Locale key and parent object are mandatory
    return function (input, parent) {
        if (input && parent) {
            return input.localize(parent);
        }
    };
});

SEVEN.filter('moment', function () {
    var formats = {
        time: 'LT',
        timeShort: 'HH:mm',
        date: 'L',
        datetime: 'L LT',
        full: 'LLLL',
        day: 'dddd',
        shortDate: 'DD.MM',
        shortDay: 'ddd',
    };

    return function (input, format, utc, add, language) {
        var time = moment.isMoment(input) ? input.clone() : moment(input);
        var locale = angular.isDefined(language) ? language : moment.locale();

        if (!angular.isUndefined(add)) {
            add = parseInt(add, 10);
            if (add !== 0) {
                time = time.add(add, 'h');
            }
        }

        return utc
            ? time
                  .locale(locale)
                  .utc()
                  .format(formats[format] || format)
            : time.locale(locale).format(formats[format] || format);
    };
});

// Contains array filter
SEVEN.filter('multiselect', function () {
    return function (input, filterObj, key) {
        var output = [];

        if (Object.keys(filterObj).length === 0) {
            return input;
        }

        for (var k in filterObj) {
            for (var i = 0; i < input.length; i++) {
                if (input[i][key] === k) output.push(input[i]);
            }
        }
        return output;
    };
});

// Adds thousands separator (',') (e.g. 1261224.241 => 12,612,224.241)
SEVEN.filter('numberFormatter', function () {
    return function (input) {
        if (typeof input === 'number') {
            input = input.toFixed(2);
        }
        var parts = input.toString().split('.');
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        parts[1] = parts[1] ? parts[1].substring(0, 2) : '00';
        return parts.join('.');
    };
});

// Offset filter for paging
SEVEN.filter('offset', function () {
    return function (input, start) {
        if (input) {
            start = parseInt(start, 10);
            return input.slice(start);
        }

        return input;
    };
});

// Range filter
SEVEN.filter('range', function () {
    return function (input, total, start, reverse) {
        // Check default values
        start = start || 1;
        reverse = reverse || false;

        // Parse total value
        total = parseInt(total);

        // Create range
        for (var i = start; i <= total; i += 1) {
            input.push(i);
        }

        // Return range
        return reverse ? input.reverse() : input;
    };
});

// Round filter
SEVEN.filter('round', function () {
    return function (input, decimals) {
        return Math.round(input * Math.pow(10, decimals)) / Math.pow(10, decimals);
    };
});

// Split filter
SEVEN.filter('split', function () {
    return function (input, char, index) {
        if (input && char && input.indexOf(char) > -1) {
            var array = input.split(char);
            if (index < array.length) {
                return input.split(char)[index];
            }
        }

        if (index === 0) {
            return input;
        }
    };
});

// Time filter
SEVEN.filter('time', function () {
    var formats = {
        time: 'LT',
        timeShort: 'HH:mm',
        date: 'L',
        datetime: 'L LT',
        fullDateTime: 'LLL',
        full: 'LLLL',
        day: 'dddd',
        shortDate: 'DD.MM',
        shortDay: 'ddd',
        preMatchSingle: 'HH:mm - DD/MM/GG',
        preMatchOverviewMob: 'DD.MM. HH:mm',
        preMatchSingleviewMob: 'DD.MM.GGGG. - HH:mm',
    };

    return function (input, settings) {
        var timezone = settings.timezone;
        var t = moment(input);
        var tzTime = t.tz(timezone);
        var format = settings.format ? formats[settings.format] : formats.time;

        return (timezone ? tzTime : t).format(format);
    };
});

/**
 * @ngodc filter
 * @memberOf SEVEN
 * @name translate
 * @kind @function
 *
 * @param {string} input Translation key to translate - use 7Web's module.camelCase format i.e general.mostPopular.
 * @param {Object} [interpolationParameters] - Interpolation object with values to interpolate
 * @returns {string} Translated key
 */
SEVEN.filter('translate', ['SEVENConfig', function (SEVENConfig) {
    return function (input, interpolationParameters) {
        var translationResult = input;

        if (input) {
            translationResult = input.localize(SEVENConfig.messages);
        }

        if (
            angular.isDefined(interpolationParameters) &&
            angular.isObject(interpolationParameters)
        ) {
            translationResult = translationResult.supplant(interpolationParameters);
        }

        return translationResult;
    };
}]);

// Unique filter
SEVEN.filter('unique', function () {
    return function (input, property) {
        var output = [],
            keys = [];

        angular.forEach(input, function (item) {
            var key = item[property];
            if (keys.indexOf(key) === -1) {
                keys.push(key);
                output.push(item);
            }
        });

        return output;
    };
});

(function () {
    SEVEN.component('sevenFixedFooter', {
        templateUrl: 'app/shared/fixed-footer/fixed-footer.html',
        controllerAs: 'fixedFooter',
        controller: ['SEVENMenus', 'SEVENSettings', function (SEVENMenus, SEVENSettings) {
            var vm = this;
            var settings = SEVENSettings;
            var menus = SEVENMenus;
            var isMobile = settings.isMobile;

            var filterMenuItems = function (item) {
                var allowedMobileURLs = ['/company/help', '/company/about'];

                return isMobile ? allowedMobileURLs.includes(item.url) : true;
            };

            var mapMenuItems = function (item) {
                var externalURL =
                    typeof item.url === 'string' ? item.url.match('^https?://') : false;
                var target = item.customData.target || (externalURL ? '_blank' : null);

                return {
                    title: item.title,
                    url: item.url,
                    target: target,
                };
            };

            var init = function () {
                vm.isMobile = isMobile;
                vm.legal = settings.company.legal;

                if (angular.isArray(menus.footer_fixed)) {
                    vm.menu = menus.footer_fixed.filter(filterMenuItems).map(mapMenuItems);
                }
            };

            vm.$onInit = init;
        }],
    });
})();

SEVEN.directive(
    'sevenFooter',
    ['$templateRequest', '$compile', 'SEVENSettings', 'SEVENConfig', 'SEVENMenus', function ($templateRequest, $compile, SEVENSettings, SEVENConfig, SEVENMenus) {
        var settings = SEVENSettings,
            config = SEVENConfig,
            menus = SEVENMenus;

        var imageSettingsPath =
            settings.footer.cardsImage == 'default'
                ? settings.externalSharedImagesPath
                : settings.externalCompanyImagesPath;

        var mapCompanyMenuItem = function (item) {
            var externalURL = typeof item.url === 'string' ? item.url.match('^https?://') : false;
            var target = item.customData.target || (externalURL ? '_blank' : null);

            return {
                title: item.title,
                url: item.url,
                icon: item.icon,
                target: target,
                rel: item.customData.rel,
                titleStyle: item.customData.titleStyle,
            };
        };

        var mapCreditCardMenuItem = function (item) {
            return {
                name: item.customData.name,
                imagePath: imageSettingsPath + 'cards',
            };
        };

        var mapBankMenuItem = function (item) {
            return {
                name: item.customData.name,
                url: item.url,
                imagePath: settings.externalSharedImagesPath + 'banks',
            };
        };

        var mapSupportMenuItem = function (item) {
            var isTelephoneLink = typeof item.url === 'string' ? item.url.match('^tel:') : false;
            var clickable = !!item.url && (isTelephoneLink ? settings.isMobile : true);

            return {
                title: item.title,
                subtitle: item.subtitle,
                url: item.url,
                icon: item.icon,
                subtitleStyle: item.customData.subtitleStyle,
                rel: item.customData.rel,
                target: item.customData.target || '_blank',
                clickable: clickable,
            };
        };

        var mapFooterResponsibleGamingMenuItem = function (item) {
            return {
                name: item.customData.name,
                url: item.url,
                rel: item.customData.rel,
                target: item.customData.target,
                imagePath: imageSettingsPath + 'cards',
            };
        };

        var mapMiniFooterMenu = function (item) {
            var externalURL = typeof item.url === 'string' ? item.url.match('^https?://') : false;
            var target = item.customData.target || (externalURL ? '_blank' : null);

            return {
                title: item.title,
                url: item.url,
                target: target,
            };
        };

        return {
            restrict: 'E',
            replace: true,
            link: function (scope, element, attrs) {
                var messages = config.messages.general;
                scope.externalUrls = settings.externalUrls;

                var scopeMenuMappings = [
                    {
                        scopeVariable: 'companyMenu',
                        menu: 'footer_company',
                        itemMappingFunction: mapCompanyMenuItem,
                    },
                    {
                        scopeVariable: 'paymentCardsMenu',
                        menu: 'footer_payment_cards',
                        itemMappingFunction: mapCreditCardMenuItem,
                    },
                    {
                        scopeVariable: 'paymentBanksMenu',
                        menu: 'footer_payment_banks',
                        itemMappingFunction: mapBankMenuItem,
                    },
                    {
                        scopeVariable: 'footerSupportMenu',
                        menu: 'footer_support',
                        itemMappingFunction: mapSupportMenuItem,
                    },
                    {
                        scopeVariable: 'responsibleGamingCardsBigMenu',
                        menu: 'footer_responsible_gaming_cards_big',
                        itemMappingFunction: mapFooterResponsibleGamingMenuItem,
                    },
                    {
                        scopeVariable: 'responsibleGamingCardsSmallMenu',
                        menu: 'footer_responsible_gaming_cards_small',
                        itemMappingFunction: mapFooterResponsibleGamingMenuItem,
                    },
                    {
                        scopeVariable: 'footerMiniMenu',
                        menu: 'footer_mini',
                        itemMappingFunction: mapMiniFooterMenu,
                    },
                ];

                scopeMenuMappings.forEach(function (scopeMenuMapping) {
                    if (angular.isArray(menus[scopeMenuMapping.menu])) {
                        scope[scopeMenuMapping.scopeVariable] = menus[scopeMenuMapping.menu].map(
                            scopeMenuMapping.itemMappingFunction
                        );
                    }
                });

                if (messages.copyright) {
                    scope.copyright = messages.copyright.supplant({
                        company: config.client.contact.copyright,
                        currentYear: new Date().getFullYear(),
                    });
                }

                if (messages.copyrightMini) {
                    scope.copyrightMini = messages.copyrightMini.supplant({
                        company: config.client.contact.copyright,
                        currentYear: new Date().getFullYear(),
                    });
                }

                $templateRequest(attrs.template).then(function (html) {
                    var template = angular.element(html);
                    element.append(template);
                    $compile(template)(scope);
                });
            },
        };
    }]
);

SEVEN.directive('sevenHorizontalSlidingList', ['$window', '$timeout', function ($window, $timeout) {
    return {
        restrict: 'E',
        transclude: true,
        replace: true,
        templateUrl: 'app/shared/horizontal-sliding-list/view.html',
        scope: {
            disabled: '<',
            itemsCount: '<',
        },
        link: function (scope, elem, attrs, controller, transclude) {
            scope.previousStepBtn = false;
            scope.nextStepBtn = false;
            var initial = true;

            var containerElement;
            var target = elem.find('[ng-transclude]');
            // pass 'clone attach function' to transclude
            transclude(function (clone) {
                // replace target content with clone
                target.empty();
                target.replaceWith(clone);
            });

            $timeout(function () {
                setupElements(true);
            }, 0);

            scope.onNext = function () {
                // get latest containers dimensions
                var wrapperWidth = containerElement.innerWidth();
                var fullContainerWidth = containerElement[0].scrollWidth;
                var currentContainerScroll = containerElement.scrollLeft();

                // check is there more content to be scrolled on the right side
                var leftForScrolling = fullContainerWidth - wrapperWidth - currentContainerScroll;

                // calculate scroll step
                var defaultStep = wrapperWidth / 3;
                var scrollStep = defaultStep;

                if (scrollStep > leftForScrolling) {
                    scrollStep = leftForScrolling;
                    scope.nextStepBtn = false;
                }

                containerElement.animate(
                    {
                        scrollLeft: currentContainerScroll + scrollStep,
                    },
                    200
                );

                // show step button on the left
                scope.$evalAsync(function () {
                    scope.previousStepBtn = true;
                });
            };

            scope.onPrevious = function () {
                // get latest containers dimensions
                var wrapperWidth = elem.innerWidth();
                var currentContainerScroll = containerElement.scrollLeft();

                // calculate scroll step
                var defaultStep = wrapperWidth / 3;
                var scrollStep =
                    defaultStep > currentContainerScroll ? currentContainerScroll : defaultStep;

                var newScrollValue = currentContainerScroll - scrollStep;

                containerElement.animate(
                    {
                        scrollLeft: newScrollValue,
                    },
                    200
                );

                // Update step buttons visibility
                scope.$evalAsync(function () {
                    scope.nextStepBtn = true;
                    if (newScrollValue <= 0) {
                        scope.previousStepBtn = false;
                    }
                });
            };

            var setupElements = function (initialization) {
                if (scope.disabled) return;

                if (initialization && typeof initialization === 'boolean') {
                    containerElement = elem.find(attrs.containerElement);
                    elem.addClass('horizontal-sliding-list');
                }

                var fullContainerWidth = containerElement[0].scrollWidth;
                var wrapperWidth = elem.innerWidth();

                scope.nextStepBtn = fullContainerWidth > wrapperWidth;
                scope.previousStepBtn = false;
            };

            scope.$watch(
                function () {
                    return scope.itemsCount;
                },
                function () {
                    if (initial) {
                        initial = false;
                        return;
                    }

                    $timeout(function () {
                        setupElements();
                        containerElement.animate(
                            {
                                scrollLeft: 0,
                            },
                            0
                        );
                    }, 0);
                }
            );

            $window.addEventListener('resize', setupElements);

            scope.$on('$destroy', function () {
                $window.removeEventListener('resize', setupElements);
            });
        },
    };
}]);

SEVEN.factory('SEVENGravityInt', ['SEVENSettings', 'SEVENConfig', 'SEVENLocale', function (SEVENSettings, SEVENConfig, SEVENLocale) {
    var settings = SEVENSettings,
        config = SEVENConfig,
        locale = SEVENLocale,
        backendApi = settings.api.gravity,
        baseUrl = backendApi.url,
        baseUrlLength = baseUrl.length,
        blacklistedUrls = [
            settings.api.gravity.url + '/web/articles/',
            settings.api.gravity.url + '/web/articles/category/',
        ];

    var isValidUrl = function (uri) {
        var url = uri.toString(),
            urlLength = url.length;

        return urlLength >= baseUrlLength && url.substring(0, baseUrlLength) === baseUrl;
    };

    var isUrlBlacklisted = function (uri) {
        var url = uri.toString(),
            gravityUrl,
            blacklistRegExp,
            urlBlacklisted = false;

        for (var i = 0; i < blacklistedUrls.length; i++) {
            gravityUrl = blacklistedUrls[i];
            blacklistRegExp = new RegExp(gravityUrl);
            if (blacklistRegExp.test(url)) {
                urlBlacklisted = true;
                break;
            }
        }

        return urlBlacklisted;
    };

    return {
        request: function (requestConfig) {
            if (!isValidUrl(requestConfig.url) || isUrlBlacklisted(requestConfig.url))
                return requestConfig;

            requestConfig.headers['Content-Type'] = 'application/json';
            requestConfig.headers['X-Nsft-SCD-Company-Id'] = config.client.uuid;
            requestConfig.headers['X-Nsft-SCD-Company-Name'] = settings.company.name;
            requestConfig.headers['X-Nsft-SCD-App-Base-Name'] = backendApi.appName;
            requestConfig.headers['X-Nsft-SCD-App-Name'] = settings.company.name + '_web';
            requestConfig.headers['X-Nsft-SCD-Locale'] = locale.activeLanguage;

            return requestConfig;
        },
    };
}]);

SEVEN.factory(
    'SEVENPlatformInt',
    ['$log', '$q', 'SEVENSettings', 'SEVENConfig', 'SEVENLocale', 'SEVENModules', 'SEVENRoutes', 'locker', function (
        $log,
        $q,
        SEVENSettings,
        SEVENConfig,
        SEVENLocale,
        SEVENModules,
        SEVENRoutes,
        locker
    ) {
        var settings = SEVENSettings,
            config = SEVENConfig,
            locale = SEVENLocale,
            routes = SEVENRoutes;

        var isValidUrl = function (url) {
            return url.toString().substring(0, config.apiBase.length) === config.apiBase;
        };

        var isTicketRoute = function (url) {
            return routes.web.player.ticket.add.url === url.substring(config.apiBase.length);
        };

        return {
            request: function (request) {
                if (isValidUrl(request.url)) {
                    request.headers['Content-Type'] = 'application/json';
                    request.headers['HTTP-X-SEVEN-CLUB-UUID'] =
                        request.headers['HTTP-X-SEVEN-CLUB-UUID'] || config.client.uuid;
                    request.headers['HTTP-X-NAB-DP'] = settings.platform.delivery;
                    request.headers['SEVEN-LOCALE'] = locale.activeLanguage;
                }

                return request;
            },
            response: function (response) {
                var url = response.config.url.toString();

                if (isValidUrl(url)) {
                    // Clear failed count
                    if (isTicketRoute(url)) {
                        try {
                            locker.driver('session').put('FailedTicketCount', 0);
                        } catch (e) {
                            $log.warn('Session storage error =>', e);
                        }
                    }
                }

                return response;
            },
            responseError: function (rejection) {
                var url = rejection.config.url.toString();

                if (isValidUrl(url)) {
                    // Store failed tickets in local storage
                    if (isTicketRoute(url)) {
                        try {
                            var failedTicketCount = locker
                                .driver('session')
                                .get('FailedTicketCount', 0);
                            locker
                                .driver('session')
                                .put('FailedTicketCount', failedTicketCount + 1);
                        } catch (e) {
                            $log.warn('Session storage error =>', e);
                        }
                    }
                }

                return $q.reject(rejection);
            },
        };
    }]
);

SEVEN.factory('SEVENRetryInt', ['$q', '$injector', '$timeout', function ($q, $injector, $timeout) {
    const RETRY_LIMIT = 3;

    return {
        responseError: function (rejection) {
            const isRetryLimitExceeded =
                rejection.config.retryNum && rejection.config.retryNum >= RETRY_LIMIT;
            const shouldRetry = rejection.config.sevenRetryOnFail || rejection.status === -1;

            if (!shouldRetry || isRetryLimitExceeded) {
                return $q.reject(rejection);
            }

            rejection.config.retryNum = rejection.config.retryNum
                ? rejection.config.retryNum + 1
                : 1;
            rejection.config.url = rejection.config.url.toString();

            const $http = $injector.get('$http');

            return $timeout(rejection.config.retryNum * 1000).then(function () {
                return $http(rejection.config);
            });
        },
    };
}]);

// Ticket interceptor
SEVEN.factory('SEVENTicketInt', ['SEVENConfig', 'SEVENSettings', function (SEVENConfig, SEVENSettings) {
    // Reference dependencies
    var config = SEVENConfig,
        settings = SEVENSettings,
        interceptor = settings.platform.interceptors.ticket,
        isValidUrl,
        createUrlParams;

    // Create URL parameters
    createUrlParams = function (interceptorPath, match) {
        if (angular.isArray(match) && interceptorPath.urlParams) {
            var params = {};
            interceptorPath.urlParams.forEach(function (param, index) {
                if (match[index + 1]) params[param] = match[index + 1];
            });

            return params;
        }
    };

    // Check valid url
    isValidUrl = function (url) {
        var i,
            apiUrl = config.apiBase + '/web/',
            path = url.toString().substring(apiUrl.length),
            len;

        if (interceptor.paths) {
            len = interceptor.paths.length;
            var match, interceptorPath;

            for (i = 0; i < len; i++) {
                interceptorPath = interceptor.paths[i];
                match = path.match(new RegExp(interceptorPath.url));
                if (match) {
                    return {
                        path: interceptor.paths[i],
                        params: createUrlParams(interceptorPath, match),
                    };
                }
            }
        }
    };

    // Create interceptor
    return {
        request: function (request) {
            // Check valid URL
            var match = isValidUrl(request.url.toString());
            if (match) {
                var transfer = match.path.transfer;
                if (transfer) {
                    var originalUrl = request.url.toString();
                    request.url = transfer[settings.server] || transfer;
                    // Add additional params
                    request.params = request.params || {};
                    request.params.sourceRoute = originalUrl;
                    if (match.params) angular.merge(request.params, match.params);
                }
            }

            // Return request
            return request;
        },
    };
}]);

SEVEN.factory('SEVENTokenInt', ['$q', 'locker', 'SEVENSettings', 'SEVENResources', 'SEVENToken', function ($q, locker, SEVENSettings, SEVENResources, SEVENToken) {
    var settings = SEVENSettings,
        resources = SEVENResources,
        tokenService = SEVENToken;

    var isValidUrl = function (url) {
        var i,
            item,
            len = resources.auth.tokenlist.length;

        for (i = 0; i < len; i++) {
            item = resources.auth.tokenlist[i];
            if (url.substring(0, item.length) === item) {
                return true;
            }
        }

        return false;
    };

    return {
        request: function (request) {
            var token = tokenService.getToken();
            // Check if request qualifies for token
            if (token && isValidUrl(request.url.toString())) {
                if (request.auth !== false) {
                    request.headers.Authorization = 'Bearer ' + token;
                }
            }

            return request;
        },
        response: function (response) {
            // Check if response qualifies for token
            var url = response.config.url.toString();
            if (isValidUrl(url)) {
                var token = response.headers('Access-Token'),
                    refresh = url.indexOf('/balance') > -1;
                if (token && refresh) {
                    tokenService.setToken(token);
                }
            }

            return response;
        },
        responseError: function (rejection) {
            var url = rejection.config.url.toString();

            if (isValidUrl(url)) {
                // Check unauthenticated
                if (rejection.status === 403) {
                    if (url.indexOf('/login_check') < 0 && locker.get('Device')) {
                        locker.forget('User');
                        tokenService.removeToken();

                        if (rejection.config.unauthorizedRedirectURL) {
                            window.location.href = rejection.config.unauthorizedRedirectURL;
                        } else if (settings.mode === 'standalone') {
                            window.location.href =
                                '//' + window.location.host + window.location.pathname;
                        } else {
                            window.location.reload();
                        }
                    }
                }
            }

            return $q.reject(rejection);
        },
    };
}]);

SEVEN.factory('SEVENVersionInt', ['SEVENSettings', 'SEVENModules', function (SEVENSettings, SEVENModules) {
    var settings = SEVENSettings,
        modules = SEVENModules;

    /**
     * @name getMappedURL
     * @param {string} url
     * @return {string|null}
     */
    var getMappedURL = function (url) {
        if (!(modules && modules.fileMap)) return null;

        var fileMap = modules.fileMap;

        for (var originalFileURl in fileMap) {
            // Check if url is key of busted file list, otherwise check if it is value (already busted and replaced
            if (fileMap[url]) {
                return fileMap[url];
            } else if (fileMap[originalFileURl] === url) {
                return url;
            }
        }

        return null;
    };

    /**
     * @name getVersionedURL
     * @param {string} url
     * @return {string}
     */
    var getVersionedURL = function (url) {
        return getMappedURL(url) || url.version(settings.version);
    };

    return {
        request: function (request) {
            if (request.url) {
                request.url = getVersionedURL(request.url);
            }

            return request;
        },
    };
}]);

// Keyboard directive
SEVEN.directive(
    'sevenKeyboard',
    ['$locale', '$injector', '$timeout', '$http', '$rootScope', 'SEVENSettings', 'SEVENConfig', function ($locale, $injector, $timeout, $http, $rootScope, SEVENSettings, SEVENConfig) {
        // Reference dependencies
        var settings = SEVENSettings,
            config = SEVENConfig;

        return {
            restrict: 'E',
            replace: false,
            templateUrl: function (element, attr) {
                // Get passed template or default if not passed
                return attr.template || settings.directory.app + 'shared/keyboard/view.html';
            },
            scope: {
                // Type: numeric
                type: '@',
                initiator: '@',
                initiatorFilter: '@',
                separator: '@',
            },
            link: function (scope) {
                scope.config = config;

                // Set defaults
                scope.type = scope.type || 'numeric';
                scope.separator = scope.separator || $locale.NUMBER_FORMATS.DECIMAL_SEP;

                // Define layouts
                var layouts = {
                    numeric: {
                        rows: [
                            {
                                buttons: [
                                    { value: '1', type: 'number' },
                                    { value: '2', type: 'number' },
                                    { value: '3', type: 'number' },
                                    { value: '4', type: 'number' },
                                    { value: '5', type: 'number' },
                                    { value: '6', type: 'number' },
                                    { value: '7', type: 'number' },
                                    { value: '8', type: 'number' },
                                    { value: '9', type: 'number' },
                                    { value: '0', type: 'number' },
                                ],
                            },
                            {
                                buttons: [
                                    {
                                        value: scope.separator,
                                        type: 'separator',
                                    },
                                    {
                                        value: config.messages.keyboard.buttonDelete,
                                        type: 'backspace',
                                        icon: 'backspace-a',
                                    },
                                    {
                                        value: config.messages.keyboard.buttonConfirm,
                                        type: 'confirm',
                                    },
                                ],
                            },
                        ],
                    },
                };

                if (settings.layout && settings.layout.indexOf('terminal') > -1) {
                    layouts.numeric.rows.unshift({
                        buttons: [
                            { value: 'min', type: 'string' },
                            { value: 'max', type: 'string', disabled: false },
                            { value: '0.5', type: 'predefinedNumber' },
                            { value: '1', type: 'predefinedNumber' },
                            { value: '5', type: 'predefinedNumber' },
                            { value: '10', type: 'predefinedNumber' },
                            { value: '20', type: 'predefinedNumber' },
                            { value: '50', type: 'predefinedNumber' },
                        ],
                    });
                    layouts.numeric.rows[1].buttons.push(
                        { value: scope.separator, type: 'separator' },
                        {
                            value: config.messages.keyboard.buttonDelete,
                            type: 'backspace',
                            icon: 'backspace-a',
                        },
                        {
                            value: config.messages.keyboard.buttonConfirm,
                            type: 'confirm',
                        },
                        {
                            value: config.messages.keyboard.buttonPayin,
                            type: 'payin',
                        }
                    );

                    layouts.numeric.rows.splice(2, 1);

                    scope.$watch('totalStake', function (newVal, oldVal) {
                        if (!isNaN(newVal) && newVal.length > 5) {
                            scope.modalStake = oldVal;
                            $destination.val(scope.modalStake);
                        }
                    });
                }

                // Get elements
                var $initiator = angular.element(scope.initiator),
                    $destination;

                // Reference keyboard type
                scope.show = false;
                var type = scope.type;

                // Show keyboard
                $initiator.on('mousedown touchstart', scope.initiatorFilter, function (e) {
                    if (scope.show) scope.confirm();
                    scope.show = true;
                    $destination = angular.element(e.currentTarget);
                    scope.modalStake = $destination.val();
                    $timeout(function () {
                        scope.$emit('SEVEN.Rendered');
                        scope.$emit('SEVEN.ShowKeyboard', true);
                    }, 100);
                });

                // Press any button
                scope.select = function (button) {
                    // Delete selection if present
                    var deleteSelection = function () {
                        var destination = $destination[0];
                        if (
                            destination &&
                            destination.selectionStart !== destination.selectionEnd
                        ) {
                            $destination.val('');
                            scope.modalStake = '';
                        }
                    };

                    // Check button type
                    switch (button.type) {
                        case 'confirm':
                            scope.confirm();
                            break;
                        case 'payin':
                            scope.payin();
                            break;
                        case 'backspace':
                            deleteSelection();
                            scope.backspace();
                            break;
                        case 'string':
                            scope.setStake(button.value);
                            break;
                        case 'predefinedNumber':
                            deleteSelection();
                            $destination.val(button.value);
                            scope.modalStake = $destination.val();
                            break;
                        default:
                            deleteSelection();
                            $destination.val($destination.val() + button.value);
                            scope.modalStake = $destination.val();
                    }
                };

                // Confirm input and close keyboard
                scope.confirm = function () {
                    var value = $destination.val();
                    var data = $destination.data();

                    if (data) {
                        var controller = data.$ngModelController;
                        controller.$setViewValue(value);
                        controller.$render();
                    }

                    $destination.blur();
                    scope.$emit('SEVEN.Rendered');
                    scope.$emit('SEVEN.ShowKeyboard', false);
                };

                scope.$on('SEVEN.ShowKeyboard', function (type, data) {
                    scope.show = data;
                });

                // Confirm and payin
                scope.payin = function () {
                    scope.confirm();
                    scope.$emit('SEVEN.TicketPayin');
                };

                // Set min/max stake amount
                scope.setStake = function (type) {
                    var data = $destination.data(),
                        controller = data.$ngModelController,
                        stakeData = {
                            action: 'setStake',
                            value: type,
                        };

                    scope.$emit('SEVEN.KeyboardAction', stakeData);
                    $destination.val(controller.$viewValue);

                    $timeout(function () {
                        scope.modalStake = $destination.val();
                    }, 100);
                };

                // Remove last character
                scope.backspace = function () {
                    var value = $destination.val();
                    if (value) {
                        $destination.val(value.substring(0, value.length - 1));
                        scope.modalStake = $destination.val();
                    } else {
                        scope.modalStake = '';
                    }
                };

                // Generate layout
                var generateLayout = function () {
                    scope.layout = layouts[type];
                };

                // Generate
                generateLayout();
            },
        };
    }]
);

angular.module('angular-locker', []).provider('locker', function () {
    var _value = function (value, param) {
        return angular.isFunction(value) ? value(param) : value;
    };

    var _defined = function (value) {
        return angular.isDefined(value) && value !== null;
    };

    var _error = function (msg) {
        console.log('[Locker] ' + msg);
    };

    var _localSupported = function () {
        window.isPrivateBrowsingMode = false;

        try {
            var l = 'l';
            window.localStorage.setItem(l, l);
            window.localStorage.removeItem(l);
            return true;
        } catch (e) {
            window.isPrivateBrowsingMode = true;
            _error('Local storage is not available.');
            return false;
        }
    };

    var defaults = {
        driver: _localSupported() ? 'local' : 'session',
        namespace: 'SEVEN',
        eventsEnabled: true,
        separator: '.',
        extend: {},
    };

    return {
        defaults: function (value) {
            if (!_defined(value)) return defaults;

            angular.forEach(value, function (val, key) {
                if (defaults.hasOwnProperty(key)) defaults[key] = val;
            });
        },

        $get: [
            '$window',
            '$rootScope',
            '$parse',
            function ($window, $rootScope, $parse) {
                function Locker(options) {
                    this._options = options;

                    try {
                        this._registeredDrivers = angular.extend(
                            {
                                local: $window.localStorage,
                                session: $window.sessionStorage,
                            },
                            options.extend
                        );
                    } catch (error) {
                        _error('Cookies are blocked.');
                    }

                    this._resolveDriver = function (driver) {
                        if (!this._registeredDrivers) return;
                        if (!this._registeredDrivers.hasOwnProperty(driver)) {
                            _error('The driver "' + driver + '" was not found.');
                        }

                        return this._registeredDrivers[driver];
                    };

                    this._driver = this._resolveDriver(options.driver);

                    this._namespace = options.namespace;

                    this._separator = options.separator;

                    this._watchers = {};

                    this._checkSupport = function (driver) {
                        if (!_defined(this._supported)) {
                            var l = 'l';
                            try {
                                this._resolveDriver(driver || options.driver).setItem(l, l);
                                this._resolveDriver(driver || options.driver).removeItem(l);
                                this._supported = true;
                            } catch (e) {
                                this._supported = false;
                                this._fallback = this._fallback || {};
                            }
                        }

                        return this._supported;
                    };

                    this._getPrefix = function (key) {
                        if (!this._namespace) return key;

                        return this._namespace + this._separator + key;
                    };

                    this._serialize = function (value) {
                        try {
                            return angular.toJson(value);
                        } catch (e) {
                            return value;
                        }
                    };

                    this._unserialize = function (value) {
                        try {
                            return angular.fromJson(value);
                        } catch (e) {
                            return value;
                        }
                    };

                    this._event = function (name, payload) {
                        if (this._options.eventsEnabled) {
                            $rootScope.$emit(
                                'locker.' + name,
                                angular.extend(payload, {
                                    driver: this._options.driver,
                                    namespace: this._namespace,
                                })
                            );
                        }
                    };

                    this._setItem = function (key, value) {
                        if (!this._checkSupport()) {
                            _error('No support for "' + options.driver + '" driver');
                        }

                        try {
                            var oldVal = this._getItem(key);
                            this._driver.setItem(this._getPrefix(key), this._serialize(value));
                            if (this._exists(key) && !angular.equals(oldVal, value)) {
                                this._event('item.updated', {
                                    key: key,
                                    oldValue: oldVal,
                                    newValue: value,
                                });
                            } else {
                                this._event('item.added', {
                                    key: key,
                                    value: value,
                                });
                            }
                        } catch (e) {
                            _error('Error saving local storage data', e);
                            this._fallback[key] = value;
                        }
                    };

                    this._getItem = function (key) {
                        if (!this._checkSupport()) {
                            _error('No support for "' + options.driver + '" driver');
                            return this._unserialize(this._fallback[key]);
                        }

                        return this._unserialize(this._driver.getItem(this._getPrefix(key)));
                    };

                    this._exists = function (key) {
                        if (!this._checkSupport()) {
                            _error('No support for "' + options.driver + '" driver');
                            return this._fallback.hasOwnProperty(key);
                        }

                        return !angular.isUndefined(this._driver[this._getPrefix(_value(key))]);
                    };

                    this._removeItem = function (key) {
                        if (!this._checkSupport()) {
                            _error('No support for "' + options.driver + '" driver');
                            delete this._fallback[key];
                            return true;
                        }

                        if (!this._exists(key)) return false;

                        this._driver.removeItem(this._getPrefix(key));
                        this._event('item.forgotten', { key: key });

                        return true;
                    };
                }

                Locker.prototype = {
                    put: function (key, value, def) {
                        if (!_defined(key)) return false;
                        key = _value(key);

                        if (angular.isObject(key)) {
                            angular.forEach(
                                key,
                                function (value, key) {
                                    this._setItem(key, _defined(value) ? value : def);
                                },
                                this
                            );
                        } else {
                            if (!_defined(value)) return false;
                            var val = this._getItem(key);
                            this._setItem(key, _value(value, _defined(val) ? val : def));
                        }

                        return this;
                    },

                    add: function (key, value, def) {
                        if (!this.has(key)) {
                            this.put(key, value, def);
                            return true;
                        }

                        return false;
                    },

                    get: function (key, def) {
                        if (angular.isArray(key)) {
                            var items = {};
                            angular.forEach(
                                key,
                                function (k) {
                                    if (this.has(k)) items[k] = this._getItem(k);
                                },
                                this
                            );

                            return items;
                        }

                        if (!this.has(key)) return arguments.length === 2 ? def : void 0;

                        return this._getItem(key);
                    },

                    has: function (key) {
                        return this._exists(key);
                    },

                    forget: function (key) {
                        key = _value(key);

                        if (angular.isArray(key)) {
                            key.map(this._removeItem, this);
                        } else {
                            this._removeItem(key);
                        }

                        return this;
                    },

                    pull: function (key, def) {
                        var value = this.get(key, def);
                        this.forget(key);

                        return value;
                    },

                    all: function () {
                        var items = {};
                        angular.forEach(
                            this._driver,
                            function (value, key) {
                                if (this._namespace) {
                                    var prefix = this._namespace + this._separator;
                                    if (key.indexOf(prefix) === 0)
                                        key = key.substring(prefix.length);
                                }
                                if (this.has(key)) items[key] = this.get(key);
                            },
                            this
                        );

                        return items;
                    },

                    keys: function () {
                        return Object.keys(this.all());
                    },

                    clean: function () {
                        return this.forget(this.keys());
                    },

                    empty: function () {
                        this._driver.clear();

                        return this;
                    },

                    count: function () {
                        return this.keys().length;
                    },

                    bind: function ($scope, key, def) {
                        if (!_defined($scope.$eval(key))) {
                            $parse(key).assign($scope, this.get(key, def));
                            this.add(key, def);
                        }

                        var self = this;
                        this._watchers[key + $scope.$id] = $scope.$watch(
                            key,
                            function (newVal) {
                                self.put(key, newVal);
                            },
                            angular.isObject($scope[key])
                        );

                        return this;
                    },

                    unbind: function ($scope, key) {
                        $parse(key).assign($scope, void 0);
                        this.forget(key);

                        var watchId = key + $scope.$id;

                        if (this._watchers[watchId]) {
                            // execute the de-registration function
                            this._watchers[watchId]();
                            delete this._watchers[watchId];
                        }

                        return this;
                    },

                    driver: function (driver) {
                        return this.instance(angular.extend(this._options, { driver: driver }));
                    },

                    getDriver: function () {
                        return this._driver;
                    },

                    namespace: function (namespace) {
                        return this.instance(
                            angular.extend(this._options, {
                                namespace: namespace,
                            })
                        );
                    },

                    getNamespace: function () {
                        return this._namespace;
                    },

                    supported: function (driver) {
                        return this._checkSupport(driver);
                    },

                    instance: function (options) {
                        return new Locker(options);
                    },
                };

                return new Locker(defaults);
            },
        ],
    };
});

SEVEN.controller(
    'SEVENLoginDialog',
    ['$location', '$state', '$scope', '$rootScope', '$timeout', 'locker', 'SEVENConfig', 'SEVENSettings', 'SEVENUser', 'SEVENVibrator', 'SEVENModalSvc', 'SEVENModules', 'SEVENException', 'SEVENPlayerAuthService', 'SEVENXtremepush', 'SevenGravitySettings', 'SEVENLegalEntitiesService', 'close', '$ocLazyLoad', function (
        $location,
        $state,
        $scope,
        $rootScope,
        $timeout,
        locker,
        SEVENConfig,
        SEVENSettings,
        SEVENUser,
        SEVENVibrator,
        SEVENModalSvc,
        SEVENModules,
        SEVENException,
        SEVENPlayerAuthService,
        SEVENXtremepush,
        SevenGravitySettings,
        SEVENLegalEntitiesService,
        close,
        $ocLazyLoad
    ) {
        // Declare variables
        var config = SEVENConfig,
            settings = SEVENSettings,
            vibrator = SEVENVibrator,
            modalService = SEVENModalSvc,
            modules = SEVENModules,
            recaptcha = modules.plugins.recaptcha,
            exception = SEVENException,
            playerAuthService = SEVENPlayerAuthService,
            user = SEVENUser,
            legalEntitiesService = SEVENLegalEntitiesService,
            loginStrategy = settings.company && settings.company.loginStrategy,
            loginStrategies = config.appSettings.config.loginStrategies;

        var init = function () {
            // Default recaptcha visibility
            recaptcha.visible = false;

            // Bind scope
            $scope.config = config;
            $scope.error = false;
            $scope.message = null;
            $scope.login = login;
            $scope.close = close;
            $scope.closeAndNotify = closeAndNotify;
            $scope.remove = remove;
            $scope.goToForgotPassword = goToForgotPassword;
            $scope.recaptcha = recaptcha;
            $scope.validation = playerAuthService.getLoginValidation(loginStrategy);
            $scope.registerDisabled = modules.routes.playerRegister.disabled;
            $scope.user = {
                username:
                    loginStrategy === 'nickname'
                        ? user.nickname === config.messages.general.guest
                            ? undefined
                            : user.nickname
                        : user.email,
                mobileNumber: null,
            };
            $scope.usernameLabel =
                loginStrategy === 'nickname'
                    ? config.messages.general.nickname
                    : config.messages.general.email;
            $scope.usernamePlaceholder =
                loginStrategy === 'nickname'
                    ? config.messages.general.nickname
                    : config.messages.general.email;
            $scope.externalUrls = settings.externalUrls;
            $scope.passwordType = 'password';
            $scope.strategyItems = [
                {
                    title: config.messages.general.email,
                    value: 'email',
                    loginHelpTooltipMessage: config.messages.general.loginHelpIconTooltipV2,
                },
                {
                    title: config.messages.general.phone,
                    value: 'phoneNumber',
                    loginHelpTooltipMessage:
                        config.messages.general.loginHelpIconTooltipPhoneNumber,
                },
            ];
            $scope.togglePasswordVisibility = togglePasswordVisibility;
            $scope.rememberMe = false;
            $scope.saveRememberMe = saveRememberMe;
            $scope.onRegisterClick = onRegisterClick;
            $scope.showLoginStrategyTabs =
                loginStrategies && loginStrategies.mobileIntegration && loginStrategies.seven;

            var loginModuleSettings = SevenGravitySettings.getDataByKey('module.Login');
            if (loginModuleSettings && loginModuleSettings.rememberMe === true) {
                $scope.rememberMe = loginModuleSettings.rememberMe;
                locker.put('rememberMe', loginModuleSettings.rememberMe);
            }
            $scope.showActiveStrategy =
                !loginStrategies ||
                (loginStrategies && loginStrategies.seven) ||
                (loginStrategies && loginStrategies.mobileIntegration);
            $scope.selectedStrategy =
                !loginStrategies || (loginStrategies && loginStrategies.seven)
                    ? $scope.strategyItems[0]
                    : $scope.strategyItems[1];
            $scope.hasMultipleLoginEntities =
                settings.company.legalEntities && settings.company.legalEntities.length > 1;
            if ($scope.hasMultipleLoginEntities) {
                $scope.loginEntities = {
                    list: angular.copy(settings.company.legalEntities),
                    selected: legalEntitiesService.getSelectedLegalEntity().id,
                };
            }

            load();
        };

        $scope.setActiveStrategy = function (strategy) {
            $scope.selectedStrategy = strategy;
            $scope.password = null;
            $scope.message = null;
            $scope.loginForm.$setPristine();
            $scope.loginForm.$setUntouched();
        };

        var load = function () {
            var cmsFormFieds = getCMSFormFields();

            cmsFormFieds.forEach(function (cmsField) {
                if (cmsField.prefix) {
                    $scope.prefixes = cmsField.prefix;
                    $scope.mobileNumberValidationPattern = cmsField.validation;
                }
            });

            if ($scope.prefixes && $scope.prefixes.length > 0)
                $scope.selectedPrefix = $scope.prefixes[0];
        };

        $scope.handlePrefixUpdate = function (updatedPrefix) {
            $scope.selectedPrefix = updatedPrefix;
        };

        $scope.updateSelectedLegalEntity = function (entity) {
            legalEntitiesService.setSelectedLegalEntity(entity);
        };

        var getCMSFormFields = function () {
            const FORM_NAME = 'web_register_player';

            return (
                (config.forms && config.forms[FORM_NAME] && config.forms[FORM_NAME].fields) || []
            );
        };

        var login = function () {
            // Create login data
            var data =
                    $scope.selectedStrategy.value === 'phoneNumber'
                        ? {
                              mobileNumber: $scope.selectedPrefix.value + $scope.user.mobileNumber,
                              password: $scope.password,
                          }
                        : {
                              _username: $scope.user.username,
                              _password: $scope.password,
                          },
                selectedLoginEntity = $scope.loginEntities && $scope.loginEntities.selected;
            playerAuthService.setUserLoginStrategyChoice($scope.selectedStrategy.value);
            // Check recaptcha
            if (recaptcha.enabled && recaptcha.visible) {
                var recaptchaName = 'g-recaptcha-response',
                    recaptchaElement = angular.element('#' + recaptchaName);
                if (recaptchaElement.length) {
                    data[recaptchaName] = recaptchaElement.val();
                }
            }

            // Login check request
            $scope.working = true;
            playerAuthService
                .login(data, selectedLoginEntity)
                .then(function (response) {
                    if (response.profile) {
                        // Save refresh token to local storage if rememberMe is true
                        if ($scope.rememberMe === true) {
                            locker.put('refreshToken', response.refreshToken);
                        }

                        // Fill player data
                        user.set(response.profile.email, response.profile, response.token);
                        user.logged = true;
                        user.saveLocal();
                        if (selectedLoginEntity) {
                            if (legalEntitiesService.goToEntity(selectedLoginEntity)) return;
                        }
                        user.getThrottledBalance();
                        user.fillProfile(true)
                            .then(function () {
                                // Vibrate on success
                                vibrator.vibrate();
                                $scope.working = false;
                                $scope.close();
                                // Remove "showLogin" query param upon closing login dialog
                                $location.search('showLogin', null);

                                // NOTE: Force password change has priority over force nickname change
                                // Check force password change
                                if (response.profile.isPasswordChangeRequired) {
                                    $state.go('PlayerPasswordUpdate', {
                                        force: true,
                                    });
                                    // Check force nickname change
                                    // NOTE: Force nickname change only for standalone (full web) clients
                                } else if (
                                    settings.mode === 'standalone' &&
                                    response.profile.isAutoGeneratedNickname
                                ) {
                                    $state.go('PlayerProfile', {
                                        force: true,
                                    });
                                }

                                $rootScope.$broadcast('SEVEN.PlayerProfileChange');

                                // If there is a redirectUrl on $rootScope, go to that redirectUrl and remove it from $rootScope
                                if ($rootScope.redirectUrl) {
                                    var redirectUrl = $rootScope.redirectUrl;
                                    delete $rootScope.redirectUrl;
                                    $location.url(redirectUrl);
                                }
                            })
                            .catch(angular.noop);
                        SEVENXtremepush.setUserId(response.profile.Uuid);
                        $rootScope.$broadcast('SEVEN.PlayerLoggedIn', {
                            playerProfile: response.profile,
                            withCredentials: true,
                        });
                    } else {
                        if (response.data.code === 40310 && data.mobileNumber) {
                            $ocLazyLoad.load(['VerifyOtp']).then(function () {
                                modalService
                                    .showModal({
                                        templateUrl:
                                            settings.directory.app +
                                            'components/otp/verify-otp.modal.view.html',
                                        controller: 'SEVENVerifyOtpCtrl',
                                        controllerAs: 'verifyOtp',
                                    })
                                    .then(function (modal) {
                                        modal.scope.verifyOtp.mobileNumber = data.mobileNumber;
                                        $scope.close();
                                        modal.element.show();
                                    });
                            });
                        } else {
                            // Show error
                            $scope.error = true;
                            $scope.message = exception.renderMessage(
                                response.data,
                                config.messages.general.loginError
                            );
                            $scope.messageContact = response.data.httpCode === 403;
                            $scope.messageContactTitle = config.messages.pages.companyContact;
                        }

                        // Remove local if exists
                        user.removeLocal();
                        user.reset();
                        user.saveLocal();
                        locker.forget('userLoginStrategyChoice');

                        // Check recaptcha
                        var details = response.data.details,
                            recaptchaVisible = recaptcha.visible;
                        if (details && details.numOfFailedLoginAttempts) {
                            recaptcha.visible =
                                details.numOfFailedLoginAttempts >=
                                details.maxNumOfFailedLoginAttempts - 1;
                        }

                        // Reset recaptcha if visible
                        if (window.grecaptcha && recaptchaVisible) {
                            window.grecaptcha.reset();
                        }
                        $scope.working = false;
                    }
                })
                .catch(function (response) {
                    return response;
                });
        };

        var remove = function (event) {
            if (event.target === event.currentTarget) {
                // Remove "showLogin" query param upon closing login dialog
                $location.search('showLogin', null);
                closeAndNotify();
            }
        };

        var goToForgotPassword = function () {
            var playerPasswordModule = modules.routes.playerPassword;

            if (
                playerPasswordModule &&
                playerPasswordModule.data &&
                playerPasswordModule.data.dialog
            ) {
                modalService
                    .showModal({
                        templateUrl:
                            settings.directory.app + 'shared/password-reset-dialog/view.html',
                        controller: 'SEVENResetPasswordDialogCtrl',
                    })
                    .then(function (modal) {
                        close();
                        modal.element.show();
                    });
            } else {
                $state.go('PlayerPassword');
            }
        };

        var togglePasswordVisibility = function () {
            $scope.passwordType = $scope.passwordType === 'password' ? 'text' : 'password';
        };

        var saveRememberMe = function () {
            locker.put('rememberMe', $scope.rememberMe);
        };

        var onRegisterClick = function () {
            $rootScope.$broadcast('SEVEN.RegisterClicked');
            closeAndNotify();
        };

        var closeAndNotify = function () {
            $rootScope.$broadcast('SEVEN.LoginDialogClosed', {
                closedByUser: true,
            });
            close();
        };

        init();
    }]
);

SEVEN.directive(
    'sevenLogin',
    ['$rootScope', '$interval', '$state', '$location', '$filter', '$ocLazyLoad', 'locker', 'SEVENSettings', 'SEVENConfig', 'SEVENModules', 'SEVENModalSvc', 'SEVENPlayerService', 'SEVENUser', 'SEVENZiqni', function (
        $rootScope,
        $interval,
        $state,
        $location,
        $filter,
        $ocLazyLoad,
        locker,
        SEVENSettings,
        SEVENConfig,
        SEVENModules,
        SEVENModalSvc,
        SEVENPlayerService,
        SEVENUser,
        SEVENZiqni
    ) {
        var user = SEVENUser,
            settings = SEVENSettings,
            config = SEVENConfig,
            modules = SEVENModules,
            modalService = SEVENModalSvc,
            playerService = SEVENPlayerService,
            ziqniService = SEVENZiqni,
            hasCustomLayout,
            customLayout,
            templateDirectory;

        return {
            templateUrl: function (elem, attrs) {
                var view = attrs.mobile ? 'view-mob' : 'view';

                // Check is custom layout provided
                hasCustomLayout = attrs.customLayoutTemplate !== undefined;
                customLayout =
                    typeof attrs.customLayoutTemplate === 'string' &&
                    attrs.customLayoutTemplate.length
                        ? attrs.customLayoutTemplate
                        : 'layout/' + config.appSettings.config.layoutFrom + '/';
                templateDirectory = hasCustomLayout ? customLayout : '';

                return (
                    settings.directory.app + templateDirectory + 'shared/login/' + view + '.html'
                );
            },
            replace: true,
            scope: true,
            controller: ['$scope', '$element', '$attrs', function ($scope, $element, $attrs) {
                var hasMessagesModule = !modules.routes.playerMessages.disabled,
                    hasOffersModule = !modules.routes.playerOffers.disabled,
                    hasBBLoyalty = !modules.routes.balkanBetLoyalty.disabled,
                    fastDeposit = settings.company.fastDeposit,
                    messageCheckInterval = 600000,
                    lastMessagesCheck = moment(
                        locker.get('PlayerMessagesChecked', moment().utc().add(-1, 'd'))
                    ),
                    getMessagesInterval;

                var init = function () {
                    var virtual = settings.company.currencyVirtual;

                    $scope.config = config;
                    $scope.appSettings = config.appSettings.config;
                    $scope.routes = modules.routes;
                    $scope.error = false;
                    $scope.message = null;
                    $scope.messagesCount = 0;
                    $scope.hasOffersModule = hasOffersModule;
                    $scope.registerDisabled = modules.routes.playerRegister.disabled;
                    $scope.loginDisabled = !settings.login;
                    $scope.goToPlayerHistory = goToPlayerHistory;
                    $scope.goToPlayerProfile = goToPlayerProfile;
                    $scope.hasBBBalance = false;
                    $scope.showZiqniWidget = showZiqniWidget;
                    $scope.ziqniExists = ziqniExists;
                    $scope.onRegisterClick = onRegisterClick;

                    if (fastDeposit && fastDeposit.enabled) {
                        $scope.fastDepositActive = fastDeposit.enabled;
                    }

                    $scope.specialButton = {
                        title: virtual
                            ? config.messages.pages.playerRanks
                            : config.messages.general.deposit,
                        route: virtual ? modules.routes.playerRanks : modules.routes.playerDeposit,
                    };

                    $scope.mobileBalanceToggle = {
                        enabled: false,
                    };

                    $scope.balanceToggle = {
                        enabled: config.balanceToggleEnabled,
                    };

                    $scope.$watch('config.balanceToggleEnabled', function (value) {
                        $scope.balanceToggle.enabled = value;
                    });

                    setMobileBalanceToggleSetting();

                    // TODO this was never activated in production for anyone so it's a candidate for removal
                    $scope.showBalanceOnMobile = function () {
                        // If user setting "showBalance" is set to true,
                        // display balance info, regardless of toggle availability.
                        var showBalance = user.settings && user.settings.showBalance;
                        var forceHideBalance = user.settings && user.settings.forceHideBalance;

                        // If user setting "showBalance" says that balance info shouldn't be displayed and
                        // "displayBalanceToggleOnMobile" app setting is false ->
                        // -> meaning that user has no option to toggle (show) balance info in mobile view,
                        // then force display balance info.

                        return (
                            !forceHideBalance &&
                            (showBalance || !$scope.mobileBalanceToggle.enabled)
                        );
                    };

                    $scope.$on('SEVEN.UserLogin', function (event, logged) {
                        $scope.hasBBBalance = false;

                        if (!logged) return;

                        getMessagesCount();
                        getBBLoyaltyBalance();
                    });

                    $scope.$on('SEVEN.PlayerMessagesChecked', function () {
                        $scope.messagesCount = 0;
                        lastMessagesCheck = moment().utc();
                        locker.put('PlayerMessagesChecked', lastMessagesCheck);
                    });

                    $scope.$on('SEVEN.ShowLoginDialog', function () {
                        $scope.showDialog();
                    });

                    if ($location.search().initShowLogin && (!user || !user.logged)) {
                        $scope.showDialog();
                    }

                    if (user.logged) {
                        getBBLoyaltyBalance();
                    }

                    $rootScope.$broadcast('SEVEN.LoginDirectiveLoaded');
                };

                var showZiqniWidget = function () {
                    ziqniService.showWidget();
                };

                var ziqniExists = function () {
                    return ziqniService.doesPluginExist();
                };

                var getMessagesCount = function () {
                    if (hasMessagesModule) {
                        playerService
                            .getMessagesCount(lastMessagesCheck)
                            .then(function (response) {
                                $scope.messagesCount = response.data.count || 0;
                                // Start message interval
                                if (!getMessagesInterval) {
                                    getMessagesInterval = $interval(
                                        getMessagesCount,
                                        messageCheckInterval
                                    );
                                }
                            })
                            .catch(function () {
                                console.log('Could not fetch user messages count!');
                            });
                    }
                };

                var getBBLoyaltyBalance = function () {
                    if (!hasBBLoyalty) return;
                    $scope.bbLoyaltyBalanceLoading = true;

                    playerService.isBBLoyaltyAccConnected().then(function (data) {
                        updateBBLoyaltyBalance(data);
                    });
                };

                var updateBBLoyaltyBalance = function (data) {
                    if (!data) {
                        $scope.hasBBBalance = false;
                        $scope.bbLoyaltyBalance = 0;
                        return;
                    }

                    $scope.hasBBBalance = true;
                    $scope.bbLoyaltyBalance = $filter('number')(data.balance, 2);
                    $scope.bbLoyaltyBalanceLoading = false;
                };

                var stateHasTicketHistory = function (state, historyMenu) {
                    var stateTicketHistory =
                            state.data && state.data.ticketHistory ? state.data.ticketHistory : {},
                        menuState;

                    // Take first match found in menu, because menu array is already sorted by priority (highest first)
                    menuState = historyMenu.find(function (item) {
                        if (item.customData.historySection === stateTicketHistory.section) {
                            return item;
                        }
                    });

                    // If state has 'ticketHistory.section' in its state definition (in modules.json) and
                    // if corresponding 'ticket_history' menu item isn't 'disabled'
                    return menuState && !menuState.disabled;
                };

                var goToPlayerHistory = function () {
                    var state = $state.current,
                        // Extend menu items with state definition (defined in modules.json) of corresponding product state
                        ticketHistoryMenu = playerService.getExtendedTicketHistoryMenu();

                    // 1) In case current state doesn't have ticket history,
                    //    take state definition attached to first 'ticket_history' menu item
                    if (!stateHasTicketHistory(state, ticketHistoryMenu)) {
                        state = ticketHistoryMenu[0].stateDefinition;
                    }

                    // 2) Otherwise, take state definition of current state
                    $state.go('PlayerHistory', {
                        section: state.data.ticketHistory.section || null,
                        type: state.data.ticketHistory.type || null,
                    });
                };

                var goToPlayerProfile = function () {
                    $state.go('PlayerProfile');
                };

                var setMobileBalanceToggleSetting = function () {
                    $scope.mobileBalanceToggle = {
                        enabled: config.appSettings.displayBalanceToggleOnMobile,
                    };
                };

                var onRegisterClick = function () {
                    $rootScope.$broadcast('SEVEN.RegisterClicked');
                };

                $scope.logout = function () {
                    $rootScope.$broadcast('SEVEN.PlayerLogout');
                };

                $scope.showDialog = function () {
                    var view = $attrs.mobile ? 'view-mob' : 'view';
                    var templateURL =
                        settings.directory.app +
                        templateDirectory +
                        'shared/login/dialog/' +
                        view +
                        '.html';

                    var exists = $('.n-modal:visible').length > 0;

                    if (!exists) {
                        modalService
                            .showModal({
                                templateUrl: templateURL,
                                controller: 'SEVENLoginDialog',
                            })
                            .then(function (modal) {
                                modal.element.show();
                            });
                    }
                };

                $scope.showFastDepositModal = function () {
                    if (fastDeposit.type === 'PaytenCSE') {
                        $ocLazyLoad.load(['PaytenCSEFastDeposit', 'Payment']).then(function () {
                            modalService
                                .showModal({
                                    templateUrl:
                                        settings.directory.app +
                                        'components/payten-cse-fast-deposit/payten-cse-fast-deposit.modal.view.html',
                                    controller: 'SEVENFastDepositCtrl',
                                    controllerAs: 'fastDeposit',
                                })
                                .then(function (modal) {
                                    modal.element.show();
                                });
                        });
                    }
                };

                $scope.refreshBalance = function () {
                    $scope.balanceLoading = true;

                    return user.getThrottledBalance().finally(function () {
                        $scope.balanceLoading = false;
                    });
                };

                $scope.refreshBBLoyaltyBalance = function () {
                    getBBLoyaltyBalance();
                };

                $scope.toggleBalance = function () {
                    if (user.logged) {
                        var key = 'platform.showBalance';
                        user.saveSetting(key, user.settings.showBalance, 'boolean').then(
                            function () {
                                user.saveLocal();
                            }
                        );
                    } else {
                        user.saveLocal();
                    }
                };

                $rootScope.$on('updateBBLoyalty', function (e, data) {
                    updateBBLoyaltyBalance(data);
                });

                $rootScope.$on('refreshBBLoyaltyBalance', function () {
                    $scope.refreshBBLoyaltyBalance();
                });

                $scope.$on('UI.Show', function (event, action) {
                    if (action.name === 'ZiqniWidget') {
                        showZiqniWidget();
                    }

                    if (action.name === 'FastDeposit' && user.logged) {
                        $scope.showFastDepositModal();
                    }
                });

                init();
            }],
        };
    }]
);

SEVEN.component('sevenLogo', {
    templateUrl: 'app/shared/logo/logo-view.html',
    controllerAs: 'logo',
    bindings: {
        disableRedirect: '<',
    },

    controller: ['SEVENSettings', function (SEVENSettings) {
        var vm = this;
        var settings = SEVENSettings;

        var setLogoSrc = function () {
            var externalAssetsBaseUrl = settings.api.assets.url[settings.server] + '/';
            var externalAssetsContainerPath =
                (settings.company.assetsContainer || settings.company.name) +
                '/7web_assets-images/';

            var externalAssetsPath = externalAssetsBaseUrl + externalAssetsContainerPath;
            var fileName = 'logo';
            var mobileFileName = 'logo-mobile';
            // high DPI display
            var mobileX2FileName = 'logo-mobile-x2';

            vm.logoSrc = externalAssetsPath + fileName;
            vm.logoMobileSrc = externalAssetsPath + mobileFileName;
            vm.logoMobileX2Src = externalAssetsPath + mobileX2FileName;
        };

        var init = function () {
            setLogoSrc();
        };

        vm.$onInit = init;
    }],
});

SEVEN.directive(
    'sevenMainMenu',
    ['SEVENSettings', 'SEVENConfig', 'SEVENMenus', 'SEVENUser', '$state', '$timeout', '$window', '$rootScope', '$parse', '$stateParams', function (
        SEVENSettings,
        SEVENConfig,
        SEVENMenus,
        SEVENUser,
        $state,
        $timeout,
        $window,
        $rootScope,
        $parse,
        $stateParams
    ) {
        var settings = SEVENSettings,
            config = SEVENConfig,
            menus = SEVENMenus,
            user = SEVENUser,
            isMobilePhone = window.isMobile.phone,
            currentActiveItem,
            dropdownTogglerWidth = 0,
            navigationItemsWidthsMap = {};

        var checkDoItemsFit = function (itemWidths, availableWidth) {
            var takenWidth = 0;
            var doItemsFit = true;

            for (var width of itemWidths) {
                takenWidth += width;

                if (takenWidth >= availableWidth) {
                    doItemsFit = false;
                    break;
                }
            }
            return doItemsFit;
        };

        var calculateHowManyItemsFit = function (itemWidths, availableWidth) {
            var numOfVisibleItems = null;
            var count = 0;
            var takenWidth = 0;

            for (var itemWidth of itemWidths) {
                count++;
                takenWidth += itemWidth;

                if (takenWidth >= availableWidth) {
                    numOfVisibleItems = count - 1;
                    break;
                }
            }

            return numOfVisibleItems;
        };

        var calculateNumOfItemsThatFit = function (itemWidths) {
            var numOfVisibleItems = null;
            var availableWidth =
                $('.n-header .bottom .header-frame').outerWidth() - $('.n-options').outerWidth();

            var doItemsFit = checkDoItemsFit(itemWidths, availableWidth);

            if (doItemsFit) {
                return numOfVisibleItems;
            }

            availableWidth = availableWidth - dropdownTogglerWidth;
            numOfVisibleItems = calculateHowManyItemsFit(itemWidths, availableWidth);

            return numOfVisibleItems;
        };

        var saveItemWidth = function (jqueryItem) {
            var currentItemWidth = jqueryItem.outerWidth();
            var currentItemText = jqueryItem.text().trim();
            navigationItemsWidthsMap[currentItemText] = currentItemWidth;
        };

        var saveItemWidths = function () {
            dropdownTogglerWidth = $('.n-header .bottom .item-more').outerWidth();
            var navItems = $('.n-main-menu .navigation li');

            navItems.each(function () {
                saveItemWidth($(this));
            });
        };

        var extractItemWidths = function (items) {
            var sortedItemWidths = [];

            items.forEach(function (item) {
                var savedWidth = navigationItemsWidthsMap[item];

                if (savedWidth) {
                    sortedItemWidths.push(savedWidth);
                }
            });

            return sortedItemWidths;
        };

        return {
            replace: true,
            transclude: true,
            scope: true,
            templateUrl: function (elem, attr) {
                var directory =
                    attr.customLayoutTemplate === undefined
                        ? ''
                        : 'layout/' + config.appSettings.config.layoutFrom + '/';

                return settings.directory.app + directory + 'shared/mainMenu/view.html';
            },
            controllerAs: 'menu',
            controller: ['$scope', function ($scope) {
                var vm = this;

                var init = function () {
                    vm.currentState = $state.current;
                    vm.numOfVisibleItems = null;
                    vm.isMobile = isMobilePhone;
                    vm.isDropdownTogglerVisible = true;
                    vm.isMenuExpanded = false;
                    vm.items = createMenuItems();
                    vm.useH1TagForActiveItem = settings.company.seo.useH1Tag;
                    vm.toggleMenu = toggleMenu;
                    vm.select = select;
                    vm.closeMenu = closeMenu;
                    vm.isDropdownExpanded = false;
                    vm.toggleDropdown = toggleDropdown;
                    vm.closeDropdown = closeDropdown;
                    vm.handleInitialNavigationRendering = handleInitialNavigationRendering;

                    setActiveMenuItem();
                    setProfileChangeListener();
                };

                var createMenuItems = function () {
                    angular.forEach(menus.main, function (item, index) {
                        var externalLinkPattern = new RegExp(/^http/);
                        var itemStatePattern = /^(\w*)(\((.*)?\))?$/;

                        item.visible = true;
                        item.isActive = false;

                        if (item.customData) {
                            item.accentuate = item.customData.accentuate;

                            if (item.customData.mobile) {
                                item.visible = isMobilePhone;
                            }

                            if (item.customData.logged) {
                                item.visible = item.visible && user.logged;
                            }
                        }

                        item.url = settings.isMobile
                            ? (item.customData && item.customData.mobileUrl) || item.url
                            : item.url;

                        // check is item url an external link
                        if (externalLinkPattern.test(item.url)) {
                            item.href = item.url;
                            item.target = (item.customData && item.customData.target) || '_blank';
                        } else {
                            var cleanItemUrl = item.url.trim().replace(/\s/g, '');
                            var itemUrlStateMatch = itemStatePattern.exec(cleanItemUrl);
                            var itemUrlStateParams =
                                itemUrlStateMatch[3] && $parse(itemUrlStateMatch[3])();
                            var matchStateParams = item.matchStateParams || false;

                            if (!item.matchStateParams) {
                                for (var i = index + 1; i < menus.main.length; i++) {
                                    var currentItem = menus.main[i];
                                    var currentItemCleanURL = currentItem.url
                                        .trim()
                                        .replace(/\s/g, '');
                                    var currentItemURLStateMatch =
                                        itemStatePattern.exec(currentItemCleanURL);
                                    var stateNamesMatch =
                                        currentItemURLStateMatch &&
                                        currentItemURLStateMatch[1] === itemUrlStateMatch[1];

                                    if (stateNamesMatch) {
                                        currentItem.matchStateParams = true;
                                        currentItem.stateParams =
                                            currentItemURLStateMatch[3] &&
                                            $parse(currentItemURLStateMatch[3])();

                                        matchStateParams = true;
                                    }
                                }
                            }

                            if (matchStateParams) {
                                item.srefOptions = '{inherit: false}';
                            }

                            if (itemUrlStateParams || matchStateParams) {
                                item.matchStateParams = matchStateParams;
                                item.stateParams = itemUrlStateParams;
                            }

                            item.stateName = itemUrlStateMatch[1];
                            item.sref = item.url;
                        }

                        item.excluded = isExcluded(item);
                    });

                    var visibleMenus = menus.main.filter(function (item) {
                        return item.visible;
                    });

                    return visibleMenus;
                };

                var getDropdownItems = function (numOfVisibleItems) {
                    if (!numOfVisibleItems) return [];

                    return vm.items.slice(numOfVisibleItems);
                };

                var checkIfStateHasOwnItem = function (item) {
                    if (!vm.items) return;

                    var stateMenuItem = vm.items.find(function (menuItem) {
                        return menuItem.stateName === $state.current.name;
                    });

                    // For cases when virtual game has its own menu item
                    return !!stateMenuItem && item.stateName !== stateMenuItem.stateName;
                };

                var isItemActive = function (item) {
                    var isCurrentParentItem = $state.current.data.parent === item.stateName;
                    var isCurrentItem = $state.is(item.stateName);
                    var stateParamsMatch = true;

                    if (item.matchStateParams) {
                        angular.forEach($stateParams, function (stateParamValue, stateParamKey) {
                            var stateParamFalsyValueMismatch =
                                !!stateParamValue !==
                                !!(item.stateParams && item.stateParams[stateParamKey]);
                            var stateParamTruthyValueMismatch =
                                stateParamValue &&
                                stateParamValue !==
                                    (item.stateParams && item.stateParams[stateParamKey]);

                            if (stateParamFalsyValueMismatch || stateParamTruthyValueMismatch) {
                                stateParamsMatch = false;
                            }
                        });
                    }

                    // For cases when virtual game has its own menu item
                    var doesStateHasOwnItem = checkIfStateHasOwnItem(item);

                    return (
                        (isCurrentItem || isCurrentParentItem) &&
                        stateParamsMatch &&
                        !doesStateHasOwnItem
                    );
                };

                var setActiveMenuItem = function () {
                    angular.forEach(vm.items, function (item) {
                        item.isActive = isItemActive(item);

                        if (item.isActive) {
                            currentActiveItem = item;
                            $rootScope.$on(
                                'updateCurrentActiveMenuItemTag',
                                function (e, activeMenuItem) {
                                    vm.isSeoDescriptionAvailable = activeMenuItem;
                                }
                            );

                            vm.isSeoDescriptionAvailable =
                                vm.isSeoDescriptionAvailable === currentActiveItem.title
                                    ? true
                                    : false;
                        }
                    });
                };

                var select = function (item) {
                    vm.isMenuExpanded = false;
                    vm.isDropdownExpanded = false;
                    notify();

                    checkAndSwapNavigationItems(vm.numOfVisibleItems, item);
                };

                var toggleMenu = function () {
                    vm.isMenuExpanded = !vm.isMenuExpanded;
                    notify(vm.isMenuExpanded);
                };

                var closeMenu = function () {
                    vm.isMenuExpanded = false;
                    notify();
                };

                var toggleDropdown = function () {
                    vm.isDropdownExpanded = !vm.isDropdownExpanded;
                };

                var closeDropdown = function () {
                    vm.isDropdownExpanded = false;
                };

                var swapItemAndDropdownItem = function (params) {
                    var clickedItem = params.clickedItem;
                    var lastVisibleItemIndex = params.lastVisibleItemIndex;

                    // remove clicked item
                    var items = vm.items.filter(function (menuItem) {
                        return menuItem.url !== clickedItem.url;
                    });

                    // swap clicked item and last visible item
                    var lastVisibleItem = items.splice(lastVisibleItemIndex, 1, clickedItem)[0];

                    // move last visible item to last position
                    items.push(lastVisibleItem);

                    vm.items = items;
                };

                var checkAndSwapNavigationItems = function (numOfVisibleItems, item) {
                    var dropdownItems = getDropdownItems(numOfVisibleItems);

                    if (dropdownItems.includes(item)) {
                        swapItemAndDropdownItem({
                            clickedItem: item,
                            lastVisibleItemIndex: numOfVisibleItems - 1,
                        });
                        checkDoNavigationItemsFit();

                        // another check if the active item cannot fit and ends up in the dropdown
                        if (getDropdownItems(vm.numOfVisibleItems).includes(item)) {
                            swapItemAndDropdownItem({
                                clickedItem: item,
                                lastVisibleItemIndex: vm.numOfVisibleItems - 1,
                            });
                        }
                    }
                };

                var setVisibleItems = function (numOfVisibleItems) {
                    vm.isDropdownExpanded = false;

                    if (!numOfVisibleItems) {
                        vm.isDropdownTogglerVisible = false;
                        vm.numOfVisibleItems = null;
                        return;
                    }

                    vm.isDropdownTogglerVisible = true;
                    vm.numOfVisibleItems = numOfVisibleItems;
                };

                var getItemWidths = function () {
                    var newOrderOfItems = vm.items.map(function (item) {
                        return item.title;
                    });

                    return extractItemWidths(newOrderOfItems);
                };

                var getNumOfItemsThatFit = function () {
                    var itemWidths = getItemWidths();

                    return calculateNumOfItemsThatFit(itemWidths);
                };

                var checkDoNavigationItemsFit = function () {
                    var numOfVisibleItems = getNumOfItemsThatFit();

                    setVisibleItems(numOfVisibleItems);
                };

                var handleInitialNavigationRendering = function () {
                    saveItemWidths();
                    var numOfVisibleItems = getNumOfItemsThatFit();

                    setVisibleItems(numOfVisibleItems);

                    if (numOfVisibleItems) {
                        checkAndSwapNavigationItems(numOfVisibleItems, currentActiveItem);
                    }
                };

                var isExcluded = function (item) {
                    var selfExcludedProducts = user.profile && user.profile.selfExcludedProducts;

                    return angular.isArray(selfExcludedProducts)
                        ? user.profile &&
                              user.profile.selfExcludedProducts.find(function (excludedProduct) {
                                  return (
                                      item.customData &&
                                      excludedProduct.product ===
                                          (item.customData.exclusionProductDisplayId ||
                                              item.customData.productDisplayId)
                                  );
                              })
                        : false;
                };

                var setProfileChangeListener = function () {
                    // Set exclusions when user does login
                    $scope.$on('SEVEN.PlayerProfileChange', setProductExclusions);
                    // Logout doesn't broadcast SEVEN.PlayerProfileChange event so this is also required
                    $scope.$on('SEVEN.UserLogin', function () {
                        setProductExclusions();
                        refreshMenus();
                    });
                };

                var setProductExclusions = function () {
                    angular.forEach(vm.items, function (item) {
                        item.excluded = isExcluded(item);
                    });
                };

                var refreshMenus = function () {
                    vm.items = createMenuItems();
                    setActiveMenuItem();

                    $timeout(function () {
                        handleInitialNavigationRendering();
                    }, 0);
                };

                var notify = function (expanded) {
                    $scope.$emit('SEVEN.MenuChanged', expanded);
                };

                $scope.$on('$stateChangeSuccess', function () {
                    setActiveMenuItem();

                    $timeout(function () {
                        checkDoNavigationItemsFit();
                        var isItemStillInDropdown = getDropdownItems(vm.numOfVisibleItems).includes(
                            currentActiveItem
                        );
                        if (isItemStillInDropdown) {
                            checkAndSwapNavigationItems(vm.numOfVisibleItems, currentActiveItem);
                        }
                    }, 0);
                });

                $scope.$on('$locationChangeSuccess', function () {
                    setActiveMenuItem();
                });

                // trigger on resize release
                var resizeTimer;
                $window.addEventListener('resize', function () {
                    clearTimeout(resizeTimer);
                    resizeTimer = setTimeout(function () {
                        checkDoNavigationItemsFit();
                    }, 200);
                });

                init();
            }],

            link: function (scope, element, attrs, controller) {
                if (isMobilePhone) return;

                $timeout(function () {
                    controller.handleInitialNavigationRendering();
                }, 0);
            },
        };
    }]
);

SEVEN.component('sevenMap', {
    template: function () {
        return ['<div class="map-content">', '</div>'].join('');
    },
    controller: /*@ngInject*/ ['$window', '$document', '$interval', '$element', '$log', 'SEVENConfig', 'SEVENMapService', 'SEVENImageExistenceChecker', 'SEVENSettings', function (
        $window,
        $document,
        $interval,
        $element,
        $log,
        SEVENConfig,
        SEVENMapService,
        SEVENImageExistenceChecker,
        SEVENSettings
    ) {
        var vm = this,
            map,
            api,
            geocoder,
            country = SEVENConfig.client.countryName,
            centerCoordinates,
            settings = SEVENSettings;
        var mapZoom = {
            default: 8,
            location: 13,
        };

        var init = function () {
            $window.initComponent = function () {
                // Hack to make sure marker clusterer is loaded
                var initMapInterval = $interval(function () {
                    if (hasMarkerClusterer()) {
                        $interval.cancel(initMapInterval);
                        setMap();
                    }
                }, 500);
            };

            if ($window.google && $window.google.maps) {
                if (!hasMarkerClusterer()) {
                    SEVENMapService.loadSideScripts(['markerClusterer']).then(function (scripts) {
                        var body = $document.find('body').eq(0);
                        scripts.forEach(function (script) {
                            body.append(script[0]);
                        });

                        $window.initComponent();
                    });
                } else {
                    setMap();
                }
            } else {
                SEVENMapService.loadScripts(['markerClusterer']).then(function (scripts) {
                    var body = $document.find('body').eq(0);
                    scripts.forEach(function (script) {
                        body.append(script[0]);
                    });
                });
            }

            vm.$onChanges = function (changes) {
                var selected = changes.selected;
                if (selected.currentValue && selected.currentValue !== selected.previousValue) {
                    centerAddress(selected.currentValue);
                }
            };
        };

        var hasMarkerClusterer = function () {
            return !!($window.markerClusterer && $window.markerClusterer.MarkerClusterer);
        };

        var setMap = function () {
            api = $window.google.maps;
            geocoder = new api.Geocoder();

            geocoder.geocode(
                {
                    address: country,
                },
                function (results, status) {
                    if (status !== 'OK') return;

                    centerCoordinates = results[0].geometry.location;
                    map = new api.Map($element[0], {
                        zoom: mapZoom.default,
                        fullscreenControl: false,
                        scrollwheel: false,
                        center: centerCoordinates,
                        mapId: settings.api.maps.mapId,
                    });

                    // Bind Map instance to element, to make it accessible globally
                    $element.data('map', map);
                    setMarkers();
                }
            );
        };

        var setLocationMarkers = function () {
            return vm.locations
                .filter(function (location) {
                    location.position = createPosition(location.position);

                    return location.position;
                })
                .map(function (location) {
                    const markerPin = new api.marker.PinElement({
                        background: settings.api.maps.style.pin.background,
                        borderColor: settings.api.maps.style.pin.borderColor,
                        glyphColor: 'white',
                    });
                    var marker = new api.marker.AdvancedMarkerElement({
                        position: location.position,
                        content: markerPin.element,
                        title: location.name,
                        map: map,
                    });

                    var infowindow = new api.InfoWindow({
                        content: [
                            '<div class="infowindow-content">',
                            '<div class="infowindow-name">{name}</div>',
                            '<div class="infowindow-address">{address}</div>',
                            '<div class="infowindow-city">{city}</div>',
                            '</div>',
                        ]
                            .join('')
                            .supplant(location),
                    });

                    marker.addListener('click', function () {
                        infowindow.open(map, marker);
                    });

                    return marker;
                });
        };

        var renderMarkerCluster = function (options, stats, map) {
            var count = options.count;
            var position = options.position;
            var color =
                count > Math.max(10, stats.clusters.markers.mean)
                    ? settings.api.maps.style.cluster.overMeanBackground
                    : settings.api.maps.style.cluster.underMeanBackground;
            var glyphColor =
                count > Math.max(10, stats.clusters.markers.mean)
                    ? settings.api.maps.style.cluster.overMeanGlyphColor
                    : settings.api.maps.style.cluster.underMeanGlyphColor;

            var svg = (
                '<svg width="52" height="52" viewBox="0 0 91 91" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
                '    <path d="M67 45.5C67 57.3741 57.3741 67 45.5 67C33.6259 67 24 57.3741 24 45.5C24 33.6259 33.6259 24 45.5 24C57.3741 24 67 33.6259 67 45.5Z"\n' +
                '          fill="{color}"/>' +
                '    <path opacity="0.6" fill-rule="evenodd" clip-rule="evenodd" ' +
                '          d="M28.6768 29.0918C32.9447 24.7166 38.9051 22 45.5 22C52.3837 22 58.5761 24.9597 62.8741 29.676L67.2307 25.5491C61.8386 19.6791 54.099 16 45.5 16C37.1794 16 29.6634 19.4448 24.3003 24.986L28.6768 29.0918ZM23.0092 38.6657L17.2646 36.9283C16.4422 39.6409 16 42.5188 16 45.5C16 59.6087 25.9044 71.4041 39.1396 74.3126L40.5073 68.4686C29.927 66.1795 22 56.7655 22 45.5C22 43.123 22.3529 40.8283 23.0092 38.6657ZM51.4398 68.2429L52.8078 74.0878C65.5654 70.8365 75 59.27 75 45.5C75 42.5886 74.5782 39.7757 73.7925 37.1191L68.0396 38.8288C68.6646 40.9436 69 43.1826 69 45.5C69 56.4267 61.5426 65.6116 51.4398 68.2429Z" ' +
                '          fill="{color}"/>' +
                '    <path opacity="0.2" fill-rule="evenodd" clip-rule="evenodd" ' +
                '          d="M7.69029 34.0326C6.59103 37.6618 6 41.5119 6 45.5C6 64.3475 19.2003 80.1118 36.8601 84.0522L35.4924 89.8959C15.1779 85.3364 0 67.1907 0 45.5C0 40.9077 0.680325 36.4744 1.94569 32.2952L7.69029 34.0326ZM55.0875 83.8284L56.4552 89.6724C76.2923 84.769 91 66.8521 91 45.5C91 41.0046 90.3481 36.6614 89.1336 32.5599L83.3807 34.2696C84.4344 37.8292 85 41.5986 85 45.5C85 64.0088 72.2698 79.5443 55.0875 83.8284ZM74.4913 18.6715C67.2761 10.8784 56.9578 6 45.5 6C34.3033 6 24.1946 10.6587 17.0067 18.1434L12.6306 14.0379C20.9134 5.38706 32.5776 0 45.5 0C58.6731 0 70.5386 5.59806 78.8475 14.545L74.4913 18.6715Z" ' +
                '          fill="{color}"/>' +
                '    <path opacity="0.4" fill-rule="evenodd" clip-rule="evenodd" ' +
                '          d="M15.3498 36.3492C14.472 39.2451 14 42.3174 14 45.5C14 60.5565 24.5636 73.1457 38.6837 76.2605L37.316 82.1043C20.5411 78.3703 8 63.3997 8 45.5C8 41.7133 8.56127 38.0576 9.60516 34.6118L15.3498 36.3492ZM53.2637 76.036L54.6316 81.8804C70.9289 77.8027 83 63.061 83 45.5C83 41.7966 82.4632 38.2185 81.463 34.8395L75.7101 36.5492C76.5495 39.3864 77 42.3906 77 45.5C77 60.2178 66.9063 72.578 53.2637 76.036ZM68.6828 24.1736C62.9261 17.919 54.6707 14 45.5 14C36.6042 14 28.5696 17.6875 22.8415 23.6174L18.4654 19.5119C25.2884 12.4159 34.8785 8 45.5 8C56.386 8 66.1886 12.6385 73.0392 20.047L68.6828 24.1736Z" ' +
                '          fill="{color}"/>' +
                '    <text x="50%" y="50%" style="fill:{glyphColor}" text-anchor="middle" font-size="20" dominant-baseline="middle" font-family="roboto,arial,sans-serif">{count}</text>' +
                '</svg>'
            ).supplant({
                glyphColor: glyphColor,
                color: color,
                count: count,
            });

            var parser = new DOMParser();
            var svgEl = parser.parseFromString(svg, 'image/svg+xml').documentElement;

            svgEl.setAttribute('transform', 'translate(0 25)');

            return new api.marker.AdvancedMarkerElement({
                map: map,
                position: position,
                zIndex: 1000000 + count,
                title: 'Cluster of ' + count + ' markers',
                content: svgEl,
            });
        };

        var setMakerClustering = function (markers) {
            if (hasMarkerClusterer()) {
                new $window.markerClusterer.MarkerClusterer({
                    map: map,
                    markers: markers,
                    renderer: { render: renderMarkerCluster },
                });
            } else {
                markers.forEach(function (marker) {
                    marker.setMap(map);
                });
            }
        };

        var setMarkers = function () {
            if (!vm.locations || vm.locations.length < 1) return;

            var markers = setLocationMarkers();
            setMakerClustering(markers);
        };

        var createPosition = function (position) {
            if (position && position.length && position.indexOf(',')) {
                var points = position.split(',');

                return {
                    lat: Number(points[0]),
                    lng: Number(points[1]),
                };
            }
        };

        var centerAddress = function (address) {
            geocoder.geocode(
                {
                    address: address,
                },
                function (results, status) {
                    if (status === 'OK') {
                        map.setCenter(results[0].geometry.location);
                        map.setZoom(mapZoom.location);
                    } else {
                        map.setZoom(mapZoom.default);
                        map.setCenter(centerCoordinates);
                    }
                }
            );
        };

        vm.$onInit = init;
    }],
    controllerAs: 'mapVm',
    bindings: {
        locations: '<',
        center: '<',
        selected: '<',
    },
});

SEVEN.service(
    'SEVENMapService',
    ['$q', '$http', 'SEVENSettings', 'SEVENConfig', 'SEVENRoutes', 'SEVENLocale', function ($q, $http, SEVENSettings, SEVENConfig, SEVENRoutes, SEVENLocale) {
        var settings = SEVENSettings,
            config = SEVENConfig,
            routes = SEVENRoutes,
            locale = SEVENLocale;

        return {
            getLocations: function () {
                return $http.get(config.apiBase + routes.web.betshop.locations.url);
            },
            loadScripts: function (sideloads) {
                var scriptUrl = {
                    api: settings.api.maps.url,
                    markerClusterer: settings.api.maps.plugins.markerClusterer.url,
                };

                var createScriptElement = function () {
                    return angular.element(document.createElement('script'));
                };

                return $q(function (resolve) {
                    var scripts = [];

                    if (sideloads) {
                        angular.forEach(sideloads, function (sideload) {
                            var sideloadAPI = createScriptElement();
                            sideloadAPI.attr('src', scriptUrl[sideload]);
                            scripts.push(sideloadAPI);
                        });
                    }

                    var scriptAPI = createScriptElement();
                    scriptAPI.attr('async');
                    scriptAPI.attr(
                        'src',
                        scriptUrl.api.supplant({
                            language: locale.activeLanguage.substring(0, 2),
                            key: settings.api.maps.key,
                        })
                    );

                    scripts.push(scriptAPI);

                    resolve(scripts);
                });
            },
            loadSideScripts: function (sideloads) {
                var scriptUrl = {
                    markerClusterer: settings.api.maps.plugins.markerClusterer.url,
                };

                var createScriptElement = function () {
                    return angular.element(document.createElement('script'));
                };

                return $q(function (resolve) {
                    var scripts = [];

                    if (sideloads) {
                        angular.forEach(sideloads, function (sideload) {
                            var sideloadAPI = createScriptElement();
                            sideloadAPI.attr('src', scriptUrl[sideload]);
                            scripts.push(sideloadAPI);
                        });
                    }

                    resolve(scripts);
                });
            },
        };
    }]
);

SEVEN.directive(
    'sevenMenu',
    ['$state', '$window', 'SEVENSettings', 'SEVENConfig', 'SEVENHelpers', 'SEVENMenus', function ($state, $window, SEVENSettings, SEVENConfig, SEVENHelpers, SEVENMenus) {
        var settings = SEVENSettings,
            config = SEVENConfig,
            helpers = SEVENHelpers,
            menus = SEVENMenus;

        return {
            replace: true,
            scope: {
                source: '@',
            },
            templateUrl: function (elem, attr) {
                var directory =
                    attr.customLayoutTemplate === undefined
                        ? ''
                        : 'layout/' + config.appSettings.config.layoutFrom + '/';
                var view = settings.isMobile ? 'view-mob.html' : 'view.html';

                return settings.directory.app + directory + 'shared/menu/' + view;
            },
            controllerAs: 'menu',
            controller: ['$scope', function ($scope) {
                var vm = this;

                var init = function () {
                    vm.expanded = false;
                    vm.isHoverable = !helpers.isTouchDevice();
                    vm.isMobile = settings.isMobile;
                    vm.items = createMenuItems($scope.source, true);
                    vm.toggle = toggle;
                    vm.select = select;
                    vm.contract = contract;
                };

                var createMenuItems = function (source, activateFirstItem) {
                    var sourceMenu = menus[source];

                    angular.forEach(sourceMenu, function (item, i) {
                        var externalLinkPattern = new RegExp(/^http/);
                        item.active = activateFirstItem && i === 0 && settings.isMobile; // track currently opened item
                        item.index = i;

                        if (item.customData && angular.isDefined(item.customData.mobile)) {
                            if (item.customData.mobile) {
                                item.visible = vm.isMobile;
                            } else {
                                item.visible = !vm.isMobile;
                            }
                        } else {
                            item.visible = true;
                        }

                        // check is item url an external link
                        if (externalLinkPattern.test(item.url)) {
                            item.href = item.url;
                            item.target = item.customData.target || '_blank';
                        } else {
                            item.sref = item.url;
                        }

                        if (item.customData.childMenu) {
                            item.items = createMenuItems(item.customData.childMenu);
                        }
                    });

                    return sourceMenu;
                };

                var toggle = function (items, item, toggleExpanded) {
                    if (toggleExpanded) vm.expanded = !vm.expanded;
                    angular.forEach(items, function (menuItem) {
                        if (menuItem.active && menuItem.items) {
                            toggle(menuItem.items, {});
                        }

                        menuItem.active = menuItem.index === item.index ? !menuItem.active : false;
                    });
                    $scope.$broadcast('SEVEN.Rendered');
                };

                var select = function () {
                    vm.expanded = false;
                    toggle(vm.items, {});
                    $scope.$broadcast('SEVEN.Rendered');
                };

                var contract = function () {
                    vm.expanded = false;
                    toggle(vm.items, {});
                    $scope.$broadcast('SEVEN.Rendered');
                };

                vm.navigate = function (item) {
                    if (item.sref) {
                        $state.go(item.sref);
                        return;
                    }

                    select();
                    $window.open(item.href, item.target);
                };

                init();
            }],
        };
    }]
);

// Exception model
SEVEN.factory('SEVENException', function () {
    // Constructor
    var Exception = function (data) {
        this.data = data;
    };

    // Get message
    Exception.prototype.getMessage = function (fallback) {
        if (!this.data) {
            return fallback;
        }

        // Check details
        if (this.data.details && this.data.details.length > 0) {
            // Declare variables
            var i,
                messages = [],
                exceptions = this.data.details,
                len = exceptions.length;

            // Loop details
            if (len > 0) {
                for (i = 0; i < len; i++) {
                    if (exceptions[i].message) {
                        messages.push(exceptions[i].message);
                    } else {
                        if (exceptions[i].length > 0) {
                            messages.push(exceptions[i]);
                        }
                    }
                }
            }

            // Check parent message
            if (messages.length === 0) {
                messages.push(this.data.message);
            }

            // Return joined messages
            return messages.join(' ').trim();
        }

        // Return root message
        return this.data.message || this.data;
    };

    // Render exception message
    Exception.renderMessage = function (data) {
        var exception = new Exception(data);
        return exception.getMessage();
    };

    // Return constructor
    return Exception;
});

SEVEN.factory(
    'SEVENUser',
    ['$injector', '$log', '$location', '$window', '$filter', '$rootScope', '$state', 'locker', 'SEVENConfig', 'SevenGravitySettings', 'SEVENHelpers', 'SEVENSettings', 'SEVENPlayerService', 'SEVENPlayerSettingsService', 'SEVENLocale', 'SEVENToken', function (
        $injector,
        $log,
        $location,
        $window,
        $filter,
        $rootScope,
        $state,
        locker,
        SEVENConfig,
        SevenGravitySettings,
        SEVENHelpers,
        SEVENSettings,
        SEVENPlayerService,
        SEVENPlayerSettingsService,
        SEVENLocale,
        SEVENToken
    ) {
        var config = SEVENConfig,
            helpers = SEVENHelpers,
            settings = SEVENSettings,
            service = SEVENPlayerService,
            settingsSrv = SEVENPlayerSettingsService,
            appSettings = SevenGravitySettings,
            locale = SEVENLocale,
            tokenService = SEVENToken,
            authModuleSettings = appSettings.getDataByKey('module.authAndPlayer');

        function getPlayerServiceImplementation() {
            var strategyAuth = authModuleSettings && authModuleSettings.strategy,
                implementations = {
                    seven: 'SEVENPlayerApiService',
                    slap:
                        settings.mode === 'integration'
                            ? 'SEVENPlayerApiService'
                            : 'SLAPPlayerService',
                };

            return $injector.get(implementations[strategyAuth] || implementations.seven);
        }

        function User() {
            this.reset();
            this.resetSettings();
            this.currency = config.client.currency;
            this.currencyVirtual = settings.company.currencyVirtual;

            $log.debug('[SEVEN] Created user =>', this);
        }

        User.prototype.reset = function (options) {
            this.name = null;
            this.id = null;
            this.email = null;
            this.firstName = null;
            this.lastName = null;
            this.nickname = config.messages.general.guest;
            this.logged = false;
            this.profile = null;
            this.registrationOrigin = null;

            if (!(options && options.disableBalanceReset)) {
                this.balance = 0;
                this.balanceTotal = 0;
            }

            $rootScope.$broadcast('SEVEN.UserChanged');
        };

        User.prototype.resetSettings = function () {
            this.settings = {
                paymentMethod: 'VirtualMoney',
                showBalance: false,
                oddType: 1,
                forceHideBalance: false,
            };
        };

        User.prototype.balanceCurrency = function () {
            return this.currencyVirtual ? settings.company.currencyVirtualCode : this.currency;
        };

        User.prototype.balanceAmount = function () {
            return $filter('number')(this.balanceTotal, 2);
        };

        User.prototype.balanceLabel = function () {
            var currency = this.currencyVirtual
                ? settings.company.currencyVirtualCode
                : this.currency;
            return $filter('number')(this.balanceTotal, 2) + ' ' + currency;
        };

        User.prototype.bonusBalanceLabel = function () {
            if (!this.bonus || !this.isBonusActive()) {
                return;
            }

            var currency = this.currencyVirtual
                ? settings.company.currencyVirtualCode
                : this.currency;

            return $filter('number')(this.bonus.balance, 2) + ' ' + currency;
        };

        User.prototype.realBalanceLabel = function () {
            var currency = this.currencyVirtual
                ? settings.company.currencyVirtualCode
                : this.currency;
            return $filter('number')(this.balance, 2) + ' ' + currency;
        };

        User.prototype.fullName = function () {
            return this.lastName ? this.firstName + ' ' + this.lastName : this.firstName;
        };

        User.prototype.saveLocal = function () {
            locker.put('User', {
                id: this.id,
                name: this.name,
                email: this.email,
                firstName: this.firstName,
                lastName: this.lastName,
                nickname: this.nickname,
                settings: this.settings,
                profile: this.profile,
            });
        };

        User.prototype.isBonusVisible = function () {
            if (this.bonus.products) {
                var current = $state.$current;
                if (current.data) {
                    return this.bonus.products.indexOf(current.data.bonus) > -1;
                } else {
                    // NOTE: Loop parent tree if needed
                    var parent = current.$parent;
                    if (parent) {
                        return this.bonus.products.indexOf(parent.data.bonus) > -1;
                    }
                }
            }

            return false;
        };

        User.prototype.isBonusActive = function () {
            return this.bonus && this.isBonusVisible() && this.bonus.active;
        };

        User.prototype.isProductExcluded = function (product) {
            var excludedProducts = this.profile && this.profile.selfExcludedProducts;

            if (!excludedProducts) return false;

            return excludedProducts.some(function (excludedProduct) {
                return excludedProduct.product === product;
            });
        };

        User.prototype.calculateBonusBalance = function (data) {
            if (data.bonus) {
                this.bonus = data.bonus;
                this.bonus.products = data.bonus.products;
            }

            this.setBonusVisibility();
        };

        User.prototype.getBalance = function () {
            if (!this.logged || !tokenService.getToken()) return;

            var self = this;

            return service.getBalance().then(function (response) {
                self.fillBalance(response.data);
            });
        };

        var throttledGetBalance = helpers.throttle(
            function (context) {
                return context.getBalance();
            },
            3000,
            { leading: true }
        );

        User.prototype.getThrottledBalance = function () {
            return throttledGetBalance(this);
        };

        User.prototype.fillBalance = function (data) {
            if (!data) return;

            this.balance = angular.isUndefined(data.balance) ? data.amount : data.balance;
            this.currency = this.currencyVirtual
                ? settings.company.currencyVirtualCode
                : data.currency;
            this.calculateBonusBalance(data);

            $log.debug('[Seven] User balance: ', data);
        };
        /**
         * @param {boolean} saveLocal - Whether to save user data to local storage.
         */
        User.prototype.fillProfile = function (saveLocal) {
            var self = this;
            var playerService = getPlayerServiceImplementation();

            return playerService.throttledGetProfile().then(function (response) {
                if (response.data) {
                    self.profile = response.data;
                    // HACK: Complete User data should update here, not only Profile object
                    // We're updating nickname manually here
                    // NOTE: User data should update on each loginCheck call
                    if (response.data.nickname) self.nickname = response.data.nickname;
                    if (saveLocal) self.saveLocal();
                    self.validateLanguage();
                    // Reboot if language different
                    // NOTE: This should be handled by platform
                    if (settings.mode !== 'integration') {
                        locale.updateLanguageChange(self.profile.language);
                    }

                    // Get stored settings
                    if (settings.mode !== 'integration') {
                        return settingsSrv
                            .getAll()
                            .then(function (response) {
                                var data = response.data;

                                self.resetSettings();

                                if (data) {
                                    // Set fetched user settings
                                    self.setSettings(data, 'platform.');
                                    self.setSettings(data, 'product.');
                                }

                                if (saveLocal) self.saveLocal();
                                return self;
                            })
                            .catch(function () {
                                self.resetSettings();
                                if (saveLocal) self.saveLocal();
                                return self;
                            });
                    } else {
                        if (saveLocal) self.saveLocal();
                        return self;
                    }
                }
            });
        };

        User.prototype.setSettings = function (data, namespace) {
            var self = this,
                keys = Object.keys(data);

            if (keys.length) {
                keys.filter(function (key) {
                    return key.substring(0, namespace.length) === namespace;
                }).forEach(function (key) {
                    var propertyKey = key.substring(namespace.length);
                    self.settings[propertyKey] = data[key];
                });
            }
        };

        User.prototype.saveSetting = function (key, value, type) {
            return settingsSrv.save(key, value, type);
        };

        User.prototype.setBonusVisibility = function () {
            if (this.bonus) {
                this.balanceTotal = this.isBonusActive()
                    ? this.balance + this.bonus.balance
                    : this.balance;
                this.bonus.visible = this.bonus.active && this.isBonusVisible();
            } else {
                this.balanceTotal = this.balance;
            }
        };

        User.prototype.updateProfile = function (data) {
            var self = this;
            service.updateProfilePart(data).then(function () {
                self.fillProfile(true).catch(angular.noop);
            });
        };

        User.prototype.validateLanguage = function () {
            if (locale.isLanguageValid(this.profile.language)) return;

            // Player profile language not valid, set first from the list as default
            if (locale.availableLanguages.length) {
                var defaultLanguage = locale.availableLanguages[0].value;
                this.profile.language = defaultLanguage;
                this.updateProfile({
                    language: defaultLanguage,
                });
            } else {
                console.error(
                    'There are no available languages configured for application so default is applied.'
                );
            }
        };

        User.prototype.fillUserFromLocal = function () {
            var stored = locker.get('User');

            if (stored) {
                this.name = stored.name;
                this.id = stored.id;
                this.email = stored.email;
                this.firstName = stored.firstName;
                this.lastName = stored.lastName;
                this.nickname = stored.nickname;
                this.settings = stored.settings;
                this.profile = stored.profile;
                this.registrationOrigin = stored.registrationOrigin;
            }
            $rootScope.$broadcast('SEVEN.UserChanged');
        };

        User.prototype.removeLocal = function () {
            locker.forget('User');
            locker.forget('refreshToken');
            locker.forget('rememberMe');

            tokenService.removeToken();
        };

        User.prototype.set = function (name, data, token) {
            var registrationOriginStorageKey = 'User:' + data.Uuid + ':registrationOrigin';

            this.name = name;
            this.id = data.Uuid;
            this.email = data.email;
            this.firstName = data.firstName;
            this.lastName = data.lastName;
            this.nickname = data.nickname;
            this.registrationOrigin =
                data.origin || this.registrationOrigin || locker.get(registrationOriginStorageKey);

            if (data.origin) {
                locker.put(registrationOriginStorageKey, data.origin);
            }

            tokenService.setToken(token);
            $rootScope.$broadcast('SEVEN.UserChanged');
        };

        User.prototype.setToken = function (token) {
            tokenService.setToken(token);
        };

        return new User();
    }]
);

SEVEN.component('sevenNotificationBar', {
    templateUrl: 'app/shared/notification-bar/notification-bar-view.html',
    controllerAs: 'notificationBar',
    bindings: {
        notification: '<',
    },

    controller: ['$scope', '$timeout', function ($scope, $timeout) {
        var vm = this;
        var timeout;

        var updateNotification = function () {
            vm.notification.icon = {
                success: 'n-i-check-a',
                error: 'n-i-status',
                info: 'n-i-status-b',
            }[vm.notification.type];

            if (timeout) $timeout.cancel(timeout);

            if (vm.notification.duration) {
                timeout = $timeout(function () {
                    vm.closeNotification();
                }, vm.notification.duration);
            }
        };

        vm.closeNotification = function () {
            vm.notification = null;

            if (timeout) $timeout.cancel(timeout);
        };

        vm.$onChanges = updateNotification;

        $scope.$on('$destroy', function () {
            vm.closeNotification();
        });
    }],
});

SEVEN.component('sevenNotificationItem', {
    templateUrl: 'app/shared/notifications-pusher/notification-item/notification-item.view.html',
    controller: ['$scope', '$timeout', '$state', '$element', '$rootScope', '$interval', function ($scope, $timeout, $state, $element, $rootScope, $interval) {
        var vm = this,
            timeoutDelay,
            countdown = {
                hideTime: null,
                interval: null,
            },
            // List of allowed notification types
            types = {
                info: 'info',
                action: 'action',
            };

        var init = function () {
            vm.preventClick = false;
            vm.fadeOut = false;

            // If 'isCloseable' wasn't received, close icon will be displayed by default
            vm.showCloseIcon = !angular.isUndefined(vm.isCloseable) ? vm.isCloseable : true;

            // If 'type' wasn't received, default is 'info'
            vm.type = types[vm.type] || types.info;

            // If 'duration' wasn't received, notification will not be removed
            timeoutDelay = vm.duration ? parseFloat(vm.duration) * 1000 : null;

            if (timeoutDelay) {
                $timeout(function () {
                    broadcastNotificationTimeout();
                    vm.close(true);
                }, timeoutDelay);
            }

            if (vm.countdown && timeoutDelay) {
                countdown.hideTime = new Date().getTime() + timeoutDelay;

                countdown.interval = $interval(function () {
                    vm.countdownWidth =
                        ((countdown.hideTime - new Date().getTime()) / timeoutDelay) * 100 + '%';
                }, 1000 / 60);
            }
        };

        var broadcastNotificationTimeout = function () {
            $rootScope.$broadcast('SEVEN.PlayerNotificationExpired', {
                notification: {
                    action: vm.actionName || null,
                    payload: vm.payload || null,
                },
            });
        };

        var broadcastEvent = function (wasRedirectedOnClick) {
            // Broadcast event with relevant information (actionName & payload) received in notification data,
            // letting any component listening this event to interpret it as they see fit
            $rootScope.$broadcast('SEVEN.PlayerNotificationClicked', {
                wasRedirectedOnClick: wasRedirectedOnClick,
                receivedTime: vm.receivedTime,
                notification: {
                    actionName: vm.actionName || null,
                    payload: vm.payload || null,
                },
            });
        };

        var checkIfShouldRedirect = function (redirectState) {
            var shouldRedirect = false;

            // Check if valid state name
            if (redirectState && $state.get(redirectState.name)) {
                // Check if already on state with same state params
                if (!$state.is(redirectState.name, redirectState.params)) {
                    shouldRedirect = true;
                }
            }

            return shouldRedirect;
        };

        vm.onClick = function () {
            // Hide <a> element and display <span>, so that User cannot click on it more than once
            vm.preventClick = true;

            // If 'redirectState' wasn't received or User is already on route, just dispatch event
            if (!checkIfShouldRedirect(vm.redirectState)) {
                broadcastEvent(false);
                vm.close(true);
            } else {
                // If not already there, redirect to route, before dispatching event
                $state.go(vm.redirectState.name, vm.redirectState.params).then(function () {
                    broadcastEvent(true);
                    vm.close(true);
                });
            }
        };

        vm.close = function (fadeEffect) {
            if (fadeEffect) {
                vm.fadeOut = true;

                // Timeout duration is synced with duration of fade-out effect defined in scss
                $timeout(function () {
                    cleanup();
                    vm.onClose();
                }, 400);
            } else {
                cleanup();
                vm.onClose();
            }
        };

        var cleanup = function () {
            if (countdown.interval) {
                $interval.cancel(countdown.interval);
            }
        };

        vm.$onInit = init;
        vm.$onDestroy = cleanup;
    }],
    controllerAs: 'notificationItemVm',
    bindings: {
        key: '<',
        messageText: '<', // Text to be displayed in notification
        type: '<', // Type of notification (info/action) - Info is not clickable, Action is.
        redirectState: '<', // State to redirect to, after User clicks on notification; i.e. {"name": "PlayerHistory", "params": { "section": "games" }}
        actionName: '<', // Arbitrary action name to be forwarded in internal broadcast event upon notification click
        payload: '<', // Payload to be forwarded in internal broadcast event upon notification click
        duration: '<', // Duration of notification display in seconds; if empty, notification will not be removed.
        countdown: '<', // If countdown is true, start animating progress bar
        receivedTime: '<', // Time which notification is received in ISO8601 format
        isCloseable: '<', // Should notification have 'close' button
        onClose: '&', // Internal method (from parent) to be called upon notification closure
    },
});

SEVEN.service(
    'SEVENNotificationsPusherService',
    ['$http', '$log', '$timeout', '$window', '$pusher', 'locker', 'SEVENConfig', 'SEVENSentry', 'SEVENSettings', 'SEVENUser', 'SEVENLocale', 'SEVENToken', function (
        $http,
        $log,
        $timeout,
        $window,
        $pusher,
        locker,
        SEVENConfig,
        SEVENSentry,
        SEVENSettings,
        SEVENUser,
        SEVENLocale,
        SEVENToken
    ) {
        var settings = SEVENSettings,
            config = SEVENConfig,
            user = SEVENUser,
            locale = SEVENLocale,
            tokenService = SEVENToken,
            gravityAppName = settings.api.gravity.appName,
            notificationsPusher = settings.notificationsPusher,
            pusherConfig = notificationsPusher.config[settings.server],
            pusherInstance;

        var globalEventHandler = function (event, data) {
            if (data && data.event !== 'pusher:pong') $log.log('[Seven] Pusher: ' + event, data);
        };

        var setListeners = function (connection, playerUuid) {
            connection.bind('error', function (error) {
                $log.error(error, playerUuid);
            });
            connection.bind_global(globalEventHandler);
        };

        return {
            init: function () {
                var pusher = null;

                if (this.isPusherEnabled()) {
                    pusherInstance = new $window.Pusher(pusherConfig.app.key, {
                        cluster: pusherConfig.app.cluster,
                        channelAuthorization: {
                            endpoint:
                                settings.api.gravity.url + '/notifications/authenticate/player',
                            params: {
                                userUUID: user.id,
                            },
                            headers: {
                                Authorization: 'Bearer ' + tokenService.getToken(),
                                'X-Nsft-SCD-Company-Id': config.client.uuid,
                                'X-Nsft-SCD-Company-Name': settings.company.name,
                                'X-Nsft-SCD-App-Name':
                                    gravityAppName || settings.company.name + '_web',
                                'X-Nsft-SCD-Locale': locale.activeLanguage,
                            },
                        },
                    });

                    setListeners(pusherInstance.connection, user.id);

                    pusher = $pusher(pusherInstance);
                }
                return pusher;
            },
            isPusherEnabled: function () {
                return (
                    !angular.isUndefined($window.Pusher) &&
                    notificationsPusher &&
                    notificationsPusher.enabled
                );
            },
        };
    }]
);

SEVEN.component('sevenNotificationsPusher', {
    templateUrl: 'app/shared/notifications-pusher/notifications-pusher.view.html',
    controller: function () {
        var vm = this;

        vm.notifications = [];

        var update = function (changes) {
            // Push newly received notification into array, to create new notification item
            if (changes.notificationData.currentValue) {
                var notification = Object.assign(
                    { receivedTime: new Date().toISOString() },
                    changes.notificationData.currentValue
                );
                vm.notifications.push(notification);
            }
        };

        vm.closeNotification = function (notification) {
            // Filter out notification (which requested to be closed) from array
            vm.notifications = vm.notifications.filter(function (n) {
                // This doesn't work, is there access to lodash isEqual?
                return !angular.equals(n, notification);
            });
        };

        vm.$onChanges = update;
    },
    controllerAs: 'notificationsVm',
    bindings: {
        notificationData: '<',
    },
});

// Date parser
SEVEN.directive('sevenDateParser', function () {
    // Directive definition
    return {
        restrict: 'A',
        require: 'ngModel',
        link: function (scope, elem, attrs, ngModel) {
            // Declare variables
            var format = 'L';

            // To model
            ngModel.$parsers.push(function dateParser(value) {
                value = moment(value, moment.localeData().longDateFormat(format));
                ngModel.$setViewValue(value.format(format));
                ngModel.$render();
                return value.toDate();
            });

            // To view
            ngModel.$formatters.push(function dateFormatter(value) {
                return moment(value).format(format);
            });
        },
    };
});

SEVEN.directive('sevenDateRangeParser', function () {
    return {
        restrict: 'A',
        require: 'ngModel',
        scope: {
            from: '=?',
            to: '=?',
        },
        link: function (scope, elem, attrs, ngModel) {
            // Declare variables
            var format = 'L';

            // To view
            ngModel.$formatters.push(function dateFormatter() {
                var from = moment(scope.from).format(format);
                var to = moment(scope.to).format(format);
                return from + ' - ' + to;
            });
        },
    };
});

// Integer parser
SEVEN.directive('sevenIntegerParser', ['$timeout', function ($timeout) {
    return {
        restrict: 'A',
        require: 'ngModel',
        link: function (scope, elem, attrs, ngModel) {
            // Variables
            var point = '.',
                pointAlt = ',';

            // Check only positive
            var positive = attrs.sevenIntegerParser ? scope.$eval(attrs.sevenIntegerParser) : false;

            // To model
            ngModel.$parsers.push(function integerParser(value) {
                // Disable e-notation
                if (value.indexOf('e') > -1) value = '0';
                // Parse value
                value = value.replace(pointAlt, point);
                value = parseInt(value, 10);
                value = Number.isNaN(value) ? ngModel.$modelValue : value;
                if (positive && value <= 0) value = ngModel.$modelValue;
                ngModel.$setViewValue(value.toString());
                // Update model without debounce
                $timeout(function () {
                    ngModel.$commitViewValue();
                });
                ngModel.$render();
                return value;
            });

            // To view
            ngModel.$formatters.push(function integerFormatter(value) {
                return parseInt(value, 10).toString();
            });
        },
    };
}]);

// Number parser
SEVEN.directive('sevenNumberParser', ['$timeout', function ($timeout) {
    return {
        restrict: 'A',
        require: 'ngModel',
        link: function (scope, elem, attrs, ngModel) {
            // Variables
            var point = '.',
                pointAlt = ',';

            // Check only positive
            var positive = attrs.sevenNumberParser ? scope.$eval(attrs.sevenNumberParser) : false,
                round = attrs.sevenNumberParserRound
                    ? parseInt(scope.$eval(attrs.sevenNumberParserRound), 10)
                    : 2;

            var numOfDecimalPlaces = attrs.sevenNumberParserRound === '0' ? 0 : 2;

            // To model
            ngModel.$parsers.push(function numberParser(value) {
                // Disable e-notation
                if (value.indexOf('e') > -1) value = '0';
                // Parse value
                value = value.replace(pointAlt, point);
                value = parseFloat(value, 10);
                value = Number.isNaN(value) ? ngModel.$modelValue : value;
                if (positive && value <= 0) value = ngModel.$modelValue;
                if (round >= 0)
                    value = Math.round(value * Math.pow(10, round)) / Math.pow(10, round);
                ngModel.$setViewValue(value ? value.toFixed(numOfDecimalPlaces).toString() : '');
                // Update model without debounce
                $timeout(function () {
                    ngModel.$commitViewValue();
                });
                ngModel.$render();
                return value;
            });

            // To view
            ngModel.$formatters.push(function numberFormatter(value) {
                value = parseFloat(value, 10);
                value = Number.isNaN(value) ? 0 : value;
                if (round >= 0)
                    value = Math.round(value * Math.pow(10, round)) / Math.pow(10, round);
                return value.toFixed(numOfDecimalPlaces).toString();
            });
        },
    };
}]);

// Phone number parser
SEVEN.directive('sevenPhoneNumberParser', function () {
    return {
        restrict: 'A',
        require: 'ngModel',
        link: function (scope, element, attrs, ngModel) {
            ngModel.$parsers.push(function (inputValue) {
                if (inputValue === undefined) return '';

                var transformedInput = inputValue.replace(/[^0-9]/g, '');

                if (transformedInput !== inputValue) {
                    ngModel.$setViewValue(transformedInput);
                    ngModel.$render();
                }

                return transformedInput;
            });
        },
    };
});

SEVEN.controller(
    'SEVENPlayerPasswordChangeDialogCtrl',
    ['$state', '$scope', '$location', 'SEVENConfig', 'SEVENException', 'SEVENModules', 'SEVENNotifier', 'SEVENPlayerService', 'SEVENPlayerAuthService', 'SEVENUser', 'close', 'urlSearchParams', function (
        $state,
        $scope,
        $location,
        SEVENConfig,
        SEVENException,
        SEVENModules,
        SEVENNotifier,
        SEVENPlayerService,
        SEVENPlayerAuthService,
        SEVENUser,
        close,
        urlSearchParams
    ) {
        var config = SEVENConfig,
            exception = SEVENException,
            modules = SEVENModules,
            notifier = SEVENNotifier,
            playerService = SEVENPlayerService,
            playerAuthService = SEVENPlayerAuthService,
            user = SEVENUser,
            vm = this;

        var init = function () {
            // Bind model
            vm.config = config;
            vm.modules = modules;
            vm.close = close;
            vm.remove = remove;
            vm.validation = angular.extend({}, playerService.getValidation());
            vm.passwordDesc = getPasswordDesc(vm.validation);

            vm.passwords = {
                password: {
                    type: 'password',
                    visible: false,
                },
                passwordCheck: {
                    type: 'password',
                    visible: false,
                },
            };
            // Set loading
            $scope.$emit('SEVEN.StateLoading', false);
        };

        var getPasswordDesc = function (validation) {
            return [
                validation.password.messages.pattern,
                validation.messages.minlength.supplant({
                    value: validation.password.minlength,
                }),
            ].join(' ');
        };

        vm.change = function () {
            vm.working = true;
            playerService
                .changePassword(vm, urlSearchParams)
                .then(function (response) {
                    vm.error = false;
                    // Try to automatically login player
                    var email = $location.search().email;
                    if (response.status === 204 && email) {
                        playerAuthService
                            .login({
                                _username: email,
                                _password: vm.password,
                            })
                            .then(function (response) {
                                if (!response.profile) return;

                                user.set(email, response.profile, response.token);
                                user.logged = true;
                                user.saveLocal();
                                user.getThrottledBalance();

                                return user
                                    .fillProfile(true)
                                    .then(function () {
                                        $state.go('PlayerProfile');
                                    })
                                    .catch(angular.noop);
                            })
                            .finally(function () {
                                notifyAndClose();
                            });
                    } else {
                        notifyAndClose();
                    }
                    vm.working = false;
                })
                .catch(function (response) {
                    vm.error = true;
                    vm.message = exception.renderMessage(response.data);
                    vm.working = false;
                });
        };

        vm.togglePasswordVisibility = function (fieldName) {
            vm.passwords[fieldName].type =
                vm.passwords[fieldName].type === 'password' ? 'text' : 'password';
            vm.passwords[fieldName].visible = !vm.passwords[fieldName].visible;
        };

        var notifyAndClose = function () {
            notifier.notify(
                config.messages.general.passwordChangeSuccess,
                { hideIcon: true, type: 'positive' },
                'default'
            );
            clearParameters();
            close();
        };

        var clearParameters = function () {
            $location.search({
                passwordChange: null,
                hash: null,
                token: null,
                email: null,
            });
        };

        var remove = function (event) {
            if (event.target === event.currentTarget) {
                clearParameters();
                close();
            }
        };

        init();
    }]
);

SEVEN.controller(
    'SEVENResetPasswordDialogCtrl',
    ['$scope', 'SEVENConfig', 'SEVENPlayerService', 'SEVENModules', 'SEVENNotifier', 'SEVENHelpers', 'close', function (
        $scope,
        SEVENConfig,
        SEVENPlayerService,
        SEVENModules,
        SEVENNotifier,
        SEVENHelpers,
        close
    ) {
        // Reference dependencies
        var config = SEVENConfig,
            modules = SEVENModules,
            notifier = SEVENNotifier,
            service = SEVENPlayerService,
            helpers = SEVENHelpers;

        // Initialize
        var init = function () {
            // Set validation
            $scope.config = config;
            $scope.modules = modules;
            $scope.close = close;
            $scope.remove = remove;
            $scope.validation = angular.extend({}, service.getValidation());
            // Set loading
            $scope.$emit('SEVEN.StateLoading', false);
        };

        // Reset password action
        $scope.reset = function (scope) {
            // Get model
            var model = scope.password;

            // Send to server
            $scope.working = true;
            service
                .resetPassword(model)
                .then(function () {
                    $scope.error = false;
                    $scope.message = config.messages.general.passwordMailSuccess.supplant({
                        username: model.email,
                    });
                    // Reset form
                    scope.passwordForm.$setPristine();
                    scope.password = {};
                    $scope.working = false;
                    $scope.success = true;

                    notifier.notify(
                        $scope.message,
                        { hideIcon: true, type: 'positive' },
                        'default'
                    );
                    close();
                })
                .catch(function (response) {
                    $scope.error = true;
                    $scope.message = helpers.getResponseErrorMessage(response);
                    $scope.working = false;
                });
        };

        var remove = function (event) {
            if (event.target === event.currentTarget) {
                close();
            }
        };

        // Initialize
        init();
    }]
);

SEVEN.component('sevenPaymentDropdown', {
    templateUrl: 'app/shared/payment-methods/payment-dropdown/payment-dropdown-view.html',
    controllerAs: 'paymentDropdown',
    require: {
        form: '^form',
    },
    bindings: {
        options: '<',
        inputModel: '=',
        inputPlaceholder: '<',
        submitDisabled: '<',
        hideDropfilterRemoveButton: '<',
        optional: '<',
        disableNameOrdering: '<',
    },

    controller: function () {
        var vm = this;

        var init = function () {
            vm.toggleDropdown = toggleDropdown;
            vm.setInputModel = setInputModel;
            vm.resetItemFilter = resetItemFilter;
        };

        var toggleDropdown = function (current, property, close) {
            vm.itemFilter = '';
            current[property] = close ? false : !current[property];

            if (!current.dropdownOpened && !vm.inputModel) {
                vm.form.dropdown.$setDirty();
            }
        };

        var setInputModel = function (item) {
            vm.inputModel = item;
        };

        var resetItemFilter = function () {
            vm.itemFilter = '';
        };

        vm.$onInit = init;
    },
});

SEVEN.component('sevenPaymentInputAmount', {
    templateUrl: 'app/shared/payment-methods/payment-input/payment-input-amount-view.html',
    controllerAs: 'paymentInput',
    bindings: {
        amount: '=',
        amountValidation: '<',
        disabled: '=',
        disallowDecimalPlaces: '<',
        onChange: '&',
    },

    controller: ['SEVENConfig', 'SEVENUser', function (SEVENConfig, SEVENUser) {
        var vm = this;
        var config = SEVENConfig;
        var user = SEVENUser;

        var init = function () {
            vm.amountMessage = config.messages.general.amount;
            vm.currency = user.currency;
            vm.inputMode =
                angular.isDefined(vm.decimalPlaces) && vm.decimalPlaces === 0
                    ? 'numeric'
                    : 'decimal';
        };

        vm.$onInit = init;
    }],
});

SEVEN.component('sevenPaymentInputEmail', {
    templateUrl: 'app/shared/payment-methods/payment-input/payment-input-email-view.html',
    controllerAs: 'paymentInput',
    bindings: {
        email: '=',
        emailValidation: '<',
    },

    controller: ['SEVENConfig', function (SEVENConfig) {
        var vm = this;
        var config = SEVENConfig;

        var init = function () {
            vm.emailMessage = config.messages.general.email;
        };

        vm.$onInit = init;
    }],
});

SEVEN.component('sevenPaymentInputField', {
    templateUrl: 'app/shared/payment-methods/payment-input/payment-input-field-view.html',
    controllerAs: 'paymentInput',
    bindings: {
        type: '@',
        inputName: '@',
        placeholder: '<',
        ngModel: '=',
        modelValidation: '<',
        readOnlyField: '<',
        disabled: '<',
        inputMode: '@',
        selectOnClick: '<',
    },
    controller: function () {
        var vm = this;
        var checkNumericInputs = false;
        var init = function () {
            vm.onKeyDown = onKeyDown;
        };

        var onChanges = function (changes) {
            if (changes.type || changes.inputMode) {
                checkNumericInputs = shouldCheckNumericInputs();
            }
        };

        var shouldCheckNumericInputs = function () {
            return vm.type === 'number' || vm.inputMode === 'numeric';
        };

        var onKeyDown = function (event) {
            if (!checkNumericInputs) return;

            var allowedInputs = [
                '0',
                '1',
                '2',
                '3',
                '4',
                '5',
                '6',
                '7',
                '8',
                '9',
                'Backspace',
                'Enter',
            ];

            if (!allowedInputs.includes(event.key)) {
                event.preventDefault();
            }
        };

        vm.$onInit = init;
        vm.$onChanges = onChanges;
    },
});

SEVEN.component('sevenPaymentInputMobile', {
    templateUrl: 'app/shared/payment-methods/payment-input/payment-input-mobile-view.html',
    controllerAs: 'paymentInput',
    bindings: {
        mobileNumber: '=',
        mobileNumberValidation: '<',
        isReadonly: '<',
    },

    controller: ['SEVENConfig', 'SEVENUser', function (SEVENConfig, SEVENUser) {
        var vm = this;
        var config = SEVENConfig;
        var user = SEVENUser;

        var init = function () {
            vm.phoneNumberMessage = config.messages.general.phoneNumber;

            setUserMobileNumber();
        };

        var setUserMobileNumber = function () {
            vm.mobileNumber = user.profile.mobileNumber;
        };

        vm.onKeyDown = function (event) {
            var allowedInputs = [
                '0',
                '1',
                '2',
                '3',
                '4',
                '5',
                '6',
                '7',
                '8',
                '9',
                'Backspace',
                'Enter',
            ];

            if (!allowedInputs.includes(event.key)) {
                event.preventDefault();
            }
        };

        vm.$onInit = init;
    }],
});

SEVEN.component('sevenPaymentMessages', {
    templateUrl: 'app/shared/payment-methods/payment-messages/payment-messages-view.html',
    controllerAs: 'paymentMessages',
    bindings: {
        isError: '<',
        isPending: '<',
        message: '<',
        messageLink: '<',
    },
});

SEVEN.directive('sevenPaymentOnSubmit', ['$q', '$rootScope', '$exceptionHandler', '$parse', function ($q, $rootScope, $exceptionHandler, $parse) {
    return {
        restrict: 'A',
        require: '^form',
        compile: function ($element, attr) {
            var fn = $parse(attr.sevenPaymentOnSubmit);
            var eventListenerTypes = {
                form: 'submit',
                button: 'click submit',
                default: 'click submit',
            };
            var eventListenerType =
                eventListenerTypes[$element[0].localName] || eventListenerTypes.default;

            return function sevenPaymentSubmitHandler(scope, element, attr, ngFormController) {
                var existingCallbackInProgress = false;

                element.on(eventListenerType, function (event) {
                    var callback = function () {
                        if (ngFormController.$invalid || existingCallbackInProgress) return;
                        existingCallbackInProgress = true;

                        const controls = ngFormController.$getControls();
                        const debouncePromises = [];

                        angular.forEach(controls, function (control) {
                            if (
                                control.$$pendingDebounce &&
                                control.$$pendingDebounce.$$state.status === 0
                            ) {
                                debouncePromises.push(control.$$pendingDebounce);
                            }
                        });

                        if (debouncePromises.length) {
                            $q.all(debouncePromises)
                                .then(function () {
                                    fn(scope, { $event: event });
                                    existingCallbackInProgress = false;
                                })
                                .catch(function () {
                                    if (ngFormController.$invalid) return;

                                    const control = ngFormController
                                        .$getControls()
                                        .find(function (control) {
                                            return control.sevenPaymentNumberParserInProgress;
                                        });

                                    if (control) {
                                        var unwatch = scope.$watch(
                                            function () {
                                                return control.sevenPaymentNumberParserInProgress;
                                            },
                                            function (newValue) {
                                                if (!newValue) {
                                                    fn(scope, { $event: event });
                                                    unwatch();
                                                    existingCallbackInProgress = false;
                                                }
                                            }
                                        );
                                    } else {
                                        fn(scope, { $event: event });
                                        existingCallbackInProgress = false;
                                    }
                                });
                        } else {
                            fn(scope, { $event: event });
                            existingCallbackInProgress = false;
                        }
                    };

                    if (!$rootScope.$$phase) {
                        scope.$apply(callback);
                    } else {
                        try {
                            callback();
                        } catch (error) {
                            $exceptionHandler(error);
                            existingCallbackInProgress = false;
                        }
                    }
                });
            };
        },
    };
}]);

SEVEN.component('sevenPaymentPlayerVerification', {
    templateUrl:
        '/app/shared/payment-methods/payment-player-verification/payment-player-verification.view.html',
    controllerAs: 'playerVerification',
    bindings: {
        verificationChange: '&',
        amount: '=',
        type: '@',
        autoRedirect: '<',
    },
    controller: ['$state', '$scope', '$window', '$location', '$filter', 'SEVENUser', 'SEVENSettings', 'SEVENPaymentPlayerVerification', function (
        $state,
        $scope,
        $window,
        $location,
        $filter,
        SEVENUser,
        SEVENSettings,
        SEVENPaymentPlayerVerification
    ) {
        var vm = this,
            user = SEVENUser,
            settings = SEVENSettings,
            playerVerificationSvc = SEVENPaymentPlayerVerification,
            playerVerification = user.profile.verified,
            translate = $filter('translate'),
            blinkingStatus = {
                COMPLETED: 'Completed',
                PENDING_APPROVAL: 'Pending back office approval',
                PENDING_CONFIRMATION: 'Pending for confirmation',
            },
            shouldPrioritizeEmailVerification =
                !!settings.company.playerVerification.prioritizeEmailVerification;

        var init = function () {
            if ($location.search().status) {
                return checkVerificationQueryParams();
            }

            if (!$location.search().isRedirected && vm.autoRedirect && playerVerification.email) {
                vm.handleVerifyIdentityButton();
            }

            vm.emailButtonPlaceholder = translate('wallet.playerVerificationEmailButton');
            vm.identityButtonPlaceholder = translate('wallet.playerVerificationIdentityButton');
            vm.showEmailButton = !playerVerification.email;
            vm.showIdentityButton = !playerVerification.identity;

            if (vm.type === 'identityOnly') {
                if (!playerVerification.email) {
                    vm.verificationInfo = {
                        title: translate('wallet.playerVerificationEmail'),
                        content: translate('wallet.playerVerificationEmailMessage'),
                        icon: 'n-i-envelope',
                    };
                    vm.showIdentityButton = false;
                } else {
                    vm.showEmailButton = false;
                    vm.verificationInfo = {
                        title: translate('wallet.playerVerificationIdentity'),
                        content: translate('wallet.playerVerificationIdentityMessage'),
                        icon: 'n-i-user-name',
                    };
                }

                return;
            }

            var pluginVerification = settings.company.playerVerification[vm.type];

            if (!playerVerification.email && !playerVerification.identity) {
                if (
                    (!vm.amount || vm.amount > pluginVerification.limit) &&
                    !shouldPrioritizeEmailVerification
                ) {
                    vm.verificationInfo = {
                        title: translate('wallet.playerVerificationEmailIdentity'),
                        content: translate('wallet.playerVerificationEmailIdentityMessage'),
                        icon: 'n-i-user-name',
                    };
                } else {
                    vm.verificationInfo = {
                        title: translate('wallet.playerVerificationEmail'),
                        content: translate('wallet.playerVerificationEmailMessage'),
                        icon: 'n-i-envelope',
                    };
                    vm.showIdentityButton = false;
                }
            } else if (!playerVerification.email) {
                vm.verificationInfo = {
                    title: translate('wallet.playerVerificationEmail'),
                    content: translate('wallet.playerVerificationEmailMessage'),
                    icon: 'n-i-envelope',
                };
            } else if (!playerVerification.identity) {
                vm.verificationInfo = {
                    title: translate('wallet.playerVerificationIdentity'),
                    content: translate('wallet.playerVerificationIdentityMessage'),
                    icon: 'n-i-user-name',
                };
            }
        };

        vm.handleVerifyIdentityButton = function () {
            if (vm.isPlayerVerificationSuccessful) {
                vm.verificationChange();

                return;
            } else if (!playerVerification.email) {
                return $state.go('PlayerResendEmail');
            } else if (vm.isPlayerVerificationProcessing) {
                if (
                    vm.isVerificationRedirectingInProcess &&
                    $state.current.data.hidePlayerVerificationIdentityButton
                )
                    return (vm.verificationInfo.content = '');
                if (vm.isVerificationRetried) {
                    $scope.working = true;
                    return handleBlinkingRedirect();
                }

                return $state.go(
                    $state.current.data.group === 'PlayerWithdrawal'
                        ? 'PlayerWithdrawal'
                        : 'PlayerDeposit'
                );
            }

            vm.disableIdentityButton = true;
            var playerVerificationType = settings.company.playerVerification.type;
            vm.verificationInfo = {
                title: translate('wallet.playerVerificationRedirecting'),
                content: translate('wallet.playerVerificationRedirectingMessage'),
                icon: 'n-i-random',
            };

            if (playerVerificationType === 'blinking') {
                handleBlinkingRedirect();
            } else if (playerVerificationType === 'default') {
                $state.go('PlayerVerifyIdentity');
            }
        };

        var checkVerificationQueryParams = function () {
            var status = $location.search().status;

            if (status === blinkingStatus.COMPLETED) {
                vm.verificationInfo = {
                    title: translate('wallet.playerVerificationFinishing'),
                    content: translate('wallet.playerVerificationFinishingMessage'),
                    icon: 'n-i-clock-round',
                };

                playerVerificationSvc
                    .confirmIsPlayerFullyVerified()
                    .then(function () {
                        vm.verificationInfo = {
                            title: translate('wallet.playerVerificationSuccessful'),
                            content: translate('wallet.playerVerificationSuccessfulMessage'),
                            icon: 'n-i-check-e',
                        };
                        vm.showIdentityButton = true;
                        vm.identityButtonPlaceholder = translate('wallet.playerVerificationFinish');
                        vm.isPlayerVerificationSuccessful = true;
                    })
                    .catch(function () {
                        vm.verificationInfo = {
                            title: translate('wallet.playerVerificationIncomplete'),
                            content: translate('wallet.playerVerificationIncompleteMessage'),
                            icon: 'n-i-status-a',
                        };
                        vm.showIdentityButton = true;
                        vm.identityButtonPlaceholder = translate('wallet.playerVerificationRetry');
                    });
            } else if (
                status === blinkingStatus.PENDING_APPROVAL ||
                status === blinkingStatus.PENDING_CONFIRMATION
            ) {
                vm.verificationInfo = {
                    title: translate('wallet.playerVerificationInProcess'),
                    content: translate('wallet.playerVerificationInProcessMessage'),
                    icon: 'n-i-info-a',
                };
                vm.showIdentityButton = true;
                vm.identityButtonPlaceholder = translate('wallet.playerVerificationOk');
                vm.isPlayerVerificationProcessing = true;
                hideIdentityButtonIfInVerifyState();
            }

            $location.search('params', null);
        };

        var handleBlinkingRedirect = function () {
            playerVerificationSvc
                .getBlinkingSessionUrl()
                .then(function (sessionUrl) {
                    var redirectUrl = new URL(sessionUrl);

                    redirectUrl.searchParams.append('redirectURL', $window.location.href);
                    redirectUrl.searchParams.append('params', 'status');
                    $location.search('isRedirected', true);
                    $window.location.href = redirectUrl;
                })
                .catch(function (error) {
                    vm.disableIdentityButton = false;
                    vm.isPlayerVerificationProcessing = true;
                    $scope.working = false;

                    if (error.data && error.data.code === 62001) {
                        hideIdentityButtonIfInVerifyState();
                        vm.identityButtonPlaceholder = translate('wallet.playerVerificationOk');
                        vm.verificationInfo = {
                            title: translate('wallet.playerVerificationRedirectingInterrupted'),
                            content: translate('wallet.playerVerificationRedirectingFailed'),
                            icon: 'n-i-close-d',
                        };
                    } else if (error.data && error.data.code === 62002) {
                        hideIdentityButtonIfInVerifyState();
                        vm.isVerificationRedirectingInProcess = true;
                        vm.identityButtonPlaceholder = translate('wallet.playerVerificationOk');
                        vm.verificationInfo = {
                            title: translate('wallet.playerVerificationRedirectingInterrupted'),
                            content: translate(
                                'wallet.playerVerificationRedirectingProcessingMessage'
                            ),
                            icon: 'n-i-close-d',
                        };
                    } else {
                        vm.isVerificationRetried = true;
                        vm.identityButtonPlaceholder = translate('wallet.playerVerificationRetry');
                        vm.verificationInfo = {
                            title: translate('wallet.playerVerificationRedirectingInterrupted'),
                            content: translate(
                                'wallet.playerVerificationRedirectingInterruptedMessage'
                            ),
                            icon: 'n-i-close-d',
                        };
                    }
                });
        };

        var hideIdentityButtonIfInVerifyState = function () {
            if ($state.current.data.hidePlayerVerificationIdentityButton) {
                vm.showIdentityButton = false;
            }
        };

        vm.$onInit = init;
    }],
});

SEVEN.component('sevenPaymentProviderDetails', {
    templateUrl:
        'app/shared/payment-methods/payment-provider-details/payment-provider-details-view.html',
    controllerAs: 'paymentPlugin',
    bindings: {
        providerTitle: '<',
        providerSubtitle: '<',
        thumbIcon: '<',
        thumbSrc: '<',
        thumbOrgSrc: '<',
        minAmount: '<',
        maxAmount: '<',
        itemUrl: '<',
        disabled: '<',
    },

    controller: function () {
        var vm = this;
        vm.url = '.';
        vm.hasUrl = false;

        var init = function () {
            if (vm.itemUrl) {
                vm.url = vm.itemUrl;
                vm.hasUrl = true;
            }
        };

        vm.$onInit = init;
    },
});

SEVEN.component('sevenPaymentSubmit', {
    templateUrl: 'app/shared/payment-methods/payment-submit/payment-submit-view.html',
    controllerAs: 'paymentSubmit',
    bindings: {
        helpMessage: '<',
        providerTitle: '<',
        submitDisabled: '<',
        type: '@',
        buttonText: '<',
    },

    controller: ['SEVENConfig', function (SEVENConfig) {
        var vm = this;
        var config = SEVENConfig;
        var generalMessages = config.messages.general;

        var init = function () {
            vm.depositMessage = config.messages.general.deposit;
            vm.withdrawalMessage = config.messages.general.withdrawal;
            vm.buttonPlaceholder =
                vm.buttonText ||
                (vm.type === 'withdrawal' ? vm.withdrawalMessage : vm.depositMessage);

            if (vm.helpMessage) {
                setHelpMessage();
            }
        };

        var setHelpMessage = function () {
            if (typeof vm.helpMessage !== 'boolean') {
                vm.help = vm.helpMessage;
                return;
            }

            if (vm.type === 'deposit') {
                vm.help = generalMessages.paymentGatewayDepositHelp.supplant({
                    provider: vm.providerTitle,
                });
            } else if (vm.type === 'withdrawal') {
                vm.help = generalMessages.paymentGatewayTransferHelp.supplant({
                    provider: vm.providerTitle,
                });
            }
        };

        vm.$onInit = init;
    }],
});

SEVEN.directive('sevenPaymentNumberParser', ['$parse', '$timeout', '$filter', '$locale', function ($parse, $timeout, $filter, $locale) {
    return {
        restrict: 'A',
        require: 'ngModel',
        link: function (scope, elem, attr, ngModel) {
            var $element = elem[0];
            var optionsGetter = $parse(attr.sevenPaymentNumberParser);
            var options = optionsGetter(scope);
            var lastValidViewValue = undefined;
            var formatOptions = {
                decimalPlaces: options.disallowDecimalPlaces ? 0 : 2,
            };
            var min = $parse(attr.ngMin)(scope) || -1;
            var max = $parse(attr.ngMax)(scope) || Number.MAX_SAFE_INTEGER;

            attr.$observe('sevenPaymentNumberParser', function () {
                options = optionsGetter(scope);
                formatOptions.decimalPlaces = options.disallowDecimalPlaces ? 0 : 2;
            });

            attr.$observe('ngMin', function () {
                min = $parse(attr.ngMin)(scope) || -1;
                validateMinMax();
            });

            attr.$observe('ngMax', function () {
                max = $parse(attr.ngMax)(scope) || Number.MAX_SAFE_INTEGER;
                validateMinMax();
            });

            function validateMinMax(value) {
                ngModel.$setValidity('min', typeof value !== 'number' || value >= min);
                ngModel.$setValidity('max', typeof value !== 'number' || value <= max);
                return value;
            }

            elem.on('keydown', function (event) {
                const allowedKeys = [
                    'Backspace',
                    'Delete',
                    'Tab',
                    'Escape',
                    'Enter',
                    'ArrowLeft',
                    'ArrowRight',
                ];

                if (
                    allowedKeys.includes(event.key) ||
                    ((event.ctrlKey || event.metaKey) &&
                        ['a', 'c', 'v', 'x'].includes(event.key.toLowerCase())) || // Allow: Ctrl+A, Ctrl+C, Ctrl+V, Ctrl+X
                    /^[\d,.]$/.test(event.key) // Allow: Numbers (0-9), comma (,), and period (.) on both Numpad and main keyboard.
                )
                    return;

                event.preventDefault();
            });

            function isValidNumber(val) {
                return typeof val === 'number' && !Number.isNaN(val);
            }

            // Runs when value is inputted and sets model value and input and view value
            ngModel.$parsers.unshift(function (inputtedViewValue) {
                var badInput = $element.validity.badInput;
                var inputValueForParsing = badInput ? lastValidViewValue : inputtedViewValue;
                var numericValue = parseFloat(
                    inputValueForParsing
                        .replace(new RegExp(`\\${$locale.NUMBER_FORMATS.GROUP_SEP}`, 'g'), '')
                        .replace($locale.NUMBER_FORMATS.DECIMAL_SEP, '.')
                );
                var viewValue = inputValueForParsing ? $filter('number')(numericValue, 2) : '';
                var modelValue = isValidNumber(numericValue) ? numericValue : null;

                if (modelValue === null) return modelValue;

                ngModel.sevenPaymentNumberParserInProgress = true;

                lastValidViewValue = viewValue;
                $timeout(function () {
                    ngModel.$setViewValue(viewValue);
                    ngModel.sevenPaymentNumberParserInProgress = false;
                });
                ngModel.$render();

                if (badInput) {
                    $element.value = viewValue;
                }
                return modelValue;
            });

            // Runs when bound model is changed programmatically
            ngModel.$formatters.unshift(function (value) {
                if (ngModel.$isEmpty(value)) return value;

                var viewValue = $filter('number')(value, 2);

                lastValidViewValue = viewValue;

                validateMinMax(ngModel.$modelValue);
                return viewValue;
            });

            ngModel.$parsers.push(function (value) {
                return validateMinMax(value);
            });
        },
    };
}]);

SEVEN.component('sevenMobileNumberField', {
    templateUrl: 'app/shared/player/mobile-number-field/mobile-number-field-view.html',
    controllerAs: 'mobileNumberField',
    require: {
        form: '^form',
    },
    bindings: {
        options: '<',
        selectedPrefix: '=',
        inputPlaceholder: '<',
        inputModel: '=',
        onUpdatePrefix: '&',
        mobileNumberValidation: '<',
        scrollIntoViewOnFocus: '<',
        maxItemCount: `<`,
    },

    controller: ['$scope', '$timeout', 'SEVENSettings', function ($scope, $timeout, SEVENSettings) {
        var vm = this,
            settings = SEVENSettings,
            dropOptionHeight = 46;

        var init = function () {
            $scope.flagsSpriteSrc = settings.externalSharedImagesPath + 'flags';
            vm.toggleDropdown = toggleDropdown;
            vm.setPrefix = setPrefix;
            vm.onSearchInputFocus = onSearchInputFocus;
            vm.dropdownHeight =
                vm.maxItemCount && vm.options && vm.options.length
                    ? vm.maxItemCount * dropOptionHeight + 'px'
                    : undefined;
            vm.itemFilter = '';
        };

        var toggleDropdown = function () {
            $scope.dropdownOpen = !$scope.dropdownOpen;
        };

        var setPrefix = function (prefix) {
            vm.selectedPrefix = prefix;
            vm.onUpdatePrefix({ updatedPrefix: vm.selectedPrefix });
        };

        var onSearchInputFocus = function (event) {
            $timeout(function () {
                event.target.scrollIntoView();
            }, 100);
        };

        vm.$onInit = init;
    }],
});

(function () {
    SEVEN.component('sevenRedirectToNativeControl', {
        templateUrl: 'app/shared/redirect-to-native-control/redirect-to-native.view.html',
        controllerAs: 'redirectToNative',
        controller: ['$scope', '$location', '$state', 'SEVENConfig', function ($scope, $location, $state, SEVENConfig) {
            var vm = this;
            vm.messages = SEVENConfig.messages;
            vm.isVisible = false;

            var init = function () {
                var params = $location.search();

                vm.hasAndroidRedirect = params.nativeRedirectPlatform === 'android';
                vm.hasIOSRedirect = params.nativeRedirectPlatform === 'ios';

                if (!vm.hasAndroidRedirect && !vm.hasIOSRedirect) return;

                // Android specifics
                vm.nativePlatformRedirectUrl = params.nativeRedirectUrl;
                vm.isVisible =
                    $state.current.data.allowedNativeRedirect &&
                    vm.hasAndroidRedirect &&
                    vm.nativePlatformRedirectUrl;

                // iOS specifics
                if (vm.hasIOSRedirect) {
                    setupNavigationListener();
                }

                // Update visibility on state change
                $scope.$on('$stateChangeSuccess', function () {
                    vm.isVisible = vm.isVisible && $state.current.data.allowedNativeRedirect;
                });
            };

            var setupNavigationListener = function () {
                $scope.$on('SEVEN.PlayerPaymentFinalized', function () {
                    vm.navigateToNativePlatform();
                });
            };

            vm.$onInit = init;

            vm.navigateToNativePlatform = function () {
                if (!$state.current.data.allowedNativeRedirect) return;

                if (vm.hasAndroidRedirect && vm.nativePlatformRedirectUrl) {
                    window.location.href = vm.nativePlatformRedirectUrl;
                    return;
                }

                // iOS redirect to parent state
                var currentState = $state.current;
                var isDeposit = currentState.name.toLowerCase().includes('deposit');
                var isWithdrawal = currentState.name.toLowerCase().includes('withdrawal');

                var newState;

                if (isDeposit) newState = 'PlayerDeposit';
                if (isWithdrawal) newState = 'PlayerWithdrawal';

                if (newState) $state.go(newState);
            };
        }],
    });
})();

// NOTE: Similar functions are contained in parent controller
// but this is part of decoupling child controllers
SEVEN.controller(
    'SEVENPlayerRegisterDialogCtrl',
    ['$filter', '$rootScope', '$scope', '$state', '$location', 'SEVENSettings', 'SEVENConfig', 'SEVENCommonService', 'SEVENModules', 'SEVENNotifier', 'SEVENPlayerService', 'SEVENException', 'SEVENAdWords', 'SEVENFacebookPixel', 'SEVENGoogleAnalyticsService', 'close', 'locker', function (
        $filter,
        $rootScope,
        $scope,
        $state,
        $location,
        SEVENSettings,
        SEVENConfig,
        SEVENCommonService,
        SEVENModules,
        SEVENNotifier,
        SEVENPlayerService,
        SEVENException,
        SEVENAdWords,
        SEVENFacebookPixel,
        SEVENGoogleAnalyticsService,
        close,
        locker
    ) {
        var vm = this,
            settings = SEVENSettings,
            config = SEVENConfig,
            exception = SEVENException,
            adWords = SEVENAdWords,
            facebookPixel = SEVENFacebookPixel,
            commonSrv = SEVENCommonService,
            modules = SEVENModules,
            notifier = SEVENNotifier,
            playerSrv = SEVENPlayerService,
            analytics = SEVENGoogleAnalyticsService,
            birthDateField,
            birthDateFieldName = 'birthDate',
            form;

        var init = function () {
            // Set loading
            vm.working = true;
            // Bind scope
            vm.close = close;
            vm.submit = submit;
            vm.modules = modules;
            vm.config = config;
            vm.nextStep = nextStep;
            vm.previousStep = previousStep;
            vm.validation = createValidationMessages();
            vm.onUpdateField = onUpdateField;
            vm.saveRegisterFormData = saveRegisterFormData;
            vm.removeRegisterDialogParam = removeRegisterDialogParam;
            vm.togglePasswordVisibility = togglePasswordVisibility;
            // Initialize register model
            vm.model = {};
            // Load data
            load();
        };

        var load = function () {
            form = parse(config.forms.web_register_player);
            var isFormFilled = checkRegisterFormData();

            vm.multiStep = form.multiStep;

            if (form.multiStep) {
                vm.fields = filterSteps(form);
                vm.step = form.steps[0];
                vm.steps = form.steps;
                vm.firstStep = true;
                vm.lastStep = false;
            } else {
                vm.fields = form.fields;
            }

            // Load dependencies from sourceURL
            commonSrv
                .loadByUrls(form.dependencies)
                .then(function (responses) {
                    angular.forEach(responses, function (n, i) {
                        // Save field to set initial value
                        var field =
                            vm.fields[form.dependencies[i].index] ||
                            form.fields[form.dependencies[i].index];

                        // HACK: Filter out countries by setting
                        // TODOv2: This should be handled better in v2
                        var fieldSource = responses[i].data;
                        var blacklistCountries = settings.company.blacklistCountries;
                        if (
                            fieldSource &&
                            field.name === 'country' &&
                            angular.isArray(blacklistCountries)
                        ) {
                            fieldSource = fieldSource.filter(function (country) {
                                return blacklistCountries.indexOf(country.value) < 0;
                            });
                        }

                        field.source = fieldSource;
                        // Set initial value (one with highest priority) for fields with source
                        vm.model[field.name] = field.source[0];
                    });
                    if (isFormFilled && settings.nativeMode) {
                        goToLastStep(form);
                    }
                    // Set loading
                    vm.working = false;
                })
                .finally(function () {
                    birthDateField = findField(vm.fields, birthDateFieldName);
                });
        };

        var checkRegisterFormData = function () {
            var savedFormData = locker.get('RegisterFormData');
            var isFormFilled = false;

            if (savedFormData) {
                vm.model = savedFormData;
                isFormFilled = true;
            }

            return isFormFilled;
        };

        var saveRegisterFormData = function () {
            locker.put('RegisterFormData', vm.model);
        };

        var removeRegisterDialogParam = function () {
            locker.forget('RegisterFormData');
            $location.search('registerDialog', null);
        };

        var filterSteps = function (form, step) {
            var stepFields = [];
            var selectedStep = step || form.steps[0];

            angular.forEach(form.fields, function (field) {
                if (field.step === selectedStep.name) {
                    stepFields.push(field);
                }
            });

            return stepFields;
        };

        var nextStep = function () {
            var nextStepIndex = vm.steps.indexOf(vm.step) + 1;

            vm.step = vm.steps[nextStepIndex];
            vm.fields = filterSteps(form, vm.step);
            vm.firstStep = false;
            vm.lastStep = nextStepIndex === vm.steps.length - 1;

            $scope.form.$setPristine();
        };

        var previousStep = function () {
            var previousStepIndex = vm.steps.indexOf(vm.step) - 1;

            vm.step = vm.steps[previousStepIndex];
            vm.fields = filterSteps(form, vm.step);
            vm.firstStep = previousStepIndex === 0;
            vm.lastStep = false;

            $scope.form.$setPristine();
        };

        var goToLastStep = function (form) {
            vm.step = form.steps[form.steps.length - 1];
            vm.fields = filterSteps(form, vm.step);
            vm.firstStep = false;
            vm.lastStep = true;
        };

        var parse = function (data) {
            var fields = [],
                dependencies = [],
                checkboxUrls = settings.company.player.register.checkboxUrls;

            // Loop source
            angular.forEach(
                data.fields,
                function (n, i) {
                    // Convert to valid RegEx
                    if (n.validation.pattern) {
                        n.validation.pattern = RegExp.apply(
                            undefined,
                            /^\/(.*)\/(.*)/.exec(n.validation.pattern).slice(1)
                        );
                    }

                    if (n.validation.patternWithoutWhitespace) {
                        n.validation.patternWithoutWhitespace = RegExp.apply(
                            undefined,
                            /^\/(.*)\/(.*)/.exec(n.validation.patternWithoutWhitespace).slice(1)
                        );
                    }

                    // Set render options
                    n.render = {
                        checkbox: n.control.name === 'input' && n.control.type === 'checkbox',
                        dropdown: n.control.name === 'select',
                        textbox:
                            n.control.name === 'input' && ['checkbox'].indexOf(n.control.type) < 0,
                    };

                    // Lock dropdowns
                    n.readonly = n.render.dropdown ? true : n.readonly;

                    // Map checkbox urls
                    if (n.control.type === 'checkbox' && checkboxUrls[n.name]) {
                        n.stateUrl = checkboxUrls[n.name];
                    }

                    // Add subcontrols exception
                    if (n.name === 'birthDate') {
                        n.visible = false;
                        n.controls = generateBirthDateControls();
                    }

                    // Add help to password
                    if (n.name === 'plainPassword') {
                        // Create password description
                        n.help = vm.validation.plainPassword.pattern;
                        n.showHelp = false;
                    }

                    // Add Google Places Autocomplete to city
                    if (n.name === 'city') {
                        n.id = 'autocomplete-city';
                        n.hasAutocomplete = true;
                        n.restriction = 'country';
                    }

                    // Attach field reference on equalTo validation
                    if (n.validation.equalTo) {
                        n.validation.equalToLabel = findField(
                            data.fields,
                            n.validation.equalTo
                        ).label;
                    }

                    if (n.control.type === 'password') {
                        n.togglePassword = true;
                        n.passwordVisible = false;
                    }

                    // Add field
                    this.push(n);

                    // Stack promise urls
                    if (n.sourceUrl) {
                        dependencies.push({
                            url: n.sourceUrl.trim(),
                            index: i,
                        });
                    }
                },
                fields
            );

            return {
                fields: fields,
                dependencies: dependencies,
                steps: data.steps,
                multiStep: data.multiStep,
            };
        };

        var findField = function (fields, name) {
            for (var i = 0; i < fields.length; i++) {
                if (fields[i].name === name) {
                    return fields[i];
                }
            }
        };

        var createValidationMessages = function () {
            var base = playerSrv.getValidation();
            var additionalValidation = {
                plainPassword: base.password.messages,
            };
            var validation = angular.merge({}, base, additionalValidation);

            return validation;
        };

        var togglePasswordVisibility = function (index) {
            vm.fields[index].control.type =
                vm.fields[index].control.type === 'text' ? 'password' : 'text';
        };

        var getDays = function (year, month) {
            year = year || moment().year();
            month = angular.isUndefined(month) ? 0 : month;

            var start = 1,
                end = moment([year, month]).daysInMonth(),
                list = [];

            for (var i = start; i <= end; i++) {
                list.push({
                    value: i,
                    name: i,
                });
            }

            return list;
        };

        var getMonths = function () {
            var list = [];
            moment.months().forEach(function (month, i) {
                list.push({
                    value: i,
                    name: month,
                });
            });

            return list;
        };

        var getYears = function () {
            var start = moment().year() - 18,
                end = start - 100,
                list = [];

            for (var i = start; i > end; i--) {
                list.push({
                    value: i,
                    name: i,
                });
            }

            return list;
        };

        var generateBirthDateControls = function () {
            var list = [],
                controls = {
                    birthDay: {
                        data: getDays(),
                    },
                    birthMonth: {
                        data: getMonths(),
                        callback: updateBirthDay,
                    },
                    birthYear: {
                        data: getYears(),
                        callback: updateBirthDay,
                    },
                };

            angular.forEach(controls, function (control, name) {
                list.push({
                    render: {
                        dropdown: true,
                    },
                    name: name,
                    label: config.messages.general[name],
                    placeholder: config.messages.general[name],
                    control: {
                        name: 'select',
                        type: 'text',
                    },
                    source: control,
                    readonly: true,
                    validation: {
                        required: true,
                    },
                });
            });

            return list;
        };

        var updateBirthDay = function () {
            birthDateField.controls[0].source.data = getDays(
                vm.model.birthYear ? vm.model.birthYear.value : moment().year(),
                angular.isUndefined(vm.model.birthMonth) || vm.model.birthMonth === null
                    ? 0
                    : vm.model.birthMonth.value
            );

            if (vm.model.birthYear && vm.model.birthMonth && vm.model.birthDay) {
                var birthDate = getBirthDate();

                if (!birthDate.isValid()) {
                    vm.model.birthDay.value = 1;
                    vm.model.birthDay.name = 1;
                }
            }
        };

        var getBirthDate = function () {
            return moment({
                year: vm.model.birthYear.value,
                month: vm.model.birthMonth.value,
                day: vm.model.birthDay.value,
            });
        };

        var onUpdateField = function (fieldName, subFieldName) {
            if (fieldName === 'birthDate') {
                if (['birthMonth', 'birthYear'].indexOf(subFieldName) > -1) {
                    updateBirthDay();
                }
            }
        };

        var triggerConversion = function (data) {
            adWords.conversion('register');
            facebookPixel.conversion('CompleteRegistration', {
                value: 1,
            });

            analytics.eventTrack('Register', {
                Type: 'standard',
                Action: 'SubmitForm',
                Origin: 'standard',
            });
            $rootScope.$broadcast('SEVEN.RegistrationSuccess', data);
        };

        var submit = function () {
            var data = angular.copy(vm.model, {});

            if (
                !!birthDateField &&
                birthDateField.validation &&
                birthDateField.validation.required
            ) {
                data.birthDate = getBirthDate().format('YYYY-MM-DD');

                delete data.birthDay;
                delete data.birthMonth;
                delete data.birthYear;
            }

            vm.working = true;
            playerSrv
                .register(data)
                .then(function () {
                    vm.working = false;
                    vm.error = false;
                    locker.forget('RegisterFormData');
                    $scope.form.$setPristine();
                    triggerConversion(data);

                    notifier.notify(
                        config.messages.general.registerSuccessInfo.supplant({
                            username: data.email,
                        }),
                        { hideIcon: true, type: 'positive' },
                        'default'
                    );
                    close();
                })
                .catch(function (response) {
                    vm.error = true;
                    vm.message = exception.renderMessage(response.data);
                    vm.working = false;
                });
        };

        init();
    }]
);

(function () {
    SEVEN.component('sevenSeoDescription', {
        templateUrl: 'app/shared/seo-description/seo-description.html',
        controllerAs: 'seoDescription',
        controller: ['$log', '$sce', 'SEVENSettings', 'SEVENCmsService', 'SEVENConfig', '$rootScope', function ($log, $sce, SEVENSettings, SEVENCmsService, SEVENConfig, $rootScope) {
            var vm = this,
                cmsService = SEVENCmsService;

            var resetScroll = function () {
                if (vm.textContainer) {
                    vm.textContainer.scrollTop(0);
                }
            };

            var getSEOArticle = function (seoArticle) {
                cmsService
                    .getArticle(seoArticle)
                    .then(function (response) {
                        vm.article = $sce.trustAsHtml(response.data.article.content);
                        vm.title = response.data.article.title;
                        vm.onShow();
                    })
                    .catch(function () {
                        $log.debug(seoArticle + " article doesn't exist for company");
                    });
            };

            vm.isMobile = SEVENSettings.isMobile;
            vm.generalMessages = SEVENConfig.messages.general;

            vm.$onChanges = function (changes) {
                resetScroll();
                $rootScope.$broadcast(
                    'updateCurrentActiveMenuItemTag',
                    changes.setData.currentValue.title
                );

                if (changes.setData && changes.setData.currentValue) {
                    getSEOArticle(changes.setData.currentValue.seoArticle);
                }
            };
        }],
        bindings: {
            setData: '<',
            isExpanded: '<',
            onToggle: '&',
            onShow: '&',
        },
    });
})();

SEVEN.service('SEVENAdWords', ['$log', '$window', 'SEVENModules', function ($log, $window, SEVENModules) {
    var globals = $window.SEVENGlobals,
        plugins = SEVENModules.plugins;

    return {
        conversion: function (name) {
            if (plugins.adWords && globals) {
                var adWordsExec = plugins.adWords.executable,
                    adWordsExecGlobal = globals[adWordsExec];

                if ($window[adWordsExec] && adWordsExecGlobal && adWordsExecGlobal[name]) {
                    var result = $window[adWordsExec](adWordsExecGlobal[name]);
                    $log.debug(
                        '[SEVEN] AdWords Conversion [' + result + '] =>',
                        adWordsExecGlobal[name]
                    );
                }
            }
        },
    };
}]);

SEVEN.service('SEVENApplicationSettingsService', ['$http', 'SEVENSettings', function ($http, SEVENSettings) {
    var settings = SEVENSettings,
        baseUrl = settings.api.gravity.url,
        appName = settings.api.gravity.appName || settings.company.name + '_web';

    return {
        get: function (key) {
            return $http.get(baseUrl + '/apps/' + appName + '/settings/' + key);
        },
        getAll: function () {
            return $http.get(baseUrl + '/apps/' + appName + '/settings/');
        },
    };
}]);

SEVEN.service('SEVENBanners', ['$http', 'SEVENConfig', 'SEVENSettings', function ($http, SEVENConfig, SEVENSettings) {
    var settings = SEVENSettings,
        config = SEVENConfig;

    var parseMarketingSlotItem = function (item) {
        var parsedItem = {
            priority: item.priority,
            provider: item.provider,
            reference: item.reference,
            source: item.resource && item.resource.url,
            url: item.url,
            textContent: item.textContent,
            hasResource: Boolean(item.resource && item.resource.name),
            hasTextContent: Boolean(item.textContent),
        };

        angular.forEach(item.customData, function (value, key) {
            parsedItem[key] = value;
        });

        return parsedItem;
    };

    var filterMarketingSlotItem = function (item) {
        var currentDate = moment();
        var currentDateBetween =
            item.startDate && item.endDate
                ? currentDate.isBetween(moment(item.startDate), moment(item.endDate))
                : true;

        return item.active && currentDateBetween;
    };

    var sortMarketingSlotItem = function (item) {
        return item.priority;
    };

    var parseMarketingSlots = function (marketingSlots) {
        return marketingSlots
            .filter(function (slot) {
                return slot.active;
            })
            .map(function (slot) {
                var parsedSlot = {
                    name: slot.name,
                    title: slot.title,
                    position: slot.name.replace('slot_', '').toUpperCase(),
                    items: slot.items
                        .filter(filterMarketingSlotItem)
                        .map(parseMarketingSlotItem)
                        .sort(sortMarketingSlotItem),
                };

                angular.forEach(slot.customData, function (value, key) {
                    parsedSlot[key] = value;
                });

                return parsedSlot;
            });
    };

    return {
        load: function () {
            if (settings.banners.enabled) {
                this.marketingSlots = parseMarketingSlots(config.marketingSlots);
            }
        },
        getMarketingSlotByPosition: function (position) {
            return this.marketingSlots.find(function (marketingSlot) {
                return marketingSlot.position === position;
            });
        },
        getModalBanner: function () {
            if (settings.banners.enabled) {
                return this.getMarketingSlotByPosition('X');
            }

            return null;
        },
    };
}]);

SEVEN.service(
    'SEVENCmsService',
    ['$http', '$interpolate', 'SEVENConfig', 'SEVENSettings', 'SEVENLocale', function ($http, $interpolate, SEVENConfig, SEVENSettings, SEVENLocale) {
        var config = SEVENConfig,
            settings = SEVENSettings,
            locale = SEVENLocale,
            headers = {
                'X-Nsft-SCD-Company-Id': config.client.uuid,
                'X-Nsft-SCD-Locale': locale.activeLanguage,
            };

        return {
            getArticle: function (articleName) {
                return $http.get(settings.api.gravity.url + '/web/articles/' + articleName, {
                    headers: headers,
                });
            },
            getArticleFallback: function (article) {
                var assetsUrl =
                    config.client.assets +
                    'html/' +
                    locale.activeLanguage +
                    '/' +
                    article +
                    '.html';
                return $http.get(assetsUrl, {
                    withCredentials: false,
                });
            },
            getArticlesByCategory: function (categoryName) {
                return $http.get(
                    settings.api.gravity.url + '/web/articles/category/' + categoryName,
                    {
                        headers: headers,
                    }
                );
            },
            getList: function (listName, currentPage, pageSize) {
                var url = $interpolate('{{base}}/web/lists/{{listName}}')({
                    base: settings.api.gravity.url,
                    listName: listName,
                });
                return $http.get(url, {
                    params: {
                        currentPage: currentPage || 1,
                        pageSize: pageSize || 10,
                    },
                });
            },
            getResources: function (containerName, currentPage, pageSize) {
                var url = $interpolate('{{base}}/web/resources/{{container}}?extend=container')({
                    base: settings.api.gravity.url,
                    container: containerName,
                });

                return $http.get(url, {
                    params: {
                        currentPage: currentPage || 1,
                        pageSize: pageSize || 10,
                    },
                });
            },
            getPageByName: function (pageName) {
                return $http.get(settings.api.gravity.url + '/web/pages/' + pageName);
            },
        };
    }]
);

SEVEN.service(
    'SEVENCommonService',
    ['$http', '$q', '$cacheFactory', 'SEVENConfig', 'SEVENRoutes', 'SEVENSettings', function ($http, $q, $cacheFactory, SEVENConfig, SEVENRoutes, SEVENSettings) {
        var config = SEVENConfig,
            routes = SEVENRoutes,
            settings = SEVENSettings;

        var urls = {
            countries: config.apiBase + routes.web.resource.countries.url,
            genders: config.apiBase + routes.common.resource.genders.url,
            languages: config.apiBase + routes.common.resource.locales.url,
            timezones: config.apiBase + routes.web.resource.timezones.url,
            dateFormats: config.apiBase + routes.common.resource.dateformats.url,
            currencies: config.apiBase + routes.common.resource.currencies.url,
            titles: config.apiBase + routes.common.resource.titles.url,
            oddsOptions: config.apiBase + routes.common.resource.oddsOptions.url,
        };

        var gravityUrls = {
            countries: settings.api.gravity.url + '/web/countries',
            timezones: settings.api.gravity.url + '/web/timezones',
        };

        var commonCache = $cacheFactory('SEVENCommonCache', {
            capacity: Object.keys(urls).length,
        });

        return {
            countries: function () {
                return $http.get(gravityUrls.countries, {
                    cache: commonCache,
                });
            },
            genders: function () {
                return $http.get(urls.genders, {
                    cache: commonCache,
                });
            },
            languages: function () {
                return $http.get(urls.languages, {
                    cache: commonCache,
                });
            },
            timezones: function () {
                return $http.get(gravityUrls.timezones, {
                    cache: commonCache,
                });
            },
            dateFormats: function () {
                return $http.get(urls.dateFormats, {
                    cache: commonCache,
                });
            },
            currencies: function () {
                return $http.get(urls.currencies, {
                    cache: commonCache,
                });
            },
            titles: function () {
                return $http.get(urls.titles, {
                    cache: commonCache,
                });
            },
            oddsOptions: function () {
                return $http.get(urls.oddsOptions, {
                    cache: commonCache,
                });
            },
            // Load multiple resources
            load: function (values) {
                if (values) {
                    var promises = [];
                    values.forEach(function (value) {
                        // Replace with gravity url
                        var url = urls[value];

                        if (url.indexOf('countries') > -1) {
                            url = gravityUrls.countries;
                        }

                        if (url.indexOf('timezones') > -1) {
                            url = gravityUrls.timezones;
                        }

                        promises.push(
                            $http.get(url, {
                                cache: commonCache,
                            })
                        );
                    });
                    return $q.all(promises);
                }
            },
            // Load by passed URLs
            loadByUrls: function (urls) {
                if (urls) {
                    var promises = [];
                    urls.forEach(function (source) {
                        var url = source.url || source; // API handling :/
                        url = config.apiBase + url;

                        // Replace with gravity url
                        if (url.indexOf('countries') > -1) {
                            url = gravityUrls.countries;
                        }

                        if (url.indexOf('timezones') > -1) {
                            url = gravityUrls.timezones;
                        }

                        promises.push(
                            $http.get(url, {
                                cache: commonCache,
                            })
                        );
                    });
                    return $q.all(promises);
                }
            },
        };
    }]
);

SEVEN.service('SEVENFacebookPixel', ['$log', '$rootScope', '$window', 'SEVENModules', 'SEVENUser', function ($log, $rootScope, $window, SEVENModules, SEVENUser) {
    var plugins = SEVENModules.plugins;
    var pluginEnabled = plugins && plugins.facebookPixel && plugins.facebookPixel.enabled;
    var user = SEVENUser;

    var setupListeners = function () {
        $rootScope.$on('TicketPayinCompleted', function (event, ticket) {
            conversion('Purchase', {
                value: ticket.payin,
                currency: user.currency,
                em: user.email,
            });
        });
        $rootScope.$on('SEVEN.CasinoGameOpened', function () {
            conversion('ViewContent', {
                value: 0,
                currency: user.currency,
            });
        });
    };

    var conversion = function (name, data) {
        if (!pluginEnabled) return;

        var executable = plugins.facebookPixel.executable;

        if (!$window[executable]) return;

        $window[executable]('track', name, data);
        $log.debug('[SEVEN] Facebook Pixel Conversion [' + name + ']', data);
    };

    return {
        conversion: conversion,
        init: function () {
            if (!pluginEnabled) return;

            setupListeners();
        },
    };
}]);

SEVEN.service('SEVENActiveGames', ['SEVENConfig', 'SEVENModules', function (SEVENConfig, SEVENModules) {
    var config = SEVENConfig;

    var gameParser = function (game) {
        var shortName;
        switch (game) {
            case 'GreyhoundRaces':
            case 'VirtualGreyhoundRaces':
                shortName = 'GreyhoundRaces';
                break;
            case 'VirtualHorseRaces':
            case 'HorseRaces':
                shortName = 'HorseRaces';
                break;
            case 'VirtualMotorcycleSpeedway':
            case 'MotorcycleSpeedway':
                shortName = 'MotorcycleSpeedway';
                break;
            case 'LiveBetting':
                shortName = 'Live';
                break;
            default:
                shortName = game;
                break;
        }
        return shortName;
    };

    var SevenGames = function () {
        this.games = [];
        this.gamesNormal = [];
        this.gamesVirtual = [];
        this.activeGame = null;
    };

    SevenGames.prototype.init = function () {
        angular.forEach(
            config.productsV2,
            function (product) {
                var routes = Object.values(SEVENModules.routes);
                // We must perform route matching since productsV2 contain products from other channels as well
                var productRoutes = routes.filter(function (route) {
                    if (route.disabled || !route.data || !route.data.productDisplayId) return false;

                    var productDisplayId = route.data.productDisplayId;

                    return productDisplayId === product.name;
                });

                if (productRoutes.length) this.games.push(product);
            },
            this
        );
    };

    SevenGames.prototype.setActiveGame = function (id) {
        this.activeGame = id;
    };

    SevenGames.prototype.parseActiveGame = function (game) {
        return gameParser(game);
    };

    SevenGames.prototype.getActiveGame = function () {
        return this.activeGame;
    };

    /**
     * @function
     * @name getGamesListForModule
     * @description  Get active games/products with optional filtering based on supplied module
     * @param {'ticketHistory' |'limits'} [moduleName] Module name for filtering games/products
     * @param {boolean} [applyExclusions] Exclude games based on module exclude list
     * @returns {Object}
     */
    SevenGames.prototype.getGamesListForModule = function (moduleName, applyExclusions) {
        if (!applyExclusions) return this.games;

        var list = {};
        var moduleExclusions = config.productExclusions[moduleName] || [];
        var exclusions = config.productExclusions.global.concat(moduleExclusions);

        this.games.forEach(function (game) {
            if (exclusions.includes(game.name)) return;

            list[game.name] = game;
        });

        return list;
    };

    return new SevenGames();
}]);

/**
 * @class SEVENGatewayService
 */
SEVEN.service(
    'SEVENGatewayService',
    ['$log', '$location', 'SEVENSettings', 'SEVENWidgetService', 'SEVENIntegrator', 'SEVENUser', 'SEVENConfig', 'SEVENLocale', 'SEVENCurrency', 'SEVENToken', 'SEVENModules', function (
        $log,
        $location,
        SEVENSettings,
        SEVENWidgetService,
        SEVENIntegrator,
        SEVENUser,
        SEVENConfig,
        SEVENLocale,
        SEVENCurrency,
        SEVENToken,
        SEVENModules
    ) {
        var settings = SEVENSettings,
            widgetService = SEVENWidgetService,
            integrator = SEVENIntegrator,
            user = SEVENUser,
            config = SEVENConfig,
            modules = SEVENModules,
            locale = SEVENLocale,
            currency = SEVENCurrency,
            tokenService = SEVENToken,
            GatewayConstructor = window.gravity.gateway.master;

        var getAnalyticsSettings = function () {
            var analytics = {};

            if (settings.company.analytics && settings.company.analytics[settings.server]) {
                analytics.googleAnalytics = settings.company.analytics[settings.server];
            }

            if (modules.plugins.googleTagManager && modules.plugins.googleTagManager.enabled) {
                analytics.googleTagManager = modules.plugins.googleTagManager.data.id;
            }

            return analytics;
        };

        return {
            /**
             * Init gateway communication
             *
             * @memberof SEVENGatewayService
             * @function init
             */
            init: function () {
                if (this.gateway) return;

                var widgets = widgetService.getAll();
                var widgetOrigins = [];

                angular.forEach(widgets, function (widget) {
                    angular.forEach(widget.source, function (source) {
                        if (source.config && source.config.useGateway && source.url) {
                            var url;

                            if (typeof source.url === 'string') {
                                url = new URL(source.url);
                                widgetOrigins.push(url.origin);
                            } else if (angular.isObject(source.url)) {
                                if (source.url.desktop) {
                                    url = new URL(source.url.desktop);
                                    widgetOrigins.push(url.origin);
                                }

                                if (source.url.mobile) {
                                    url = new URL(source.url.mobile);
                                    widgetOrigins.push(url.origin);
                                }
                            }
                        }
                    });
                });

                var allowedOrigins =
                    settings.gateway[settings.server].allowedOrigins.concat(widgetOrigins);

                this.gateway = new GatewayConstructor({
                    // settings.debug is either 1 or 0 but gatway expects === true/false
                    debug: !!settings.debug,
                    allowedOrigins: allowedOrigins,
                    slaves: {},
                });
            },
            addSlave: function (config) {
                var mergedConfig = angular.copy(config);

                angular.merge(mergedConfig.data, {
                    integration: settings.mode === 'integration',
                });

                this.gateway.addSlave(mergedConfig);
            },
            removeSlave: function (slaveId) {
                if (this.gateway) {
                    this.gateway.removeSlave(slaveId);
                }
            },
            listen: function (event, callback) {
                return this.gateway.on(
                    event,
                    callback ||
                        function (message) {
                            $log.debug(event + ' => ', message);
                        }
                );
            },
            emitMessage: function (slave, message) {
                if (angular.element(slave.frameId)) {
                    this.gateway.emit(slave.frameId, message);
                }
            },
            emitToAllSlaves: function (message) {
                var service = this;
                angular.forEach(this.gateway.slaves, function (slave) {
                    service.emitMessage(slave, message);
                });
            },
            emitAuthorizationChanged: function (authData, postLogin) {
                this.emitToAllSlaves({
                    action: 'User.AuthorizationChanged',
                    data: {
                        // TODO: Key 'auth' should be replaced with 'user', as documented in Gateway API. Used in VFL2 product.
                        auth: authData,
                        postLogin: postLogin,
                    },
                });
            },
            emitBalanceChanged: function (balance) {
                this.emitToAllSlaves({
                    action: 'User.BalanceChanged',
                    data: {
                        balance: balance,
                    },
                });
            },
            // TODO: Should be deprecated, it is not a standardised event. Used in VFL2 product.
            emitProfileChanged: function (profile) {
                this.emitToAllSlaves({
                    action: 'User.ProfileChanged',
                    data: {
                        profile: profile,
                    },
                });
            },
            emitRouteChanged: function (route, previousRoute, path, params) {
                this.emitToAllSlaves({
                    action: 'Router.RouteChanged',
                    data: {
                        path: path,
                        params: params,
                        route: route, // deprecated
                        previousRoute: previousRoute, // deprecated
                    },
                });
            },
            emitOutsideClick: function () {
                this.emitToAllSlaves({
                    action: 'UI.Hide',
                    data: {
                        name: ['All'],
                    },
                });

                // Legacy, remove after products implementation
                this.emitToAllSlaves({
                    action: 'Widget.ClickedOutside',
                    data: {},
                });
            },

            /**
             *
             * @param {Object} [data]
             * @param {Object} [data.show] - Indicates whether the sidebar is shown.
             */
            emitBetslipToggled: function (data) {
                var action = data && data.isVisible ? 'Show' : 'Hide';

                this.emitToAllSlaves({
                    action: 'UI.' + action,
                    data: {
                        name: ['Betslip'],
                    },
                });
            },
            emitUIChange: function (action, data) {
                this.emitToAllSlaves({
                    action: 'UI.' + action,
                    data: {
                        name: data,
                    },
                });
            },
            emitNotificationClicked: function (notification) {
                this.emitToAllSlaves({
                    action: 'Notification.Clicked',
                    data: {
                        actionName: notification.actionName,
                        payload: notification.payload,
                    },
                });
            },
            emitNotificationExpired: function (notification) {
                this.emitToAllSlaves({
                    action: 'Notification.Expired',
                    data: {
                        action: notification.actionName,
                        payload: notification.payload,
                    },
                });
            },
            /**
             *
             * @param {Object} payload
             * @param {Boolean} payload.showBalance
             */
            emitUserSettingsChanged: function (payload) {
                this.emitToAllSlaves({
                    action: 'User.SettingsChanged',
                    data: payload,
                });
            },

            /**
             *
             * @param {Object} payload
             * @param {Boolean} payload.oddType
             */
            emitSettingsChanged: function (payload) {
                this.emitToAllSlaves({
                    action: 'Widget.SettingsChanged',
                    data: payload,
                });
            },

            /**
             * Get common load data that is sent to slave frame via Slave.Load
             *
             * @param {Object} additionalData
             * @returns {Object}
             */
            getLoadData: function (additionalData) {
                var activeLocaleCodes = locale.getActiveLanguageCodes(user.profile);

                var authData = {
                    tpToken: integrator.getThirdPartyToken(),
                    token: tokenService.getToken(),
                    id: user.id,
                };

                var companyAnalytics = getAnalyticsSettings();

                var data = {
                    auth: authData, // deprecate, moved to user.auth
                    channel: 'Web',
                    company: angular.extend(
                        // deprecate, moved to tenant
                        {},
                        {
                            uuid: settings.company.id,
                        },
                        settings.company,
                        {
                            analytics: companyAnalytics,
                        }
                    ),
                    currency: {
                        symbol: currency.symbol,
                        virtualSymbol: currency.virtualSymbol,
                    },
                    isMobile: window.isMobile.any, // deprecate, will be removed
                    language: locale.activeLanguage,
                    locale: activeLocaleCodes,
                    mode: $location.search().smAppMode, // deprecate, will be removed
                    route: null,
                    tenant: {
                        name: settings.company.name,
                        uuid: settings.company.id,
                        country: config.client.countryCode,
                    },
                    timezone:
                        (user.profile && user.profile.timezone) ||
                        config.client.timezone ||
                        'Europe/Sarajevo',
                    user: {
                        auth: {
                            tpToken: authData.tpToken,
                            token: authData.token,
                        },
                        balance: user.balanceTotal,
                        currency: user.currency, // deprecated, use currency.symbol
                        country: user.profile && user.profile.country,
                        email: user.email,
                        firstName: user.firstName,
                        id: user.id, // deprected, use user.uuid
                        uuid: user.id,
                        lastName: user.lastName,
                        logged: user.logged,
                        name: user.name,
                        nickname: user.nickname, // deprecated
                        profile: user.nicprofilekname, // deprecated
                        settings: {
                            showBalance: user.settings.showBalance, // deprecated, use settings.showBalance
                        },
                        token: tokenService.getToken(), // deprecated, use `user.auth.token`
                    },
                    settings: {
                        oddType: user.settings.oddType,
                        showBalance: user.settings.showBalance,
                        analytics: companyAnalytics,
                    },
                    referrerUrl: window.encodeURIComponent($location.absUrl()),
                };

                return angular.extend(data, additionalData);
            },
        };
    }]
);

SEVEN.service('SEVENGoogleAnalyticsService', ['$window', '$rootScope', 'SEVENSettings', function ($window, $rootScope, SEVENSettings) {
    var setupListeners = function () {
        $rootScope.$on('DepositPaymentIntent', function (event, provider) {
            var eventName = 'account_payment_intent';

            var eventData = {
                payment_type: provider,
            };

            eventTrack(eventName, eventData);
        });

        $rootScope.$on('WithdrawalPaymentIntent', function (event, provider) {
            var eventName = 'account_payout_intent';

            var eventData = {
                payment_type: provider,
            };

            eventTrack(eventName, eventData);
        });

        $rootScope.$on('DepositPaymentCompleted', function (event, data) {
            var eventName = 'account_payment';

            var eventData = {
                payment_type: data.provider,
                payment_amount: data.amount,
            };

            eventTrack(eventName, eventData);
        });

        $rootScope.$on('WithdrawalPaymentCompleted', function (event, data) {
            var eventName = 'account_payout';

            var eventData = {
                payment_type: data.provider,
                payment_amount: data.amount,
            };

            eventTrack(eventName, eventData);
        });

        $rootScope.$on('SEVEN.ArticleBannerClicked', function (event, data) {
            var eventName = 'click_article_banner';

            var eventData = {
                banner_title: data.bannerTitle,
                banner_redirect_url: data.bannerRedirectUrl,
                article_name: data.articleName,
                item_position: data.itemPosition,
            };

            eventTrack(eventName, eventData);
        });

        $rootScope.$on('RegistrationFormSubmitted', function (event, data) {
            var eventName = 'registration_form_submitted';

            var eventData = {
                email: data.email,
                phone_number: data.mobileNumber,
                _tag_mode: 'MANUAL',
            };

            eventTrack(eventName, eventData);
        });
    };

    var eventTrack = function (event, eventParameters) {
        if (!_isEnabled()) return;

        $window.gtag('event', event, eventParameters);
    };

    var _isEnabled = function () {
        return $window.gtag && !!SEVENSettings.analytics && !SEVENSettings.debug;
    };

    return {
        init: function () {
            if (_isEnabled()) {
                setupListeners();
            }
        },

        eventTrack: eventTrack,
        _isEnabled: _isEnabled,
    };
}]);

SEVEN.service('SEVENGoogleTagManager', ['$rootScope', '$window', 'SEVENModules', function ($rootScope, $window, SEVENModules) {
    var plugins = SEVENModules.plugins;
    var pluginEnabled = plugins && plugins.googleTagManager && plugins.googleTagManager.enabled;
    var productIds = {
        casino: 'Casino',
        liveCasino: 'Live_Casino',
    };

    var setupListeners = function () {
        $rootScope.$on('TicketPayinCompleted', function (event, ticket) {
            var eventName = ticket.product + '_ticket';
            var eventData = {
                ticket_stack: ticket.payin,
            };

            trackEvent(eventName, eventData);
        });

        $rootScope.$on('SEVEN.CasinoGameOpened', function (event, game) {
            var eventName = game.isLive ? productIds.liveCasino : productIds.casino;

            var eventData = {
                provider_name: game.providerName,
                game_name: game.title,
            };

            trackEvent(eventName, eventData);
        });

        $rootScope.$on('DepositPaymentIntent', function (event, provider) {
            var eventName = 'account_payment_intent';

            var eventData = {
                payment_type: provider,
            };

            trackEvent(eventName, eventData);
        });

        $rootScope.$on('WithdrawalPaymentIntent', function (event, provider) {
            var eventName = 'account_payout_intent';

            var eventData = {
                payment_type: provider,
            };

            trackEvent(eventName, eventData);
        });

        $rootScope.$on('DepositPaymentCompleted', function (event, data) {
            var eventName = 'account_payment';

            var eventData = {
                payment_type: data.provider,
                payment_value: data.amount,
            };

            trackEvent(eventName, eventData);
        });

        $rootScope.$on('WithdrawalPaymentCompleted', function (event, data) {
            var eventName = 'account_payout';

            var eventData = {
                payment_type: data.provider,
                payment_value: data.amount,
            };

            trackEvent(eventName, eventData);
        });

        $rootScope.$on('SEVEN.ArticleBannerClicked', function (event, data) {
            var eventName = 'click_article_banner';

            var eventData = {
                banner_title: data.bannerTitle,
                banner_redirect_url: data.bannerRedirectUrl,
                article_name: data.articleName,
                item_position: data.itemPosition,
            };

            trackEvent(eventName, eventData);
        });

        $rootScope.$on('RegistrationFormSubmitted', function (event, data) {
            var eventName = 'registration_form_submitted';

            var eventData = {
                email: data.email,
                phone_number: data.mobileNumber,
                _tag_mode: 'MANUAL',
            };

            trackEvent(eventName, eventData);
        });
    };

    var trackEvent = function (eventName, eventData) {
        if (!pluginEnabled) return;

        var dataLayer = ($window.dataLayer = $window.dataLayer || []);
        var initialPayload = {};
        if (eventName) initialPayload.event = eventName;

        var payload = angular.extend(initialPayload, eventData);

        dataLayer.push(payload);
    };

    return {
        init: function () {
            if (pluginEnabled) {
                setupListeners();
            }
        },
        trackEvent: trackEvent,
        isEnabled: function () {
            return !!pluginEnabled;
        },
    };
}]);

// Helpers service
// RULE: Store helper functions here
SEVEN.factory('SEVENHelpers', ['$timeout', '$interval', '$q', '$http', '$filter', '$location', function ($timeout, $interval, $q, $http, $filter, $location) {
    // Define helpers
    var helpers,
        isMobile = window.isMobile.any;

    // Cretate functions
    helpers = {
        // Compare array of objects by property
        // NOTE: Use for sorting array of objects
        compare: function (property) {
            // Set default order
            var order = 1;

            // Check order
            // NOTE: Start with '-' for descending order
            if (property[0] === '-') {
                order = -1;
                property = property.substr(1);
            }

            // Resolve to child
            var resolve = function (path, obj, safe) {
                return path.split('.').reduce(function (prev, curr) {
                    return !safe ? prev[curr] : prev ? prev[curr] : undefined;
                }, obj);
            };

            // Return comparer function
            return function (a, b) {
                var result, x, y;

                if (property.indexOf('.') >= 0) {
                    // Resolve to child and compare
                    x = resolve(property, a, true);
                    y = resolve(property, b, true);
                } else {
                    // Normal property compare
                    x = a[property];
                    y = b[property];
                }

                // Compare
                result = x < y ? -1 : x > y ? 1 : 0;

                // Return with sorting
                return result * order;
            };
        },

        // Check flash plugin
        hasFlashPlugin: function (forceOnWindows) {
            var plugin,
                exists = false,
                mimeType;

            try {
                plugin = new window.ActiveXObject('ShockwaveFlash.ShockwaveFlash');
                if (plugin) {
                    exists = true;
                }
            } catch (e) {
                if (navigator && navigator.mimeTypes) {
                    mimeType = navigator.mimeTypes['application/x-shockwave-flash'];
                    if (!angular.isUndefined(mimeType) && mimeType.enabledPlugin) {
                        exists = true;
                    } else if (forceOnWindows === true) {
                        exists = navigator.platform.indexOf('Win') > -1;
                    }
                }
            }

            return exists;
        },

        // Compare numbers
        // NOTE: Use for sorting array of numbers
        compareNumbers: function (a, b) {
            return a - b;
        },

        // Current time
        currentTime: function () {
            return new Date().getTime();
        },

        // Current year
        currentYear: function () {
            return new Date().getFullYear();
        },

        // Get combinations
        getCombinations: function (n, k) {
            return Math.factorial(n) / (Math.factorial(k) * Math.factorial(n - k));
        },

        // Get responsive tag
        getResponsiveTag: function () {
            var style, tag;

            style = window.getComputedStyle(document.body, ':after');
            if (style) {
                tag = style.getPropertyValue('content');
                tag = tag.replace(/'/g, '').replace(/"/g, '');
                return tag;
            }

            return 'max';
        },

        getWeekDays: function (options) {
            function Day(settings) {
                this.id = settings.id;
                this.name = moment(settings.dateEnd).format('ddd');
                this.fullName = moment(settings.dateEnd).format('ddd');
                this.start = settings.dateStart.toDate();
                this.end = settings.dateEnd.toDate();
                this.startTime = settings.dateStart.valueOf();
                this.endTime = settings.dateEnd.valueOf();
            }

            var i;
            var timeTZ = options.timezone ? moment.tz(options.timezone) : moment();
            var days = {};
            if (!options.reverse) {
                for (i = 0; i < 7; i++) {
                    days[i] = new Day({
                        id: i,
                        dateStart:
                            i === 0 ? timeTZ.clone() : timeTZ.clone().startOf('day').add(i, 'd'),
                        dateEnd: timeTZ.clone().endOf('day').add(i, 'd'),
                    });
                }
            } else {
                for (i = 0; i < 7; i++) {
                    days[i] = new Day({
                        id: i,
                        dateStart: timeTZ.clone().startOf('day').subtract(i, 'd'),
                        dateEnd:
                            i === 0 ? timeTZ.clone() : timeTZ.clone().endOf('day').subtract(i, 'd'),
                    });
                }
            }

            return days;
        },

        // Get hours
        getHours: function () {
            var hours = {};

            for (var i = 1; i <= 4; i++) {
                var hour = {
                    id: i,
                    active: false,
                };

                if (i === 1) {
                    hour.value = 3;
                } else {
                    hour.value = hours[i - 1].value * 2;
                }

                hours[i] = hour;
            }

            return hours;
        },

        // Get month days
        getMonthDays: function (month, year) {
            year = year || new Date().getFullYear();
            return new Date(year, month, 0).getDate();
        },

        // Get month difference
        getMonthDiff: function (dateFrom, dateTo) {
            return moment(dateTo)
                .startOf('day')
                .diff(moment(dateFrom).add(1, 'days').startOf('day'), 'months');
        },

        // Create hours list
        getHoursList: function (values, messages) {
            if (values) {
                var list = [];
                var minutes = ' ' + messages.minutes.toLowerCase();
                var hours = ' ' + messages.hours.toLowerCase();

                values.forEach(function (item) {
                    list.push({
                        name: item < 60 ? item + minutes : item / 60 + hours,
                        value: item,
                    });
                });

                return list;
            }
        },

        // Get four digits year from 3 digits (from UMCN)
        getBirthYear: function (year) {
            var currentYear = moment().year();
            var birthYear = currentYear.toString().charAt(0) + year;

            return birthYear >= currentYear ? birthYear - 1000 : birthYear;
        },

        // Extract birth date and gender from UMCN (JMBG)
        extractDataFromUMCN: function (taxIdentifier) {
            var date = moment({
                year: helpers.getBirthYear(taxIdentifier.substring(4, 7)),
                month: parseInt(taxIdentifier.substring(2, 4)) - 1,
                day: taxIdentifier.substring(0, 2),
            }).format('YYYY-MM-DD');

            var genderValue = parseInt(taxIdentifier.substring(9, 12));
            var gender = genderValue >= 0 && genderValue < 500 ? 'M' : 'F';

            return {
                birthDate: date,
                gender,
            };
        },

        // Get bool list values
        getBoolList: function (messages) {
            return [
                {
                    value: 1,
                    name: messages.yes,
                },
                {
                    value: 0,
                    name: messages.no,
                },
            ];
        },

        // Get querystring date format
        getQueryDate: function (date, days, tzOffset) {
            var dateFormat = 'YYYY-MM-DD HH:mm:ss';
            var time = moment(date.getTime())
                .startOf('day')
                .add(days - 1, 'day')
                .toDate();
            var offset = parseInt(tzOffset, 10);

            // Set day utc start time
            time.setHours(24 - offset);
            time.setMinutes(0);
            time.setSeconds(0);

            return $filter('moment')(time, dateFormat, false);
        },

        // Get UNIX timestamp
        getUnixTimestamp: function (date, removeTime, addDays) {
            var value = new Date(date.getTime());
            if (removeTime)
                value = new Date(value.getFullYear(), value.getMonth(), value.getDate());
            if (addDays > 0) value.setTime(value.getTime() + addDays * 86400000);
            return Math.floor(value.getTime() / 1000) - value.getTimezoneOffset() * 60;
        },

        // Check native device
        getNativeDevice: function () {
            if (navigator) {
                var userAgent = navigator.userAgent || navigator.vendor;
                if (userAgent) {
                    if (userAgent.match(/Android/i)) {
                        return 'Android';
                    } else if (userAgent.match(/iPhone/i)) {
                        return 'iPhone';
                    }
                }
            }

            return false;
        },

        // Get rule value
        getTicketRuleValue: function (list, name, type, nameProperty, typeProperty) {
            nameProperty = nameProperty || 'name';
            typeProperty = typeProperty || 'ticketType';

            if (list) {
                var len = list.length,
                    i;
                for (i = 0; i < len; i++) {
                    if (
                        list[i][nameProperty] === name &&
                        list[i][typeProperty].toString() === type
                    ) {
                        return parseFloat(list[i].conditions[0].value, 10);
                    }
                }
            }
        },

        // Check mobile or tablet view
        isMobileOrTablet: function () {
            var tag = this.getResponsiveTag().toUpperCase();
            return ['MIN', 'SMALL'].indexOf(tag) > -1;
        },

        // Check empty object
        isEmpty: function (object) {
            var property;
            for (property in object) {
                if (object.hasOwnProperty(property)) {
                    return false;
                }
            }
            return true;
        },

        // Check if element is in array
        inArray: function (element, array) {
            return angular.isUndefined(array) ? false : array.indexOf(element) > -1;
        },

        // Check touch device
        isTouchDevice: function () {
            return 'ontouchstart' in window;
        },

        isValidUrl: function (url) {
            if (!url) return;

            try {
                return Boolean(new URL(url));
            } catch (e) {
                return false;
            }
        },

        isIcon: function (icon) {
            if (!icon) return;

            return icon.startsWith('n-i-');
        },

        // E-mail pattern
        emailPattern: function () {
            return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        },

        // Phone Number pattern
        phoneNumberPattern: function () {
            return /^(?:\+|00)\d{2,14}$/i;
        },

        // Find item in array by property
        find: function (array, property, value) {
            if (array && array.length > 0) {
                for (var i = 0; i < array.length; i++) {
                    if (array[i][property] && value) {
                        if (
                            array[i][property].toString().toUpperCase() ===
                            value.toString().toUpperCase()
                        ) {
                            return array[i];
                        }
                    }
                }
            }
        },

        // Find last existing value in array
        findLast: function (array, property) {
            var value;
            array.forEach(function (i) {
                value = i[property] ? i[property] : value;
            });
            return value;
        },

        // Remove file extension from string
        stripFileExtension: function (image) {
            if (!image) return;

            return image.replace(/\.[^.]*$/, '');
        },

        formatThumbImage: function (item) {
            var thumb = {
                orgSrc: isMobile && item.thumbMobile ? item.thumbMobile : item.thumb,
                src: null,
            };

            if (thumb.orgSrc) {
                thumb.src = helpers.stripFileExtension(thumb.orgSrc);
            }

            return thumb;
        },

        // Parse date
        parseDate: function (value) {
            if (typeof value === 'number') {
                return new Date(value);
            }

            // String parsing
            value = value.replace(/-/g, '/');
            return new Date(value);
        },

        // Parse max integer
        parseMaxInt: function (value, max) {
            max = max || 1000;

            if (value) {
                value = parseInt(value);
                return value > 0 ? value : max;
            }

            return max;
        },

        // Generate random number
        randomNum: function (min, max) {
            // Set default values
            min = angular.isUndefined(min) ? 1 : min;
            max = angular.isUndefined(max) ? 10 : max;

            return Math.random() * (max - min) + min;
        },

        // Generate random integer
        randomInt: function (min, max) {
            // Set default values
            min = angular.isUndefined(min) ? 1 : min;
            max = angular.isUndefined(max) ? 10 : max;

            return Math.floor(Math.random() * (max - min + 1)) + min;
        },

        // Generate array of random integers
        randomInts: function (min, max, length, sort) {
            // Set default values
            min = angular.isUndefined(min) ? 1 : min;
            max = angular.isUndefined(max) ? 10 : max;
            length = angular.isUndefined(length) ? 10 : length;
            sort = angular.isUndefined(sort) ? true : sort;

            // Declare empty array
            var num,
                nums = [];

            // Check length
            if (length > 0) {
                // Loop and create random numbers
                while (nums.length < length) {
                    num = helpers.randomInt(min, max);
                    if (nums.indexOf(num) < 0) {
                        nums.push(num);
                    }
                }

                // Check sorting
                if (nums.length > 1) {
                    if (sort === true || sort === 1) {
                        // Sort ascending
                        nums.sort(function (x, y) {
                            return x - y;
                        });
                    } else if (sort === 0) {
                        // Sort descending
                        nums.sort(function (x, y) {
                            return y - x;
                        });
                    }
                }
            }

            // Return array
            return nums;
        },

        // Toggle fullscreen
        toggleFullscreen: function (element) {
            if (!element) return;

            // Check fullscreen element
            if (
                !document.fullscreenElement &&
                !document.mozFullScreenElement &&
                !document.webkitFullscreenElement &&
                !document.msFullscreenElement
            ) {
                if (element.requestFullscreen) {
                    element.requestFullscreen();
                } else if (element.msRequestFullscreen) {
                    element.msRequestFullscreen();
                } else if (element.mozRequestFullScreen) {
                    element.mozRequestFullScreen();
                } else if (element.webkitRequestFullscreen) {
                    element.webkitRequestFullscreen();
                }
            } else {
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                } else if (document.msExitFullscreen) {
                    document.msExitFullscreen();
                } else if (document.mozCancelFullScreen) {
                    document.mozCancelFullScreen();
                } else if (document.webkitExitFullscreen) {
                    document.webkitExitFullscreen();
                }
            }
        },

        shuffleArray: function (o) {
            for (
                var j, x, i = o.length;
                i;
                j = parseInt(Math.random() * i), x = o[--i], o[i] = o[j], o[j] = x
            );
            return o;
        },

        /**
         * @param {Function} func The function to throttle.
         * @param {number} [wait=0]
         *  The number of milliseconds to throttle invocations to; if omitted,
         *  `requestAnimationFrame` is used (if available).
         * @param {Object} [options={}] The options object.
         * @param {boolean} [options.leading=false]
         *  Specify invoking on the leading edge of the timeout.
         * @returns {Function} Returns the new throttled function.
         */
        throttle: function (func, wait, options) {
            var leading = false;

            if (angular.isObject(options)) {
                leading = 'leading' in options ? !!options.leading : leading;
            }

            // copied lodash.throttle since it's only available as es6 module
            var lastArgs,
                lastThis,
                maxWait = wait,
                result,
                timerId,
                lastCallTime,
                lastInvokeTime = 0;

            wait = wait || 0;

            function invokeFunc(time) {
                var args = lastArgs,
                    thisArg = lastThis;

                lastArgs = lastThis = undefined;
                lastInvokeTime = time;
                result = func.apply(thisArg, args);
                return result;
            }

            function leadingEdge(time) {
                // Reset any `maxWait` timer.
                lastInvokeTime = time;
                // Start the timer for the trailing edge.
                timerId = setTimeout(timerExpired, wait);
                // Invoke the leading edge.
                return leading ? invokeFunc(time) : result;
            }

            function remainingWait(time) {
                var timeSinceLastCall = time - lastCallTime,
                    timeSinceLastInvoke = time - lastInvokeTime,
                    result = wait - timeSinceLastCall;

                return Math.min(result, maxWait - timeSinceLastInvoke);
            }

            function shouldInvoke(time) {
                var timeSinceLastCall = time - lastCallTime,
                    timeSinceLastInvoke = time - lastInvokeTime;

                // Either this is the first call, activity has stopped and we're at the
                // trailing edge, the system time has gone backwards and we're treating
                // it as the trailing edge, or we've hit the `maxWait` limit.
                return (
                    lastCallTime === undefined ||
                    timeSinceLastCall >= wait ||
                    timeSinceLastCall < 0 ||
                    timeSinceLastInvoke >= maxWait
                );
            }

            function timerExpired() {
                var time = Date.now();
                if (shouldInvoke(time)) {
                    return trailingEdge(time);
                }
                // Restart the timer.
                timerId = setTimeout(timerExpired, remainingWait(time));
            }

            function trailingEdge(time) {
                timerId = undefined;

                // Only invoke if we have `lastArgs` which means `func` has been
                // debounced at least once.
                if (lastArgs) {
                    return invokeFunc(time);
                }
                lastArgs = lastThis = undefined;
                return result;
            }

            function throttled() {
                var time = Date.now(),
                    isInvoking = shouldInvoke(time);

                lastArgs = arguments;
                lastThis = this;
                lastCallTime = time;

                if (isInvoking) {
                    if (timerId === undefined) {
                        return leadingEdge(lastCallTime);
                    }
                    // Handle invocations in a tight loop.
                    timerId = setTimeout(timerExpired, wait);
                    return invokeFunc(lastCallTime);
                }

                if (timerId === undefined) {
                    timerId = setTimeout(timerExpired, wait);
                }

                return result;
            }

            return throttled;
        },
        /**
         * @name cleanSpaces
         * @description removes whitespace from string
         * @param {String} value
         * @return {String|null}
         */
        cleanSpaces: function (value) {
            return value ? value.replace(/\s+/g, '') : null;
        },

        getUtmParametersFromQueryParams: function () {
            const supportedUtmParams = [
                'utm_source',
                'utm_campaign',
                'utm_medium',
                'utm_content',
                'utm_term',
            ];

            const filteredData = Object.fromEntries(
                Object.entries($location.search()).filter(([key]) =>
                    supportedUtmParams.includes(key)
                )
            );

            const utmParameters = Object.keys(filteredData)
                .sort()
                .map((key) => `${key}=${filteredData[key]}`)
                .join('&');

            return utmParameters;
        },
        getResponseErrorMessage: function (response, useResponseMessageFirst) {
            if (response.status === -1) {
                return $filter('translate')('general.status_minus_one_error');
            } else {
                return (
                    (useResponseMessageFirst && response && response.message) ||
                    (response.data && response.data.message) ||
                    (typeof response.data === 'string' && response.data) ||
                    $filter('translate')('general.generic_error')
                );
            }
        },
    };

    // Return helpers
    return helpers;
}]);

SEVEN.service('SEVENImageExistenceChecker', ['$q', function ($q) {
    var checkImage = function (url, resolve, reject, onError) {
        var image = new Image();

        image.onload = function () {
            resolve(this.src);
        };

        image.onerror = function (errorEvent) {
            onError(errorEvent, reject);
        };

        image.src = url;
    };

    var onImageError = function (errorEvent, reject) {
        reject(errorEvent);
    };

    return {
        checkImage: function (url, fallbackUrl) {
            return $q(function (resolve, reject) {
                checkImage(url, resolve, reject, function (errorEvent) {
                    if (fallbackUrl) {
                        checkImage(fallbackUrl, resolve, reject, onImageError);
                    } else {
                        onImageError(errorEvent, reject);
                    }
                });
            });
        },
    };
}]);

SEVEN.service('SEVENIncomeAccessAffiliateService', ['locker', 'SEVENConfig', '$location', function (locker, SEVENConfig, $location) {
    var config = SEVENConfig,
        incomeAccessAffiliatePlatform =
            config.appSettings.config && config.appSettings.config.incomeAccessAffiliatePlatform,
        incomeAccessData = {},
        key = 'btag',
        btag = $location.search().btag;

    const EXPIRY_DAYS = 90;

    var saveBtag = function () {
        getBtag();

        if (btag) {
            incomeAccessData = {
                value: btag,
                expiry: new Date().getTime() + EXPIRY_DAYS * 24 * 60 * 60 * 1000,
            };

            locker.put(key, incomeAccessData);
        }
    };

    var getBtag = function () {
        if (incomeAccessAffiliatePlatform) {
            const now = new Date().getTime();
            const incomeAccessQueryParam = locker.get(key);
            if (!incomeAccessQueryParam) return null;
            else if (now > incomeAccessQueryParam.expiry) {
                locker.forget(key);
                return null;
            }
            return incomeAccessQueryParam.value;
        }
    };

    return {
        getBtag: getBtag,
        init: function () {
            if (incomeAccessAffiliatePlatform) {
                saveBtag();
            }
        },
    };
}]);

/**
 * @ngdoc service
 * @kind function
 * @name SEVENIntegrator
 */
SEVEN.service(
    'SEVENIntegrator',
    ['$rootScope', '$location', '$injector', '$log', '$q', '$state', 'SEVENSettings', 'SEVENPlayerAuthService', 'SEVENProductService', 'SEVENUser', 'SEVENConfig', function (
        $rootScope,
        $location,
        $injector,
        $log,
        $q,
        $state,
        SEVENSettings,
        SEVENPlayerAuthService,
        SEVENProductService,
        SEVENUser,
        SEVENConfig
    ) {
        var user = SEVENUser,
            playerAuthService = SEVENPlayerAuthService,
            productService = SEVENProductService,
            settings = SEVENSettings,
            config = SEVENConfig,
            listenersSet = false;

        var checkData = function (onAuthChange) {
            // Get previous and current data
            var previousData = JSON.stringify(service.data),
                currentData = $location.search();

            // Set auth type
            service.data.auth = service.data.auth || currentData.auth;
            // Check B2B
            if (service.data.auth === 'b2b') {
                // Check logout action
                if (currentData.logout) {
                    service.data = currentData;
                    logout(onAuthChange);
                } else if (
                    previousData !== JSON.stringify(currentData) &&
                    currentData.id &&
                    currentData.token
                ) {
                    service.data = currentData;
                    service.setThirdPartyToken(currentData.token);
                    login(onAuthChange);
                } else if (onAuthChange && typeof onAuthChange === 'function') {
                    onAuthChange(true);
                }
            } else if (onAuthChange && typeof onAuthChange === 'function') {
                onAuthChange(true);
            }

            if (!listenersSet) waitForIFrameResizer();
        };

        var login = function (onAuthChangeCallback) {
            var data = service.data;
            var isError = false;

            playerAuthService
                .loginB2B({
                    id: data.id,
                    token: data.token,
                    authStrategy: data.authStrategy || 'token',
                    customValues: data.customValues,
                })
                .then(function (response) {
                    if (!response.token) return;

                    var disableBalanceResetSetting =
                        config.appSettings.config &&
                        config.appSettings.config.user &&
                        config.appSettings.config.user.disableBalanceResetAfterLogin &&
                        false;
                    var disableBalanceReset =
                        service.isBalanceChangedListenerActive() && disableBalanceResetSetting;
                    var resetOptions = {
                        disableBalanceReset: disableBalanceReset,
                    };

                    user.reset(resetOptions);
                    user.set(response.profile.email, response.profile, response.token);
                    user.saveLocal();
                    user.logged = true;

                    sendLoginSuccess();

                    return fillProfileAndBalance(response);
                })
                .catch(function (error) {
                    isError = true;
                    sendLoginFailed();
                    logout();

                    onAuthChangeCallback({
                        isError: true,
                        error: error,
                    });
                })
                .finally(function () {
                    if (
                        !onAuthChangeCallback ||
                        (typeof onAuthChangeCallback !== 'function' && !isError)
                    )
                        return;

                    onAuthChangeCallback(true);
                });
        };

        var fillProfileAndBalance = function (loginResponse) {
            return $q.all([getBalanceIfNeeded(), fillProfile(loginResponse)]);
        };

        var fillProfile = function (response) {
            return user.fillProfile(true).then(function () {
                user.logged = true;
                // TODO: Check if saveLocal really is necessary here?
                // TODO: Seems like a duplicate action, since it already gets done inside fillProfile function.
                user.saveLocal();
                service.setThirdPartyToken(response.tpToken || service.data.token);
                $log.debug('Login: B2B login success =>' + JSON.stringify(service.data));
            });
        };

        var getBalanceIfNeeded = function () {
            var hasActiveListeners = false;

            angular.forEach(settings.api.listeners, function (listener) {
                if (listener && !angular.isObject(listener)) {
                    hasActiveListeners = true;
                }
            });

            if (settings.api.listeners.terminal && settings.isTerminalLayout) {
                angular.forEach(settings.api.listeners.terminal, function (listener) {
                    if (listener) {
                        hasActiveListeners = true;
                    }
                });
            }

            if (
                (!hasActiveListeners ||
                    (!service.isBalanceChangedListenerActive() &&
                        settings.api.listeners.updateBalance)) &&
                service.thirdPartyToken
            ) {
                return user.getBalance();
            } else {
                return $q.resolve();
            }
        };

        var waitForIFrameResizer = function () {
            var removeListener = $rootScope.$watch(
                function () {
                    return window.SEVENIFrameResizerReady;
                },
                function (ready) {
                    if (ready) {
                        $log.debug('[SEVEN] SEVENIFrameResizerReady');
                        setListeners();
                        removeListener();
                    }
                }
            );
        };

        var logout = function (onAuthChangeCallback) {
            // Clean login data
            var clean = function () {
                user.removeLocal();
                user.reset();
                user.resetSettings();
                user.saveLocal();
            };

            // Logout
            playerAuthService
                .logout()
                .then(function (loggedOut) {
                    if (loggedOut) {
                        clean();
                        $log.debug('Login: B2B Logout success');
                        service.sendMessage({
                            type: 'logoutSuccess',
                        });
                    }
                })
                .finally(function () {
                    if (!onAuthChangeCallback || typeof onAuthChangeCallback !== 'function') return;

                    onAuthChangeCallback(true);
                });

            // Clean without wait
            clean();
        };

        var ticketRebet = function (ticket) {
            return productService.getGlobalTicket(ticket.id).then(function (response) {
                var rebet = $injector.get('SEVENTicketsRebet'),
                    product = response.data.product,
                    tickets = [response.data],
                    ticketType = productService.getTicketType(product),
                    parsedTickets = angular.isUndefined(ticketType)
                        ? tickets
                        : productService.parseTickets(product, ticketType, tickets);

                rebet.setExternalRebet(parsedTickets);
                $rootScope.$broadcast('SEVEN.TicketRebet', tickets);

                return response.data;
            });
        };

        var setListeners = function () {
            listenersSet = true;
            setInAppListeners();

            window.addEventListener(
                'message',
                function (event) {
                    // Check our signature to disregard iFrameResizer message
                    if (!event.data || !event.data.type || !event.data.content) return;

                    var content = event.data.content;

                    switch (event.data.type) {
                        case 'authorizationChanged':
                            service.data.token = content.token || null;
                            service.data.id = content.id || null;

                            var currentUrl = new URL(window.location.href);
                            var searchParams = currentUrl.searchParams;

                            // Search params need to be updated, otherwise next navigation will do a logout
                            // Check https://phabricator.nsoft.ba/T124814
                            if (service.data.token) {
                                searchParams.append('token', service.data.token);
                                searchParams.append('id', service.data.id);
                                searchParams.delete('logout');

                                $state.go(
                                    $state.current.name,
                                    { q: searchParams.toString() },
                                    { reloadOnSearch: false }
                                );
                                login();
                            } else {
                                searchParams.delete('token');
                                searchParams.delete('id');
                                searchParams.append('logout', true);

                                $state.go(
                                    $state.current.name,
                                    { q: searchParams.toString() },
                                    { reloadOnSearch: false }
                                );
                                logout();
                            }
                            break;
                        case 'balanceChanged':
                            if (service.isBalanceChangedListenerActive()) {
                                var balance = content.balance;
                                if (balance >= 0) {
                                    $rootScope.$applyAsync(
                                        user.fillBalance({
                                            balance: balance,
                                            currency: config.client.currency,
                                        })
                                    );
                                }

                                $log.debug('[SEVEN] Balance received', balance);
                            }
                            break;
                        case 'bettingDisabled':
                            $rootScope.$broadcast('SEVEN.BettingDisabledByParent', content.status);
                            break;
                        case 'ticketRebet':
                            $log.debug('[SEVEN] Ticket rebet received', content);
                            ticketRebet(content.ticket);
                            break;
                    }
                },
                false
            );

            service.sendMessage({
                type: 'listenersReady',
            });

            $log.debug('[SEVEN] Listeners ready');
        };

        var setInAppListeners = function () {
            $rootScope.$on('SEVEN.TicketResolved', function (event, data) {
                if (!data) return;

                // always notify ticket resolved
                service.sendMessage({
                    type: 'ticketResolved',
                    data: {
                        id: data.id,
                    },
                });

                if (settings.api.events.ticketPrint) {
                    var ticket = parsePrintTicket(data);
                    if (ticket.status.code !== 'OPEN') return;

                    service.sendMessage({
                        type: 'ticketPrint',
                        data: ticket,
                    });
                }
            });

            $rootScope.$on('SEVEN.AnalyticsEvent', function (event, data) {
                if (!data || !data.type) return;

                var eventType = data.type.charAt(0).toLowerCase() + data.type.slice(1);

                if (settings.api.events[eventType]) {
                    service.sendMessage({
                        type: eventType,
                        data: data.params,
                    });
                }
            });
        };

        var parsePrintTicket = function (data) {
            var timezoneOffset = user.profile.timezoneOffset;

            var item = {
                id: data.id,
                product: {
                    code: data.product,
                },
                type: {
                    id: parseInt(data.type),
                },
                time: {
                    created: moment.utc(data.ticketDateTimeUTC).utcOffset(timezoneOffset).format(),
                },
                bets: parsePrintTicketBets(data.bets, timezoneOffset),
                betsRawInfo: data.bets,
                totalOdd: data.totalOdd,
                money: {
                    payin: data.payin,
                    payinTax: data.payinTax,
                    maxPayout: data.maxPossiblePayout,
                    maxPayoutTax: data.maxPossiblePayoutTax,
                    maxWin: data.maxPossibleWin,
                },
                status: {
                    code: data.status.value.toUpperCase(),
                },
                wallet: {
                    payment: {
                        id: data.paymentId,
                    },
                    player: {
                        id: data.playerUuid,
                    },
                },
            };

            if (data.ticketBonus) {
                item.money.bonus = data.ticketBonus.amount;
                item.money.bonusPercentage = data.ticketBonus.percentage;
            }

            if (data.ticketCombinationGroups) {
                item.systems = [];
                angular.forEach(data.ticketCombinationGroups, function (source) {
                    var system = {
                        title: source.parlays + '/' + source.events,
                        combinations: source.numberOfCombinations,
                        money: {
                            payin: source.amount,
                            payinPerCombination: source.amountPerCombination,
                            maxGain: source.maxGain,
                            minGain: source.minGain,
                        },
                    };

                    item.systems.push(system);
                });
            }

            return item;
        };

        var parsePrintTicketBets = function (data, timezoneOffset) {
            var list = [];

            angular.forEach(data, function (source) {
                var item = {
                    value: source.value,
                    odd: source.oddValue,
                    money: {
                        payin: source.amount,
                        payinTax: source.tax,
                    },
                };

                if (source.ticketBetBank) {
                    item.banker = !!parseInt(source.ticketBetBank);
                }

                if (source.type) {
                    item.type = {
                        id: source.type,
                    };
                }

                if (source.eventId) {
                    item.round = {
                        display: source.eventId,
                    };
                }

                if (source.matchId) {
                    item.match = {
                        id: source.matchId,
                        display: source.matchDisplayId,
                        title: source.matchName,
                        time: moment.utc(data.matchDateTimeUTC).utcOffset(timezoneOffset).format(),
                    };
                }

                // Normalize Games and Sportsbook bets
                if (angular.isObject(source.betOutcome)) {
                    item.bet = {
                        id: source.bet.idBet,
                        uniqueId: source.bet.idMatchBet,
                        title: source.bet.betShortName.length
                            ? source.bet.betShortName
                            : source.bet.betDisplayName,
                    };

                    item.outcome = {
                        id: source.betOutcome.idBetOutcome,
                        uniqueId: source.betOutcome.idMatchBetOutcome,
                        title: source.betOutcome.betOutcomeShortName.length
                            ? source.betOutcome.betOutcomeShortName
                            : source.betOutcome.betOutcomeDisplayName,
                    };
                } else {
                    item.bet = angular.copy(source.bet);
                }

                list.push(item);
            });

            return list;
        };

        var sendRouteChange = function () {
            service.sendMessage({
                type: 'routeChanged',
                data: {
                    url: $location.url(),
                },
            });
        };

        var sendLoginSuccess = function () {
            service.sendMessage({
                type: 'loginSuccess',
            });
        };

        var sendLoginFailed = function () {
            service.sendMessage({
                type: 'loginFailed',
            });
        };

        var service = {
            data: {},
            thirdPartyToken: null,
            userAuthAndInitPromise: null,
            /**
             * @ngdoc method
             * @name setThirdPartyToken
             * @param {string|null} [thirdPartyToken]
             */
            setThirdPartyToken: function (thirdPartyToken) {
                this.thirdPartyToken = thirdPartyToken || null;
            },
            getThirdPartyToken: function () {
                return this.thirdPartyToken;
            },
            watch: function () {
                if (settings.mode === 'integration' && settings.platform.authType === 'b2b') {
                    $rootScope.$on('$locationChangeSuccess', function () {
                        sendRouteChange();
                        checkData();
                    });
                    checkData();
                }
            },
            sendMessage: function (data, delay) {
                if ('parentIFrame' in window) {
                    setTimeout(function () {
                        $log.debug('[SEVEN] Integrator:sendMessage: ', data.type);
                        window.parentIFrame.sendMessage(data);
                    }, delay || 0);
                }
            },
            waitForUserAuthAndInit: function () {
                this.userAuthAndInitPromise = $q(function (resolve) {
                    if (settings.mode === 'integration' && settings.platform.authType === 'b2b') {
                        $rootScope.$on('$locationChangeSuccess', function () {
                            sendRouteChange();
                            checkData();
                        });
                        checkData(resolve);
                    }
                });

                return this.userAuthAndInitPromise;
            },
            watchAndResolveUserAuthInit: function () {
                var self = this;

                return $q(function (resolve, reject) {
                    var unwatch = $rootScope.$watch(
                        function () {
                            return self.userAuthAndInitPromise;
                        },
                        function (promise) {
                            if (promise) {
                                unwatch();

                                return self.userAuthAndInitPromise
                                    .then(function (result) {
                                        if (result && result.isError) {
                                            return reject(result);
                                        }
                                        return resolve(true);
                                    })
                                    .catch(function (error) {
                                        return reject(error);
                                    });
                            }
                        }
                    );
                });
            },
            isBalanceChangedListenerActive: function () {
                return (
                    (settings.api.listeners.terminal.balanceChanged && settings.isTerminalLayout) ||
                    settings.api.listeners.balanceChanged
                );
            },
        };

        return service;
    }]
);

angular
    .module('SEVEN')
    .service(
        'SEVENLegalEntitiesService',
        ['$cookies', '$q', '$window', 'SEVENArticles', 'SEVENCmsService', 'SEVENConfig', 'SEVENSettings', function (
            $cookies,
            $q,
            $window,
            SEVENArticles,
            SEVENCmsService,
            SEVENConfig,
            SEVENSettings
        ) {
            var articles = SEVENArticles;
            var cmsService = SEVENCmsService;
            var config = SEVENConfig;
            var settings = SEVENSettings;
            var selectedLegalEntity =
                settings.company.legalEntities &&
                settings.company.legalEntities.find(function (entity) {
                    return entity.id === settings.company.id;
                });

            return {
                goToDefaultUnauthorizedEntity: function () {
                    if (!settings.company.legalEntities || !settings.company.legalEntities.length) {
                        return;
                    }

                    var defaultEntityForUnauthorized = settings.company.legalEntities.find(
                        function (entity) {
                            return entity.unauthorizedDefault;
                        }
                    );

                    var redirecting =
                        defaultEntityForUnauthorized &&
                        config.client.uuid !== defaultEntityForUnauthorized.id;

                    if (redirecting) {
                        var nonDefaultEntriesForUnauthorized =
                            settings.company.legalEntities.filter(function (entity) {
                                return !entity.unauthorizedDefault;
                            });
                        nonDefaultEntriesForUnauthorized.forEach(function (entity) {
                            $cookies.remove(entity.cookie);
                        });
                        $cookies.put(defaultEntityForUnauthorized.cookie, true, {
                            path: '/',
                        });
                        $window.location.href = '/';
                    }

                    return redirecting;
                },
                goToEntity: function (entityId) {
                    var loginEntity = settings.company.legalEntities.find(function (entity) {
                        return entity.id === entityId;
                    });

                    var redirecting = config.client.uuid !== loginEntity.id;

                    if (redirecting) {
                        var otherEntities = settings.company.legalEntities.filter(function (
                            entity
                        ) {
                            return entity.id !== entityId;
                        });

                        otherEntities.forEach(function (entity) {
                            $cookies.remove(entity.cookie);
                        });
                        $cookies.put(loginEntity.cookie, true, { path: '/' });
                        $window.location.reload();
                    }

                    return redirecting;
                },
                getEntitiesArticles: function () {
                    var legalEntitiesIDs =
                        (settings.company.legalEntities &&
                            settings.company.legalEntities.length &&
                            settings.company.legalEntities.map(function (entity) {
                                return entity.id;
                            })) ||
                        [];
                    var articlePromises = articles
                        .filter(function (articleName) {
                            var match = articleName.match(/(register_company_info_)(.*)/);

                            return match && match[1] && legalEntitiesIDs.includes(match[2]);
                        })
                        .map((articleName) => {
                            return cmsService.getArticle(articleName);
                        });

                    return $q.all(articlePromises).then(function (articlesResponses) {
                        return articlesResponses.map(function (articleResponse) {
                            var article = articleResponse.data.article;

                            return {
                                entityUUID: article.name.replace('register_company_info_', ''),
                                content: article.content,
                                summary: article.summary,
                            };
                        });
                    });
                },
                setSelectedLegalEntity: function (entity) {
                    selectedLegalEntity = entity;
                },
                getSelectedLegalEntity: function () {
                    return selectedLegalEntity;
                },
            };
        }]
    );

SEVEN.service(
    'SEVENLocale',
    ['$window', '$location', 'locker', 'SEVENLocales', 'SEVENSettings', 'SEVENConfig', '$timeout', function ($window, $location, locker, SEVENLocales, SEVENSettings, SEVENConfig, $timeout) {
        var locales = SEVENLocales,
            settings = SEVENSettings,
            config = SEVENConfig,
            companyLocaleCodes = {
                iso1: config.client.language && config.client.language.replace('_', '-'),
                iso2: config.client.languageISO6392,
            };
        /**
         *
         * @param {string} langValue - value to find
         * @returns
         */
        var findLanguage = function (langValue) {
            return locales.find(function (lang) {
                return lang.value === langValue;
            });
        };
        /**
         *
         * @param {string} lang - value
         */
        var rebootApp = function (lang) {
            $location.search('lang', lang);
            $timeout(function () {
                $window.location.reload();
            }, 100);
        };

        // Can be removed if formatting locales isn't needed
        var formatLocales = function () {
            var configLanguages = config.client.languages;
            var findClientLanguage = function (langValue) {
                return configLanguages.find(function (clientLang) {
                    return clientLang.value === langValue;
                });
            };
            locales.forEach(function (lang) {
                var foundLanguage = findClientLanguage(lang.value) || {};

                lang.active = foundLanguage.active || false;
                lang.title = foundLanguage.title || lang.name;
            });
        };

        formatLocales();

        return {
            activeLanguage: settings.language,
            availableLanguages: locales,
            productLanguages: settings.languages,
            /**
             *
             * @param {object} user - user.profile part
             * @returns object with iso1 and iso2 codes
             */
            getActiveLanguageCodes: function (user) {
                if (!user) return companyLocaleCodes;

                return {
                    iso1: user.language,
                    iso2: user.languageISO6392,
                };
            },
            /**
             *
             * @param {string} value - value to search for
             * @returns language object
             */
            getActiveLanguageDetails: function (value) {
                value = value || this.activeLanguage;
                return findLanguage(value);
            },
            /**
             *
             * @param {string} lang - value
             * @returns
             */
            updateLanguageChange: function (lang) {
                if (lang === this.activeLanguage || $window.isPrivateBrowsingMode) return;

                // Persist selection
                locker.put('Language', lang);
                rebootApp(lang);
            },
            /**
             *
             * @param {string} value
             * @returns boolean
             */
            isLanguageValid: function (value) {
                return !!findLanguage(value);
            },
        };
    }]
);

SEVEN.service('SEVENMaintenanceService', ['$http', '$log', 'SEVENSettings', function ($http, $log, SEVENSettings) {
    var settings = SEVENSettings,
        endpoint = settings.platform.api[settings.server] + '/maintenance/api/v1';

    return {
        getSchedule: function () {
            return $http
                .get(endpoint + '/schedule', {
                    params: {
                        company: settings.company.id,
                        channel: settings.platform.delivery,
                    },
                })
                .then(function (response) {
                    return response.data;
                });
        },
        scheduleChannel: settings.platform.delivery,
        scheduleRequestInterval: 0, // 0: Do not ping platform (only on load)
    };
}]);

// Modal service
// Based on: https://github.com/dwmkerr/angular-modal-service
SEVEN.factory(
    'SEVENModalSvc',
    ['$document', '$compile', '$controller', '$http', '$rootScope', '$q', '$templateCache', function (
        // Dependencies
        $document,
        $compile,
        $controller,
        $http,
        $rootScope,
        $q,
        $templateCache
    ) {
        // Constructor
        var ModalService = function () {
            // Reference self
            var self = this;

            // Returns a promise which gets the template, either
            // from the template parameter or via a request to the
            // template url parameter.
            var getTemplate = function (template, templateUrl) {
                var deferred = $q.defer();

                if (template) {
                    deferred.resolve(template);
                } else if (templateUrl) {
                    // Check to see if the template has already been loaded
                    var cachedTemplate = $templateCache.get(templateUrl);
                    if (cachedTemplate !== undefined) {
                        deferred.resolve(cachedTemplate);
                    }
                    // Get the template for the first time
                    else {
                        $http({
                            method: 'GET',
                            url: templateUrl,
                            cache: true,
                        }).then(
                            function (result) {
                                // Save template into the cache and return the template
                                $templateCache.put(templateUrl, result.data);
                                deferred.resolve(result.data);
                            },
                            function (error) {
                                deferred.reject(error);
                            }
                        );
                    }
                } else {
                    deferred.reject('No template or templateUrl has been specified.');
                }

                return deferred.promise;
            };

            // Show modal
            self.showModal = function (options) {
                // Create a deferred we'll resolve when the modal is ready.
                var deferred = $q.defer();

                // Validate the input parameters.
                var controllerName = options.controller;
                if (!controllerName) {
                    deferred.reject('No controller has been specified.');
                    return deferred.promise;
                }

                // If a 'controllerAs' option has been provided, we change the controller
                // name to use 'as' syntax. $controller will automatically handle this.
                if (options.controllerAs) {
                    controllerName = controllerName + ' as ' + options.controllerAs;
                }

                // Get the actual html of the template.
                getTemplate(options.template, options.templateUrl)
                    .then(function (template) {
                        // Get default element for appending modal
                        var appendElement = $document.find('.n-content').first();
                        // Create a new scope for the modal.
                        var modalScope = $rootScope.$new();

                        // Create the inputs object to the controller - this will include
                        // the scope, as well as all inputs provided.
                        // We will also create a deferred that is resolved with a provided
                        // close function. The controller can then call 'close(result)'.
                        // The controller can also provide a delay for closing - this is
                        // helpful if there are closing animations which must finish first.
                        var closeDeferred = $q.defer();
                        var inputs = {
                            $scope: modalScope,
                            close: function (result, delay) {
                                if (delay === undefined || delay === null) delay = 0;
                                window.setTimeout(function () {
                                    // Resolve the 'close' promise.
                                    if (closeDeferred) closeDeferred.resolve(result);

                                    // Reset overflow on body
                                    document.body.style.overflow = 'initial';

                                    //  We can now clean up the scope and remove the element from the DOM.
                                    if (modalScope) modalScope.$destroy();
                                    if (modalElement) modalElement.remove();

                                    // Unless we null out all of these objects we seem to suffer
                                    // from memory leaks, if anyone can explain why then I'd
                                    // be very interested to know.
                                    if (inputs) inputs.close = null;
                                    deferred = null;
                                    closeDeferred = null;
                                    modal = null;
                                    inputs = null;
                                    modalElement = null;
                                    modalScope = null;
                                }, delay);
                            },
                        };

                        // If we have provided any inputs, pass them to the controller.
                        if (options.inputs) {
                            for (var inputName in options.inputs) {
                                inputs[inputName] = options.inputs[inputName];
                            }
                        }

                        // Parse the modal HTML into a DOM element (in template form).
                        var modalElementTemplate = angular.element(template);

                        // Compile then link the template element, building the actual element.
                        // Set the $element on the inputs so that it can be injected if required.
                        var linkFn = $compile(modalElementTemplate);
                        var modalElement = linkFn(modalScope);
                        inputs.$element = modalElement;

                        // Create the controller, explicitly specifying the scope to use.
                        var modalController = $controller(controllerName, inputs);

                        // Finally, append the modal to the dom
                        if (options.appendElement) {
                            // Append to custom append element
                            options.appendElement.append(modalElement);
                        } else {
                            // Append to default when no custom append element is specified
                            appendElement.append(modalElement);
                        }

                        // Set overflow on body: Hide scrollbar
                        document.body.style.overflow = 'hidden';

                        // We now have a modal object
                        var modal = {
                            controller: modalController,
                            scope: modalScope,
                            element: modalElement,
                            close: closeDeferred.promise,
                        };

                        // Close on ESC key
                        angular.element(document).on('keydown', function (e) {
                            if (e.which === 27 && modal && modal.scope) {
                                modal.scope.close();
                            }
                        });

                        // Listen to close event
                        modal.scope.$on('SEVEN.CloseModals', modal.scope.close);

                        // Modal object is passed to the caller via the promise.
                        deferred.resolve(modal);
                    })
                    .then(null, function (error) {
                        // Catch doesn't work in IE8
                        deferred.reject(error);
                    });

                return deferred.promise;
            };
        };

        // Return service instance
        return new ModalService();
    }]
);

SEVEN.service('SEVENNotifier', ['$http', '$timeout', 'SEVENSettings', 'SEVENUser', 'SEVENLocale', function ($http, $timeout, SEVENSettings, SEVENUser, SEVENLocale) {
    var settings = SEVENSettings,
        user = SEVENUser,
        locale = SEVENLocale;

    var _defaultOptions = {
        native: false,
        lang: locale.activeLanguage.toUpperCase(),
        icon: settings.directory.css + 'assets/icons/notification.png',
    };

    var _supported = function () {
        if ('Notification' in window) {
            return true;
        }

        // Support with vendor prefixes
        var vendors = ['webkit', 'moz', 'ms'],
            vendor,
            i;
        for (i = 0; i < vendors.length; i++) {
            vendor = vendors[i] + 'Notification';
            if (vendor in window) {
                window.Notification = window[vendor];
                return true;
            }
        }
    };

    var _getApi = function () {
        return window.OneSignal;
    };

    return {
        granted: false,
        grant: function () {
            // quick hack to stop asking user to allow native notification,
            // native notifications are not implemented (this is just POC).
            // Probably this code will be changed durgin development of  https://phabricator.nsoft.ba/T86533
            // P.S. don't mix it with Pusher. Pusher implementation is fine and it can be used in production.
            // if (_supported() && settings.notifications) {
            //     window.Notification.requestPermission(function (permission) {
            //         self.granted = (permission.toUpperCase() === 'GRANTED');
            //     });
            //     _initPushNotifications();
            // }
        },
        notify: function (title, options, timeout) {
            // Create notification
            // NOTE: Timeout can be 'default'=5000ms
            options = !options || options === null ? {} : options;
            angular.extend(options, _defaultOptions);
            timeout = angular.isUndefined(timeout) || timeout === 'default' ? 4000 : timeout;

            var notification;
            // Show native notification
            if (options.native && _supported() && this.granted) {
                notification = new window.Notification(title, options);
                if (timeout) {
                    $timeout(notification.close.bind(notification), timeout);
                }
            } else {
                // Define elements
                var template = settings.directory.app + 'shared/notification/view.html';
                var body = $('body');
                var element = body.find('.n-notify');

                // Hide notification
                var hide = function () {
                    element.fadeOut('slow', function () {
                        var current = $(this);
                        if (current.length) {
                            current.remove();
                        }
                    });
                };

                // Show notification
                var show = function () {
                    element[0].classList.value = 'n-notify';

                    if (options.type) {
                        element[0].classList.add(options.type);
                    }

                    element.find('.content').html(title);
                    element.fadeIn('slow').unbind().on('click', '.close', hide);
                    if (timeout) {
                        $timeout(hide, timeout);
                    }
                };

                // Load custom notification
                if (element.length === 0 && settings.notifications) {
                    $http.get(template).then(function (response) {
                        element = $(response.data);
                        body.append(element);
                        show();
                    });
                } else {
                    // Use existing
                    show();
                }
            }

            return notification;
        },
        isApiEnabled: function () {
            return settings.notifications;
        },
        sendUserTags: function () {
            var api = _getApi();
            if (!api) return;
            api.push([
                'sendTags',
                {
                    user: user.id,
                },
            ]);
        },
        deleteUserTags: function () {
            var api = _getApi();
            if (!api) return;
            api.push(['deleteTags', ['user']]);
        },
    };
}]);

SEVEN.service('SEVENOddsOptionService', ['$location', 'SEVENUser', 'SEVENConfig', function ($location, SEVENUser, SEVENConfig) {
    var user = SEVENUser,
        config = SEVENConfig;

    var getOddsOptionByName = function (name) {
        var oddsOption = false;
        angular.forEach(config.client.oddsOptions, function (item) {
            if (item.name.toLowerCase() === name.toLowerCase()) {
                oddsOption = item;
            }
        });
        return oddsOption;
    };

    var getDefaultClientOddsOption = function () {
        var oddsOption = false;
        angular.forEach(config.client.oddsOptions, function (item) {
            if (item.default === true) {
                oddsOption = item;
            }
        });
        return oddsOption;
    };

    var isOddsOptionChanged = function (oddsOption) {
        return oddsOption.id !== parseInt(user.profile.oddsOptions);
    };

    return {
        setPlayerOddsOption: function (queryParam) {
            var oddsOptionFromParam = queryParam ? getOddsOptionByName(queryParam) : null,
                defaultOddsOption = getDefaultClientOddsOption(),
                // If param value is invalid, use company's default oddsOption value
                oddsOption = oddsOptionFromParam || defaultOddsOption;

            // Update User profile only if oddsOption (param or default) value is different from current one
            if (isOddsOptionChanged(oddsOption)) {
                // Update oddsOptions in User profile and local storage
                user.updateProfile({
                    oddsOptions: oddsOption.id,
                });
            }
        },
    };
}]);

SEVEN.service('SEVENOddTypeService', ['$location', 'SEVENUser', 'SEVENConfig', 'SEVENSettings', function ($location, SEVENUser, SEVENConfig, SEVENSettings) {
    var user = SEVENUser,
        config = SEVENConfig,
        settings = SEVENSettings;

    var getOddTypeById = function (id) {
        return config.client.oddTypes[id];
    };

    var getOddTypeByName = function (title) {
        var oddType = false;

        angular.forEach(config.client.oddTypes, function (item) {
            if (item.title.toLowerCase() === title.toLowerCase()) {
                oddType = item;
            }
        });
        return oddType;
    };

    var getDefaultClientOddType = function () {
        var oddType = false;

        angular.forEach(config.client.oddTypes, function (item) {
            if (item.active === true) {
                oddType = item;
            }
        });
        return oddType;
    };

    var getDefaultProductOddType = function (productOddTypes) {
        var oddType = false;

        if (productOddTypes && productOddTypes.active) {
            angular.forEach(config.client.oddTypes, function (item) {
                if (item.title === productOddTypes.active) {
                    oddType = item;
                }
            });
        }
        return oddType;
    };

    var getProductOddTypeFromUser = function (product) {
        var key = product + '_oddType',
            setting = user && user.settings && user.settings[key];

        return setting ? getOddTypeById(setting) : false;
    };

    return {
        getDefaultOddType: function (productOddTypes) {
            var defaultProductOddType = getDefaultProductOddType(productOddTypes),
                defaultClientOddType = getDefaultClientOddType();

            // Return product's default odd type || config.client default odd type
            return defaultProductOddType ? defaultProductOddType : defaultClientOddType;
        },
        getPlayerOddType: function (queryParam, product, productOddTypes) {
            var productOddTypeSetting = getProductOddTypeFromUser(product),
                oddTypeFromParam = queryParam ? getOddTypeByName(queryParam) : null;

            if (settings.mode === 'integration' && oddTypeFromParam) {
                // 1. Return odd type sent as 'odds' query parameter
                return oddTypeFromParam;
            }

            if (productOddTypeSetting) {
                // 2. Return product-specific odd type from User settings
                return productOddTypeSetting;
            } else {
                // 3. Return product's default odd type || 4. Return config.client default odd type
                return this.getDefaultOddType(productOddTypes);
            }
        },
    };
}]);

SEVEN.service(
    'SEVENPlayerAuthService',
    ['$http', '$injector', 'SEVENConfig', 'SEVENSettings', 'SEVENRoutes', 'SevenGravitySettings', 'locker', function (
        $http,
        $injector,
        SEVENConfig,
        SEVENSettings,
        SEVENRoutes,
        SevenGravitySettings,
        locker
    ) {
        var config = SEVENConfig,
            routes = SEVENRoutes,
            appSettings = SevenGravitySettings,
            authModuleSettings = appSettings.getDataByKey('module.authAndPlayer');

        function getImplementation() {
            var userLoginStrategyChoice = locker.get('userLoginStrategyChoice');
            var strategyAuth = authModuleSettings && authModuleSettings.strategy,
                loginStrategies = config.appSettings.config.loginStrategies,
                loginStrategy = null,
                implementations = {
                    seven: 'SEVENPlayerAuthApiService',
                    slap:
                        SEVENSettings.mode === 'integration'
                            ? 'SEVENPlayerAuthApiService'
                            : 'SLAPAuthService',
                    mobileNumberLogin: 'SEVENMobileIntegrationAuthApiService',
                };
            if (loginStrategies && loginStrategies.seven && loginStrategies.mobileIntegration) {
                loginStrategy =
                    userLoginStrategyChoice === 'phoneNumber' ? 'mobileNumberLogin' : 'seven';
            } else {
                loginStrategy =
                    loginStrategies && loginStrategies.mobileIntegration
                        ? 'mobileNumberLogin'
                        : strategyAuth;
            }
            return $injector.get(implementations[loginStrategy] || implementations.seven);
        }

        return {
            b2bAuthPromise: null,
            isDeAuthInProgress: function () {
                return getImplementation().isDeAuthInProgress();
            },
            isAuthInProgress: function () {
                return getImplementation().isAuthInProgress();
            },
            isAuthorizationPending: function () {
                return getImplementation().isAuthorizationPending();
            },
            /**
             * @param {"email"|"nickname"} loginStrategy -
             * @returns {Object}
             */
            getLoginValidation: function (loginStrategy) {
                return getImplementation().getLoginValidation(loginStrategy);
            },

            setUserLoginStrategyChoice: function (choice) {
                locker.put('userLoginStrategyChoice', choice);
            },

            /**
             *
             * @param {Object} data
             * @param {String} data._username
             * @param {String} data._password
             * @param {String} [uuid]
             */
            login: function (data, uuid) {
                return getImplementation().login(data, uuid);
            },
            loginB2B: function (data) {
                this.b2bAuthPromise = $http.post(
                    config.apiBase + routes.common.user.loginB2B.url,
                    data,
                    {
                        headers: {
                            'SEVEN-TP-TOKEN': data.token,
                            'SEVEN-TP-CUSTOM': data.customValues,
                        },
                    }
                );

                return this.b2bAuthPromise.then(function (response) {
                    if (response.status === 200) {
                        return {
                            profile: response.data,
                            token: response.headers('Access-Token'),
                            tpToken: response.headers('X-Nsft-Seven-TP-Token'),
                        };
                    }
                    return response;
                });
            },
            loginCheck: function () {
                return getImplementation().loginCheck();
            },
            logout: function () {
                locker.forget('userLoginStrategyChoice');
                return getImplementation().logout();
            },
            getAuthToken: function () {
                return getImplementation().getAuthToken();
            },
            updateAccessAndRefreshTokens: function () {
                return getImplementation().updateAccessAndRefreshTokens();
            },
            isPhoneNumberVerified: function (mobileNumber) {
                return getImplementation().isPhoneNumberVerified(mobileNumber);
            },
            hasLoginStrategy: function (loginStrategy) {
                var loginStrategies = config.appSettings.config.loginStrategies;

                return !!(loginStrategies && loginStrategies[loginStrategy]);
            },
        };
    }]
);

SEVEN.service('SEVENPlayerSettingsService', ['$http', 'SEVENSettings', function ($http, SEVENSettings) {
    var settings = SEVENSettings,
        baseUrl = settings.api.gravity.url;

    return {
        get: function (key) {
            return $http.get(baseUrl + '/settings/users/' + key);
        },
        getAll: function () {
            return $http.get(baseUrl + '/settings/users');
        },
        save: function (key, value, type) {
            return $http.put(baseUrl + '/settings/users', {
                key: key,
                value: value,
                type: type || 'string',
            });
        },
    };
}]);

SEVEN.service(
    'SEVENPlayerService',
    ['$http', '$location', '$log', '$injector', '$state', 'FileUploader', 'SEVENConfig', 'SEVENSettings', 'SEVENHelpers', 'SEVENMenus', 'SEVENRoutes', 'SEVENRouteService', 'SEVENLocale', 'SevenGravitySettings', 'SEVENToken', function (
        $http,
        $location,
        $log,
        $injector,
        $state,
        FileUploader,
        SEVENConfig,
        SEVENSettings,
        SEVENHelpers,
        SEVENMenus,
        SEVENRoutes,
        SEVENRouteService,
        SEVENLocale,
        SevenGravitySettings,
        SEVENToken
    ) {
        var config = SEVENConfig,
            settings = SEVENSettings,
            helpers = SEVENHelpers,
            menus = SEVENMenus,
            routes = SEVENRoutes,
            routeService = SEVENRouteService,
            locale = SEVENLocale,
            tokenService = SEVENToken,
            requestDateFormat = 'YYYY-MM-DD HH:mm:ss',
            appSettings = SevenGravitySettings,
            authModuleSettings = appSettings.getDataByKey('module.authAndPlayer'),
            cleanSpaces = helpers.cleanSpaces;

        function getRegisterImplementation() {
            var registerStrategy =
                config.appSettings.config.registerStrategy &&
                config.appSettings.config.registerStrategy.mobileIntegration
                    ? 'mobileRegistration'
                    : 'seven';

            var implementations = {
                seven: 'SEVENRegisterApiService',
                mobileRegistration: 'SEVENMobileIntegrationApiService',
            };
            return $injector.get(implementations[registerStrategy] || implementations.seven);
        }

        function getForgotPasswordImplementation(model) {
            var implementations = {
                seven: 'SEVENRegisterApiService',
                mobileRegistration: 'SEVENMobileIntegrationApiService',
            };
            var forgotPasswordStrategy = model.mobileNumber
                ? $injector.get(implementations.mobileRegistration)
                : $injector.get(implementations.seven);

            return forgotPasswordStrategy;
        }

        function getImplementation() {
            var strategyAuth = authModuleSettings && authModuleSettings.strategy,
                implementations = {
                    seven: 'SEVENPlayerApiService',
                    slap:
                        SEVENSettings.mode === 'integration'
                            ? 'SEVENPlayerApiService'
                            : 'SLAPPlayerService',
                };
            return $injector.get(implementations[strategyAuth] || implementations.seven);
        }

        var stateHasTicketHistory = function (state, historyMenu) {
            var stateTicketHistory =
                    state.data && state.data.ticketHistory ? state.data.ticketHistory : {},
                menuState;

            // Take first match found in menu, because menu array is already sorted by priority (highest first)
            menuState = historyMenu.find(function (item) {
                if (item.customData.historySection === stateTicketHistory.section) {
                    return item;
                }
            });

            // If state has 'ticketHistory.section' in its state definition (in modules.json) and
            // if corresponding 'ticket_history' menu item isn't 'disabled'
            return menuState && !menuState.disabled;
        };

        return {
            changePassword: function (model, searchParams) {
                var request,
                    data,
                    // Fallback to passed parameters in case they can't be passed in url
                    search = searchParams || $location.search();

                if (search.token && search.hash && search.email) {
                    // Change with security info
                    // NOTE: This player is created from shop
                    data = angular.extend({}, search);
                    data.newPassword = model.password;
                    data.newPasswordRepeat = model.passwordCheck;
                    request = {
                        method: routes.web.player.account.retailChangePassword.method,
                        url: config.apiBase + routes.web.player.account.retailChangePassword.url,
                        data: data,
                    };
                } else {
                    // Force change
                    request = {
                        method: routes.common.user.requestPasswordChange.method,
                        url:
                            config.apiBase +
                            routes.common.user.requestPasswordChange.url.supplant({
                                token: search.token,
                            }),
                        data: {
                            fos_user_resetting_form: {
                                plainPassword: {
                                    first: model.password,
                                    second: model.passwordCheck,
                                },
                            },
                        },
                    };
                }

                return $http(request);
            },
            closeAccount: function (params) {
                var request = {
                    method: routes.web.player.account.close.method,
                    url: config.apiBase + routes.web.player.account.close.url,
                    data: {
                        password: params.password,
                        message: params.reason,
                    },
                };

                if (params.dateTo)
                    request.data.dateTo = params.dateTo.clone().format(requestDateFormat);
                if (params.isPermanentlyClosed)
                    request.data.isPermanentlyClosed = params.isPermanentlyClosed;

                return $http(request);
            },
            closeAccountConfirm: function (params) {
                var request = {
                    method: routes.web.player.account.close.method,
                    url: config.apiBase + routes.web.player.account.close.url,
                    data: {
                        token: params.token,
                    },
                };

                return $http(request);
            },
            closeAccountConfirmExternal: function (params) {
                var route = routes.web.player.account.closeExternal;
                return $http({
                    method: route.method,
                    url: config.apiBase + route.url,
                    data: params,
                });
            },
            getBalance: function () {
                return getImplementation().getBalance();
            },
            getBetshops: function () {
                return $http.get(config.apiBase + routes.web.betshop.list.url);
            },
            getDocumentUploader: function () {
                var uploader = new FileUploader(),
                    token = tokenService.getToken();

                uploader.headers = {
                    'HTTP-X-SEVEN-CLUB-UUID': config.client.uuid,
                    Authorization: 'Bearer ' + token,
                };
                uploader.url = config.apiBase + routes.web.player.account.uploadDocument.url;
                uploader.alias = 'document';
                uploader.withCredentials = true;
                uploader.removeAfterUpload = true;
                uploader.queueLimit = settings.company.player.verifyIdentity.fileUpload.maxLimit;
                uploader.minLimit = settings.company.player.verifyIdentity.fileUpload.minLimit;

                return uploader;
            },
            getExtendedTicketHistoryMenu: function () {
                var menu = angular.extend([], menus.ticket_history);

                angular.forEach(menu, function (item) {
                    // Attach $state definition to menu item
                    item.stateDefinition = routeService.findStateDefinitionByStateName(
                        item.customData.productStateName
                    );
                });

                // Returns menu items extended with state definition (defined in modules.json) of corresponding product state
                return menu;
            },
            getPlayerHistoryState: function () {
                var state = $state.current,
                    // Extend menu items with state definition (defined in modules.json) of corresponding product state
                    ticketHistoryMenu = this.getExtendedTicketHistoryMenu();

                // 1) In case current state doesn't have ticket history,
                //    take state definition attached to first 'ticket_history' menu item
                if (!stateHasTicketHistory(state, ticketHistoryMenu) && ticketHistoryMenu.length) {
                    state = ticketHistoryMenu[0].stateDefinition;
                }

                // 2) Otherwise, take state definition of current state
                return {
                    state: 'PlayerHistory',
                    params: {
                        section: state.data.ticketHistory ? state.data.ticketHistory.section : null,
                        type: state.data.ticketHistory ? state.data.ticketHistory.type : null,
                    },
                };
            },
            getForm: function (name, company) {
                return $http.get(
                    config.apiBase +
                        routes.common.form.register.url.supplant({
                            name: name,
                            companyUuid: company,
                        })
                );
            },
            getFormField: function (name, company, fieldName) {
                return this.getForm(name, company)
                    .then(function (response) {
                        const fields = response && response.data && response.data.fields;

                        if (!angular.isArray(fields)) return null;

                        return fields.find(function (field) {
                            return field.name === fieldName;
                        });
                    })
                    .catch((error) => {
                        $log.error(
                            '[SEVEN] Error getting form ' + name + ' for company ' + company,
                            error
                        );

                        return null;
                    });
            },
            getMessagesCount: function (time) {
                return getImplementation().getMessagesCount(time);
            },
            throttledGetProfile: function () {
                return getImplementation().throttledGetProfile();
            },
            getProfile: function () {
                return getImplementation().getProfile();
            },
            getTopPlayers: function (params) {
                var request = {
                    method: routes.common.player.ranks.method,
                    url: config.apiBase + routes.common.player.ranks.url,
                    params: params,
                };

                return $http(request);
            },
            getTransactions: function (params) {
                params.dateFrom = params.dateFrom.startOf('day').toISOString();
                params.dateTo = params.dateTo.startOf('day').add(1, 'days').toISOString();

                return $http({
                    method: routes.web.player.transaction.list.method,
                    url: config.apiBase + routes.web.player.transaction.list.url,
                    params: params,
                });
            },
            getValidation: function () {
                var messages = config.messages.general;

                return {
                    messages: {
                        required: messages.validationRequired,
                        minlength: messages.validationMinLength,
                        maxlength: messages.validationMaxLength,
                        pattern: messages.validationPattern,
                        patternWithoutWhitespace: messages.validationPattern,
                        match: messages.validationMatch,
                        checkRequired: messages.validationRequired,
                    },
                    firstName: {
                        required: true,
                        minlength: 2,
                        maxlength: 30,
                    },
                    lastName: {
                        required: true,
                        minlength: 2,
                        maxlength: 30,
                    },
                    nickname: {
                        name: 'nickname',
                        required: true,
                        minlength: 4,
                        maxlength: 20,
                        pattern: /^[a-zA-Z0-9-]+$/,
                        messages: {
                            pattern: messages.nicknamePatternError,
                        },
                    },
                    birthDate: {
                        required: true,
                    },
                    email: {
                        name: 'email',
                        required: true,
                        minlength: 2,
                        maxlength: 60,
                        pattern: helpers.emailPattern(),
                    },
                    taxIdentifier: {
                        required: settings.company.player.taxIdentifier,
                        minlength: 1,
                        maxlength: 20,
                    },
                    password: {
                        name: 'password',
                        required: true,
                        minlength: 8,
                        maxlength: 20,
                        pattern: /^(?=.*[a-z])(?=.*[A-Z]).+$/,
                        messages: {
                            pattern: messages.passwordPatternError,
                        },
                    },
                    passwordCheck: {
                        required: true,
                        match: 'password',
                    },
                    timezone: {
                        required: true,
                    },
                    mobileNumber: {
                        messages: {
                            pattern: messages.validationMobileNumberPattern,
                            patternWithoutWhitespace: messages.validationMobileNumberPattern,
                        },
                    },
                };
            },
            getParsedValidation: function (validationPattern) {
                return (
                    validationPattern &&
                    RegExp.apply(undefined, /^\/(.*)\/(.*)/.exec(validationPattern).slice(1))
                );
            },
            isBBLoyaltyAccConnected: function () {
                var url = settings.api.balkanBetLoyalty.url[settings.server] + '/api/v1/accounts';

                return $http
                    .get(url, {
                        headers: {
                            Authorization: 'Bearer ' + tokenService.getToken(),
                        },
                    })
                    .then(function (response) {
                        return response.data;
                    })
                    .catch(function () {
                        return false;
                    });
            },
            parseTopPlayers: function (list) {
                var items = [];

                function TopPlayer(item) {
                    this.player = {
                        firstName: item.firstName,
                        lastName: item.lastName,
                        nickname: item.nickname,
                        email: item.email,
                    };
                    this.deposit = item.deposit;
                    this.balance = item.balance;
                    this.growth = item.monthlyGrowth;
                }

                angular.forEach(
                    list,
                    function (item) {
                        this.push(new TopPlayer(item));
                    },
                    items
                );

                return items;
            },
            payoutVoucher: function (model) {
                var request = {
                    method: routes.web.player.voucher.payout.method,
                    url: config.apiBase + routes.web.player.voucher.payout.url,
                    data: {
                        totalAmount: model.amount,
                    },
                };

                return $http(request);
            },
            register: function (model, uuid) {
                var activityAlerts = config.client.activityAlerts,
                    maxInactivityTimes = config.client.maxInactivityTimes,
                    data = {};

                // Prepare data
                angular.forEach(model, function (item, key) {
                    data[key] = item.value || item;
                });

                if (data.mobileNumber && !data.mobilePrefix) {
                    data.mobileNumber = cleanSpaces(data.mobileNumber.replace(/^00/, '+'));
                }

                if (
                    settings.company.player.storeUTMParamsInField &&
                    settings.company.player.storeUTMParamsInField.enabled
                ) {
                    const utmParameters = helpers.getUtmParametersFromQueryParams();

                    if (utmParameters) {
                        data[settings.company.player.storeUTMParamsInField.field] = utmParameters;
                    }
                }

                if (
                    config.appSettings.config.registerStrategy &&
                    config.appSettings.config.registerStrategy.mobileIntegration
                ) {
                    data.origin = 'import';
                }

                // Add default settings
                data.maxInactivityTime = maxInactivityTimes[maxInactivityTimes.length - 1];
                data.activityAlert = activityAlerts[activityAlerts.length - 1];
                data.language = model.language ? model.language.value : locale.activeLanguage;

                return getRegisterImplementation().register(data, uuid);
            },
            resetPassword: function (model, entityUuid) {
                return getForgotPasswordImplementation(model).forgotPassword(model, entityUuid);
            },
            selfExclude: function (params) {
                var request = {
                    method: routes.web.player.account.exclude.method,
                    url: config.apiBase + routes.web.player.account.exclude.url,
                    data: {
                        password: params.password,
                        products: params.products,
                        message: params.reason,
                    },
                };

                if (params.dateTo)
                    request.data.dateTo = params.dateTo.clone().format(requestDateFormat);
                if (params.isPermanentlyExcluded)
                    request.data.isPermanentlyExcluded = params.isPermanentlyExcluded;

                return $http(request);
            },
            sendEmailVerification: function () {
                var request = {
                    method: routes.web.player.account.sendVerificationMail.method,
                    url: config.apiBase + routes.web.player.account.sendVerificationMail.url,
                };

                return $http(request);
            },
            updatePassword: function (model) {
                var request = {
                    method: routes.web.player.account.changePassword.method,
                    url: config.apiBase + routes.web.player.account.changePassword.url,
                    data: {
                        oldPassword: model.currentPassword,
                        newPassword: model.password,
                        newPasswordRepeat: model.passwordCheck,
                    },
                };

                return $http(request);
            },
            // Update profile from model
            updateProfile: function (model) {
                var request,
                    activityAlerts = config.client.activityAlerts,
                    maxInactivityTimes = config.client.maxInactivityTimes,
                    mobileNumber = null;

                if (model.mobileNumber) {
                    mobileNumber = cleanSpaces(model.mobileNumber);
                }

                request = {
                    method: routes.web.player.profile.save.method,
                    url: config.apiBase + routes.web.player.profile.save.url,
                    data: {
                        academicTitle: model.academicTitle,
                        bankAccountNumber: model.bankAccountNumber,
                        title: model.title ? model.title.value : null,
                        firstName: model.firstName,
                        lastName: model.lastName,
                        nickname: model.nickname,
                        birthDate: model.birthDate,
                        country: model.country ? model.country.value : null,
                        city: model.city,
                        postalNumber: model.postalNumber,
                        address: model.address,
                        taxIdentifier: model.taxIdentifier,
                        mobileNumber: mobileNumber,
                        phoneNumber: cleanSpaces(model.phoneNumber),
                        language: model.language ? model.language.value : locale.activeLanguage,
                        timezone: model.timezone ? model.timezone.value : null,
                        oddsDisplay: model.oddsDisplay ? model.oddsDisplay.id : null,
                        oddsOptions: model.oddsOption ? model.oddsOption.id : null,
                        maxInactivityTime: model.maxInactivityTime
                            ? model.maxInactivityTime.value
                            : maxInactivityTimes[maxInactivityTimes.length - 1],
                        activityAlert: model.activityAlert
                            ? model.activityAlert.value
                            : activityAlerts[activityAlerts.length - 1],
                        gender: model.gender ? model.gender.value : null,
                        isPoliticallyExposedPerson: model.isPoliticallyExposedPerson,
                    },
                };

                return $http(request);
            },
            updateProfilePart: function (model) {
                var request = {
                    method: routes.web.player.profile.update.method,
                    url: config.apiBase + routes.web.player.profile.update.url,
                    data: (function () {
                        var properties = {};
                        for (var key in model) {
                            if (model.hasOwnProperty(key)) {
                                properties[key] = model[key];
                            }
                        }
                        return properties;
                    })(),
                };

                return $http(request);
            },
            uploadDocuments: function (model) {
                model.uploader.uploadAll();
            },
            verifyEmail: function () {
                // Get data from querystring
                var query = $location.search();

                var request = {
                    method: routes.web.player.account.verifyEmail.method,
                    url:
                        config.apiBase +
                        routes.web.player.account.verifyEmail.url.supplant({
                            email: query.email,
                            token: query.token,
                        }),
                };

                return $http(request);
            },
            getPublicProfile: function (profileIdentifier) {
                var request = {
                    method: 'GET',
                    url: config.apiBase + '/v1/players/profile/public/' + profileIdentifier,
                };

                return $http(request).then(function (response) {
                    return response.data;
                });
            },
        };
    }]
);

SEVEN.service('SEVENPlugins', ['$q', '$window', '$http', '$log', function ($q, $window, $http, $log) {
    var loadPlugin = function (plugin, additionalData) {
        return $q(function (resolve) {
            if (!plugin) return false;

            var isPluginLoaded = $window[plugin.executable];
            if (!plugin.enabled || plugin.type !== 'script' || isPluginLoaded) return false;

            var appendTo = plugin.appendTo || 'body';

            if (plugin.remote) {
                var script = document.createElement('script');
                script.setAttribute('src', plugin.origin);
                angular.element(appendTo).append(script);
                resolve();
            } else {
                $http
                    .get(plugin.origin)
                    .then(function (pluginScript) {
                        if (pluginScript.data) {
                            plugin.data = angular.merge(plugin.data, additionalData) || {};
                            angular
                                .element(appendTo)
                                .append(pluginScript.data.supplant(plugin.data));
                            resolve();
                        }
                    })
                    .catch(function (err) {
                        $log.error('[SEVEN] Loading plugin ' + plugin.origin + ' failed', err);
                    });
            }
            return false;
        });
    };

    return {
        loadPlugin: loadPlugin,
    };
}]);

SEVEN.service('SEVENPreviousState', ['$rootScope', function ($rootScope) {
    var previousState = null;

    $rootScope.$on('$stateChangeSuccess', function (event, toState, toStateParams, fromState) {
        previousState = fromState;
    });

    return {
        is: function (name) {
            return !!(previousState && previousState.name === name);
        },
    };
}]);

SEVEN.service(
    'SEVENProductService',
    ['$http', '$filter', 'SEVENConfig', 'SEVENSettings', 'SEVENHelpers', 'SEVENRoutes', '_7Ticket', 'SEVENUser', 'SEVENLocale', 'SEVENToken', function (
        $http,
        $filter,
        SEVENConfig,
        SEVENSettings,
        SEVENHelpers,
        SEVENRoutes,
        _7Ticket,
        SEVENUser,
        SEVENLocale,
        SEVENToken
    ) {
        var config = SEVENConfig,
            settings = SEVENSettings,
            helpers = SEVENHelpers,
            routes = SEVENRoutes,
            ticket = _7Ticket,
            user = SEVENUser,
            tokenService = SEVENToken,
            locale = SEVENLocale;

        /**
         *
         * These are list of ticket statuses aligned with Ticket API definitions.
         * See https://docs.google.com/document/d/1hFJx1bDaL71tMuEITCn5mR5j6MTQMTdtQDHfe155o3o/edit#
         * They are documented under seven-sdk.
         *
         * @typedef {String} SEVEN_TICKET_STATUS
         **/

        /**
         * @readonly
         * @enum {SEVEN_TICKET_STATUS}
         */
        var TICKET_STATUSES = {
            /**
             * At least one winning bet found on ticket
             */
            WON: 'WON',
            /**
             * No winning bets found on ticket
             */
            LOST: 'LOST',
            /**
             * Winnings collected by/given to punter
             */
            PAIDOUT: 'PAIDOUT',
            /**
             * Ticket canceled by punter
             */
            CANCELED: 'CANCELED',
            /**
             * Won ticket which winnings are never collected before ticket expiry time
             */
            EXPIRED: 'EXPIRED',
            /**
             * Ticket accepted by Seven platform
             */
            ACCEPTED: 'ACCEPTED',
            /**
             * Ticket voided py product
             */
            VOID: 'VOID',
        };

        var convertRacerBets = function (bet, item) {
            var racer = config.messages.vms.racer;
            bet.reverse = false;
            bet.data = [];

            if (item.combinations) {
                bet.value = [];

                angular.forEach(item.combinations, function (prop) {
                    bet.value.push(prop.value);
                    if (prop.status.value === 'WON' && item.type !== 14) {
                        bet.valueHtml = prop.value;
                        bet.data = prop;
                    } else if (prop.status.value === 'WON' && item.type === 14) {
                        var value = prop.value.split('-');
                        bet.valueHtml = racer + ' ' + value[0] + racer + ' ' + value[1];
                        bet.data.odd = prop.odd;
                        bet.data.amount = prop.amount;
                        bet.data.possibleWin = prop.possibleWin;
                    }
                });

                // if item reverse forecast/tricast
                if (item.type === 12 || item.type === 13) {
                    bet.reverse = true;
                    bet.valueHtml = item.value;
                }

                if (item.combinations.length > 1 && !bet.reverse) {
                    bet.valueHtml = item.value
                        .map(function (number, index) {
                            var idx = index + 1 + '.';
                            return '<div>' + idx + ' ' + number + '</div>';
                        })
                        .join('');
                }

                if (item.combinations.length === 1 && !bet.reverse) {
                    bet.valueHtml = item.combinations[0].value;
                }

                if (item.type === 14) {
                    bet.pick = racer + ' ' + item.value[0];
                    var sortedValue = item.value.sort();
                    bet.valueHtml =
                        racer + ' ' + sortedValue[0] + ' - ' + racer + ' ' + sortedValue[1];
                }
            } else {
                bet.data = item;
                var statuses = ['LOST', 'OPEN', 'INPLAY', 'CLOSED'];
                if (statuses.indexOf(item.status.value) > -1) {
                    bet.data.amount = '-';
                    bet.data.odd = '-';
                    bet.data.possibleWin = '-';
                }
            }
        };

        // Convert special bets
        var convertBet = {
            LuckySix: function (bet) {
                // Normalize title for systems
                if ((bet.type < 5 && bet.type > 0) || bet.type === 10) {
                    bet.title = bet.label;
                }

                var resolveColor = function () {
                    var resolved = [],
                        colorIndex;

                    [0, 1, 2, 3, 4, 5, 6, 7, 8].forEach(function (n) {
                        if (parseInt(bet.value, 10) & Math.pow(2, n)) {
                            colorIndex = n === 0 ? 7 : n - 1;
                            resolved.push(String(colorIndex));
                        }
                    });

                    return resolved;
                };

                // Resolve values
                bet.value = (function () {
                    if (bet.type === 5) return resolveColor();
                    if ([6, 8].indexOf(bet.type) > -1)
                        return parseInt(bet.value, 10) === 0
                            ? config.messages.general.low
                            : config.messages.general.high;
                    if ([7, 9, 11].indexOf(bet.type) > -1)
                        return parseInt(bet.value, 10) === 0
                            ? config.messages.general.even
                            : config.messages.general.odd;
                    return bet.value;
                })();

                // Check numbered bet
                if (bet.type < 5 || bet.type === 10) {
                    var value = bet.value.split(','),
                        active = [],
                        index;

                    // Sort drawn values
                    if (!angular.isArray(bet.drawn))
                        bet.drawn = bet.drawn ? bet.drawn.split(',').sort() : [];

                    // Create active value indexes
                    angular.forEach(bet.drawn, function (i) {
                        index = value.indexOf(i.toString());
                        if (index > -1) active.push(index);
                    });

                    // Create html value
                    bet.valueHtml = value
                        .map(function (number, index) {
                            var colorIdx = number % 8 === 0 ? 7 : (number % 8) - 1;
                            return (
                                (active && active.indexOf(index) > -1
                                    ? '<i class="circle active color-' + colorIdx + '">'
                                    : '<i class="circle">') +
                                number.trim() +
                                '</i>'
                            );
                        })
                        .join('');

                    // Create html number
                    bet.valueHtmlNumber = value
                        .map(function (number, index) {
                            return (
                                (active && active.indexOf(index) > -1
                                    ? '<span class="active number">'
                                    : '<span class="number">') +
                                number +
                                '</span>'
                            );
                        })
                        .join(', ');
                }
            },
            LuckyX: function (bet, item) {
                // Set value
                bet.value = item.outcome.title;

                // Normalize system titles
                if (bet.type > 10 && bet.type < 20) {
                    bet.title += ' ' + item.system + '/' + bet.value.length;
                }

                // Normalize special numbered bet
                if (bet.type === 23) {
                    bet.title = bet.label;
                }

                // Check numbered bet
                if (bet.type < 20 || bet.type === 23) {
                    var active = [],
                        index;

                    // Sort drawn values
                    bet.value = angular.isArray(bet.value) ? bet.value : [bet.value];
                    bet.drawn = bet.drawn ? bet.drawn.sort() : [];

                    // Create active value indexes
                    angular.forEach(bet.drawn, function (i) {
                        index = bet.value.indexOf(i);
                        if (index > -1) active.push(index);
                    });

                    // Create html value
                    bet.valueHtml = bet.value
                        .map(function (number, index) {
                            return (
                                (active && active.indexOf(index) > -1
                                    ? '<i class="circle active">'
                                    : '<i class="circle">') +
                                number +
                                '</i>'
                            );
                        })
                        .join('');

                    // Create html number
                    bet.valueHtmlNumber = bet.value
                        .map(function (number, index) {
                            return (
                                (active && active.indexOf(index) > -1
                                    ? '<span class="active number">'
                                    : '<span class="number">') +
                                number +
                                '</span>'
                            );
                        })
                        .join(', ');
                }
            },
            NextSix: function (bet, item) {
                // Set value
                bet.value = item.outcome.title;
                bet.subLabel = item.ballPositions;
                bet.title = '';

                // Check numbered bet
                if (bet.type < 9) {
                    // Split value
                    var active = [],
                        index;

                    // Sort drawn values
                    bet.value = angular.isArray(bet.value) ? bet.value : [bet.value];
                    bet.drawn = angular.isArray(bet.drawn) ? bet.drawn : [bet.drawn];

                    bet.title = item.bet.title;

                    // Create active value indexes
                    angular.forEach(bet.drawn, function (i) {
                        index = bet.value.indexOf(i);
                        if (index > -1) active.push(index);
                    });

                    // Create html value
                    bet.valueHtml = bet.value
                        .map(function (number, index) {
                            return (
                                (active && active.indexOf(index) > -1
                                    ? '<i class="circle active">'
                                    : '<i class="circle">') +
                                number +
                                '</i>'
                            );
                        })
                        .join('');

                    // Create html value
                    bet.valueHtmlNumber = bet.value
                        .map(function (number, index) {
                            return (
                                (active && active.indexOf(index) > -1
                                    ? '<span class="active number">'
                                    : '<span class="number">') +
                                number +
                                '</span>'
                            );
                        })
                        .join(', ');
                }
            },
            Keno: function (bet) {
                var active = [],
                    index;

                // Create active value indexes
                angular.forEach(bet.drawn, function (i) {
                    index = bet.value.indexOf(i);
                    if (index > -1) active.push(index);
                });

                if (bet.type > 30) {
                    bet.title = 'SPECIAL';
                }

                bet.value = (function () {
                    if ([30, 31, 38, 39].indexOf(bet.type) > -1)
                        return parseInt(bet.value, 10) === 0
                            ? config.messages.general.under
                            : config.messages.general.over;
                    if ([32, 33].indexOf(bet.type) > -1)
                        return parseInt(bet.value, 10) === 0
                            ? config.messages.general.yes
                            : config.messages.general.no;
                    if ([34, 35].indexOf(bet.type) > -1)
                        return parseInt(bet.value, 10) === 0
                            ? config.messages.general.odd
                            : config.messages.general.even;
                    if ([36, 37].indexOf(bet.type) > -1) return bet.outcome.title;
                    return bet.value;
                })();

                if (bet.outcome.value.length) {
                    bet.valueHtml = bet.value
                        .map(function (number, index) {
                            return (
                                (active && active.indexOf(index) > -1
                                    ? '<i class="circle active">'
                                    : '<i class="circle">') +
                                number +
                                '</i>'
                            );
                        })
                        .join('');

                    // Create html number
                    bet.valueHtmlNumber = bet.value
                        .map(function (number, index) {
                            return (
                                (active && active.indexOf(index) > -1
                                    ? '<span class="active number">'
                                    : '<span class="number">') +
                                number +
                                '</span>'
                            );
                        })
                        .join(', ');
                }
            },
            GreyhoundRaces: function (bet, item) {
                convertRacerBets(bet, item);
            },
            HorseRaces: function (bet, item) {
                convertRacerBets(bet, item);
            },
            MotorcycleSpeedway: function (bet, item) {
                convertRacerBets(bet, item);
            },
            SlotCarRaces: function (bet, item) {
                convertRacerBets(bet, item);
            },
            PenaltyShootout: function (bet, item) {
                bet.value = item.outcome.title;
                bet.label = item.typeValue;
            },
        };

        var service = {
            getBingoPrintTemplate: function (data) {
                var bingoHeaderTemplate = '',
                    messages = config.messages.general,
                    currency = config.client.currency;
                bingoHeaderTemplate +=
                    messages.payIn + " {{ payin | number_format(2, '.', ',') }}" + currency + ' ';
                bingoHeaderTemplate +=
                    messages.payout + " {{ payout | number_format(2, '.', ',') }}" + currency + ' ';

                return (
                    bingoHeaderTemplate +
                    data.ticket.bets
                        .map(function (bet) {
                            var betTitle = bet.title || bet.value,
                                betSubTitle = bet.subtitle,
                                bingoTemplate = '',
                                i,
                                round;

                            for (i = 0; i < bet.rounds; i++) {
                                round = messages.round + ':' + ' {{bets[0].eventId + ' + i + '}} ';
                                bingoTemplate += round + ' ';
                                bingoTemplate += betTitle + ' ';
                                if (bet.id !== 0) {
                                    bingoTemplate += betSubTitle + ' ';
                                }
                                bingoTemplate += bet.desc + ' ';
                            }

                            return bingoTemplate;
                        })
                        .join('; ') +
                    ' {{ id }}'
                );
            },
            getRacesPrintTemplate: function (data) {
                var racesHeaderTemplate = '',
                    messages = config.messages.general,
                    currency = config.client.currency;
                racesHeaderTemplate +=
                    messages.payIn + " {{ payin | number_format(2, '.', ',') }}" + currency + ' ';
                racesHeaderTemplate +=
                    messages.payout + " {{ payout | number_format(2, '.', ',') }}" + currency + ' ';
                return (
                    racesHeaderTemplate +
                    data.ticket.bets
                        .map(function (bet) {
                            var betTitle = bet.title || bet.title[0],
                                racesTemplate = '',
                                oddValue = bet.oddDisplay['1'] || bet.oddDisplay;

                            if (!bet.combValue) {
                                racesTemplate += betTitle + ' ';
                            } else {
                                racesTemplate += bet.combValue + ' ';
                            }
                            racesTemplate += bet.subtitle + ' ';
                            if (bet.pick) racesTemplate += bet.pick + ' ';
                            racesTemplate += bet.round + ' ';
                            racesTemplate += oddValue + ' ';
                            racesTemplate += bet.stake + ' ';

                            return racesTemplate;
                        })
                        .join('; ') +
                    ' {{ id }}'
                );
            },
            getNextSixPrintTemplate: function (data) {
                var nextSixHeaderTemplate = '',
                    messages = config.messages.general,
                    currency = config.client.currency;

                nextSixHeaderTemplate +=
                    messages.payIn + " {{ payin | number_format(2, '.', ',') }}" + currency + ' ';
                nextSixHeaderTemplate +=
                    messages.payout + " {{ payout | number_format(2, '.', ',') }}" + currency + ' ';

                return (
                    nextSixHeaderTemplate +
                    data.ticket.bets
                        .map(function (bet) {
                            var nextSixTicketTemplate = '';

                            nextSixTicketTemplate += messages.round + ': ' + bet.round + ' ';
                            nextSixTicketTemplate += bet.title + ' ';
                            nextSixTicketTemplate += bet.subtitle + ' ';

                            return nextSixTicketTemplate;
                        })
                        .join('; ') +
                    ' {{ id }}'
                );
            },
            getSportPrintTemplate: function (data) {
                var messages = SEVENConfig.messages.general,
                    ticketTemplate = ' ',
                    ticketHeader,
                    ticketWinnings = '',
                    ticketTypesTranslations = {
                        1: messages.ticketCombo,
                        2: messages.ticketSystem,
                        3: messages.ticketSingle,
                    },
                    ticketType = ticketTypesTranslations[data.ticket.ticketType];

                // System ticket type
                if (data.ticket.ticketType === '2') {
                    ticketTemplate +=
                        messages.ticketSystem +
                        ' ' +
                        messages.payIn +
                        ' ' +
                        messages.maxMinWin +
                        ' ' +
                        messages.status +
                        ' ';
                    var bankerCounter = data.ticket.bets.reduce(function (sum, val) {
                        return val.ticketBetBank ? sum + 1 : sum;
                    }, 0);

                    ticketTemplate += data.ticket.ticketCombinationGroups
                        .map(function (system) {
                            return (
                                (bankerCounter > 0
                                    ? bankerCounter +
                                      messages.bankersSymbol +
                                      ' + ' +
                                      system.parlays +
                                      '/' +
                                      system.events
                                    : system.parlays + '/' + system.events) +
                                ' (' +
                                helpers.getCombinations(system.events, system.parlays) +
                                ')'
                            );
                        })
                        .join(' ');
                }

                ticketWinnings +=
                    ' ' +
                    messages.payIn +
                    ": {{payIn  | number_format(2, '.', ',')}} " +
                    messages.maxPossibleWin +
                    ": {{maxWin | number_format(2, '.', ',')}} " +
                    messages.payout +
                    ": {{payout | number_format(2, '.', ',')}} ";

                if (ticket.ticket().winnings.bonusTotal) {
                    ticketWinnings +=
                        messages.bonuses +
                        "({{bonusPercentage}}): {{bonus | number_format(2, '.', ',')}} ";
                }

                ticketHeader =
                    ticketType +
                    ticketWinnings +
                    messages.match.toUpperCase() +
                    ' ' +
                    messages.bet.toUpperCase() +
                    ' ' +
                    messages.odds.toUpperCase() +
                    ' ' +
                    messages.pick.toUpperCase() +
                    ' ';

                data.ticket.bets = $filter('orderBy')(data.ticket.bets, '-ticketBetBank');

                return (
                    ticketTemplate +
                    ticketHeader +
                    data.ticket.bets
                        .map(function (bet) {
                            var betTemplate = '';
                            // System ticket type
                            if (data.ticket.ticketType === '2' && bet.ticketBetBank) {
                                betTemplate += messages.bankers[0] + ' ';
                            }

                            betTemplate +=
                                bet.match +
                                ' ' +
                                $filter('moment')(
                                    moment(bet.matchDate).add(user.profile.timezoneOffset, 'h'),
                                    'datetime'
                                ) +
                                ' ';
                            betTemplate += bet.outcome + ' ';
                            betTemplate +=
                                '{{ attribute(matchOdds,' +
                                "'" +
                                bet.idMatchBetOutcome +
                                "'" +
                                ' ) }} ';
                            betTemplate += bet.pick.toUpperCase() + ' ';

                            return betTemplate;
                        })
                        .join(' ') +
                    ' {{ticketId}}'
                );
            },
            /**
             * @function
             * @name getProductByName - Searches for product by it's unique name from one of product sources
             * @param {string} name - Unique product name
             * @param {('products'|'productsV2')} [productsSource="products"] - Source of products
             * @returns {(Object | null)}
             */
            getProductByName: function (name, productsSource) {
                var product = null;
                var products = productsSource ? config[productsSource] : config.products;

                angular.forEach(products, function (n) {
                    if (n.name === name) {
                        product = n;
                    }
                });

                return product;
            },
            getTicketType: function (product) {
                var type;

                switch (product.toUpperCase()) {
                    case 'LUCKYSIX':
                    case 'LUCKYX':
                    case 'NEXTSIX':
                    case 'KENO':
                    case 'VIRTUALPENALTYSHOOTOUT':
                        type = 'bingo';
                        break;
                    case 'GREYHOUNDRACES':
                    case 'VIRTUALGREYHOUNDRACES':
                    case 'VIRTUALHORSERACES':
                    case 'VIRTUALMOTORCYCLESPEEDWAY':
                    case 'SLOTCARRACES':
                        type = 'races';
                        break;
                    case 'GOALFLASH':
                        type = 'sport';
                        break;
                }

                return type;
            },
            getTicket: function (id) {
                var request,
                    params = {};

                params.id_language = locale.activeLanguage;

                request = {
                    method: routes.web.player.ticket.detail.method,
                    url:
                        config.apiBase +
                        routes.web.player.ticket.detail.url.supplant({
                            id: id.toUpperCase(),
                        }),
                    params: params,
                };

                return $http(request);
            },
            /**
             * Get ticket from open api by barcode (no authorisation)
             * @param {String} barcode - seven barcode
             */
            getPublicTicket: function (barcode) {
                var request,
                    url = (settings.platform.api[settings.server] + '/barcodes/{barcode}').supplant(
                        {
                            barcode: barcode.toUpperCase(),
                        }
                    );

                request = {
                    method: 'GET',
                    url: url,
                    headers: {
                        'X-NSFT-SEVEN-COMPANY-UUID': config.client.uuid,
                    },
                };

                return $http(request);
            },
            getGlobalTicket: function (id) {
                var request,
                    url = (settings.api.gravity.url + '/web/tickets/{id}').supplant({
                        id: id.toUpperCase(),
                    });

                request = {
                    method: 'GET',
                    url: url,
                    headers: {
                        'X-Nsft-SCD-Locale': locale.activeLanguage,
                        'X-Nsft-SCD-Company': settings.company.name,
                        'X-Nsft-SCD-Company-Id': config.client.uuid,
                    },
                };

                return $http(request);
            },
            /**
             *
             * @param {String} type - Seven product ID
             * @param {Date} [params.timeFrom]
             * @param {Date} [params.timeTo]
             * @param {Number} [params.timezoneOffset]
             * @param {Number} [params.count=1000]
             * @param {Boolean} [params.isFullHistoryRequest=-web.json]
             * @param {Boolean} [params.status]
             */
            getTickets: function (type, params) {
                // Default values
                params = params || {};

                if (params.status) {
                    params.status = this.parseTicketStatusToProductStatus(params.status, type);
                }

                // Convert dates
                if (params.timeFrom)
                    params.timeFrom = helpers.getQueryDate(
                        params.timeFrom,
                        0,
                        params.timezoneOffset
                    );
                if (params.timeTo)
                    params.timeTo = helpers.getQueryDate(params.timeTo, 1, params.timezoneOffset);
                delete params.timezoneOffset; // Delete unnecessary request param

                // Get url from config
                var product = service.getProductByName(type),
                    productType = this.getTicketType(product.name),
                    languages,
                    language,
                    headers = {},
                    url;

                // Check languages object
                if (locale.productLanguages) {
                    languages = locale.productLanguages[locale.activeLanguage];
                    if (languages) {
                        language = languages[product.name];
                    }
                }

                // Add mandatory params
                params.id_language = locale.activeLanguage;
                params.language = language;
                params.count = params.count || 1000;
                params.product = product.name;
                params.timezone = user.profile.timezone;

                if (productType === 'sport') {
                    params.cpvUuid = product.channel;

                    url =
                        config.apiBase +
                        routes.web.player.ticket.list.url.supplant({
                            cpvUuid: params.cpvUuid,
                        });
                } else {
                    url = settings.api.ngs.url + '/last-player-tickets';
                    url += params.isFullHistoryRequest ? '.json' : '-web.json';

                    headers = {
                        Authorization: 'Bearer ' + tokenService.getToken(),
                        'X-Nsft-Ngs-Product': product.channel,
                        'X-Nsft-Ngs-Company': settings.company.id,
                        'X-Nsft-Ngs-Player': user.id,
                        'SEVEN-LOCALE': locale.activeLanguage,
                    };
                }

                var request = {
                    method: routes.web.player.ticket.list.method,
                    url: url,
                    params: params,
                    headers: headers,
                };

                return $http(request);
            },
            /**
             * @returns {SEVEN_TICKET_STATUS}
             */
            getTicketStautuses: function () {
                return TICKET_STATUSES;
            },
            parseTicketStatus: function (data) {
                var status,
                    code = data.value || data,
                    title = data.translated || data.name || data;

                function Status() {
                    this.code = code;
                    this.title = title;
                }

                status = new Status();

                // Add styling
                switch (code) {
                    case 'BONUS':
                    case 'JACKPOT':
                    case 'PAYEDOUT':
                    case 'WON':
                    case 'WON_OVERDUE':
                        status.state = 'won';
                        status.icon = 'check';
                        break;
                    case 'LOST':
                        status.state = 'lost';
                        status.icon = 'close';
                        break;
                    case 'AFTERCLOSED':
                    case 'CLOSED':
                    case 'REJECTED':
                    case 'UNKNOWN':
                        status.state = 'closed';
                        status.icon = 'void';
                        break;
                    case 'OPEN':
                        status.state = 'open';
                        status.icon = 'clock';
                        status.cancellable = true;
                        break;
                    default:
                        status.state = 'active';
                        status.icon = 'clock-start';
                        status.live = true;
                }

                return status;
            },
            /**
             * Converts seven ticket status to product status
             *
             * @param {SEVEN_TICKET_STATUS} platformStatus
             * @param {String} product - Seven product id
             *
             * @returns {String} - Returns mapped product status or if not mapped then same status as requsted
             */
            parseTicketStatusToProductStatus: function (platformStatus, product) {
                var productConfig = service.getProductByName(product);
                if (
                    productConfig.ticketStatusMapper &&
                    productConfig.ticketStatusMapper[platformStatus]
                ) {
                    return productConfig.ticketStatusMapper[platformStatus];
                }

                return platformStatus;
            },
            parseTickets: function (moduleType, ticketType, data, tickets) {
                var product = service.getProductByName(moduleType);
                tickets = angular.isUndefined(tickets) ? data : tickets;

                var list = data.message || data.data || data;
                var items = [];

                function Ticket(item, i) {
                    this.id = item.id;
                    this.title = product.title;
                    this.status = service.parseTicketStatus(item.status);
                    this.cancellable = angular.isUndefined(item.cancellable)
                        ? true
                        : item.cancellable;
                    this.superBonus = item.superBonus || false;
                    this.time = helpers.parseDate(item.ticketDateTime || item.createdAt);
                    this.payin = item.payin;
                    this.payinTax = item.payinTax;
                    this.payout = item.payout || 0;
                    this.payoutTax = item.payoutTax || 0;
                    this.winnings = item.winnings;
                    this.maxPossibleWin = item.maxPossibleWin;
                    this.maxPossiblePayout = item.maxPossiblePayout;
                    this.maxPossiblePayoutTax = item.maxPossiblePayoutTax;
                    this.totalOdd = item.totalOdd;
                    this.systems = item.ticketCombinationGroups;
                    this.product = item.product;
                    this.ticketBonus = item.ticketBonus;
                    this.taxAuthorityId = item.taxAuthorityId;
                    this.ticketCombinationGroups = item.ticketCombinationGroups || [];
                    this.bets = service.parseTicketBets(moduleType, ticketType, item, tickets[i]);
                    this.totalCombinations = this.ticketCombinationGroups.reduce(function (
                        acc,
                        curr
                    ) {
                        return acc + curr.numberOfCombinations;
                    },
                    0);

                    // Set bankers length
                    this.bankers = this.bets.filter(function (n) {
                        return n.banker === true;
                    }).length;

                    // Expand type
                    var type = parseInt(item.type, 10);
                    this.type = {
                        id: type,
                        name: config.messages.prematch['ticketType_' + item.type],
                        system: type === 2,
                    };
                }

                angular.forEach(
                    list,
                    function (item, i) {
                        this.push(new Ticket(item, i));
                    },
                    items
                );

                return items;
            },
            parseTicketBets: function (moduleType, ticketType, data, ticket) {
                // Normalize type
                ticketType = ticketType.toUpperCase();

                // Create list
                var list = data.bets;
                var items = [],
                    setRound,
                    setLabel;

                setLabel = function (betId, betName, combinations) {
                    if (betId >= 10 && combinations.length > 1) {
                        return (
                            ' (' +
                            config.messages.general.combinations +
                            ': ' +
                            combinations.length +
                            ')'
                        );
                    }
                };

                // Set round handler
                setRound = function (rounds) {
                    if (rounds.length > 1) {
                        return rounds[0] !== rounds[rounds.length - 1]
                            ? rounds[0] + '-' + rounds[rounds.length - 1]
                            : rounds[0];
                    }

                    return rounds[0];
                };

                function Bet(item, i) {
                    switch (ticketType) {
                        case 'BINGO':
                        case 'RACES':
                            this.title = item.category;
                            this.amount = item.tax ? item.amount + item.tax : item.amount;
                            this.label = item.typeValue || item.bet.title;
                            this.subLabel = item.combinations
                                ? setLabel(item.bet.id, item.bet.title, item.combinations)
                                : '';
                            this.type = item.type;
                            this.round = item.eventId || setRound(item.eventIds);
                            this.value = item.value;
                            this.system = item.system;

                            // Only 'LuckyX, LuckySix and NextSix' should have special odds displayed
                            if (item.odd && ticketType === 'BINGO')
                                this.odd = parseFloat(item.odd).toFixed(2);

                            if (item.outcome && item.outcome.drawn) {
                                this.drawn = item.outcome.drawn;
                            } else {
                                if (
                                    data.status.value === 'IN_PLAY' &&
                                    (item.eventValue === '' || !item.eventValue)
                                ) {
                                    this.drawn = ticket.bets[i].drawn ? ticket.bets[i].drawn : '';
                                } else {
                                    this.drawn = item.eventValue;
                                }
                            }
                            this.winning = item.winnings;
                            this.outcome = item.outcome;
                            this.status = service.parseTicketStatus(item.status);
                            convertBet[moduleType.replace('Virtual', '')](this, item);
                            break;
                        case 'SPORT':
                            this.banker = item.ticketBetBank === '1';
                            this.title = item.matchDisplayName || item.matchName;
                            this.time = new Date(item.matchDateTime);
                            this.special = item.specialValue;
                            this.name = service.parseTicketBetSpecialName(
                                item.bet.betShortName || item.bet.betDisplayName,
                                this.special,
                                item.bet.betGroupingEnabled
                            );
                            this.pick =
                                item.betOutcome.betOutcomeDisplayName ||
                                item.betOutcome.providerBetOutcomeId;
                            this.value = item.oddValue;
                            this.status = parseInt(item.status, 10);
                            this.results = item.results || item.result;
                            this.bet = item.bet;
                            this.betOutcome = item.betOutcome;
                            this.matchId = item.matchId;
                            this.timestamp = item.matchDateTimeUTC;
                            this.isMatchStarted = item.isMatchStarted;
                            this.sport = {
                                id: item.idSport,
                                title: item.sportName,
                            };
                            this.selections = item.selections;
                            this.isCustomBet = item.selections && !!item.selections.length;
                            break;
                    }
                }

                angular.forEach(
                    list,
                    function (item, i) {
                        this.push(new Bet(item, i));
                    },
                    items
                );

                return items;
            },
            parseTicketBetSpecialName: function (name, special, grouped) {
                // Check special
                if (!special || special.length === 0 || special === '*' || grouped) {
                    return name;
                }

                var replaced = false;

                // Define patterns
                var patterns = ['[X]', '[Y]', '[Z]', '[Q]'],
                    parts = special.toString().split('|'),
                    pattern,
                    i;

                // Loop parts and replace special value
                for (i = 0; i < parts.length; i += 1) {
                    pattern = patterns[i];
                    if (name.indexOf(pattern) >= 0) {
                        name = name.replace(pattern, parts[i]);
                        replaced = true;
                    }
                }

                // Set special value to the end if no replace occurred
                if (!replaced) {
                    name = name + ' (' + special + ')';
                }

                // Return transformed
                return name;
            },
        };

        return service;
    }]
);

SEVEN.service(
    'SEVENRouteService',
    ['SEVENModules', '$location', '$window', '$state', '$urlMatcherFactory', function (SEVENModules, $location, $window, $state, $urlMatcherFactory) {
        var modules = SEVENModules,
            queryParams = {};

        return {
            persistQueryParams: function (params) {
                $location.search(angular.merge($location.search(), params));
            },
            getSavedQueryParam: function (paramName) {
                return queryParams[paramName];
            },
            saveQueryParams: function (paramNames) {
                var paramValue;

                paramNames.forEach(function (paramName) {
                    paramValue = $location.search()[paramName];
                    if (paramValue) {
                        queryParams[paramName] = paramValue;
                    }
                });
            },
            applyQueryParams: function (paramNames) {
                var params = {};

                paramNames.forEach(function (paramName) {
                    if (queryParams[paramName]) {
                        params[paramName] = queryParams[paramName];
                    }
                });
                this.persistQueryParams(params);
            },
            getQueryParamsObjectFromString: function (params) {
                var searchObject = {},
                    redirectParams = params ? params.split('&') : [],
                    paramStringSplit;

                // Turn query params strings into `key: value` pairs, applicable to $location.search()
                redirectParams.forEach(function (paramString) {
                    paramStringSplit = paramString.split('=');
                    searchObject[paramStringSplit[0]] = paramStringSplit[1];
                });

                // Returns empty object if no valid query params found
                return searchObject;
            },
            goToPageWithQueryParams: function (url) {
                var urlSplit = url.split('?'),
                    path = urlSplit[0],
                    search = this.getQueryParamsObjectFromString(urlSplit[1]);

                // If there are no query params, it will just redirect to path
                $location.path(path).search(search);
            },
            findStateDefinitionByStateName: function (stateName) {
                var stateDefinition = null;

                Object.keys(modules.routes).forEach(function (route) {
                    if (modules.routes[route].name === stateName) {
                        stateDefinition = modules.routes[route];
                    }
                });
                return stateDefinition;
            },
            findMatchingStateByUrl: function (url) {
                var states = $state.get();

                // url must be valid
                try {
                    var urlToCheck = new URL(url);
                } catch (e) {
                    return null;
                }

                var paramsForMatchingState = {};
                var matchingState = null;

                // First check is incoming url on same origin as our site
                if (urlToCheck.origin !== $window.location.origin) return null;

                var doesStateMatch = function (stateUrl, stateParams) {
                    var options = stateParams ? { params: stateParams } : {};
                    var matcher = $urlMatcherFactory.compile(stateUrl, options);
                    var params = matcher.exec(urlToCheck.pathname);

                    return params;
                };

                // Check do we have a state with matching parameters
                states.forEach(function (state) {
                    if (state.abstract) return null;

                    var matchParams = null;

                    if (state.urls) {
                        angular.forEach(state.urls, function (value) {
                            matchParams = doesStateMatch(value, state.params);
                            if (!matchParams) return;

                            matchingState = state;
                            paramsForMatchingState = matchParams;
                        });
                    } else {
                        matchParams = doesStateMatch(state.url, state.params);
                        if (!matchParams) return;

                        matchingState = state;
                        paramsForMatchingState = matchParams;
                    }
                });

                if (!matchingState) return null;

                return {
                    name: matchingState.name,
                    params: paramsForMatchingState,
                    pathname: urlToCheck.pathname,
                };
            },
        };
    }]
);

angular
    .module('SEVEN')
    .service(
        'SEVENSegmentAnalyticsService',
        ['$window', '$rootScope', '$location', 'SEVENModules', 'SEVENSentry', function ($window, $rootScope, $location, SEVENModules, SEVENSentry) {
            var plugin = SEVENModules.plugins && SEVENModules.plugins.segmentAnalytics;
            var isPluginEnabled = plugin && plugin.enabled;

            var getPluginExecutable = function () {
                return $window[plugin.executable];
            };
            var isPluginAvailable = function () {
                return isPluginEnabled && !!getPluginExecutable();
            };

            var trackEvent = function (event, eventParameters) {
                if (!isPluginAvailable()) return;

                getPluginExecutable().track(event, eventParameters);
            };

            var trackPaymentEvent = function (sevenEvent, data) {
                var eventMap = {
                    DepositPaymentStarted: 'Deposit started',
                    WithdrawalPaymentStarted: 'Withdrawal started',
                    DepositPaymentSuccess: 'Deposit success',
                    WithdrawalPaymentSuccess: 'Withdrawal success',
                    DepositPaymentFailed: 'Deposit Failed',
                    WithdrawalPaymentFailed: 'Withdrawal failed',
                };
                var parameters = {
                    paymentPlugin: data.provider,
                    Amount: data.amount,
                };

                trackEvent(eventMap[sevenEvent.name], parameters);
            };

            var trackPluginOpened = function (sevenEvent, data) {
                var eventMap = {
                    DepositPluginOpened: 'Deposit plugin opened',
                    WithdrawalPluginOpened: 'Withdrawal plugin opened',
                };
                var parameters = {
                    paymentPlugin: data.provider,
                    pageURL: data.pageURL,
                };

                trackEvent(eventMap[sevenEvent.name], parameters);
            };

            var onStateChangeSuccess = function (event, toState) {
                if (!isPluginAvailable()) return;

                var seoPage = $rootScope.seoPage;
                var stateData = toState.data;
                var title = (seoPage && seoPage.title) || stateData.seoTitle || stateData.title;
                var pageEventProperties = {
                    url: $location.absUrl(),
                    title: title,
                };

                angular.forEach($location.search(), function (value, key) {
                    if (key.match(/^utm_/)) {
                        pageEventProperties[key] = value;
                    }
                });

                getPluginExecutable().page(pageEventProperties);
            };

            var trackRegisterIntent = function () {
                trackEvent('Registration started');
            };

            var trackRegistrationSuccess = function (event, data) {
                trackEvent('Registration success', {
                    email: data.email,
                });
            };

            var trackTicketResolve = function (event, data) {
                if (!data.payin) {
                    SEVENSentry.captureMessage('Segment ticket event without payin amount', data);
                }

                trackEvent('Ticket payin', {
                    ticketId: data.id,
                    ticketStake: data.payin,
                    product: data.product,
                });
            };

            var identifyPlayer = function (event, data) {
                if (!isPluginAvailable()) return;

                getPluginExecutable().identify(data.playerProfile.Uuid);
            };

            var trackCasinoGameOpened = function (event, data) {
                trackEvent('Casino game open', {
                    productDisplayId: data.productDisplayId,
                    demo: data.demo,
                });
            };

            var trackCasinoGameClosed = function (event, data) {
                trackEvent('Casino game close', {
                    productDisplayId: data.productDisplayId,
                    demo: data.demo,
                });
            };

            var setupListeners = function () {
                $rootScope.$on('$stateChangeSuccess', onStateChangeSuccess);
                $rootScope.$on('SEVEN.PlayerLoggedIn', identifyPlayer);
                $rootScope.$on('SEVEN.RegisterClicked', trackRegisterIntent);
                $rootScope.$on('SEVEN.RegistrationSuccess', trackRegistrationSuccess);
                $rootScope.$on('TicketPayinCompleted', trackTicketResolve);
                $rootScope.$on('DepositPaymentStarted', trackPaymentEvent);
                $rootScope.$on('WithdrawalPaymentStarted', trackPaymentEvent);
                $rootScope.$on('DepositPaymentSuccess', trackPaymentEvent);
                $rootScope.$on('DepositPaymentFailed', trackPaymentEvent);
                $rootScope.$on('WithdrawalPaymentSuccess', trackPaymentEvent);
                $rootScope.$on('DepositPluginOpened', trackPluginOpened);
                $rootScope.$on('WithdrawalPluginOpened', trackPluginOpened);
                $rootScope.$on('WithdrawalPaymentFailed', trackPaymentEvent);
                $rootScope.$on('SEVEN.CasinoGameOpened', trackCasinoGameOpened);
                $rootScope.$on('SEVEN.CasinoGameClosed', trackCasinoGameClosed);
            };

            return {
                init: function () {
                    if (!isPluginEnabled) return;

                    setupListeners();
                },
            };
        }]
    );

SEVEN.service('SEVENSentry', ['SEVENUser', '$rootScope', function (SEVENUser, $rootScope) {
    var isSentryActive = window.SEVENGlobals.isSentryActive;
    var user = SEVENUser;

    var setUser = function () {
        Sentry.configureScope(function (scope) {
            scope.setUser({ id: user.id });
        });
    };

    var setListeners = function () {
        $rootScope.$on('SEVEN.UserChanged', setUser);
        $rootScope.$on('ocLazyLoad.componentLoaded', function (event, data) {
            // register type is for controllers and services.
            // There are other types like directive that aren't relevant and will clutter breadcrumbs
            if (data[1] !== 'register') return;

            Sentry.addBreadcrumb({
                category: 'dependencyLoad',
                message: 'ocLazyLoad.componentLoaded',
                level: 'debug',
                data: {
                    data: data,
                },
            });
        });
        $rootScope.$on('ocLazyLoad.moduleLoaded', function (event, data) {
            Sentry.addBreadcrumb({
                category: 'dependencyLoad',
                message: 'ocLazyLoad.moduleLoaded',
                level: 'debug',
                data: {
                    data: data,
                },
            });
        });
        $rootScope.$on('ocLazyLoad.moduleReloaded', function (event, data) {
            Sentry.addBreadcrumb({
                category: 'dependencyLoad',
                message: 'ocLazyLoad.moduleReloaded',
                level: 'debug',
                data: {
                    data: data,
                },
            });
        });
    };

    return {
        init: function () {
            if (!isSentryActive) return;

            setUser();
            setListeners();
        },
        captureException: function (exception) {
            if (!isSentryActive) return;

            // If it's an http error, don't send those caused by no connection
            if (
                angular.isDefined(exception.status) &&
                (exception.status === -1 || exception.status === 0)
            )
                return;

            Sentry.withScope(function (scope) {
                scope.setExtra('error', exception);

                Sentry.captureException(exception);
            });
        },
        captureMessage: function (message, data) {
            if (!isSentryActive) return;

            // If it's an http error, don't send those caused by no connection
            if (angular.isDefined(data.status) && (data.status === -1 || data.status === 0)) return;

            Sentry.withScope(function (scope) {
                scope.setExtra('data', data);

                Sentry.captureMessage(message);
            });
        },
    };
}]);

// Socket factory
SEVEN.factory('SEVENSocket', ['$q', '$timeout', 'socketFactory', 'SEVENConfig', 'SEVENSettings', function ($q, $timeout, socketFactory, SEVENConfig, SEVENSettings) {
    // Create a promise instance
    var socketPromise = $q.defer(),
        config,
        settings,
        query,
        socketConfig;

    // Resolve in next digest cycle
    $timeout(function () {
        // Create the socket
        var socketInstance = function (prefix) {
            // Reference dependencies
            config = SEVENConfig;
            settings = SEVENSettings;

            // Configure socket
            query = 'token={token}&username={id}&clientType={type}&clientSubType={subtype}';
            socketConfig = {
                query: query.supplant({
                    token: config.ncm.auth.token,
                    id: config.ncm.auth.UUID,
                    type: settings.platform.clientType,
                    subtype: settings.platform.clientSubType,
                }),
                forceNew: true,
            };
            // Force transport through WebSocket only, if supported in browser
            if (window.WebSocket) {
                socketConfig.transports = ['websocket'];
            }

            // Return socket
            return socketFactory({
                ioSocket: io.connect(config.ncm.url, socketConfig),
                prefix: 'SEVEN' + (prefix ? prefix : '') + ':',
            });
        };

        // Resolve the promise
        socketPromise.resolve(socketInstance);
    });

    // Return the promise
    return socketPromise.promise;
}]);

SEVEN.service('SEVENTimezoneService', ['$location', 'SEVENUser', function ($location, SEVENUser) {
    var user = SEVENUser;

    var timezoneExists = function (tz) {
        return moment.tz.zone(tz);
    };

    var timezoneChanged = function (tz) {
        return tz !== user.profile.timezone;
    };

    return {
        setPlayerTimezone: function (timezone) {
            if (timezoneExists(timezone) && timezoneChanged(timezone)) {
                user.updateProfile({
                    timezone: timezone,
                });
            }
        },
    };
}]);

SEVEN.service('SEVENToken', ['locker', 'SEVENSettings', function (locker, SEVENSettings) {
    var settings = SEVENSettings;

    function TokenService() {
        this.token = null;
    }

    TokenService.prototype.getToken = function () {
        if (settings.mode === 'integration') {
            return this.token;
        }
        return locker.get('Token');
    };

    TokenService.prototype.setToken = function (token) {
        if (settings.mode === 'integration') {
            this.token = token;
        } else {
            locker.put('Token', token);
        }
    };

    TokenService.prototype.removeToken = function () {
        if (settings.mode === 'integration') {
            this.token = null;
        } else {
            locker.forget('Token');
        }
    };

    return new TokenService();
}]);

SEVEN.service('SEVENUiService', ['$rootScope', 'SEVENSettings', function ($rootScope, SEVENSettings) {
    var settings = SEVENSettings;
    var isMobileDevice = settings.isMobile;
    var isTabletDevice = window.isMobile.tablet;
    var isTerminalLayout = settings.layout && settings.layout === 'terminal';
    var uiProperties = ['footer', 'fixed'];
    var uiState = {
        footer: null,
        fixed: null,
    };

    var setListeners = function () {
        $rootScope.$on('$stateChangeSuccess', function (event, toState) {
            uiProperties.forEach(function (uiProperty) {
                var uiPropertyValue = toState.data && toState.data[uiProperty];

                switch (typeof uiPropertyValue) {
                    case 'object':
                        var uiPropertyLayoutValue;

                        if (isTabletDevice) {
                            uiPropertyLayoutValue = uiPropertyValue.tablet;
                        } else if (isMobileDevice) {
                            uiPropertyLayoutValue = uiPropertyValue.mobile;
                        } else if (isTerminalLayout) {
                            uiPropertyLayoutValue = uiPropertyValue.terminal;
                        } else {
                            uiPropertyLayoutValue = uiPropertyValue.desktop;
                        }

                        uiState[uiProperty] = Boolean(uiPropertyLayoutValue);
                        break;
                    case 'undefined':
                    case 'boolean':
                        uiState[uiProperty] = Boolean(uiPropertyValue);
                        break;
                    default:
                        uiState[uiProperty] = false;
                }
            });
        });
    };

    return {
        init: function () {
            setListeners();
        },
        uiState: uiState,
    };
}]);

SEVEN.service('SEVENVaixTracker', ['$rootScope', '$http', 'SEVENUser', 'SEVENSettings', function ($rootScope, $http, SEVENUser, SEVENSettings) {
    var user = SEVENUser;
    var settings = SEVENSettings;

    var deviceType = settings.isMobile ? 'Mobile' : 'Desktop';
    var vaixTrackerApiUrl = settings.api.vaixTracker.url[settings.server];
    var authorizationToken = settings.vaixTracker && settings.vaixTracker.authToken;
    var clientId = settings.vaixTracker && settings.vaixTracker.clientId;
    var isVaixTrackerEnabled = settings.vaixTracker && settings.vaixTracker.enabled;

    var VAIX_IMPRESSIONS_EVENT = 'VaixImpressions';

    var setupListeners = function () {
        $rootScope.$on('SEVEN.CasinoGameOpened', function (_e, game) {
            if (!isVaixTrackerEnabled || !game.isVaixEvent) return;

            var data = {
                user_id: user.id,
                game_id: game.productDisplayId,
                position: game.position ? game.position.toString() : null,
                page: game.page,
                swim_lane: game.swimLane || '',
                channel: deviceType,
            };

            $http.post(
                vaixTrackerApiUrl,
                {
                    data: data,
                    event_type: 'clicks:game',
                    user_id: user.id,
                },
                {
                    headers: {
                        'x-vaix-client-id': clientId,
                        Authorization: 'Bearer ' + authorizationToken,
                    },
                    withCredentials: false,
                }
            );
        });

        $rootScope.$on('SEVEN.AnalyticsEvent', function (_e, data) {
            if (!isVaixTrackerEnabled || data.type !== VAIX_IMPRESSIONS_EVENT) return;

            var content = data.content[0];

            var gameImpressionData = {
                user_id: user.id,
                game_ids: content.value.gameIds,
                page: content.value.page,
                swim_lane: content.id,
                channel: deviceType,
            };

            $http.post(
                vaixTrackerApiUrl,
                {
                    data: gameImpressionData,
                    event_type: 'impressions:games',
                    user_id: user.id,
                },
                {
                    headers: {
                        'x-vaix-client-id': clientId,
                        Authorization: 'Bearer ' + authorizationToken,
                    },
                    withCredentials: false,
                }
            );
        });
    };

    return {
        init: function () {
            if (!isVaixTrackerEnabled) return;

            setupListeners();
        },
    };
}]);

// Vibrate API service
SEVEN.service('SEVENVibrator', function () {
    // Check support
    var supported = function () {
        // Standard support
        if ('vibrate' in window.navigator) {
            return true;
        }

        // Support with vendor prefixes
        var vendors = ['webkit', 'moz', 'ms'],
            vendor,
            i;
        for (i = 0; i < vendors.length; i++) {
            vendor = vendors[i] + 'Vibrate';
            if (vendor in window.navigator) {
                window.navigator.vibrate = window.navigator[vendor];
                return true;
            }
        }
    };

    return {
        // Vibrate with pattern number or number[]
        vibrate: function (pattern) {
            if (supported()) {
                pattern = pattern || 500;
                window.navigator.vibrate(pattern);
            }
        },
        vibrateStart: function (pattern, interval) {
            var self = this;
            window.vibrateInterval = window.setInterval(function () {
                self.vibrate(pattern);
            }, interval);
        },
        vibrateStop: function () {
            if (window.vibrateInterval) {
                window.clearInterval(window.vibrateInterval);
            }
            window.navigator.vibrate(0);
        },
    };
});

SEVEN.service('SEVENWidgetService', ['$window', '$log', function ($window, $log) {
    var _widgets = {};

    var CONDITIONS = {
        ANYOF: {
            /**
             *
             * @param {Object} whenDefinition
             * @param {String} whenScopeData.condition
             * @param {Array} whenDefinition.values
             * @param {Number | String} scopeValue
             */
            validate: function (whenDefinition, scopeValue) {
                return whenDefinition.values.indexOf(scopeValue) >= 0;
            },
        },
        ALLOFF: {
            /**
             *
             * @param {Object} whenDefinition
             * @param {String} whenScopeData.condition
             * @param {Array} whenDefinition.values
             * @param {Array} scopeValue
             */
            validate: function (whenDefinition, scopeValue) {
                var result = scopeValue.filter(function (value) {
                    return whenDefinition.values.indexOf(value) >= 0;
                });
                return result.length === whenDefinition.values.length;
            },
        },
    };

    return {
        registerWidget: function (widget, key) {
            if (key.startsWith('widget.')) {
                var localWidget = null;
                var widgetDefinition = null;
                var widgetKey = key.replace('widget.', '');

                try {
                    // register widget through localStorage will not work when localStorage is not avilable
                    localWidget = JSON.parse($window.localStorage.getItem(key));
                } catch (e) {
                    $log.debug('[Seven] Cannot override widget from localStorage: error => ', e);
                }

                widgetDefinition = localWidget || widget;

                if (widgetDefinition && widgetDefinition.active) {
                    _widgets[widgetKey] = angular.copy(widgetDefinition);
                }
            }
        },
        registerWidgetsFromAppSettings: function (applicationSettings) {
            var self = this;
            angular.forEach(applicationSettings, function (appSetting, key) {
                self.registerWidget(appSetting, key);
            });
        },
        getWidgetByKey: function (key) {
            return _widgets[key];
        },
        getAll: function () {
            return _widgets;
        },
        /**
         * Get widet by type and filter it by scope data.
         *
         * @param {'BETTING_AREA'|'TICKET_DETAILS'} type - It can be BETTING_AREA, TICKET_DETAILS
         * @param {Object} whenScopeData
         * @param {String} whenScopeData.condition
         * @param {String | Number | Array} whenScopeData.value - Value to compare with widget when definition
         */
        getByType: function (type, whenScopeData) {
            var widgets = this.getAll();
            var foundWidget = null;
            Object.keys(widgets).forEach(
                function (key) {
                    var widget = widgets[key];
                    if (
                        widget.type === type &&
                        this._validateCondition(widget.when, whenScopeData.value) &&
                        widget.active === true
                    ) {
                        foundWidget = widget;
                    }
                }.bind(this)
            );

            return foundWidget;
        },

        /**
         *
         * @param {Object} whenDefinition
         * @param {Array} whenDefinition.values
         * @param {String} whenDefinition.condition
         * @param {String | Number | Array} scopeValue
         */
        _validateCondition: function (whenDefinition, scopeValue) {
            return CONDITIONS[whenDefinition.condition.toUpperCase()].validate(
                whenDefinition,
                scopeValue
            );
        },
    };
}]);

SEVEN.service(
    'SEVENZiqni',
    ['$rootScope', '$window', 'SEVENModules', 'SEVENPlugins', 'SEVENLocale', 'SEVENUser', function ($rootScope, $window, SEVENModules, SEVENPlugins, SEVENLocale, SEVENUser) {
        var pluginsService = SEVENPlugins,
            user = SEVENUser,
            locale = SEVENLocale,
            plugins = SEVENModules.plugins;

        var plugin = plugins && plugins.ziqni;
        var isPluginEnabled = plugin && plugin.enabled;
        var pluginExecutableName = plugin && plugin.executable;
        var isPluginLoading = false;
        var isPluginLoaded = false;

        var isPluginAvailable = function () {
            var isPluginExecutable = pluginExecutableName && $window[pluginExecutableName];
            return isPluginEnabled && isPluginExecutable;
        };

        var loadZiqniPlugin = function () {
            isPluginLoading = true;

            pluginsService
                .loadPlugin(plugins.ziqni, {
                    memberId: user.id,
                    language: locale.activeLanguage,
                })
                .then(function () {
                    isPluginLoading = false;
                    isPluginLoaded = true;

                    showWidgetWhenPluginExecutableLoads();
                    setListeners();
                    setWatchers();
                });
        };

        var showWidget = function () {
            if (!isPluginLoaded && !isPluginLoading) {
                return loadZiqniPlugin();
            }
            if (!isPluginAvailable()) {
                return showWidgetWhenPluginExecutableLoads();
            }

            $window[pluginExecutableName].showWidget();
        };

        var showWidgetWhenPluginExecutableLoads = function () {
            const unregisterWatcher = $rootScope.$watch(
                function () {
                    return $window[pluginExecutableName];
                },
                function (pluginExecutable) {
                    if (pluginExecutable) {
                        unregisterWatcher();

                        pluginExecutable.showWidget();
                    }
                }
            );
        };

        var doesPluginExist = function () {
            return !!isPluginEnabled;
        };

        var closeWidget = function () {
            $window[pluginExecutableName].closeEverything();
        };

        var stopWidgetActivity = function () {
            $window[pluginExecutableName].stopActivity();
        };

        var logoutWidgetUserAndStopWidgetActivity = function () {
            $window[pluginExecutableName].unloadMemberAndStopActivity();
        };

        var setWidgetUser = function () {
            $window[pluginExecutableName].setMemberId(user.id);
        };

        var setListeners = function () {
            $window.addEventListener('cl-widget-close', handleCloseWidgetMessage);
        };

        var handleCloseWidgetMessage = function () {
            stopWidgetActivity();
        };

        var setWatchers = function () {
            $rootScope.$on('SEVEN.UserLogin', function (event, isLogged) {
                if (!isPluginLoaded) return;

                if (isLogged) {
                    setWidgetUser();
                } else {
                    closeWidget();
                    logoutWidgetUserAndStopWidgetActivity();
                }
            });
        };

        return {
            showWidget: showWidget,
            isPluginAvailable: isPluginAvailable,
            doesPluginExist: doesPluginExist,
        };
    }]
);

SEVEN.component('sevenProductHeader', {
    bindings: {},
    controllerAs: 'vm',
    templateUrl: 'app/shared/seven-product-header/view.html',
    controller: ['$state', 'SevenGravitySettings', 'SEVENIntegrator', 'SEVENSettings', function ($state, SevenGravitySettings, SEVENIntegrator, SEVENSettings) {
        var vm = this,
            appSettings = SevenGravitySettings,
            integrator = SEVENIntegrator,
            settings = SEVENSettings;

        var init = function () {
            vm.widgetSettings = appSettings.getDataByKey('widget.productHeader');
            vm.title = $state.current.data.title;
            vm.goBack = goBack;

            vm.isShown = shouldBeShown();
        };

        var shouldBeShown = function () {
            return vm.widgetSettings && vm.widgetSettings.active && settings.mode === 'integration';
        };

        var goBack = function () {
            integrator.sendMessage({
                type: 'navigateBack',
            });
        };

        vm.$onInit = init;
    }],
});

SEVEN.component('sevenWidget', {
    bindings: {
        widgetSettings: '<',
        productId: '<',
        widgetLoading: '<',
        frameId: '@',
        data: '<',
        onFinally: '<',
        autoResize: '<',
        fullContentHeight: '<',
        iframeScrolling: '@',
        allowHotjar: '<',
    },
    template:
        '<iframe ng-ref="vm.iframeElement" id="{{vm.frameId}}" class="content-plugin" ng-src="{{vm.url}}" data-type="slave-frame" frameborder="0" scrolling="{{vm.scrolling}}" allow="{{vm.permissionsPolicy}}" ng-attr-data-hj-allow-iframe="{{vm.allowHotjar}}"></iframe>',
    controllerAs: 'vm',
    controller: ['$element', '$document', '$timeout', '$location', '$sce', '$scope', '$window', '$state', 'SEVENSettings', 'SEVENConfig', 'SEVENGatewayService', 'SEVENIntegrator', 'SEVENUiService', 'SEVENToken', 'SEVENLocale', 'SEVENZiqni', function (
        $element,
        $document,
        $timeout,
        $location,
        $sce,
        $scope,
        $window,
        $state,
        SEVENSettings,
        SEVENConfig,
        SEVENGatewayService,
        SEVENIntegrator,
        SEVENUiService,
        SEVENToken,
        SEVENLocale,
        SEVENZiqni
    ) {
        var vm = this,
            source,
            config,
            settings = SEVENSettings,
            sevenConfig = SEVENConfig,
            gatewayService = SEVENGatewayService,
            integrator = SEVENIntegrator,
            tokenService = SEVENToken,
            uiService = SEVENUiService,
            locale = SEVENLocale,
            ziqniService = SEVENZiqni,
            permissionsPolicyItems = ['autoplay', 'fullscreen'],
            sourceParams = {
                integrationType: 'gravityGateway',
                platform: 'seven',
                application: 'web',
            },
            setURLTimeout;

        var init = function () {
            setThemeVariantSourceParam();
            setWidgetVisualViewportChangeListeners();

            source = vm.widgetSettings && vm.widgetSettings.source[0];
            config = source && source.config;

            setScrolling();
            vm.frameId = vm.frameId || (config && config.frameId);

            if (vm.widgetSettings && vm.widgetSettings.framePermissionsPolicy) {
                permissionsPolicyItems = permissionsPolicyItems.concat(
                    vm.widgetSettings.framePermissionsPolicy
                );
            }

            vm.permissionsPolicy = permissionsPolicyItems.join('; ') + ';';

            setURLTimeout = $timeout(function () {
                vm.url = setSlaveUrl(); //since allow permissions policy is dynamic, we need to offset url loading for a bit - https://stackoverflow.com/questions/68616159/set-feature-policy-to-iframe-after-insertion
            }, 0);

            if (config && config.useGateway !== false) {
                addGatewaySlave(config);
            }

            if (vm.widgetSettings.fullWidth) {
                $scope.$emit('SEVEN.ToggleFullWidth', true);
            }
        };

        var parseSlaveUrlFromConfig = function () {
            var url = source && source.url;

            if (typeof url === 'object') {
                url = url[settings.isMobile ? 'mobile' : 'desktop'];
            }

            if (!url) return null;

            url = url.supplant(getURLSupplantData());

            return url;
        };

        /**
         * Handles dynamic route parameters for integration (param1, param2...)
         */
        var handleRouteParams = function () {
            if (!$state.params) return;

            var configuredParams = $state.current.params;

            for (var key in $state.params) {
                if (!$state.params[key] || !configuredParams[key]) continue;

                if (configuredParams[key].propagateDown)
                    sourceParams[key] = encodeURIComponent($state.params[key]);
            }
        };

        /**
         * Generates qp based on source params and widget config for global query inclusion
         * @returns {String}
         */
        var generateQuery = function () {
            var query = '';

            for (var key in sourceParams) {
                var connectingCharacter = query.length ? '&' : '';
                query += connectingCharacter + key + '=' + sourceParams[key];
            }

            if (vm.widgetSettings.includeQuery) {
                var globalQuery = $window.location.search;
                if (globalQuery && globalQuery.length > 0) {
                    if (globalQuery.substring(0, 1) === '?') {
                        globalQuery = globalQuery.replace('?', '&');
                    }
                }

                query += globalQuery;
            }

            return query;
        };

        /**
         *
         * @param {Object} config
         * @param {Boolean} config.autoResize
         */
        var addGatewaySlave = function (config) {
            var hasAutoResize = typeof vm.autoResize !== 'undefined';
            var autoResize = hasAutoResize ? vm.autoResize : config.autoResize !== false;

            var slaveConfig = {
                slaveId: vm.frameId,
                frameId: vm.frameId,
                autoResize: autoResize,
                debug: settings.debug,
                init: function (message) {
                    if (message && message.data && message.data.settings) {
                        vm.settings = angular.copy(message.data.settings);
                    }
                },
                load: function () {},
                loaded: vm.onFinally,
                data: getSlaveLoadData(),
            };

            gatewayService.addSlave(slaveConfig);
        };

        var setSlaveUrl = function () {
            var url = parseSlaveUrlFromConfig();

            if (!url) return null;

            handleRouteParams();
            var query = generateQuery();
            var connectingCharacter = '&';

            if (!url.includes('?')) {
                connectingCharacter = '?';
            }

            url += connectingCharacter + query;

            return $sce.trustAsResourceUrl(url);
        };

        var getSlaveLoadData = function () {
            var fullContentHeight = angular.isDefined(vm.fullContentHeight)
                ? vm.fullContentHeight
                : !uiService.uiState.fixed;
            var height = window.innerHeight - $element[0].getBoundingClientRect().top;
            var widgetVisualViewport = {
                height: height,
            };

            return angular.merge({}, vm.data, {
                settings: {
                    fullContentHeight: fullContentHeight,
                    scrollable: !fullContentHeight,
                    visualViewport: widgetVisualViewport,
                    gamification: {
                        ziqni: ziqniService.doesPluginExist(),
                        fastDeposit:
                            settings.company.fastDeposit && settings.company.fastDeposit.enabled,
                    },
                },
            });
        };

        var getURLSupplantData = function () {
            var product = sevenConfig.productsV2[vm.productId] || {};
            var vmData = {};
            var ancestorOrigins = window.location.ancestorOrigins;
            var topURL =
                (ancestorOrigins && ancestorOrigins[ancestorOrigins.length - 1]) ||
                (document.referrer && new URL(document.referrer).origin) ||
                $location.absUrl();
            var topLocationUrl = (topURL && window.encodeURIComponent(topURL)) || null;

            var baseData = {
                companyId: settings.company.id,
                tenantUuid: settings.company.id,
                lang: locale.activeLanguage,
                language: locale.activeLanguage,
                isMobile: settings.isMobile.toString(),
                companyName: settings.company.baseName || settings.company.name,
                tpToken: integrator.getThirdPartyToken(),
                token: tokenService.getToken(),
                productDisplayId: product.name || null,
                linkId: product.cm ? product.cm.channel : null,
                topReferrerUrl: topLocationUrl,
            };

            angular.forEach(vm.data, function (value, key) {
                vmData[key] = typeof value === 'boolean' ? value.toString() : value;
            });

            return angular.extend(baseData, vmData);
        };

        var onChanges = function (changes) {
            if (!changes.widgetSettings || changes.widgetSettings.isFirstChange()) return;

            // if widget is changed remove previous widget config and set up new one
            var currentWidgetName = changes.widgetSettings.currentValue.name;
            var previousWidget = changes.widgetSettings.previousValue;
            if (previousWidget.name !== currentWidgetName) {
                gatewayService.removeSlave(previousWidget.source[0].config.frameId);
                vm.frameId = null;
                init();
            }
        };

        var setThemeVariantSourceParam = function () {
            var themeVariant = $location.search().themeVariant;

            if (!themeVariant || vm.widgetSettings.includeQuery) return;

            sourceParams.themeVariant = themeVariant;
        };

        var onDestroy = function () {
            $timeout.cancel(setURLTimeout);
            if (config && config.destroy !== false) {
                gatewayService.removeSlave(vm.frameId);
                $scope.$emit('SEVEN.ToggleFullWidth', false);
            }
        };

        var setScrolling = function () {
            if (vm.iframeScrolling) {
                vm.scrolling = vm.iframeScrolling;
            } else {
                vm.scrolling = settings.isMobile && uiService.uiState.fixed ? 'auto' : 'no';
            }
        };

        var setupIframeIntersectionObserver = function () {
            var unwatchIframeElementRef = $scope.$watch(
                'vm.iframeElement',
                function (iframeElement) {
                    if (!iframeElement) return;

                    var iframeIntersectionObserver = new IntersectionObserver(
                        function (entries) {
                            emitVisualViewportChanges(entries[0].boundingClientRect.top);
                        },
                        {
                            threshold: generateIntersectionObserverThresholds(),
                            root: document.body,
                        }
                    );

                    iframeIntersectionObserver.observe(iframeElement[0]);
                    unwatchIframeElementRef();
                }
            );
        };

        var setupBodyResizeObserver = function () {
            var bodyResizeObserver = new ResizeObserver(function () {
                if (!vm.iframeElement) return;

                emitVisualViewportChanges(vm.iframeElement[0].getBoundingClientRect().top);
            });

            bodyResizeObserver.observe(document.body);
        };
        var emitVisualViewportChanges = function (top) {
            var height = window.innerHeight - top;

            gatewayService.emitSettingsChanged({
                visualViewport: {
                    height,
                },
            });
        };

        var generateIntersectionObserverThresholds = function () {
            var thresholds = [];

            for (var i = 0; i <= 1.0; i += 0.01) {
                thresholds.push(i);
            }

            return thresholds;
        };

        var setWidgetVisualViewportChangeListeners = function () {
            setupIframeIntersectionObserver();
            setupBodyResizeObserver();
        };

        vm.$onInit = init;
        vm.$onChanges = onChanges;
        vm.$onDestroy = onDestroy;
    }],
});

SEVEN.component('smPrematchTicketItem', {
    bindings: {
        ticket: '=',
        mode: '=',
        mobile: '<?',
        componentClass: '<?',
    },
    controllerAs: 'smTicketItemVm',
    template: '<div ng-include="getTemplateUrl()"></div>',
    controller: /*@ngInject*/ ['$http', '$scope', 'SEVENConfig', 'SEVENModules', 'SEVENUser', 'SEVENModalSvc', function (
        $http,
        $scope,
        SEVENConfig,
        SEVENModules,
        SEVENUser,
        SEVENModalSvc
    ) {
        var vm = this,
            config = SEVENConfig;

        var init = function () {
            vm.selectedTicket = '';
            vm.userTimezoneOffset = SEVENUser.profile.timezoneOffset;
            vm.messages = config.messages.general;
            vm.statusMessages = config.messages['ticket.status'];
            vm.ticketHasWays = false;
            if (!vm.mode) {
                vm.mode = 'history';
            }
        };

        vm.selectTicket = function (ticketId) {
            vm.selectedTicket = ticketId !== vm.selectedTicket ? ticketId : '';
        };

        vm.isTicketBonusAvailable = function (ticket) {
            if (!ticket.ticketBonusEligibles) return false;
            var items = ticket.ticketBonusEligibles.filter(function (item) {
                if (item.type === 2 && item.eligible === 1) return true;
            });

            return items.length > 0 && ticket.status === 'LOST';
        };

        var formatTicketCheckData = function (ticket) {
            var ticketUnits = [];
            var ticketBets = [];
            // Format hash
            var ticketHash = ticket.ticketHashes.find(function (hash) {
                return hash.type.toLowerCase() === 'normal';
            });
            // Format bonus
            var bonus = {
                maxBonus: 0,
                minBonus: 0,
                maxBonusPercentage: '0',
            };
            if (ticket.ticketAppliedBonuses.length) {
                var bonusType = 1;
                var status = ticket.status.id.toLowerCase();
                if (status === 'won' || status === 'paidout') {
                    bonusType = 3;
                }
                var ticketBonus = ticket.ticketAppliedBonuses.find(function (value) {
                    return value.type === bonusType;
                });
                if (ticketBonus) {
                    bonus.maxBonus = ticketBonus.amountMax;
                    bonus.minBonus = ticketBonus.amountMin;
                    bonus.maxBonusPercentage = ticketBonus.maxPercentage;
                }
            }
            // Format units
            ticketUnits = ticket.ticketUnits.map(function (ticketUnit) {
                return {
                    id: ticketUnit.id,
                    independentBets: ticketUnit.independentBets,
                    numberOfCombinations: ticketUnit.numberOfCombinations,
                    oddMax: ticketUnit.oddMax,
                    oddMin: ticketUnit.oddMin,
                    resolutionStatus: ticket.status.value.toUpperCase(),
                    selectedSystems: ticketUnit.selectedSystems,
                    stakeAmount: ticketUnit.stakeAmount,
                    paymentAmount: ticketUnit.paymentAmount || ticketUnit.stakeAmount,
                    unitBets: ticketUnit.ticketUnitBets,
                    winningAmountMax: ticketUnit.winningAmountMax,
                    winningAmountMin: ticketUnit.winningAmountMin,
                };
            });
            // Format bets
            ticketBets = ticket.ticketBets.map(function (ticketBet) {
                var foundTicketUnitBet = {};
                ticket.ticketUnits.forEach(function (ticketUnit) {
                    ticketUnit.ticketUnitBets.forEach(function (ticketUnitBet) {
                        if (ticketUnitBet.betId === ticketBet.id) {
                            foundTicketUnitBet = ticketUnitBet;
                        }
                    });
                });
                var foundMatchResult = ticketBet.eventResults.find(function (results) {
                    return results.resultType.constName === 'FT';
                });
                return {
                    banker: foundTicketUnitBet.banker,
                    eventName: ticketBet.event.name,
                    iconCode: '',
                    marketName: ticketBet.market.name,
                    marketOutcomeName: ticketBet.marketOutcome.name,
                    odd: ticketBet.odd,
                    combineResolutionStatus: ticketBet.combineResolutionStatus.id.toUpperCase(),
                    resolutionStatus: ticketBet.combineResolutionStatus.id.toUpperCase(),
                    specialValues: ticketBet.specialValues,
                    eventMarketCompetitors: ticketBet.eventMarketCompetitors,
                    eventCompetitors: ticketBet.eventCompetitors,
                    results: foundMatchResult,
                    startAt: ticketBet.event.startsAt,
                    ticketBetId: ticketBet.id,
                    ways: foundTicketUnitBet.ways,
                    tournamentPrefix: ticketBet.tournament && ticketBet.tournament.prefix,
                };
            });

            return {
                bets: ticketBets,
                createdAt: ticket.createdAt,
                maxBonus: bonus.maxBonus,
                minBonus: bonus.minBonus,
                maxBonusPercentage: bonus.maxBonusPercentage,
                maxWinnings: ticket.winningAmountMax,
                minWinnings: ticket.winningAmountMin,
                normalTicketHash: ticketHash.hash,
                oddMax: ticket.oddMax,
                oddMin: ticket.oddMin,
                hasBonus:
                    ticket.hasBonus ||
                    (ticket.ticketAppliedBonuses && ticket.ticketAppliedBonuses.length),
                paidOutStatus: ticket.paidOutStatus,
                paymentTax: ticket.paymentTaxAmount,
                payment: ticket.paymentAmount,
                payout: ticket.paidOutAmount,
                payoutMax: ticket.displayPayoutAmount,
                payoutTax: ticket.paidOutTaxAmount,
                taxAuthorityId: ticket.taxAuthorityId,
                resolutionStatus: ticket.status.value.toUpperCase(),
                status: ticket.status.value.toUpperCase(),
                stake: ticket.stakeAmount,
                ticketId: ticket.id,
                type: ticket.type.toUpperCase(),
                winnings: ticket.winningAmountMax,
                units: ticketUnits,
                ticketBonusEligibles: ticket.ticketBonusEligibles,
            };
        };

        var ticketHasWays = function (ticket) {
            if (!ticket || (ticket && !ticket.bets)) return;

            return ticket.bets.find(function (bet) {
                return !!bet.ways;
            });
        };

        $scope.$watch(
            function () {
                return vm.ticket;
            },
            function (newValue) {
                vm.ticketBonusAvailable = vm.isTicketBonusAvailable(newValue);
                if (vm.mode === 'ticketCheck' && newValue && newValue.ticketHashes) {
                    vm.ticket = formatTicketCheckData(newValue);
                } else {
                    vm.ticketHasWays = !!ticketHasWays(newValue);
                }
            }
        );

        $scope.getTemplateUrl = function () {
            var view = vm.mobile ? '-view-mob.html' : '-view.html';
            return SEVENModules.directives.smPrematchTicketItem + view;
        };

        $scope.openDetailsInModal = function (template) {
            SEVENModalSvc.showModal({
                templateUrl: template,
                controller: [
                    '$scope',
                    'close',
                    function (modalScope, close) {
                        vm.ticket.bets.forEach(function (bet) {
                            var competitors = bet.eventCompetitors.sort(function (a, b) {
                                return a.type - b.type;
                            });
                            bet.eventCompetitor1 = competitors[0].teamName;
                            bet.eventCompetitor2 = competitors[1].teamName;
                        });

                        // Bind to modal scope
                        modalScope.config = config;
                        modalScope.ticket = vm.ticket;
                        modalScope.statusMessages = vm.statusMessages;
                        modalScope.userTimezoneOffset = vm.userTimezoneOffset;

                        console.log('ticket:', vm.ticket);

                        // Set close function
                        modalScope.close = function () {
                            close();
                        };
                    },
                ],
            }).then(function (modal) {
                // Show modal
                modal.element.show();
            });
        };

        init();
    }],
});

SEVEN.directive(
    'sevenStreambox',
    ['$log', '$timeout', '$ocLazyLoad', 'uuid', 'SEVENSettings', 'SEVENConfig', 'SEVENHelpers', function ($log, $timeout, $ocLazyLoad, uuid, SEVENSettings, SEVENConfig, SEVENHelpers) {
        var settings = SEVENSettings,
            config = SEVENConfig,
            helpers = SEVENHelpers;

        var getProvider = function () {
            return {
                name: 'jwplayer',
                alternative: 'dashjs',
            };
        };

        return {
            restrict: 'E',
            replace: true,
            scope: {
                key: '@',
                url: '=',
                ratio: '=',
                header: '=?',
                headerTitle: '=?',
                controls: '=?',
                error: '=?',
                reload: '=?',
                mute: '=?',
                fullscreen: '=?',
            },
            templateUrl: function (element, attr) {
                return attr.template || settings.directory.app + 'shared/streambox/view.html';
            },
            link: function (scope, element) {
                var container = element.parent(),
                    elementId = 'streambox-' + scope.key,
                    provider = getProvider();

                var init = function () {
                    // Lazy load player libraries
                    if (config.client[provider.name]) {
                        $ocLazyLoad.load(config.client[provider.name].url).then(function () {
                            $timeout(function () {
                                scope.header = scope.header === true;
                                scope.controls = scope.controls === true;
                                scope.ratio = scope.ratio || '16:9';
                                scope.opened = true;
                                scope.autostart = true;
                                scope.loaded = false;

                                // Load on URL change
                                scope.$watch('url', function (currentUrl) {
                                    if (currentUrl) load();
                                });
                            });
                        });
                    }
                };

                var load = function () {
                    // Keep aspect ratio
                    setContainerSize();

                    // Check is player loaded
                    if (window.jwplayer) {
                        var player = window.jwplayer(elementId).setup({
                            primary: 'flash',
                            width: '100%',
                            file: scope.url,
                            title: scope.headerTitle || '',
                            aspectratio: scope.ratio,
                            controls: scope.controls,
                            autostart: scope.autostart,
                            mute: angular.isUndefined(scope.mute) ? true : scope.mute,
                            mediaid: uuid.v4(),
                        });

                        player.on('displayClick', toggleFullScreen);
                        player.on('all', playerEvent);

                        checkMinimumSize();
                        setLoaded();
                    }
                };

                var loadAlternative = function () {
                    // Clean primary player
                    var playerElement = element.find('#' + elementId);
                    playerElement.empty().removeClass();

                    if (config.client[provider.alternative]) {
                        $ocLazyLoad.load(config.client[provider.alternative].url).then(function () {
                            if (window.dashjs) {
                                var videoId = elementId + '-video',
                                    videoElement = $(
                                        '<video id="' + videoId + '" autoplay controls></video>'
                                    );

                                playerElement.append(videoElement);

                                var player = window.dashjs.MediaPlayer().create(),
                                    replacementUrl = scope.url
                                        .replace('.m3u8', '.mpd')
                                        .replace('http://', '//');

                                player.initialize(videoElement[0], replacementUrl, true);

                                $log.debug('[SEVEN] Playing Stream With DashJS =>', replacementUrl);
                            }
                        });
                    }
                };

                var resetSize = function () {
                    container.css({
                        width: 'auto',
                        height: 'auto',
                    });
                };

                var checkMinimumSize = function () {
                    // Hack to resize player
                    var minWidth = 464;
                    if (container.width() < minWidth) {
                        container.css({
                            width: minWidth,
                            height: 0,
                        });

                        $timeout(resetSize, 1000);
                    }
                };

                var playerEvent = function (name) {
                    switch (name) {
                        case 'ready':
                            $log.debug('[SEVEN] Playing Stream With jwPlayer =>', scope.url);
                            break;
                        case 'mediaError':
                        case 'setupError':
                            loadAlternative();
                            break;
                    }
                };

                var setContainerSize = function () {
                    var ratio = scope.ratio.split(':');
                    element.children('.streambox-container').css({
                        'padding-top': (ratio[1] / ratio[0]) * 100 + '%',
                    });
                };

                var setLoaded = function () {
                    scope.loaded = true;
                    scope.$emit('SEVEN.Rendered');
                };

                var toggleFullScreen = function () {
                    if (scope.fullscreen) {
                        helpers.toggleFullscreen(document.getElementById(elementId));
                    }
                };

                init();
            },
        };
    }]
);

// Ticket Check Directive
SEVEN.directive(
    'sevenTicketCheck',
    ['SEVENSettings', 'SEVENConfig', 'SEVENProductService', 'SEVENException', 'SEVENActiveGames', 'SEVENModalSvc', '$http', '$injector', '$rootScope', function (
        // Dependencies
        SEVENSettings,
        SEVENConfig,
        SEVENProductService,
        SEVENException,
        SEVENActiveGames,
        SEVENModalSvc,
        $http,
        $injector,
        $rootScope
    ) {
        // Declare variables
        var settings = SEVENSettings,
            config = SEVENConfig,
            service = SEVENProductService,
            modals = SEVENModalSvc,
            Exception = SEVENException,
            activeGames = SEVENActiveGames,
            messages = config.messages,
            gameConfigModule;

        var getGameConfigModule = function (activeGame) {
            var name = 'SEVEN' + activeGames.parseActiveGame(activeGame) + 'Config';
            return $injector.has(name) ? $injector.get(name) : angular.noop();
        };

        // Directive definition
        return {
            restrict: 'E',
            replace: true,
            scope: {
                activeGame: '@',
                rules: '=',
                modalTemplate: '@',
                format: '@',
                rebetOption: '@',
            },
            templateUrl: function (element, attr) {
                // Get passed template or default if not passed
                return attr.template || settings.directory.app + 'shared/ticket-check/view.html';
            },
            link: function (scope) {
                // Load current game config to access its generatedCodeCheck settings
                gameConfigModule = getGameConfigModule(scope.activeGame) || {};

                var isValidGeneratedCode = function () {
                    var generatedCodeConfig = gameConfigModule.generatedCodeCheck;

                    // If any of config is missing, generated code cannot be validated nor ticket fetched via code
                    if (
                        !generatedCodeConfig ||
                        !generatedCodeConfig.enabled ||
                        !generatedCodeConfig.regexRule ||
                        !generatedCodeConfig.url
                    ) {
                        return false;
                    }
                    var regex = new RegExp(generatedCodeConfig.regexRule);
                    return regex.test(scope.model.code);
                };

                var fetchTicketViaGeneratedCode = function () {
                    scope.processing = true;
                    var url = gameConfigModule.generatedCodeCheck.url.supplant({
                        code: scope.model.code,
                    });

                    $http
                        .get(url)
                        .then(function (response) {
                            scope.error = false;

                            var parsedTicket = service.parseTickets(
                                scope.activeGame,
                                service.getTicketType(scope.activeGame),
                                [response.data]
                            )[0];

                            // NOTE for future development:
                            // In order to implement Generated-Code-Check functionality in a game,
                            // each game for itself should handle following event
                            // and apply 'TicketRebet' functionality upon receiving this event.
                            $rootScope.$broadcast('SEVEN.PlaceGeneratedTicketBets', parsedTicket);
                        })
                        .catch(function (response) {
                            scope.error = Exception.renderMessage(response.data);
                        })
                        .finally(function () {
                            scope.processing = false;
                        });
                };

                // Check on server
                var checkTicket = function () {
                    scope.processing = true;
                    service
                        .getGlobalTicket(scope.model.code)
                        .then(function (response) {
                            scope.error = false;

                            // Ticket format
                            var format = scope.format || 'SEVEN',
                                rebet = scope.rebetOption,
                                // Parse data
                                data = response.data,
                                type,
                                tickets;

                            switch (format.toUpperCase()) {
                                case 'SEVEN':
                                    type = service.getTicketType(data.product);
                                    tickets = service.parseTickets(data.product, type, [data]);
                                    break;
                                case 'NAB':
                                    tickets = [data];
                                    break;
                                default:
                                    type = service.getTicketType(data.product);
                                    tickets = service.parseTickets(data.product, type, [data]);
                            }

                            // Open modal
                            if (tickets.length) {
                                modals
                                    .showModal({
                                        templateUrl: scope.modalTemplate
                                            ? scope.modalTemplate
                                            : settings.directory.app +
                                              'shared/tickets/view-' +
                                              type +
                                              '-ticket.html',
                                        controller: [
                                            '$scope',
                                            'close',
                                            function (scope, close) {
                                                // Bind to modal scope
                                                scope.ticket = tickets[0];
                                                scope.rebet = rebet || '';
                                                scope.messages = config.messages.general;
                                                scope.config = config;
                                                scope.close = function (result) {
                                                    close(result);
                                                };
                                            },
                                        ],
                                    })
                                    .then(function (modal) {
                                        modal.element.show();
                                    });
                            }
                        })
                        .catch(function (response) {
                            scope.error = Exception.renderMessage(response.data);
                        })
                        .finally(function () {
                            scope.processing = false;
                        });
                };

                // Set validation
                scope.validation = {
                    message: {
                        required: messages.general.validationRequired,
                        minlength: messages.general.validationMinLength,
                        maxlength: messages.general.validationMaxLength,
                    },
                    rule: {
                        required: true,
                        minlength: scope.rules && scope.rules.minlength ? scope.rules.minlength : 9,
                        maxlength: scope.rules && scope.rules.maxlength ? scope.rules.maxlength : 9,
                    },
                };

                // Bind messages
                scope.messages = {
                    title: messages.pages.playerTicketCheck,
                    button: messages.general.check,
                    input: messages.general.ticketCode,
                };

                scope.checkCode = function () {
                    if (isValidGeneratedCode()) {
                        fetchTicketViaGeneratedCode();
                    } else {
                        checkTicket();
                    }
                };
            },
        };
    }]
);

SEVEN.service('SEVENTicketPrintService', ['SEVENSettings', 'SEVENProductService', 'SEVENUser', function (SEVENSettings, SEVENProductService, SEVENUser) {
    var settings = SEVENSettings,
        user = SEVENUser,
        productService = SEVENProductService;

    return {
        isTicketPrintEnabled: function () {
            return settings.betslip.global.attachTicketPrint;
        },
        getTwigTemplate: function (input) {
            var isTicketPrintEnabled = this.isTicketPrintEnabled();

            var template = [];
            if (isTicketPrintEnabled) {
                var data = angular.copy(input);

                data.product = productService.getProductByName(input.product);

                template.push(data.product.title);
                template.push("{{ ticketDateTimeUTC | date('d.m.Y H:i:s', false) }}");

                var productBetslip = settings.betslip[data.product.name];
                if (
                    productBetslip &&
                    productBetslip.ticketPrintTemplateHook &&
                    angular.isFunction(productService[productBetslip.ticketPrintTemplateHook])
                ) {
                    var productOutput =
                        productService[productBetslip.ticketPrintTemplateHook](data);
                    if (productOutput) {
                        template.push(productOutput);
                    }
                }
            }

            return isTicketPrintEnabled
                ? {
                      printTemplate: template.join(';'),
                      timezone: {
                          offset: parseInt(user.profile.timezoneOffset),
                      },
                  }
                : null;
        },
    };
}]);

// Ticket directive
SEVEN.directive(
    'sevenTicket',
    ['$log', '$timeout', '$http', '$filter', '$rootScope', '$injector', '$window', 'locker', 'uuid', 'SEVENSettings', 'SEVENHelpers', 'SEVENConfig', 'SEVENRoutes', 'SEVENException', 'SEVENActiveGames', 'SEVENIntegrator', 'SEVENProductService', 'SEVENTicketPrintService', 'SEVENUser', 'SEVENLocale', function (
        $log,
        $timeout,
        $http,
        $filter,
        $rootScope,
        $injector,
        $window,
        locker,
        uuid,
        SEVENSettings,
        SEVENHelpers,
        SEVENConfig,
        SEVENRoutes,
        SEVENException,
        SEVENActiveGames,
        SEVENIntegrator,
        SEVENProductService,
        SEVENTicketPrintService,
        SEVENUser,
        SEVENLocale
    ) {
        // Reference dependencies
        var user = SEVENUser,
            settings = SEVENSettings,
            helpers = SEVENHelpers,
            locale = SEVENLocale,
            activeGames = SEVENActiveGames,
            productSrv = SEVENProductService,
            api = _7Ticket,
            config = SEVENConfig,
            routes = SEVENRoutes,
            exception = SEVENException,
            integrator = SEVENIntegrator,
            configModule;

        return {
            restrict: 'E',
            replace: true,
            scope: {
                oddType: '=',
            },
            templateUrl: function (element, attr) {
                // Get passed template or default if not passed
                return attr.template || settings.directory.app + 'shared/ticket/view.html';
            },
            controller: ['$scope', '$element', '$attrs', function ($scope, $element, $attrs) {
                // Bind scope properties
                $scope.betslips = $rootScope.betslips || [];
                $scope.oddType = $scope.oddType || 1;
                $scope.config = config;
                $scope.type = $attrs.type;
                $scope.disabled = false;
                $scope.dirty = false;
                $scope.showKeyboard = false;
                $scope.payinLoading = false;

                // Bind settings values
                $scope.ticketType = settings.betslip[$scope.type].ticketType;
                $scope.totalOdds = settings.betslip[$scope.type].totalOdds;
                $scope.bonus = settings.betslip[$scope.type].bonus;
                $scope.winnings = settings.betslip[$scope.type].winnings;
                $scope.stakePerSystem = angular.isUndefined(
                    settings.betslip[$scope.type].stakePerSystem
                )
                    ? false
                    : settings.betslip[$scope.type].stakePerSystem;
                $scope.future = settings.betslip[$scope.type].future;
                $scope.editable = settings.betslip[$scope.type].editable;
                $scope.odds = settings.betslip[$scope.type].odds;
                $scope.settings = settings.betslip.global;

                // Define product
                var product,
                    taxesLib = $window.gravity.tax,
                    taxesConfig = null;

                // Initialize messages
                var initMessages = function () {
                    // Ticket messages
                    $scope.message = {
                        1: {
                            type: 'Odd changed',
                            value: '',
                        },
                        2: {
                            type: 'Validation error',
                            value: '',
                        },
                        3: {
                            type: 'Betting disabled',
                            value: '',
                        },
                        4: {
                            type: 'Ticket added',
                            value: '',
                            link: false,
                        },
                    };
                };

                // Initialize messages
                initMessages();

                // Get message status
                $scope.getMessageStatus = function () {
                    var status = false;
                    angular.forEach($scope.message, function (text) {
                        if (text.value && text.value !== '' && !status) {
                            status = true;
                        }
                    });
                    return status;
                };

                // Get user profile property
                var getPlayerProfileProperty = function (name, convert, defaultValue) {
                    var value;
                    if (user.profile && user.profile[name]) {
                        value = user.profile[name];

                        if (convert) {
                            switch (convert) {
                                case 'integer':
                                    value = parseInt(value, 10);
                                    break;
                            }
                        }

                        return value;
                    }

                    return defaultValue;
                };

                // Define input update model
                var setInputUpdateModel = function () {
                    if (helpers.isTouchDevice()) {
                        // Virtual keyboard
                        $scope.virtualKeyboard = true;
                        $scope.updateInputModel = {
                            updateOn: 'blur',
                            debounce: {
                                blur: 0,
                            },
                        };
                    } else {
                        // No keyboard
                        $scope.virtualKeyboard = false;
                        $scope.updateInputModel = {
                            updateOn: 'default blur',
                            debounce: {
                                default: 2000,
                                blur: 0,
                            },
                        };
                    }
                };

                // Calculate rule condition
                var calculateRuleCondition = {
                    LT: function (x, y) {
                        return parseFloat(x, 10) < parseFloat(y, 10);
                    },
                    LTE: function (x, y) {
                        return parseFloat(x, 10) <= parseFloat(y, 10);
                    },
                    GT: function (x, y) {
                        return parseFloat(x, 10) > parseFloat(y, 10);
                    },
                    GTE: function (x, y) {
                        return parseFloat(x, 10) >= parseFloat(y, 10);
                    },
                };

                // Create betslip
                var createBetslip = function (type) {
                    // Local check variables
                    var betslips, betslip;

                    // Check ticket by type
                    betslips = $scope.betslips.filter(function (item) {
                        return item.type === type;
                    });

                    // Create betslip if it doesn't exist
                    if (betslips.length > 0) {
                        betslip = betslips[0];
                    } else {
                        betslip = { type: type };
                        $scope.betslips.push(betslip);
                    }

                    return betslip;
                };

                // Create betslip tabs
                var createBetslipTabs = function () {
                    var tabs = [],
                        tab;

                    if (settings.betslip[$scope.type].tickets) {
                        angular.forEach(settings.betslip[$scope.type].tickets, function (n) {
                            tab = {};
                            tab.label = config.messages.general[n.title];
                            tab.name = n.name;
                            tab.priority = n.priority;
                            tab.type = n.type;
                            tab.fix = n.fix;
                            tab.stake = n.stake;
                            tab.active = n.active;
                            tab.enabled = n.enabled;

                            if (tab.enabled) {
                                tabs.push(tab);
                            }
                        });
                    }
                    return tabs.sort(function (a, b) {
                        return a.priority - b.priority;
                    });
                };

                // Set active ticket
                var setActiveTicket = function () {
                    // Set active ticket
                    $scope.ticket = api
                        .ts()
                        .setActiveTicket($scope.type + $scope.activeTicket.name);
                    // Bind future
                    $scope.ticket.future =
                        $scope.ticket.betsCollection.bets[0] &&
                        $scope.ticket.betsCollection.bets[0].rounds
                            ? $scope.ticket.betsCollection.bets[0].rounds
                            : 1;
                    // Define tax
                    taxesConfig = configModule.taxes;
                    if (taxesConfig) {
                        $scope.ticket.taxes = {
                            payin: taxesLib.calculateTax(
                                taxesConfig.payin.policy,
                                $scope.ticket.stake
                            ),
                        };
                    }

                    // Check double bets
                    if ($scope.activeTicket.type !== '3') {
                        filterDoubleBets();
                    }

                    // Set default stake (will call getStake hook) and caulcaule winnings
                    $scope.ticket.setStake();

                    // Set default stake
                    if (configModule.rules.futureBet && configModule.rules.futureBet.active) {
                        $scope.future = configModule.rules.futureBet.active;
                    }

                    // Check payin
                    $scope.hasPayin = true;
                    $scope.hasCode = false;
                    if (configModule.ticket && configModule.ticket.payinTypes) {
                        $scope.hasPayin = configModule.ticket.payinTypes.indexOf('regular') > -1;
                        $scope.hasCode = configModule.ticket.payinTypes.indexOf('code') > -1;
                    }

                    // Reset stakeTouched on tab change
                    $scope.ticket.config.stakeTouched = false;
                    // Reset system
                    resetSystemSelections();
                    // Set stake and validate
                    isValidTicket();
                    notifyTicketChanged();
                };

                // Set default ticket
                var setDefaultStake = function (tab) {
                    var stake = {
                            global: null,
                            combination: null,
                        },
                        list,
                        len,
                        i;

                    tab = tab || $scope.activeTicket;

                    // Set from config
                    if (configModule && configModule.rules) {
                        if (configModule.rules.minBetAmount) {
                            // Virtual games
                            stake.global = configModule.rules.minBetAmount.value || 1;
                        } else {
                            // Sport
                            list = configModule.rules.list || configModule.rules;
                            if (list) {
                                len = list.length;
                                for (i = 0; i < len; i++) {
                                    if (
                                        list[i].name === '9' &&
                                        tab.type === list[i].ticketType.toString()
                                    ) {
                                        stake.combination = parseFloat(list[i].conditions[0].value);
                                    } else if (
                                        (list[i].group === 'min_ticket_payin' ||
                                            list[i].name === 'min_ticket_payin') &&
                                        tab.type === list[i].ticketType.toString()
                                    ) {
                                        stake.global = parseFloat(list[i].conditions[0].value);
                                    }
                                }
                            }
                        }
                    }
                    return stake;
                };

                // Toggle bets
                var toggleBets = function (items) {
                    // Check items type
                    var list = angular.isArray(items) ? items : [items];

                    // Loop items
                    var item, i;
                    for (i = 0; i < list.length; i += 1) {
                        item = list[i];
                        // Check if bet is added by unique key
                        if ($scope.ticket.betsCollection.getFirstBy(item.key, 'key') === false) {
                            // Set not dirty state
                            item.dirty = false;
                            item.dirtyType = 'same';
                            // Check bet parent key
                            if (
                                !item.keyParent ||
                                $scope.ticket.betsCollection.getFirstBy(
                                    item.keyParent,
                                    'keyParent'
                                ) === false ||
                                ($scope.ticketType !== 'games' && $scope.activeTicket.type === '3')
                            ) {
                                // Add new bets
                                item.uid = uuid.v4();
                                item.payin = item.amount || $scope.ticket.config.stake;
                                item.stake = item.amount || item.payin;
                                item.banker = false;
                                item.locked = false;
                                setFuture(item);
                                setEditMode(false);
                                resetSystemSelections();
                                $scope.ticket.addBets(item);
                            } else {
                                // Update existing bets
                                $scope.ticket.updateBets(item, 'keyParent');
                            }
                        } else {
                            if ($scope.edit) {
                                // Update existing
                                setFuture(item);
                                $scope.ticket.updateBets(item, 'key');
                                $scope.ticket.setStake();
                                setEditMode(false);
                            } else {
                                // Remove existing bet
                                $scope.removeBet(item);
                            }
                        }
                    }

                    // Refresh
                    isValidTicket();
                    isDirtyTicket();
                    notifyTicketChanged();
                    hideKeyboard();
                };

                // Set betslip type
                var setBetslipType = function () {
                    product = productSrv.getProductByName($scope.type);
                    configModule = getConfigModule();

                    // Create tabs
                    $scope.tabs = createBetslipTabs();
                    var i = $scope.tabs.length - 1;

                    for (i; i >= 0; i--) {
                        // Init ticket store
                        initialiseTicketStore($scope.tabs[i]);
                    }

                    // Find active tab
                    $scope.activeTicket = $scope.tabs.filter(function (tab) {
                        return tab.active === true;
                    })[0];

                    // Activate ticket
                    createBetslip($scope.type);
                    setActiveTicket();
                };

                // Create bonus
                var createBonus = function (data) {
                    var bonus = {};
                    bonus[data.game] = {
                        bonuses: data.bonuses,
                    };
                    _7Ticket.Bonuses.create(bonus);
                };

                // Initialize ticket
                var initialiseTicketStore = function (tab) {
                    var localTicketType = $filter('filter')(
                        settings.betslip[$scope.type].tickets,
                        {
                            name: tab.name,
                        },
                        true
                    )[0];

                    // if ticket already exist, do not proceed with creation
                    if (api.t($scope.type + tab.name)) return;

                    api.config()
                        .getConfig()
                        .use.push({
                            id: $scope.type + tab.name,
                            arg: [
                                {
                                    oddProp: 'odd',
                                    maxOddProp: 'maxOdd',
                                    bonusId: $scope.type,
                                    multiSystem: true,
                                    group: { id: $scope.type },
                                    hasSystem: localTicketType.system,
                                    stake:
                                        setDefaultStake(tab).global ||
                                        setDefaultStake(tab).combination ||
                                        1,
                                    combStake: setDefaultStake(tab).combination || 1,
                                    stakeTouched: false,
                                    setStakeStrategy: localTicketType.setStakeStrategy
                                        ? localTicketType.setStakeStrategy
                                        : false,
                                    winningStrategy: localTicketType.winningStrategy
                                        ? localTicketType.winningStrategy
                                        : false,
                                    betsCollection: $scope.type,
                                    ticketType: tab.type,
                                    getStakeHook: function (stake) {
                                        // Payin tax
                                        if (taxesConfig && !taxesConfig.payin.hideTax) {
                                            if (!this.taxes) this.taxes = {};

                                            this.taxes.payin = taxesLib.calculateTax(
                                                taxesConfig.payin.policy,
                                                this.systems ? stake : this.stake
                                            );

                                            return stake - this.taxes.payin.taxAmountRounded;
                                        }

                                        return stake;
                                    },
                                },
                            ],
                        });

                    api.boot();
                };

                // Prepare ticket bets
                var prepareTicketBets = function () {
                    if ($scope.ticket.bets) {
                        var bets = [],
                            item;
                        for (var i = 0; i < $scope.ticket.bets.length; i += 1) {
                            item = $scope.ticket.bets[i];
                            // Bet object is different per product
                            var bet = {};
                            switch ($scope.ticketType) {
                                case 'sport':
                                    // NOTE:
                                    // For combo ticketBetBank must be set to 1
                                    // For single ticketBetBank must be set to 0
                                    bet.idMatchBetOutcome = item.id;
                                    bet.ticketBetBank =
                                        $scope.activeTicket.type === '3'
                                            ? 0
                                            : item.banker || $scope.activeTicket.type === '1'
                                            ? 1
                                            : 0;
                                    bet.ticketBetWay = 0;
                                    bet.mboOddValue = item.odd;
                                    break;
                                case 'games':
                                    bet.payin = item.stake || item.payin;
                                    bet.type = item.id;
                                    bet.value = item.betValue || item.value;
                                    bet.system = item.system;
                                    bet.numEvents = $scope.ticket.future
                                        ? parseInt($scope.ticket.future, 10)
                                        : 1;
                                    break;
                            }

                            // Add bet
                            bets.push(bet);
                        }
                        return bets;
                    }
                };

                // Prepare ticket combinations for system and single bets
                var prepareTicketCombinations = function () {
                    var systems = $scope.ticket.config.hasSystem
                            ? $scope.ticket.systems.getActiveSystems()
                            : null,
                        bets = $scope.ticket.bets,
                        result,
                        ticketCombinationGroups = [
                            {
                                amount: null,
                                parlays: 1,
                                events: bets.length,
                                combinations: [],
                            },
                        ],
                        setSystemComb = function (system) {
                            return {
                                parlays: system.id,
                                events: system.of,
                                amount: $scope.stakePerSystem ? system.stake : null,
                            };
                        };

                    if ($scope.activeTicket.type === '1') {
                        return [];
                    }

                    for (var i = 0; i < bets.length; i++) {
                        if ($scope.activeTicket.type === '2') {
                            result = systems.map(setSystemComb);
                        } else if ($scope.activeTicket.type === '3') {
                            ticketCombinationGroups[0].combinations.push({
                                combinationBets: [bets[i].id],
                                combinationAmount: bets[i].stake,
                            });
                            result = ticketCombinationGroups;
                        }
                    }
                    return result;
                };

                // Check if rule condition is passed
                var isRuleConditionPassed = function (condition, rule, tickets, passed) {
                    var input = true,
                        systemStake,
                        system,
                        i;

                    if (!passed) return;

                    // Map input value
                    switch (condition.dataType) {
                        case 'amount':
                            input = $scope.ticket.stake;
                            break;
                        case 'betCount':
                            input = $scope.ticket.bets.length;
                            break;
                        case 'betPayout':
                            if ($scope.activeTicket.type === '3') {
                                // Loop bets
                                for (i = 0; i < tickets.length; i++) {
                                    input = tickets[i].maxWinning;
                                    passed = calculateRuleCondition[condition.ruleType](
                                        input,
                                        condition.value
                                    );
                                    if (!passed) break;
                                }
                            } else {
                                input = $scope.ticket.winnings.max / tickets.length;
                            }
                            break;
                        case 'bankBetCount':
                            input = $scope.ticket.betsCollection.getBankers().length;
                            break;
                        case 'nonBankBetCount':
                            input = $scope.ticket.betsCollection.getNonBankers().length;
                            break;
                        case 'combinationAmount':
                            // Loop bets
                            if ($scope.activeTicket.type === '3' || !$scope.stakePerSystem) {
                                for (i = 0; i < tickets.length; i++) {
                                    if ($scope.activeTicket.type === '3') {
                                        input = tickets[i].stake;
                                    } else {
                                        input = (
                                            $scope.ticket.stake /
                                            $scope.ticket.systems.totalCombinations
                                        ).toFixed(5);
                                    }
                                    passed = calculateRuleCondition[condition.ruleType](
                                        Number(input),
                                        condition.value
                                    );
                                    if (!passed) break;
                                }
                            } else {
                                // Loop systems
                                for (i = 0; i < $scope.ticket.systems.list.length; i++) {
                                    system = $scope.ticket.systems.list[i];
                                    if (system.active) {
                                        systemStake = (
                                            condition.value * system.combinations
                                        ).toFixed(2);
                                        passed = calculateRuleCondition[condition.ruleType](
                                            system.stake,
                                            systemStake
                                        );
                                        input = !passed ? systemStake : true;

                                        // Override default server message for min system combination amount
                                        if (!passed) {
                                            if (rule.clientMessage) {
                                                condition.newValue = systemStake;
                                                rule.combination = system.id + '/' + system.of;
                                            }
                                            break;
                                        }
                                    }
                                }
                            }
                            if (!passed) return false;

                            break;
                        case 'payout':
                        case 'gain':
                            input = $scope.ticket.winnings.max + $scope.ticket.winnings.bonusTotal;
                            break;
                    }

                    if (input === true) {
                        return true;
                    } else {
                        return input || input === 0
                            ? calculateRuleCondition[condition.ruleType](input, condition.value)
                            : false;
                    }
                };

                // Check if ticket is valid
                var isValidTicket = function (stake, reset) {
                    // Reset values
                    var valid = true,
                        item,
                        betCombinations,
                        ruleLimit,
                        minRuleLimit,
                        isStakeUndefined = angular.isUndefined(stake),
                        invalidStake,
                        ruleStake = 0,
                        tickets,
                        showMessage = false,
                        i;

                    // Clear messages
                    $scope.message[2].value = '';
                    if (isStakeUndefined || reset) $scope.message[4].value = '';

                    // Check ticket bets
                    if ($scope.ticket.bets.length === 0) return false;

                    // Check if user is setting stake
                    invalidStake = isStakeUndefined || !stake;

                    // Validate by type
                    switch ($scope.type) {
                        case 'LuckySix':
                        case 'LuckyX':
                        case 'GreyhoundRaces':
                        case 'VirtualGreyhoundRaces':
                        case 'VirtualHorseRaces':
                        case 'VirtualMotorcycleSpeedway':
                        case 'SlotCarRaces':
                            // Update stake only if number
                            if (stake && stake !== true) updateStake(stake);

                            // Max future
                            if (
                                configModule.rules.futureBet &&
                                configModule.rules.futureBet.active
                            ) {
                                ruleLimit = configModule.rules.futureBet.limit;
                                if ($scope.ticket.future > ruleLimit) {
                                    $scope.message[2].value =
                                        config.messages.general.maxFutureCountRule.supplant({
                                            value: ruleLimit.round(0),
                                        });
                                    valid = false;
                                    break;
                                }
                            }

                            // Amounts
                            for (i = 0; i < $scope.ticket.bets.length; i++) {
                                item = $scope.ticket.bets[i];
                                betCombinations = configModule.getBetById
                                    ? configModule.getBetById(item.id).combinations
                                    : item.combinations;

                                if (configModule.rules.maxBetAmount) {
                                    ruleLimit = configModule.rules.maxBetAmount.value * item.rounds;

                                    // If system bet stake is bigger then ruleLimit
                                    if (betCombinations && (invalidStake || reset)) {
                                        item.stake = item.payin = (
                                            configModule.rules.minCombBetAmount.value *
                                            betCombinations *
                                            item.rounds
                                        ).round(2);

                                        if (item.stake > ruleLimit && invalidStake) {
                                            $scope.message[2].value =
                                                config.messages.general.maxBetAmountRule.supplant({
                                                    value: ruleLimit.round(2),
                                                });
                                            valid = false;
                                        }
                                    }
                                    if (item.stake > ruleLimit && !invalidStake) {
                                        $scope.message[2].value =
                                            config.messages.general.maxBetAmountRule.supplant({
                                                value: ruleLimit.round(2),
                                            });
                                        valid = false;
                                        break;
                                    }
                                }

                                // Minimum combination amount
                                if (betCombinations) {
                                    if (configModule.rules.minCombBetAmount) {
                                        ruleLimit = (
                                            configModule.rules.minCombBetAmount.value *
                                            betCombinations *
                                            item.rounds
                                        ).round(2);
                                        minRuleLimit = (
                                            configModule.rules.minBetAmount.value * item.rounds
                                        ).round(2);

                                        if (ruleLimit < minRuleLimit) {
                                            ruleLimit = minRuleLimit;
                                        }
                                        ruleStake += ruleLimit;
                                        item.payin = ruleLimit;
                                        if (item.stake < ruleLimit && !invalidStake) {
                                            showMessage = true;
                                        }
                                    }
                                } else {
                                    // Minimum amount
                                    if (configModule.rules.minBetAmount) {
                                        ruleLimit = (
                                            configModule.rules.minBetAmount.value * item.rounds
                                        ).round(2);
                                        ruleStake += ruleLimit;
                                        item.payin = ruleLimit;
                                        if (item.stake < ruleLimit && !invalidStake) {
                                            showMessage = true;
                                        }
                                    }
                                }
                            }

                            if (showMessage) {
                                // If future is active and stake value gets bigger then value with future update stake
                                // and don't show message
                                if ($scope.ticket.stake >= ruleStake) {
                                    updateStake($scope.ticket.stake);
                                    return;
                                }
                                // Show message for min stake change, if validation has failed
                                $scope.message[2].value =
                                    config.messages.general.minBetAmountRule.supplant({
                                        value: ruleStake.toFixed(2),
                                    });
                                valid = false;
                            } else if (reset) {
                                // On bet remove reset future and stake to default
                                updateStake();
                            } else if (invalidStake) {
                                // If stake not set, update to ruleStake
                                updateStake(ruleStake);
                            }

                            // Minimum winning amount
                            if (configModule.rules.maxWinningCap) {
                                ruleLimit = configModule.rules.maxWinningCap.value;
                                if ($scope.ticket.winnings.max > ruleLimit) {
                                    $scope.message[2].value =
                                        config.messages.general.maxWinningLimitRule.supplant({
                                            value: ruleLimit.round(2),
                                        });
                                    valid = false;
                                    break;
                                }
                            }

                            // End
                            break;
                        default:
                            // Check systems
                            if ($scope.activeTicket.type === '2') {
                                if ($scope.ticket.systems.getActiveSystems().length === 0) {
                                    $scope.message[2].value =
                                        config.messages.general.warningNoSystemSelected;
                                }
                            }

                            if ($scope.activeTicket.type === '3') {
                                if (
                                    (!$scope.ticket.config.stakeTouched ||
                                        !$scope.stakePerSystem) &&
                                    invalidStake
                                ) {
                                    for (i = 0; i < $scope.ticket.bets.length; i++) {
                                        $scope.ticket.bets[i].setStake($scope.ticket.config.stake);
                                    }
                                }
                                $scope.ticket.setStake();
                            }

                            // Check rules
                            if (configModule.rules || configModule.rules.list) {
                                var rules = configModule.rules.list || configModule.rules,
                                    placeholder,
                                    passed = true,
                                    condition,
                                    rule,
                                    j;

                                tickets = $scope.ticket.bets;

                                // Label for exit
                                outer: for (i = 0; i < rules.length; i++) {
                                    rule = rules[i];
                                    placeholder = rule.clientMessage || rule.message;

                                    if (
                                        rule.ticketType === null ||
                                        rule.ticketType.toString() === $scope.activeTicket.type
                                    ) {
                                        for (j = 0; j < rule.conditions.length; j++) {
                                            condition = rule.conditions[j];
                                            passed = isRuleConditionPassed(
                                                condition,
                                                rule,
                                                tickets,
                                                passed
                                            );
                                            if (!passed) {
                                                valid = false;
                                                $log.debug('Ticket: Rule violation =>', rule);
                                                $scope.message[2].value = placeholder.supplant({
                                                    combination: rule.combination,
                                                    value: condition.newValue || condition.value,
                                                });
                                                break outer;
                                            }
                                        }
                                    }
                                }
                            }

                            // End
                            break;
                    }

                    // Set disabled state
                    $scope.valid = valid;

                    // Override all messages with betting disabled if true
                    if ($scope.disabled) {
                        $scope.$broadcast('SEVEN.BettingDisabled', true);
                    }

                    return valid;
                };

                // Update stake
                var updateStake = function (stake) {
                    var newStake = stake || 0;
                    // If min stake is not sent, find it
                    if (!stake) {
                        $scope.ticket.bets.forEach(function (bet) {
                            newStake += bet.payin;
                        });
                    }
                    // Finally send stake to ticket
                    $scope.ticket.setStake(newStake || $scope.ticket.stake);

                    // If system is active update system stake
                    if ($scope.ticket.config.hasSystem) $scope.ticket.systems.setStake(stake);
                };

                // Check if ticket has been changed
                var isDirtyTicket = function () {
                    var bets = $scope.ticket.bets,
                        i;

                    // Check odds options
                    var oddsOptions = getPlayerProfileProperty('oddsOptions', 'integer', 2),
                        dirty;

                    // Set warning
                    $scope.dirty = false;
                    $scope.message[1].value = '';

                    // Filter changed bets
                    for (i = bets.length - 1; i >= 0; i--) {
                        var disregard =
                            oddsOptions === 1 || (oddsOptions === 2 && bets[i].dirtyType === 'up');
                        if (bets[i].locked) {
                            dirty = 'locked';
                            break;
                        } else if (bets[i].dirty && !disregard) {
                            dirty = 'odds';
                        }
                    }

                    if (dirty) {
                        $scope.dirty = dirty;
                        $scope.message[1].value = config.messages.live.warningOddChange;
                    }

                    return dirty;
                };

                // Get needed module config
                var getConfigModule = function () {
                    // Get config name from type
                    var name;

                    // Parse active game type so it can correspond to game config
                    // Construct name and check loaded state
                    name = 'SEVEN' + activeGames.parseActiveGame($scope.type) + 'Config';

                    // Return dependency or empty
                    return $injector.has(name) ? $injector.get(name) : angular.noop();
                };

                // Notify that ticket is changed
                var notifyTicketChanged = function () {
                    // Ticket changed
                    $scope.$emit('SEVEN.TicketChanged', {
                        betCount: $scope.ticket.bets ? $scope.ticket.bets.length : 0,
                    });

                    // Refresh scroller
                    $scope.$emit('SEVEN.Rendered');
                };

                // Set future rounds
                var setFuture = function (item) {
                    if ($scope.future) {
                        var rounds, add;
                        rounds = $scope.ticket.future;
                        add = rounds - 1;
                        item.rounds = rounds;
                        item.info =
                            rounds > 1 ? item.round + '-' + (item.round + add) : item.round + add;
                    } else {
                        item.rounds = 1;
                    }
                };

                // Filter double bets
                var filterDoubleBets = function () {
                    var list;
                    var i = $scope.ticket.bets.length - 1;

                    var reductor = function (i) {
                        return function (item) {
                            return item.keyParent === $scope.ticket.bets[i].keyParent;
                        };
                    };

                    // Compares last array item, with rest of the array,
                    // if duplicate is found removes last one.
                    for (i; i >= 0; i--) {
                        list = $scope.ticket.bets.slice(0, i);
                        var found = $filter('filter')(list, reductor(i));

                        if (found.length) {
                            $scope.ticket.bets.splice(i, 1);
                        }
                    }
                };

                // Set edit mode
                var setEditMode = function (edit, item) {
                    if ($scope.ticket.bets) {
                        $scope.ticket.bets.forEach(function (current) {
                            if (item && current.uid === item.uid) {
                                edit = edit ? !current.edit : false;
                                current.edit = edit;
                            } else {
                                current.edit = false;
                            }
                        });

                        $rootScope.$broadcast('SEVEN.TicketEditBet', {
                            reference: edit ? item.reference : null,
                            edit: $scope.edit,
                        });
                        $scope.edit = edit;
                    }
                };

                // Reset future bets
                var resetFuture = function (reset, value) {
                    value = value || 1;
                    $scope.ticket.future = value;
                    $scope.updateFuture(reset);
                };

                // Reset system selections
                var resetSystemSelections = function () {
                    if ($scope.ticket.systems) {
                        if ($scope.ticket.systems.totalCombinations > 0) {
                            $scope.ticket.systems.toggleAllSystems(false, false, false);
                            $scope.ticket.setStake($scope.ticket.config.stake);
                        }
                        $scope.ticket.generateSystems();
                    }
                };

                // Hide virtual keyboard
                var hideKeyboard = function () {
                    $scope.$broadcast('SEVEN.ShowKeyboard', false);
                };

                // Go to detail
                $scope.goToDetail = function (item) {
                    if ($scope.editable) {
                        // Select only one item for edit
                        setEditMode(true, item);
                    } else {
                        // Broadcast detail key
                        $rootScope.$broadcast('SEVEN.GoToDetail', item.detailUrl);
                    }
                };

                // Update future bet
                // If reset, set future to default, if future update to new value, and show messages
                $scope.updateFuture = function (reset, future) {
                    // Define stake on future update
                    future = future || false;
                    // Update ticket bets
                    angular.forEach($scope.ticket.bets, function (item) {
                        setFuture(item);
                    });

                    // Update and validate
                    isValidTicket(future, reset);
                };

                // Remove bet
                $scope.removeBet = function (item) {
                    // Set default stake
                    if ($scope.ticket.bets.length === 1) {
                        $scope.removeAllBets();
                    } else if (item && item.key) {
                        $scope.ticket.removeBets([item.key], 'key');

                        resetSystemSelections();

                        if ($scope.future) {
                            resetFuture(true);
                        } else {
                            // Update and validate
                            isValidTicket($scope.ticket.stake, true);
                        }
                        setEditMode(false, item);
                        isDirtyTicket();
                        notifyTicketChanged();
                        hideKeyboard();

                        // Notify bet remove
                        $scope.$emit('SEVEN.BetRemoved', item);
                    }
                };

                // Remove all bets
                $scope.removeAllBets = function () {
                    // If ticket payin is active return
                    if ($scope.payinLoading) return;
                    // Reinitialize
                    api.ts().reinitialise({
                        collection: $scope.type,
                        group: $scope.type,
                    });
                    // Set active ticket
                    $scope.ticket = api
                        .ts()
                        .setActiveTicket($scope.type + $scope.activeTicket.name);
                    // Bind future
                    $scope.ticket.future = $scope.ticket.future || 1;
                    // Define tax
                    taxesConfig = configModule.taxes;

                    if (taxesConfig) {
                        $scope.ticket.taxes = {
                            payin: taxesLib.calculateTax(
                                taxesConfig.payin.policy,
                                $scope.ticket.stake
                            ),
                        };
                    }
                    // Reset dirty
                    $scope.dirty = false;
                    if ($scope.future) {
                        resetFuture(true);
                    } else {
                        // Update and validate
                        $scope.ticket.setStake($scope.ticket.config.stake);
                        isValidTicket(false, true);
                    }
                    setEditMode(false);
                    isDirtyTicket();
                    notifyTicketChanged();
                    hideKeyboard();

                    // Notify bets remove
                    $scope.$emit('SEVEN.BetsRemoved');
                };

                // Toggle bankers/fix
                $scope.toggleFix = function (item) {
                    item.toggleBanker();
                    resetSystemSelections();
                    isValidTicket();
                    hideKeyboard();
                };

                // Toggle systems
                $scope.toggleSystem = function (item) {
                    // Toggle system
                    if (!$scope.stakePerSystem && $scope.ticket.config.stakeTouched) {
                        // If stakePerSystem not active set min stake per system
                        $scope.ticket.systems.toggleSystem(
                            item,
                            false,
                            undefined,
                            $scope.ticket.config.combStake
                        );
                    } else {
                        item.setStakePerCombination($scope.ticket.config.combStake);
                        $scope.ticket.systems.toggleSystem(item);
                    }
                    // Reset stake to default if there is not even one selected system
                    if ($scope.ticket.systems.totalCombinations === 0) {
                        $scope.ticket.setStake($scope.ticket.config.stake);
                    } else {
                        $scope.ticket.setStake($scope.ticket.systems.getStake());
                    }
                    isValidTicket();
                    hideKeyboard();

                    // Refresh scroller
                    $scope.$emit('SEVEN.Rendered');
                };

                // Login needed for payin
                $scope.payinLoginNeeded = function () {
                    return $scope.ticket.bets && $scope.ticket.bets.length > 0 && !user.logged;
                };

                // Payin disabled
                $scope.payinDisabled = function () {
                    // Check all conditions
                    var currentStake = $scope.ticket.stake,
                        ignoreBalance =
                            settings.mode === 'integration' &&
                            !settings.api.events.insufficientFunds &&
                            !(
                                integrator.isBalanceChangedListenerActive() ||
                                settings.api.listeners.updateBalance
                            ),
                        logged = user.logged,
                        systems = $scope.ticket.systems
                            ? $scope.ticket.systems.totalCombinations === 0
                            : false,
                        payable = ignoreBalance ? true : user.balanceTotal >= currentStake,
                        betCount = $scope.ticket.bets.length,
                        disabled =
                            !logged ||
                            betCount <= 0 ||
                            !payable ||
                            !$scope.valid ||
                            $scope.disabled ||
                            $scope.disabledByParent ||
                            $scope.payinLoading ||
                            $scope.showKeyboard ||
                            systems;

                    // Show message if not payable
                    $scope.unpayable = logged && !payable && betCount > 0 && !$scope.payinLoading;

                    // Set button text
                    $scope.payinButtonTitle = !logged ? config.messages.general.signIn : null;

                    // Disable button
                    return disabled;
                };

                // Generate code disabled
                $scope.generateCodeDisabled = function () {
                    // Check all conditions
                    var systems = $scope.ticket.systems
                            ? $scope.ticket.systems.totalCombinations === 0
                            : false,
                        betCount = $scope.ticket.bets.length,
                        disabled =
                            betCount <= 0 ||
                            !$scope.valid ||
                            $scope.disabled ||
                            $scope.disabledByParent ||
                            $scope.payinLoading ||
                            $scope.showKeyboard ||
                            systems;

                    // Disable button
                    return disabled;
                };

                // Remove locked matches
                $scope.acceptLocked = function () {
                    var bets = $scope.ticket.bets,
                        systemFlag = false,
                        i;

                    if (!bets.length) return;

                    // Remove locked matches
                    for (i = bets.length - 1; i >= 0; i--) {
                        if (bets[i].locked) {
                            systemFlag = true;
                            bets.splice(i, 1);
                        }
                    }
                    $scope.dirty = false;
                    $scope.message[1].value = '';
                    if (systemFlag) resetSystemSelections();
                    isValidTicket();
                };

                // Accept dirty ticket
                $scope.acceptDirty = function () {
                    // Payin
                    $scope.payIn(true);
                };

                // Show login
                $scope.openLogin = function () {
                    // Broadcast login event
                    $rootScope.$broadcast('SEVEN.ShowLoginDialog');
                };

                // Payin
                $scope.payIn = function (acceptDirty) {
                    // Check if login is needed
                    if ($scope.payinLoginNeeded()) {
                        $scope.showLogin();
                        return;
                    }

                    // Check if disabled
                    if ($scope.payinDisabled()) {
                        return;
                    }

                    // Check if dirty
                    if (!acceptDirty) {
                        if (isDirtyTicket()) {
                            return;
                        }
                    }

                    // Create request
                    var requestUuid = uuid.v4();
                    var request = {
                        method: routes.web.player.ticket.add.method,
                        url: config.apiBase + routes.web.player.ticket.add.url,
                        headers: {
                            'HTTP-X-NAB-PRODUCTINSTANCE-ID': product.id,
                            'HTTP-X-NAB-PRODUCTNAME': $scope.type,
                            'SEVEN-TP-TOKEN': integrator.getThirdPartyToken(),
                            'SEVEN-TP-CUSTOM': integrator.data.customValues,
                        },
                        params: {
                            requestUuid: requestUuid,
                        },
                        data: {
                            metadata: {
                                product: $scope.type,
                                deliveryPlatform: settings.platform.delivery,
                                cpvUuid: product.channel,
                                paymentMethod: user.settings.paymentMethod,
                                requestUuid: requestUuid,
                                appDeviceUuid: locker.get('Device', uuid.v4()),
                                sources: [
                                    {
                                        type: settings.platform.clientSubType.toLowerCase(),
                                        uuid: user.id,
                                    },
                                    {
                                        type: 'productInstance',
                                        uuid: product.uuid,
                                    },
                                ],
                            },
                            ticket: {
                                bets: prepareTicketBets(),
                                payin: $scope.ticket.stake,
                                ticketComment: config.client.name,
                                ticketCombinationGroups: prepareTicketCombinations(),
                                ticketType: $scope.activeTicket.type,
                                ticketOddsOptions: getPlayerProfileProperty(
                                    'oddsOptions',
                                    'integer',
                                    2
                                ),
                            },
                        },
                    };

                    // Set additional info needed for backend to fill tax authority
                    request.data.additionalInfo = SEVENTicketPrintService.getTwigTemplate({
                        ticket: $scope.ticket,
                        product: $scope.type,
                    });

                    // Post to server
                    $scope.payinLoading = true;

                    // Broadcast payinLoading state
                    $rootScope.$broadcast('SEVEN.TicketPayinActive', {
                        active: $scope.payinLoading,
                        reset: false,
                    });

                    // Send request to server
                    $http(request)
                        .then(function (response) {
                            if (response.data) {
                                // Reset message
                                $scope.message[1].value = '';
                                $scope.message[4].value = '';
                                $scope.message[4].link = false;
                                var sendData = {
                                    requestUuid: response.data.requestUuid,
                                    productId: $scope.type,
                                };

                                // Send notification to ticket resolver
                                $rootScope.$broadcast('SEVEN.TicketResolving', sendData);
                            }
                        })
                        .catch(function (response) {
                            var data = response.data;
                            if (data) {
                                // If 'Odd changed' message exists, don't add it again
                                // NOTE: dirty mode (odds changed) - code 4000
                                if (data.code === 4000) {
                                    $scope.dirty = 'odds';
                                    $scope.message[1].value = exception.renderMessage(data);
                                } else if (data.code === 11000) {
                                    $scope.message[4].link = 'PlayerProfile';
                                    $scope.message[4].value = exception.renderMessage(data);
                                } else {
                                    // Show error
                                    $scope.message[4].value = exception.renderMessage(data);
                                }
                                // Enable button
                                $scope.payinDone = true;
                                $scope.payinLoading = false;
                                setEditMode(false);
                                // Broadcast payinLoading state
                                $rootScope.$broadcast('SEVEN.TicketPayinActive', {
                                    active: $scope.payinLoading,
                                    reset: false,
                                });
                            }
                        });
                };

                // Generate code
                $scope.generateCode = function () {
                    // Define variables
                    var request,
                        cpvUuid = product.channel;

                    // Create request
                    request = {
                        method: 'POST',
                        url: configModule.ticket.codeAddRoute,
                        data: {
                            metadata: {
                                product: $scope.type,
                                deliveryPlatform: settings.platform.delivery,
                                cpvUuid: cpvUuid,
                                requestUuid: uuid.v4(),
                                locale: locale.activeLanguage,
                                sources: [],
                            },
                            ticket: {
                                bets: prepareTicketBets(),
                                payin: $scope.ticket.stake,
                                ticketCombinationGroups: prepareTicketCombinations(),
                                ticketType: $scope.activeTicket.type,
                            },
                        },
                    };

                    // Post to server
                    $scope.payinLoading = true;
                    $http(request)
                        .then(function (response) {
                            response.data.url = configModule.ticket.codeCheckRoute;
                            response.data.cpvUuid = cpvUuid;
                            $scope.payinLoading = false;
                            $scope.removeAllBets();
                            $rootScope.$broadcast('SEVEN.TicketCodeGenerated', response.data);
                        })
                        .catch(function (response) {
                            $scope.payinLoading = false;
                            $scope.message[4].value = exception.renderMessage(response.data);
                        })
                        .finally(function () {
                            $scope.generateCodeDone = true;
                        });
                };

                // Fast payin listener
                $scope.$on('SEVEN.TicketPayin', function () {
                    $scope.payIn();
                });

                // Listen for update on REBET for future and stake
                $scope.$on('SEVEN.UpdateFutureAndStake', function (event, data) {
                    resetFuture(true, data.future);
                    $scope.setStake(data.stake);
                });

                // Ticket resolved listener
                $scope.$on('SEVEN.TicketResolved', function (event, data) {
                    // Enable payin
                    $scope.payinDone = true;
                    $scope.payinLoading = false;
                    $rootScope.$broadcast('SEVEN.TicketAdded');

                    if (data && data.status.value.toUpperCase() === 'REJECTED') {
                        if (data.alternativeStake) {
                            $scope.ticket.setStake(data.alternativeStake);
                        }
                    } else if (data && data.status.value.toUpperCase() !== 'CLOSED') {
                        // Remove all bets
                        $scope.removeAllBets();
                    }
                    // Broadcast payinLoading state
                    $rootScope.$broadcast('SEVEN.TicketPayinActive', {
                        active: $scope.payinLoading,
                        reset: false,
                    });
                });

                // Show login
                $scope.showLogin = function () {
                    // Get elements
                    var widget = $('.n-login'),
                        button = widget.find('.button-login:visible');

                    // On mobile click on button to open dialog
                    // On desktop just focus on username
                    if (button.length > 0) {
                        $timeout(function () {
                            button.click();
                        });
                    } else {
                        // Send message to parent
                        integrator.sendMessage({
                            type: 'loginRequired',
                        });
                    }
                };

                // On input change update stake (global/total stake)
                $scope.setStake = function (value) {
                    if (angular.isUndefined(value)) {
                        value = $scope.ticket.config.stake;
                    }

                    if (angular.isString(value)) {
                        if (['min', 'max'].indexOf(value) > -1) {
                            if (value === 'min') {
                                isValidTicket(0);
                                value = $scope.ticket.stake;
                            } else {
                                if (configModule.rules.maxBetAmount) {
                                    if (user.balanceTotal < configModule.rules.maxBetAmount.value) {
                                        value = user.balanceTotal;
                                    } else {
                                        value = configModule.rules.maxBetAmount.value;
                                    }
                                }
                            }
                        }
                    }

                    $scope.ticket.setStake(value);
                    $scope.ticket.calculateWinnings();
                    $scope.ticket.config.stakeTouched = true;

                    isValidTicket(value);
                };

                // Set stake for system ticket type
                $scope.setSystemStake = function (system) {
                    /// Set system stake
                    system.setStake(system.stake);
                    // Set total stake
                    $scope.ticket.setStake($scope.ticket.systems.getStake());
                    // Calculate winnings
                    $scope.ticket.calculateWinnings();

                    $scope.ticket.config.stakeTouched = true;
                    isValidTicket($scope.ticket.stake);
                };

                // Set stake for single ticket type
                $scope.setSingleStake = function (item) {
                    // Set single stake
                    item.setStake(item.stake);
                    // Calculate winnings
                    $scope.ticket.calculateWinnings();

                    $scope.ticket.config.stakeTouched = true;
                    isValidTicket($scope.ticket.stake);
                };

                // Modal Keyboard action listener
                $scope.$on('SEVEN.KeyboardAction', function (e, data) {
                    $scope[data.action](data.value);
                });

                // On keyboard changed ticket stake
                $scope.$on('SEVEN.ShowKeyboard', function (type, data) {
                    $scope.showKeyboard = data;
                });

                // Listen to bet change
                $scope.$on('SEVEN.TicketBetChanged', function (event, data) {
                    if (data) {
                        // Create new object
                        var item = {},
                            locked = data.locked || data.value === 1;

                        // Check update type
                        switch (data.type.toUpperCase()) {
                            case 'BET':
                                item.keyParent = data.keyParent;
                                item.locked = locked || item.value === 1;
                                item.active = true;
                                $scope.ticket.updateBets(item, 'keyParent');
                                break;
                            case 'ODD':
                                data.value = data.value ? data.value : 1;
                                item.key = data.key;
                                item.locked = locked;
                                item.active = true;
                                item.dirty = data.dirty;
                                item.dirtyType = data.dirtyType;
                                item.previousOdd = data.previousValue;
                                item.odd = data.value;
                                item.maxOdd = data.maxOdd;
                                item.value = data.value;
                                item.oddDisplay = data.oddDisplay;
                                $scope.ticket.updateBets(item, 'key');
                                break;
                        }

                        // Is valid ticket
                        isValidTicket($scope.ticket.stake);

                        // Check dirty
                        isDirtyTicket();
                    }
                });

                // Listen on adding bet event
                $scope.$on('SEVEN.TicketBetAdding', function (event, data) {
                    // Broadcast payinLoading state
                    $rootScope.$broadcast('SEVEN.TicketPayinActive', {
                        active: $scope.payinLoading,
                        reset: true,
                    });
                    // If ticket payin is active return
                    if ($scope.payinLoading) return;
                    // Log receiving data
                    $log.debug('Ticket: Received data =>', data);

                    // Add bet
                    if (data.item && data.item !== null) {
                        // Check detail URL and remove hash
                        if (data.item.detailUrl) {
                            data.item.detailUrl = data.item.detailUrl.replace('#', '');
                        }

                        toggleBets(data.item);
                    }
                });

                // Listen on bet removing
                $scope.$on('SEVEN.RemoveAllBets', function () {
                    $scope.removeAllBets();
                });

                // Listen tab change event
                $scope.$on('SEVEN.TabsChanged', function (event, activeIndex) {
                    // Set active tab
                    angular.forEach($scope.tabs, function (item, index) {
                        if (index === activeIndex) {
                            $scope.activeTicket = item;
                            item.active = true;
                        } else {
                            item.active = false;
                        }
                    });

                    // Set active ticket on tab change
                    $timeout(function () {
                        setActiveTicket();
                    });
                    hideKeyboard();
                });

                // Listen to round change
                $scope.$on('SEVEN.RoundChanged', function (event, roundId) {
                    var increment = $scope.future ? 1 : 0;
                    angular.forEach($scope.ticket.bets, function (item) {
                        item.round = roundId + increment;
                        item.info = roundId + increment;
                        setFuture(item);
                    });
                });

                // Listen to betting disabled
                $scope.$on('SEVEN.BettingDisabled', function (event, disabled) {
                    $scope.disabled = disabled;
                    $scope.message[3].value = disabled
                        ? config.messages.general.bettingDisabledWarning
                        : '';
                    $timeout();
                });

                // Listen to betting disabled by parent
                $scope.$on('SEVEN.BettingDisabledByParent', function (event, disabled) {
                    $timeout(function () {
                        $scope.disabledByParent = disabled;
                        $scope.message[3].value = disabled
                            ? config.messages.general.bettingDisabledWarning
                            : '';
                        console.log($scope.disabledByParent);
                    });
                });

                // Initialize
                var init = function () {
                    setBetslipType();
                    setEditMode(false);
                    setInputUpdateModel();

                    // Watch bonus resolve
                    var bonusesWatcher = $scope.$watch(
                        function () {
                            return configModule.bonuses;
                        },
                        function (bonuses) {
                            if (bonuses) {
                                createBonus({
                                    bonuses: bonuses,
                                    game: $scope.type,
                                });
                                bonusesWatcher();
                            }
                        }
                    );

                    // Watch ticket winnings change
                    if (configModule.taxes) {
                        taxesConfig = configModule.taxes;
                        if (!$scope.ticket.taxes) $scope.ticket.taxes = {};

                        $scope.$watch(
                            'ticket.winnings.max',
                            function (winnings) {
                                $scope.ticket.taxes.payout = taxesLib.calculateTax(
                                    taxesConfig.payout.policy,
                                    winnings +
                                        ($scope.ticket.winnings.bonusTotal
                                            ? $scope.ticket.winnings.bonusTotal
                                            : 0),
                                    $scope.ticket.getStake()
                                );
                            },
                            true
                        );
                    }
                };

                // Initialize
                init();
            }],
        };
    }]
);

SEVEN.directive(
    'sevenTickets',
    ['$timeout', '$interval', '$http', '$rootScope', '$window', '$injector', '$filter', 'uuid', 'SEVENSettings', 'SEVENProductService', 'SEVENConfig', 'SEVENRoutes', 'SEVENModalSvc', 'SEVENNotifier', 'SEVENException', 'SEVENHelpers', 'SEVENUser', 'SEVENTicketsRebet', function (
        $timeout,
        $interval,
        $http,
        $rootScope,
        $window,
        $injector,
        $filter,
        uuid,
        SEVENSettings,
        SEVENProductService,
        SEVENConfig,
        SEVENRoutes,
        SEVENModalSvc,
        SEVENNotifier,
        SEVENException,
        SEVENHelpers,
        SEVENUser,
        SEVENTicketsRebet
    ) {
        var user = SEVENUser,
            settings = SEVENSettings,
            productSrv = SEVENProductService,
            config = SEVENConfig,
            routes = SEVENRoutes,
            modalSrv = SEVENModalSvc,
            notifier = SEVENNotifier,
            exception = SEVENException,
            helpers = SEVENHelpers,
            rebet = SEVENTicketsRebet,
            count = 5;

        return {
            restrict: 'E',
            replace: true,
            scope: {},
            templateUrl: function (element, attr) {
                return attr.template || settings.directory.app + 'shared/tickets/view.html';
            },
            controller: ['$scope', '$element', '$attrs', function ($scope, $element, $attrs) {
                var excludeStatusList = ['CLOSED', 'REJECTED', 'AFTERCLOSED'],
                    refreshInterval,
                    refreshIntervalLength = parseInt($attrs.refreshInterval || 30000),
                    loadDataTimestamp,
                    loadDataTimeout,
                    betslipConfig;

                var init = function () {
                    $scope.config = config;
                    $scope.type = $attrs.type;
                    $scope.history = $attrs.history;
                    $scope.visible = false;
                    $scope.pager = {};
                    $scope.pager.cycle = true;
                    $scope.browserFocused = false;
                    $scope.modalTemplate = $attrs.modalTemplate;
                    count = $attrs.ticketsCount ? $attrs.ticketsCount : count;
                    // Check is device touchable
                    $scope.isTouchDevice = helpers.isTouchDevice();

                    isBrowserFocused();

                    setTicketType();
                    setTicketSubType();
                    loadData();
                };

                // Check only for mobile devices
                // because of different data on desktop and mobile
                // in situation when mobile goes to sleep mode
                var isBrowserFocused = function () {
                    if ($scope.isTouchDevice) {
                        $window.addEventListener('focus', function (evt) {
                            if (evt.type === 'focus') {
                                $scope.browserFocused = true;
                            }
                        });
                    }
                };

                var setRefreshInterval = function () {
                    $interval.cancel(refreshInterval);
                    refreshInterval = $interval(function () {
                        loadData(0, null, true);
                    }, refreshIntervalLength);
                };

                var setTicketType = function () {
                    betslipConfig = settings.betslip[$scope.type];
                    $scope.cancellable = betslipConfig.cancellable;
                    $scope.rebet = betslipConfig.rebet;
                    $scope.ticketType = betslipConfig.ticketType;
                };

                var setTicketSubType = function () {
                    $scope.ticketSubType = betslipConfig.ticketSubType;
                };

                var loadData = function (delay, callback, silent, change) {
                    if (!user.logged) return;

                    $timeout.cancel(loadDataTimeout);
                    loadDataTimeout = $timeout(function () {
                        $scope.loading = silent ? false : true;
                        loadDataTimestamp = moment();
                        productSrv
                            .getTickets($scope.type, {
                                count: count,
                                notstatus: excludeStatusList.toString(),
                                timezoneOffset: user.profile.timezoneOffset,
                            })
                            .then(function (response) {
                                if (user.logged) {
                                    if ($scope.ticketType === 'sport') {
                                        $scope.tickets = productSrv.parseTickets(
                                            $scope.type,
                                            $scope.ticketType,
                                            response.data
                                        );
                                    } else {
                                        $scope.tickets = productSrv.parseTickets(
                                            $scope.type,
                                            $scope.ticketSubType,
                                            response.data,
                                            !$scope.tickets || change || $scope.browserFocused
                                                ? response.data
                                                : $scope.tickets
                                        );
                                    }

                                    if (angular.isFunction(callback)) {
                                        callback.apply();
                                    }

                                    setRefreshInterval();
                                    $scope.visible = true;
                                    $scope.pager.list = $scope.tickets;
                                }

                                checkTicketForFutureRounds();
                                $scope.loading = false;
                                $scope.browserFocused = false;
                            });
                    }, delay);

                    $rootScope.$broadcast('SEVEN.LoadData');
                };

                var checkTicketForFutureRounds = function () {
                    if (!$scope.tickets) {
                        return;
                    }

                    $scope.tickets.forEach(function (ticket) {
                        var ticketBets = ticket.bets;

                        // Only check racer games
                        if (ticketBets && $scope.ticketSubType === 'races') {
                            var roundId = ticketBets[0].round,
                                tempBet;

                            // Try to find a different roundId among the bets
                            tempBet = ticketBets.find(function (bet) {
                                return bet.round !== roundId;
                            });

                            // Status flag for showing/hiding round in ticket footer
                            ticket.hasFutureRounds = tempBet ? true : false;
                        }
                    });
                };

                var clearData = function () {
                    $scope.tickets = [];
                    $scope.visible = false;
                };

                var valueDrawn = function (product, value, active, type) {
                    return value.map(function (number, index) {
                        var drawn = '',
                            drawnColor = '';

                        switch (type) {
                            case 'drawn':
                                if (active && active.indexOf(index) > -1) {
                                    drawn = number;
                                }
                                break;
                            case 'html':
                                if (product === 'LuckySix') {
                                    drawnColor =
                                        ' color-' + (number % 8 === 0 ? 7 : (number % 8) - 1);
                                }
                                drawn =
                                    (active && active.indexOf(index) > -1
                                        ? '<i class="active' + drawnColor + '">'
                                        : '<i>') +
                                    number +
                                    '</i>';
                                break;
                            case 'htmlNumber':
                                drawn =
                                    (active && active.indexOf(index) > -1
                                        ? '<span class="active number">'
                                        : '<span class="number">') +
                                    number +
                                    '</span>';
                                break;
                        }
                        return drawn;
                    });
                };

                var setDrawnTickets = {
                    LuckySix: function (bet, data) {
                        var values = bet.value.split(',').map(Number),
                            active = [];

                        for (var i = 0; i < data.result.balls.length; i++) {
                            if (data.result.balls[i].number) {
                                var index = values.indexOf(data.result.balls[i].number);
                                if (bet.type === 10) {
                                    if (i < 5 && index > -1) active.push(index);
                                } else {
                                    if (index > -1) active.push(index);
                                }
                            }
                        }
                        bet.drawn = valueDrawn('LuckySix', values, active, 'drawn');
                        bet.valueHtml = valueDrawn('LuckySix', values, active, 'html').join('');
                        bet.valueHtmlNumber = valueDrawn(
                            'LuckySix',
                            values,
                            active,
                            'htmlNumber'
                        ).join(', ');
                    },
                    LuckyX: function (bet, data) {
                        var values = bet.value.map(Number),
                            active = [],
                            oddsLength = bet.type === 23 ? 6 : data.bets[bet.type].odds.length;

                        for (var i = 0; i < data.result.balls.length; i++) {
                            if (data.result.balls[i].number) {
                                var index = values.indexOf(data.result.balls[i].number);
                                if (index > -1 && data.result.balls[i].id <= oddsLength) {
                                    active.push(index);
                                }
                            }
                        }

                        bet.drawn = valueDrawn('LuckyX', values, active, 'drawn');
                        bet.valueHtml = valueDrawn('LuckyX', values, active, 'html').join('');
                        bet.valueHtmlNumber = valueDrawn(
                            'LuckyX',
                            values,
                            active,
                            'htmlNumber'
                        ).join(', ');
                    },
                    Keno: function (bet, data) {
                        if (bet.type < 30 || bet.type === 40 || bet.type === 41) {
                            var values = bet.value.map(Number),
                                active = [];

                            for (var i = 0; i < data.result.balls.length; i++) {
                                if (data.result.balls[i].number) {
                                    var index = values.indexOf(data.result.balls[i].number);
                                    if (index > -1) {
                                        active.push(index);
                                    }
                                }
                            }
                            bet.drawn = valueDrawn('Keno', values, active, 'drawn');
                            bet.valueHtml = valueDrawn('Keno', values, active, 'html').join('');
                            bet.valueHtmlNumber = valueDrawn(
                                'Keno',
                                values,
                                active,
                                'htmlNumber'
                            ).join(', ');
                        }
                    },
                };

                var setBetState = function (data) {
                    if (!$scope.tickets) {
                        return;
                    }

                    $scope.tickets
                        .filter(function (ticket) {
                            return ticket.status.live === true;
                        })
                        .forEach(function (ticket) {
                            if (ticket.bets) {
                                ticket.bets.forEach(function (bet) {
                                    if (bet.round === data.result.round) {
                                        if (
                                            (bet.type < 5 || bet.type === 10) &&
                                            ticket.product === 'LuckySix'
                                        ) {
                                            setDrawnTickets[ticket.product](bet, data);
                                        } else if (
                                            (bet.type < 20 || bet.type === 23) &&
                                            ticket.product === 'LuckyX'
                                        ) {
                                            setDrawnTickets[ticket.product](bet, data);
                                        } else if (ticket.product === 'Keno') {
                                            setDrawnTickets[ticket.product](bet, data);
                                        }
                                    } else {
                                        bet.drawn = '';
                                    }
                                });
                            }
                        });
                };

                // Check if rebet button should be displayed on ticket preview
                var checkRebet = function (ticket) {
                    return ticket.bets.filter(function (bet) {
                        return bet.status === 5;
                    }).length;
                };

                $scope.setEdit = function (ticket, edit) {
                    if (angular.isUndefined(edit)) {
                        if (
                            (ticket.product === 'LuckySix' ||
                                ticket.product === 'LuckyX' ||
                                ticket.product === 'Keno') &&
                            !ticket.edit
                        ) {
                            edit = true;
                        } else if (ticket.edit) {
                            edit = false;
                        } else {
                            edit =
                                $scope.cancellable &&
                                ticket.status.cancellable &&
                                ticket.cancellable;
                        }
                    }

                    ticket.edit = edit;
                    ticket.editConfirm = false;
                };

                $scope.isVirtualRace = function (ticket, bet) {
                    var betType = false;
                    if (ticket.product.toUpperCase().indexOf('RACES') > -1) {
                        if (bet.type >= 0 && bet.type <= 2) {
                            betType = true;
                        }
                    }

                    return betType;
                };

                $scope.cancelTicketConfirm = function (ticket) {
                    ticket.editConfirm =
                        $scope.cancellable && ticket.status.cancellable && ticket.cancellable;
                };

                $scope.rebetTicket = function (ticket) {
                    var newTicket, items;
                    switch (ticket.product) {
                        default:
                            $rootScope.$broadcast('SEVEN.RemoveAllBets');
                            newTicket = angular.copy(ticket);
                            newTicket.bets = rebet.recreateFutureBets(newTicket);
                            items = rebet.getTicketFormat(newTicket, newTicket.product);

                            // Emit adding event
                            $rootScope.$broadcast('SEVEN.TicketAddBet', {
                                type: newTicket.product,
                                items: items,
                                stake: newTicket.payin,
                            });

                            $scope.setEdit(ticket, false);
                            $rootScope.$broadcast('SEVEN.ScrollToTop');
                    }
                };

                $scope.cancelTicket = function (ticket) {
                    // Check status
                    if (!$scope.cancellable && !ticket.status.cancellable) {
                        return;
                    }

                    // Block ticket
                    ticket.working = true;

                    // Get product
                    var product = productSrv.getProductByName($scope.type);

                    // Create request
                    var requestUuid = uuid.v4();
                    var request = {
                        method: routes.web.player.ticket.cancel.method,
                        url: config.apiBase + routes.web.player.ticket.cancel.url,
                        headers: {
                            'HTTP-X-NAB-PRODUCTINSTANCE-ID': product.id,
                            'HTTP-X-NAB-PRODUCTNAME': $scope.type,
                        },
                        params: {
                            requestUuid: requestUuid,
                        },
                        data: {
                            metadata: {
                                product: $scope.type,
                                deliveryPlatform: settings.platform.delivery,
                                cpvUuid: product.channel,
                                paymentMethod: user.settings.paymentMethod,
                                requestUuid: requestUuid,
                                sources: [
                                    {
                                        type: 'player',
                                        uuid: user.id,
                                    },
                                    {
                                        type: 'productInstance',
                                        uuid: product.uuid,
                                    },
                                ],
                            },
                            ticket: {
                                id: ticket.id,
                            },
                        },
                    };

                    // Post to server
                    $http(request)
                        .then(function (response) {
                            if (response.data) {
                                // Refresh data
                                loadData(
                                    1000,
                                    function () {
                                        ticket.working = false;
                                        ticket.edit = false;
                                        ticket.editConfirm = false;
                                    },
                                    false,
                                    true
                                );
                            }
                        })
                        .catch(function (response) {
                            // Notify player of error
                            notifier.notify(
                                exception.renderMessage(response.data),
                                null,
                                'default'
                            );
                            // Refresh data
                            loadData(
                                1000,
                                function () {
                                    ticket.working = false;
                                },
                                false,
                                true
                            );
                        });
                };

                $scope.showDetail = function (ticket) {
                    var modalTemplate =
                        $scope.modalTemplate ||
                        settings.directory.app +
                            'shared/tickets/view-' +
                            $scope.ticketType +
                            '-ticket.html';
                    // Open modal with inline controller -> isolate scope
                    modalSrv
                        .showModal({
                            templateUrl: modalTemplate,
                            controller: [
                                '$scope',
                                'close',
                                function (scope, close) {
                                    // Bind to modal scope
                                    scope.ticket = ticket;
                                    scope.cancellable = $scope.cancellable;
                                    scope.config = config;
                                    scope.messages = config.messages.general;
                                    scope.cancelTicket = $scope.cancelTicket;
                                    scope.rebetTicket = $scope.rebetTicket;
                                    scope.isVirtualRace = $scope.isVirtualRace;
                                    scope.checkRebet = checkRebet;
                                    scope.close = function (result) {
                                        close(result);
                                    };
                                },
                            ],
                        })
                        .then(function (modal) {
                            // Show modal
                            modal.element.show();
                        });
                };

                $scope.refresh = function () {
                    $interval.cancel(refreshInterval);
                    loadData();
                };

                $scope.$on('SEVEN.TicketAdded', function () {
                    loadData(2000);
                });

                $scope.$on('SEVEN.TicketResolved', function () {
                    loadData(0, false, false, true);
                });

                $scope.$on('SEVEN.RoundFinished', function () {
                    loadData(1000);
                });

                $scope.$on('SEVEN.RoundChanged', function (evt, data) {
                    if (moment().diff(loadDataTimestamp) > 3000) {
                        if (data.state) {
                            loadData(1000, false, false, true);
                        } else {
                            loadData(1000);
                        }
                    }
                });

                $scope.$on('SEVEN.RoundDataChanged', function (event, data) {
                    if (data) {
                        $timeout(
                            function () {
                                setBetState(data);
                            },
                            data.delay ? data.delay : 0
                        );
                    }
                });

                $scope.$on('SEVEN.UserLogin', function (event, logged) {
                    if (logged) {
                        loadData(1000);
                    } else {
                        clearData();
                    }
                });

                $scope.$on('$destroy', function () {
                    $interval.cancel(refreshInterval);
                });

                init();
            }],
        };
    }]
);

SEVEN.service('SEVENTicketsRebet', ['$filter', function ($filter) {
    return {
        activeExternalTicket: null,

        setExternalRebet: function (ticket) {
            this.activeExternalTicket = ticket;
        },

        getExternalRebet: function () {
            return this.activeExternalTicket;
        },

        resetExternalRebet: function () {
            this.activeExternalTicket = null;
        },

        getTicketFormat: function (ticket, type) {
            return this.convertBet(ticket, type);
        },

        convertBet: function (ticket) {
            var items = [];
            ticket.bets.forEach(function (bet) {
                bet.rebet = true;
                bet.rounds = bet.numEvents;
                items.push(bet);
            });
            return items;
        },

        recreateFutureBets: function (ticket) {
            var oldBets = ticket.bets;
            var newBets = {};
            var groups = {};
            var finalBets = [];
            var tmpBet;
            var sameIdBets;

            // Group bets by type and value
            oldBets.forEach(function (bet) {
                groups[bet.value + '-' + bet.type] = groups[bet.value + '-' + bet.type] || [];
                groups[bet.value + '-' + bet.type].push(bet);
            });

            for (var group in groups) {
                groups[group].sort(function (a, b) {
                    return a.round - b.round;
                });

                // Get bets from each group with same id and move them
                // to separate arrays in newBets object
                while (groups[group].length) {
                    tmpBet = groups[group][0];
                    sameIdBets = $filter('filter')(groups[group], { round: tmpBet.round }, true);
                    groups[group].splice(0, sameIdBets.length);

                    sameIdBets.forEach(function (bet, idx) {
                        newBets[group + '/' + idx] = newBets[group + '/' + idx] || [];
                        newBets[group + '/' + idx].push(bet);
                    });
                }
            }

            // Merge bets that were played as future to one bet
            for (var i in newBets) {
                newBets[i][0].numEvents = newBets[i].length;
                finalBets.push(newBets[i][0]);
            }

            return finalBets;
        },
    };
}]);

SEVEN.directive('sevenTooltip', ['SEVENSettings', function (SEVENSettings) {
    var settings = SEVENSettings;

    return {
        restrict: 'E',
        templateUrl: settings.directory.app + 'shared/tooltip/view.html',
        scope: {
            template: '@template',
            data: '=data',
        },
        link: function (scope, elem) {
            scope.isShown = false;

            elem.bind('mouseover mouseout', function () {
                scope.$apply(function () {
                    scope.isShown = !scope.isShown;
                });
            });
        },
    };
}]);

angular.module('SEVEN').directive('patternWithoutWhitespace', ['$parse', 'SEVENHelpers', function ($parse, SEVENHelpers) {
    var cleanSpaces = SEVENHelpers.cleanSpaces;

    return {
        restrict: 'A',
        require: 'ngModel',
        link: function (scope, elem, attr, ctrl) {
            if (!ctrl) return;

            var parsePattern = function (regex) {
                if (!regex) return undefined;

                if (typeof regex === 'string') {
                    return new RegExp('^' + regex + '$');
                }

                return regex;
            };

            var regexp = parsePattern($parse(attr.patternWithoutWhitespace)(scope));

            attr.$observe('patternWithoutWhitespace', function (newVal) {
                var oldRegexp = regexp;

                regexp = parsePattern($parse(newVal)(scope));

                if ((oldRegexp && oldRegexp.toString()) !== (regexp && regexp.toString())) {
                    ctrl.$validate();
                }
            });

            ctrl.$validators.patternWithoutWhitespace = function (modelValue, viewValue) {
                return (
                    ctrl.$isEmpty(viewValue) ||
                    angular.isUndefined(regexp) ||
                    regexp.test(cleanSpaces(viewValue))
                );
            };
        },
    };
}]);

// VideoJS component
// Add component example: <seven-video-js sources="sources"></seven-video-js>
SEVEN.component('sevenVideoJs', {
    template: function () {
        return ['<video id="{{model.id}}" class="video-js vjs-default-skin"></video>'].join('');
    },
    controller: /*@ngInject*/ ['$log', '$q', '$window', '$document', '$timeout', '$ocLazyLoad', 'uuid', 'SEVENSettings', function (
        $log,
        $q,
        $window,
        $document,
        $timeout,
        $ocLazyLoad,
        uuid,
        SEVENSettings
    ) {
        var vm = this,
            libraryBaseUrl = SEVENSettings.assetsBase + 'gravity/videojs/7.4.0',
            player,
            hasSources;

        var init = function () {
            // Generate unique video element id
            vm.id = 'video-' + uuid.v4();
        };

        var bindingsChanges = function (changes) {
            var sourcesChanges = changes.sources;
            if (
                sourcesChanges.currentValue &&
                sourcesChanges.currentValue !== sourcesChanges.previousValue
            ) {
                // Lazy load library
                loadLibrary([
                    libraryBaseUrl + '/bundle.min.js',
                    libraryBaseUrl + '/bundle.min.css',
                ]).then(function (library) {
                    $timeout(function () {
                        setupPlayer(library);
                    });
                });
            }
        };

        var cleanup = function () {
            if (player) {
                player.dispose();
            }
        };

        var loadLibrary = function (librarySource) {
            if ($window.videojs) {
                return $q(function (resolve) {
                    resolve($window.videojs);
                });
            } else {
                return $ocLazyLoad.load(librarySource).then(function () {
                    return $window.videojs;
                });
            }
        };

        var setupPlayer = function (library) {
            hasSources = vm.sources && vm.sources[0];

            var muted = angular.isDefined(vm.muted) ? vm.muted : true;
            var hasControls = vm.controls || $window.isMobile.any;

            if (player) {
                if (hasSources) {
                    player.src(vm.sources);
                } else {
                    player.pause();
                }
            } else {
                player = library(vm.id, {
                    muted: muted,
                    controls: hasControls,
                    autoplay: false,
                    playsinline: 1,
                    any: true,
                    aspectRatio: vm.aspectRatio || '16:9',
                    preload: 'auto',
                    techOrder: ['html5'],
                    controlBar: {
                        remainingTimeDisplay: false,
                    },
                    plugins: {
                        reloadSourceOnError: {},
                    },
                });

                player.ready(function () {
                    player.src(vm.sources);
                    player.play();

                    if (vm.fullscreenOnClick) {
                        // TODO: Check why this is not working
                        player.on('click', function () {
                            if (player.isFullscreen()) {
                                player.exitFullscreen();
                            } else {
                                player.requestFullscreen();
                            }
                        });
                    }

                    $log.info('[SEVEN] Video player started =>', player.currentSource());
                });
            }

            $window.addEventListener(
                'online',
                function () {
                    player.src(vm.sources);
                    player.play();
                },
                false
            );

            // Log player error
            player.on('error', function (error) {
                $log.error('[SEVEN] Video player error =>', error);
                player.pause();
            });

            player.on('pause', function () {
                player.one('play', function () {
                    player.src(vm.sources);
                    player.play();
                });
            });

            // Safety player dispose
            player.on('ended', function () {
                this.dispose();
            });
        };

        vm.$onInit = init;
        vm.$onChanges = bindingsChanges;
        vm.$onDestroy = cleanup;
    }],
    controllerAs: 'model',
    bindings: {
        aspectRatio: '@',
        fullscreenOnClick: '<',
        sources: '<',
        controls: '=',
        muted: '=',
    },
});

SEVEN.service(
    'SEVENXtremepush',
    ['$window', '$document', '$rootScope', 'SEVENModules', 'SEVENUser', function ($window, $document, $rootScope, SEVENModules, SEVENUser) {
        var plugins = SEVENModules.plugins;
        var plugin = plugins && plugins.xtremepush;
        var pluginEnabled = plugin && plugin.enabled;
        var pluginExecutableName = plugin && plugin.executable;
        var user = SEVENUser;

        var isPluginAvailable = function () {
            var pluginExecutable = pluginExecutableName && $window[pluginExecutableName];

            return pluginEnabled && pluginExecutable;
        };

        var setupListeners = function () {
            $document.on('click', function () {
                if (!isPluginAvailable()) return;

                $window[pluginExecutableName]('event', 'click');
            });

            $rootScope.$on('SEVEN.AnalyticsEvent', function (event, data) {
                if (!isPluginAvailable() || !data || !data.type) return;

                var contents = data && data.params && data.params.content;

                if (!contents) return;

                if (data.type === 'TicketUpdate') {
                    angular.forEach(contents, function (content) {
                        $window[pluginExecutableName]('tag', content.type, content.value);
                    });
                } else if (data.type === 'CasinoJackpot') {
                    var content = contents[0];
                    $window[pluginExecutableName]('event', data.params.category, content);
                }
            });

            $rootScope.$on('SEVEN.PlayerLoggedIn', function (event, data) {
                if (!isPluginAvailable() || !data.withCredentials) return;

                var profileUnwatch = $rootScope.$watch(
                    function () {
                        return user.profile && user.profile.verified;
                    },
                    function (profileVerified) {
                        if (!profileVerified) return;

                        if (!profileVerified.email || !profileVerified.identity) {
                            $window[pluginExecutableName]('event', 'login', {
                                emailVerified: profileVerified.email,
                                identityVerified: profileVerified.identity,
                            });
                        }

                        profileUnwatch();
                    }
                );
            });
        };

        return {
            init: function () {
                if (!pluginEnabled) return;

                setupListeners();
            },
            setUserId: function (userId) {
                if (!isPluginAvailable()) return;

                $window[pluginExecutableName]('set', 'user_id', userId);
            },
        };
    }]
);

SEVEN.service(
    'SEVENPlayerAuthApiService',
    ['$q', '$http', '$rootScope', 'locker', 'SEVENConfig', 'SEVENSettings', 'SEVENRoutes', 'SEVENHelpers', 'SEVENUser', 'SEVENToken', function (
        $q,
        $http,
        $rootScope,
        locker,
        SEVENConfig,
        SEVENSettings,
        SEVENRoutes,
        SEVENHelpers,
        SEVENUser,
        SEVENToken
    ) {
        var config = SEVENConfig,
            settings = SEVENSettings,
            helpers = SEVENHelpers,
            routes = SEVENRoutes,
            user = SEVENUser,
            tokenService = SEVENToken,
            AUTH_STATES = {
                NOT_AUTHORIZED: 'NOT_AUTHORIZED',
                AUTHORIZATION_PENDING: 'AUTHORIZATION_PENDING',
                AUTHORIZED: 'AUTHORIZED',
                AUTHORIZATION_IN_PROGRESS: 'AUTHORIZATION_IN_PROGRESS',
                REAUTHORIZATION_IN_PROGRESS: 'REAUTHORIZATION_IN_PROGRESS',
                DEAUTHORIZATION_IN_PROGRESS: 'DEAUTHORIZATION_IN_PROGRESS',
            },
            loginEntityUUID;

        return {
            _authState: tokenService.getToken()
                ? AUTH_STATES.AUTHORIZATION_PENDING
                : AUTH_STATES.NOT_AUTHORIZED,
            isAuthInProgress: function () {
                return this._authState === AUTH_STATES.AUTHORIZATION_IN_PROGRESS;
            },
            isAuthorizationPending: function () {
                return this._authState === AUTH_STATES.AUTHORIZATION_PENDING;
            },
            isDeAuthInProgress: function () {
                return this._authState === AUTH_STATES.DEAUTHORIZATION_IN_PROGRESS;
            },
            isReAuthInProgress: function () {
                return this._authState === AUTH_STATES.REAUTHORIZATION_IN_PROGRESS;
            },
            /**
             * @returns {Object}
             * @param {"email"|"nickname"} loginStrategy
             */
            getLoginValidation: function (loginStrategy) {
                var messages = config.messages.general;
                var usernameValidation =
                    loginStrategy === 'nickname'
                        ? {
                              name: 'username',
                              required: true,
                              minlength: 2,
                              maxlength: 60,
                          }
                        : {
                              name: 'username',
                              required: true,
                              minlength: 2,
                              maxlength: 60,
                              pattern: helpers.emailPattern(),
                          };

                return {
                    messages: {
                        required: messages.validationRequired,
                        minlength: messages.validationMinLength,
                        maxlength: messages.validationMaxLength,
                        pattern: messages.validationPattern,
                    },
                    username: usernameValidation,
                    password: {
                        required: true,
                        minlength: 8,
                        maxlength: 20,
                    },
                };
            },
            /**
             *
             * @param {Object} data
             * @param {String} data._username
             * @param {String} data._password
             * @param {String} [uuid]
             */
            login: function (data, uuid) {
                var self = this;
                // Check login flag and destroy data
                if (!settings.login) data = {};
                if (uuid) loginEntityUUID = uuid;
                this._authState = AUTH_STATES.AUTHORIZATION_IN_PROGRESS;

                return $http
                    .post(config.apiBase + routes.common.user.login.url, data, {
                        headers: {
                            'HTTP-X-SEVEN-CLUB-UUID': uuid || config.client.uuid,
                        },
                    })
                    .then(function (response) {
                        var success = response.status === 200;

                        self._authState = success
                            ? AUTH_STATES.AUTHORIZED
                            : AUTH_STATES.NOT_AUTHORIZED;
                        if (success) {
                            return {
                                profile: response.data,
                                token: response.headers('Access-Token'),
                                refreshToken: response.headers('x-nsft-iam-refresh-token'),
                            };
                        }
                        return response;
                    })
                    .catch(function (response) {
                        self._authState = AUTH_STATES.NOT_AUTHORIZED;

                        return response;
                    });
            },
            loginCheck: function () {
                var self = this;

                if (this.isDeAuthInProgress()) {
                    return $q.reject({
                        code: AUTH_STATES.DEAUTHORIZATION_IN_PROGRESS,
                    });
                } else if (!this.isReAuthInProgress()) {
                    this._authState = AUTH_STATES.AUTHORIZATION_IN_PROGRESS;
                }

                return $http
                    .get(config.apiBase + routes.common.user.validate.url, {
                        headers: {
                            'HTTP-X-SEVEN-CLUB-UUID': loginEntityUUID || config.client.uuid,
                        },
                    })
                    .then(function (response) {
                        var success = response.status === 200;

                        self._authState = success
                            ? AUTH_STATES.AUTHORIZED
                            : AUTH_STATES.NOT_AUTHORIZED;
                        if (success) {
                            // Save with new token
                            user.logged = true;
                            tokenService.token = response.headers('Access-Token');
                            user.saveLocal();
                            return {
                                data: response.data,
                                token: tokenService.getToken(),
                            };
                        }

                        return false;
                    })
                    .catch(function (error) {
                        self._authState = AUTH_STATES.NOT_AUTHORIZED;
                        $rootScope.$broadcast('SEVEN.AuthFailed');

                        throw error;
                    });
            },
            logout: function () {
                var self = this;
                var previousAuthState = this._authState;

                this._authState = AUTH_STATES.DEAUTHORIZATION_IN_PROGRESS;

                return $http
                    .get(config.apiBase + routes.common.user.logout.url)
                    .then(function (response) {
                        var success = response && response.status === 200;

                        self._authState = success ? AUTH_STATES.NOT_AUTHORIZED : previousAuthState;

                        return success;
                    })
                    .catch(function (error) {
                        self._authState = previousAuthState;
                        throw error;
                    });
            },

            getAuthToken: function () {
                return tokenService.getToken();
            },

            updateAccessAndRefreshTokens: function () {
                var self = this;
                var previousAuthState = this._authState;

                this._authState = AUTH_STATES.REAUTHORIZATION_IN_PROGRESS;

                return $http
                    .post(config.apiBase + routes.common.user.generateRefreshToken.url, {
                        refreshToken: locker.get('refreshToken'),
                    })
                    .then(function (response) {
                        user.setToken(response.data.accessToken);
                        locker.put('refreshToken', response.data.refreshToken);
                    })
                    .catch(function (error) {
                        self._authState = previousAuthState;
                        $rootScope.$broadcast('SEVEN.AuthFailed');

                        throw error;
                    });
            },
        };
    }]
);

SEVEN.service(
    'SEVENPlayerApiService',
    ['$http', '$log', 'SEVENConfig', 'SEVENSettings', 'SEVENRoutes', 'SEVENSentry', 'SEVENHelpers', function ($http, $log, SEVENConfig, SEVENSettings, SEVENRoutes, SEVENSentry, SEVENHelpers) {
        var config = SEVENConfig,
            settings = SEVENSettings,
            routes = SEVENRoutes,
            helpers = SEVENHelpers,
            thirdPartyParams = {};

        var throttledGetProfile = helpers.throttle(
            function (context) {
                return context.getProfile();
            },
            3000,
            { leading: true }
        );

        return {
            getBalance: function () {
                var errorCallback = function (err) {
                    $log.error('[SEVEN] Loading balance failed');
                    if (err && ![429, 403].includes(err.status)) {
                        SEVENSentry.captureMessage('Loading balance failed', err);
                    }
                    return {};
                };
                // Third-party balance
                if (
                    settings.mode === 'integration' &&
                    (settings.api.events.insufficientFunds || settings.api.listeners.updateBalance)
                ) {
                    if (settings.thirdPartyValues.clientVal) {
                        thirdPartyParams.clientVal = settings.thirdPartyValues.clientVal;
                    }
                    return $http
                        .get(config.apiBase + routes.common.player.balance.b2b.url, {
                            data: {},
                            headers: {
                                'X-NSFT-WALLET-USERGROUP': config.client.uuid,
                            },
                            params: thirdPartyParams,
                        })
                        .catch(errorCallback);
                }

                // Platform balance
                return $http
                    .get(config.apiBase + routes.web.player.balance.detail.url)
                    .catch(errorCallback);
            },
            getMessagesCount: function (time) {
                var request = {
                    method: 'GET',
                    url:
                        settings.api.gravity.url +
                        '/desk/v2/tickets/count/updated?time=' +
                        time.toISOString(),
                    headers: {
                        'X-Nsft-WebAPI-Company': settings.company.name,
                    },
                };

                return $http(request);
            },
            getProfile: function () {
                return $http.get(config.apiBase + routes.web.player.profile.detail.url);
            },
            throttledGetProfile: function () {
                return throttledGetProfile(this);
            },
        };
    }]
);

SEVEN.service('SEVENRegisterApiService', ['$http', 'SEVENConfig', 'SEVENRoutes', function ($http, SEVENConfig, SEVENRoutes) {
    var config = SEVENConfig,
        routes = SEVENRoutes;

    return {
        register: function (data, uuid) {
            var request;
            request = {
                method: routes.web.player.account.create.method,
                url: config.apiBase + routes.web.player.account.create.url,
                data: data,
                headers: {
                    'HTTP-X-SEVEN-CLUB-UUID': uuid || config.client.uuid,
                },
            };
            return $http(request);
        },
        forgotPassword: function (model, entityUuid) {
            var request = {
                method: routes.common.user.resetPasswordSendEmail.method,
                url: config.apiBase + routes.common.user.resetPasswordSendEmail.url,
                data: {
                    username: model.email,
                },
                headers: {
                    'HTTP-X-SEVEN-CLUB-UUID': entityUuid || config.client.uuid,
                },
            };

            return $http(request);
        },
    };
}]);

SEVEN.service(
    'SEVENMobileIntegrationApiService',
    ['$http', 'SEVENSettings', 'SEVENConfig', 'SEVENRoutes', 'SEVENToken', function ($http, SEVENSettings, SEVENConfig, SEVENRoutes, SEVENToken) {
        var settings = SEVENSettings,
            mobileRegistrationUrl = settings.api.mobileRegistration.url,
            config = SEVENConfig,
            tokenService = SEVENToken,
            routes = SEVENRoutes;

        return {
            register: function (data) {
                var request;
                request = {
                    headers: {
                        'HTTP-X-SEVEN-CLUB-UUID': config.client.uuid,
                        Authorization: 'Bearer ' + tokenService.getToken(),
                    },
                    method: routes.web.player.account.create.method,
                    url: mobileRegistrationUrl + '/api/v1/player/register',
                    data: data,
                };
                return $http(request);
            },

            forgotPassword: function (model) {
                var request = {
                    headers: {
                        'HTTP-X-SEVEN-CLUB-UUID': config.client.uuid,
                    },
                    method: routes.common.user.resetPasswordSendEmail.method,
                    url: mobileRegistrationUrl + '/api/v1/player/forgot-password',
                    data: {
                        mobileNumber: model.mobileNumber,
                    },
                };
                return $http(request);
            },
        };
    }]
);

SEVEN.service(
    'SEVENMobileIntegrationAuthApiService',
    ['$q', '$http', 'SEVENConfig', 'SEVENSettings', 'SEVENToken', 'SEVENRoutes', 'SEVENUser', 'locker', function ($q, $http, SEVENConfig, SEVENSettings, SEVENToken, SEVENRoutes, SEVENUser, locker) {
        var config = SEVENConfig,
            routes = SEVENRoutes,
            settings = SEVENSettings,
            user = SEVENUser,
            mobileRegistrationUrl = settings.api.mobileRegistration.url,
            tokenService = SEVENToken,
            commonHeaders = {
                'HTTP-X-SEVEN-CLUB-UUID': config.client.uuid,
                Authorization: 'Bearer ' + tokenService.getToken(),
            },
            AUTH_STATES = {
                NOT_AUTHORIZED: 'NOT_AUTHORIZED',
                AUTHORIZED: 'AUTHORIZED',
                AUTHORIZATION_IN_PROGRESS: 'AUTHORIZATION_IN_PROGRESS',
                REAUTHORIZATION_IN_PROGRESS: 'REAUTHORIZATION_IN_PROGRESS',
                DEAUTHORIZATION_IN_PROGRESS: 'DEAUTHORIZATION_IN_PROGRESS',
            };

        return {
            _authState: AUTH_STATES.NOT_AUTHORIZED,
            isDeAuthInProgress: function () {
                return this._authState === AUTH_STATES.DEAUTHORIZATION_IN_PROGRESS;
            },
            isReAuthInProgress: function () {
                return this._authState === AUTH_STATES.REAUTHORIZATION_IN_PROGRESS;
            },
            /**
             * @returns {Object}
             * @param {"mobileNumber"}
             */
            getLoginValidation: function () {
                var messages = config.messages.general;

                return {
                    messages: {
                        required: messages.validationRequired,
                        minlength: messages.validationMinLength,
                        maxlength: messages.validationMaxLength,
                        pattern: messages.validationPattern,
                    },
                    password: {
                        required: true,
                        minlength: 8,
                        maxlength: 20,
                    },
                };
            },
            /**
             *
             * @param {Object} data
             * @param {String} data.mobileNumber
             * @param {String} data.password
             */
            login: function (data) {
                var self = this;
                // Check login flag and destroy data
                if (!settings.login) data = {};
                this._authState = AUTH_STATES.AUTHORIZATION_IN_PROGRESS;

                return $http
                    .post(mobileRegistrationUrl + '/api/v1/player/login', data, {
                        headers: commonHeaders,
                    })
                    .then(function (response) {
                        var success = response.status === 200;

                        self._authState = success
                            ? AUTH_STATES.AUTHORIZED
                            : AUTH_STATES.NOT_AUTHORIZED;
                        if (success) {
                            return {
                                profile: response.data,
                                token: response.headers('Access-Token'),
                                refreshToken: response.headers('x-nsft-iam-refresh-token'),
                            };
                        }
                        return response;
                    })
                    .catch(function (response) {
                        self._authState = AUTH_STATES.NOT_AUTHORIZED;

                        return response;
                    });
            },
            loginCheck: function () {
                var self = this;

                if (this.isDeAuthInProgress()) {
                    return $q.reject({
                        code: AUTH_STATES.DEAUTHORIZATION_IN_PROGRESS,
                    });
                } else if (!this.isReAuthInProgress()) {
                    this._authState = AUTH_STATES.AUTHORIZATION_IN_PROGRESS;
                }

                return $http
                    .get(config.apiBase + routes.common.user.validate.url)
                    .then(function (response) {
                        var success = response.status === 200;

                        self._authState = success
                            ? AUTH_STATES.AUTHORIZED
                            : AUTH_STATES.NOT_AUTHORIZED;
                        if (success) {
                            // Save with new token
                            user.logged = true;
                            tokenService.token = response.headers('Access-Token');
                            user.saveLocal();
                            return {
                                data: response.data,
                                token: tokenService.getToken(),
                            };
                        }

                        return false;
                    })
                    .catch(function (error) {
                        self._authState = AUTH_STATES.NOT_AUTHORIZED;

                        throw error;
                    });
            },
            logout: function () {
                var self = this;
                var previousAuthState = this._authState;

                this._authState = AUTH_STATES.DEAUTHORIZATION_IN_PROGRESS;

                return $http
                    .get(config.apiBase + routes.common.user.logout.url)
                    .then(function (response) {
                        var loggedOut = response && response.status === 200;

                        self._authState = loggedOut
                            ? AUTH_STATES.NOT_AUTHORIZED
                            : previousAuthState;

                        return loggedOut;
                    })
                    .catch(function (error) {
                        self._authState = previousAuthState;
                        throw error;
                    });
            },
            getAuthToken: function () {
                return tokenService.getToken();
            },
            updateAccessAndRefreshTokens: function () {
                var self = this;
                var previousAuthState = this._authState;

                this._authState = AUTH_STATES.REAUTHORIZATION_IN_PROGRESS;
                return $http
                    .post(config.apiBase + routes.common.user.generateRefreshToken.url, {
                        refreshToken: locker.get('refreshToken'),
                    })
                    .then(function (response) {
                        user.setToken(response.data.accessToken);
                        locker.put('refreshToken', response.data.refreshToken);
                    })
                    .catch(function (error) {
                        self._authState = previousAuthState;

                        throw error;
                    });
            },
            isPhoneNumberVerified: function (mobileNumber) {
                return $http({
                    method: 'GET',
                    url: mobileRegistrationUrl + '/api/v1/player/is-verified',
                    headers: commonHeaders,
                    params: { mobileNumber: mobileNumber },
                })
                    .then(function (response) {
                        return response.data;
                    })
                    .catch(function (error) {
                        return error;
                    });
            },
        };
    }]
);
